@import '~styles/base.scss';

.LegendWrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0;
  flex-wrap: wrap;

  li {
    list-style-type: none;

    button {
      display: flex;
      align-items: center;
      color: $of-winter-green-dream;
      border: none;
      background: none;
    }
  }

  .LegendColor {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 6px;
  }
}
