
.User {
  line-height: 2;
  word-break: break-all;
  word-break: break-word;
  position: relative;
}

.AddButton {
  display: inline-flex;
  padding: 0 0.2rem 0.1rem 0;
  vertical-align: middle;

  > :first-child {
    align-self: center;
  }

  > :last-child {
    margin-left: 0 !important;
  }
}

.ActionLink {
  > :last-child {
    margin-left: 0.25rem !important;
  }
}
