@import '~styles/base.scss';

.InputContainer {
  @include flex(1);

  display: flex;

  &.Disabled {
    textarea {
      background: $of-inactive;
      border-color: $of-inactive;
      color: $of-forest-green-03;
      cursor: not-allowed;

      &:hover {
        border-color: $of-inactive;
      }
    }
  }
}

.TextArea {
  @include flex(1);

  cursor: default !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .Label {
    color: $of-forest-green-01;
    cursor: default !important;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 15px;
    padding-bottom: 0.25rem;
  }

  .InputField {
    @include flex(1);

    border-radius: 6px;
    border: 1px solid $of-dark-green-04;
    color: $of-forest-green-01;
    display: flex;
    font-size: 14px !important;
    letter-spacing: -0.02em;
    line-height: 15px;
    padding: 13px;
    resize: none;
    margin-bottom: 0;

    &:focus-within,
    &:focus,
    &:active {
      border: 1px solid $of-forest-green-01;
      color: $of-forest-green-01;
      outline-color: transparent;
      outline-style: none;
    }

    &:hover {
      border-color: $of-dark-green-02;
    }

    &::placeholder {
      color: $of-winter-green-dream;
    }

    &.Error {
      border: 1px solid $of-red-02;
      color: $of-red-02;
    }
  }

  .ResizeBoth {
    resize: both;
  }

  .ResizeVertical {
    resize: vertical;
  }

  .ResizeHorizontal {
    resize: horizontal;
  }
}

.ErrorMessage {
  color: $of-red-02;
  cursor: default !important;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0.25rem 0 0.25rem 0;
  min-height: 0.85rem;
  font-weight: 400;
}

.DropdownValuesField {
  margin: 8px 0 0 !important;

  span {
    color: $of-forest-green-01 !important;
    font-size: 12px !important;
    font-style: italic;
    font-weight: 400;
  }
}
