@import '~styles/base.scss';

.ExportButton {
  border: 1px solid $of-forest-green-05;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  color: $of-forest-green-04;

  &:hover,
  &:focus {
    border: 1px solid $of-forest-green-04;
    background-color: $of-forest-green-04;
    color: white;
  }
}

.CheckboxContainer {
  margin: 5px 0 25px 0;

  .Checkbox {
    padding-left: 5px;
  }
}

.TooltipText {
  width: auto;
}

.NoData {
  @include flex(1);
}

.ErrorMessage {
  color: $of-red-01;
  background-color: $of-red-05;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 0.2rem;
  margin-top: 6rem;

  .ErrorHeader {
    font-weight: 500;
  }
}

.RequestButton {
  margin-left: 15px;
}

.LoadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  padding: 50px;
}
