@import '~styles/base.scss';

:root {
  --of-forest-green-03: #{$of-forest-green-03};
  --of-inactive: #{$of-inactive};
}

.SignButton {
  margin: 8px 0;
  display: inline-block;
  height: 44px;
  padding: 0 32px;
  line-height: 44px;

  :first-child {
    display: inline-block;
  }
}

.TextFieldSelectFieldContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 34px;

  .TextField {
    flex: 3;
    margin-right: 6px;
    position: relative;

    input {
      padding-right: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .CharacterCount {
      position: absolute;
      font-size: 12px;
      right: 10px;
      top: 32px;
      color: $of-forest-green-03;

      &.Warning {
        color: $of-red-02;
      }
    }
  }

  .FontSelectField {
    flex: 1;
    height: 100%;
    margin: 0 0 0 6px;

    :global(.ReactSelect__control) {
      width: 140px;
      border-radius: 6px;
      height: 43px;
      margin-top: 19px;
    }

    :global(.ReactSelect__value-container) {
      font-size: 13px;
    }

    :global(.ReactSelect__menu) {
      width: 200px;
      right: 0;
      margin-top: 5px;
      border-radius: 7px;
      border-color: $of-forest-green-05;
    }

    :global(.ReactSelect__single-value) {
      width: 140px;
    }
  }

  @include respondTo(sm, max) {
    flex-direction: column;
    height: auto;
    margin-bottom: 0;

    .TextField {
      flex: 1;
      margin: 0;
      order: 3;
    }

    .FontSelectField {
      flex: 1;
      height: 100%;
      margin: 0;
      order: 1;

      :global(.ReactSelect__control) {
        width: 100%;
        border-radius: 6px;
        height: 43px;
        margin-top: 0;
      }

      :global(.ReactSelect__value-container) {
        font-size: 13px;
      }

      :global(.ReactSelect__menu) {
        width: 100%;
        right: 0;
        margin-top: 5px;
        border-radius: 7px;
        border-color: $of-forest-green-05;
      }

      :global(.ReactSelect__single-value) {
        width: 100%;
      }
    }

    .input[type="text"].SignatureTextPreviewInput,
    .Canvas {
      flex: 2;
      order: 2;
      margin: -5px 0 5px 0;
    }
  }
}

.RadiobuttonContainer {
  display: flex;
  margin: 20px 0 25px 0;

  @include respondTo(sm, max) {
    margin: 0 0 15px 0;
  }

  .Radiobutton {
    margin: 0 8px 0 0;
    -webkit-appearance: none;
    appearance: none;
    color: $of-forest-green-01;
    width: 16px;
    height: 16px;
    border: none;
    background-color: #fff !important; //needed to override bootstrap
    outline: 0.1em solid $of-winter-green-dream;
    border-radius: 50%;
    transform: translateY(-1px);
    display: inline-grid;
    place-content: center;
    vertical-align: middle;

    &:hover {
      outline-color: $of-forest-green-01;
    }

    &:focus {
      outline: 0.1em solid $of-forest-green-01;
      outline-offset: 0;
    }

    &:disabled {
      cursor: default;
      opacity: 1;
      background-color: $of-inactive !important; //needed to override bootstrap
      outline-color: $of-forest-green-05;

      :hover {
        outline-color: $of-forest-green-05;
      }
    }

    &:checked {
      border: 0.35em solid $of-forest-green-01;
      outline-color: $of-forest-green-01;

      &:hover {
        outline-color: $of-forest-green-01;
      }

      &:disabled {
        border-color: $of-inactive;
        background-color: $of-forest-green-04 !important; //needed to override bootstrap
        outline-color: $of-forest-green-05;

        &:hover {
          outline-color: $of-forest-green-05;
        }
      }
    }
  }

  label {
    margin: 4px 4px 4px 0;
    font-size: 16px;
    font-weight: 400;

    &:first-child {
      margin-right: 52px;
    }

    @include respondTo(sm, max) {
      font-size: 16px;

      &:first-child {
        margin-right: 36px;
      }
    }
  }
}

.Canvas {
  display: block;
  background-color: $of-inactive;
  border-radius: 12.8px;
}

input[type="text"].SignatureTextPreviewInput {
  // box config
  margin: 5px 0;
  padding: 10px 5px;
  border: 0;
  border-bottom: 1px solid black;
  outline: none;
  border-radius: 0;
  box-shadow: none;

  // size
  height: 80px;
  width: 100%;

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

input[readonly].SignatureTextPreviewInput {
  outline: none;
  background-color: transparent !important;
  box-shadow: none;
}

.ClearButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.DisclaimerText {
  color: $of-forest-green-01;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  justify-self: self-start;
  margin-bottom: 4px;
}

.SignButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ActionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2em 0 2em;
  align-self: center;
}

.CustomOption {
  padding: 8px 18px 8px 18px;

  &:hover {
    cursor: pointer;
    background: $of-dark-green-05;
  }

  &:first-child {
    margin-top: 10px;
  }
}

.CustomOptionText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  padding: 10px 1px;
}

.TryAgainButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.AsteriskStyle {
  margin-left: 2px;
  display: inline;
  color: $of-red-02;
  font-size: 13px;
}

.FullnameLabel {
  color: $of-dark-green-02;
}
