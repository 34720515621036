@import '~styles/base.scss';

.TrashBanner {
  display: flex;
  gap: 4px;
  background-color: $of-red-05;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 10px;
  border-radius: 8px;
  min-height: 36px;
}

.EmptyState {
  .Paragraph {
    font-weight: 500;
    font-size: 1rem;
  }
}

.LoadingSpinner {
  height: 100%;
  display: flex;
  align-self: center;
}

.TrashDocumentsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.TableContainer {
  flex: 1 1;
}

.Bold {
  font-weight: 500;
}

.InfoTooltipMessage {
  max-width: 320px;
  padding: 16px 26px;
  width: 268px;
}

.TrashedDocumentList {
  padding: unset;
  padding-bottom: 1.2rem;
}

.TrashTime {
  width: 300px;
  word-wrap: break-word;
}

.CreatedTime {
  width: 80px;
}

.DocumentTitleContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.State {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.Draft {
    background-color: $of-dark-green-05;
  }

  &.Signed {
    background-color: $of-green-01;
  }

  &.Pending {
    background-color: $of-blue-02;
  }

  &.Overdue {
    background-color: $of-contract-overdue-new;
  }

  &.Declined,
  &.Canceled,
  &.Ended,
  &.SignedAndTerminated {
    background-color: $of-red-02;
  }

  .Approved,
  .ApprovalPending {
    background-color: $of-purple-02;
  }
}

.EmptyTrashLoading {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  padding-top: 14px;
  color: $of-forest-green-01;
  height: fit-content;

  > svg {
    color: #2e6da4;
  }
}
