@import '~styles/base.scss';

.Groups {
  .Name {
    display: inline-block;
    word-break: break-all;
    word-break: break-word;
  }

  .Buttons {
    margin: auto 0;
  }

  .ContentHeader {
    margin: 2rem;
  }
}
