@import '~styles/base.scss';

.Description {
  font-size: 0.875rem;
  color: $of-forest-green-01;
  margin-bottom: 16px;
}

.PhoneNumberContainer {
  height: 83px;
  display: flex;
  margin-top: 18px;
}

.ActionsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.SkipButton {
  color: white;
  background-color: $of-winter-green-dream;
  border: 1px solid $of-winter-green-dream;
  border-radius: 4px;
  height: 44px;
  padding: 14px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background-color: $of-dark-green-01;
  }
}
