@import '~styles/base.scss';

.ButtonDropdownContainer {
  display: flex;
  height: 42px;

  .ButtonNextToDropdown {
    > [class*=DisabledButtonWrapper] {
      height: 100%;
    }

    [class*=label] {
      font-size: 0.875rem;
    }
  }

  .ButtonNextToDropdown, //custom button
  .ButtonNextToDropdown > button, //custom button
  .ButtonNextToDropdown > [class*=DisabledButtonWrapper] > button {
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
  }
}

.TriggerButton {
  height: 42px;
  width: 42px;

  > [class*=Button] {
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
    border-left: solid 1px rgba($of-forest-green-01, 0.3);
  }

  &.Disabled {
    > [class*=Button] {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: $of-disable;
      color: $of-forest-green-04;
      border: solid 1px $of-forest-green-05;
      border-left: unset;
    }
  }
}
