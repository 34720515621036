@import '~styles/base.scss';

$bubble-size: 46px;

.Label {
  width: $bubble-size;
  margin-left: -$bubble-size;
  text-align: center;
  color: $of-forest-green-01;

  &:has(svg) {
    display: flex;
    justify-content: center;
  }

  &.WhiteLabel {
    color: white;
  }

  &.EmptyLabelIcon {
    > svg {
      padding-bottom: 8px;

      > path:nth-child(3) {
        display: none;
      }
    }
  }
}
