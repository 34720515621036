@import '~styles/base.scss';

.ExtensionConfigurationHeader {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .ConnectionIcon {
    color: $of-green-01;
    margin-left: 5px;
  }
}

.ExtensionConfigurationBody {
  margin-top: 0;
  padding-bottom: 10px; /* padding to avoid margins from collapsing */
}

.AuthButton {
  align-self: flex-start;
  margin-top: 10px;
}

.LoginLink {
  color: $of-peace;
}
