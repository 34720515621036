@import '~styles/base.scss';

.Buttons {
  display: flex;

  & > * {
    margin-left: 0.3rem;
  }
}

.Loading {
  margin: 2em auto;
}

.LinkButton {
  display: inline-block;
}

.SmsSignContainer {
  text-align: center;
  margin: 0 auto;
}

.SignCodeInputContainer {
  width: 150px;
  padding-top: 12px;
  margin: auto;

  svg {
    margin-right: 8px;
    margin-left: 8px;
  }
}

.SmsSignError {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  svg {
    width: 72px;
    height: 72px;
    color: $of-passion;
  }
}

.SignButton {
  height: 42px;
  padding: 0 32px;
}
