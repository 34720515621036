@import '~styles/base.scss';

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  min-height: 40px;
  flex: 1 1;

  button {
    margin: 0 12px;
  }
}

.DeleteLink {
  button {
    color: $of-red-02 !important;
  }
}

.DeleteWarning {
  padding: 0.6rem 0 0 0.75rem;
}

.DeleteWarningMobileLarge {
  padding: 0.75rem 0 0 0.75rem;
}

.DeleteWarningMobileSmall {
  padding: 0 0 0 0.75rem;
}

.DeleteLinkMobile {
  button {
    padding-top: 10px;
    margin-left: 4px;
  }
}
