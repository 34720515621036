@import '~styles/base.scss';

.InvitationHeader {
  font-size: 1.125rem;
  color: $of-forest-green-01;
  padding: 0 18px 4px 18px;
  margin: 0;
  letter-spacing: 0.2px;
  font-weight: 500;
}
