@import '~styles/base.scss';

.Divider {
  margin: 24px 0;
  border-bottom-color: $of-dark-green-05;
}

.FormattingForm {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .FormattingSubmitButton {
    margin-top: 12px;
    margin-bottom: 9px;
    align-self: flex-end;
    max-width: fit-content;
    min-width: 60px;
    height: 34px;
    opacity: 1 !important;
  }

  [class*="DisabledButtonWrapper"] {
    align-self: flex-end;

    .FormattingSubmitButton {
      background-color: $of-inactive !important;
    }
  }

  :global(.ReactSelect__single-value--is-disabled),
  :global(.ReactSelect__indicators) {
    color: $of-forest-green-04 !important;
  }

  :global(.ReactSelect__control--is-disabled) {
    background-color: $of-inactive;

    &:hover {
      cursor: not-allowed !important;
    }
  }
}

.FormattingOptions {
  padding: 0 12px;

  :global(.ReactSelect .ReactSelect__control) {
    min-height: 32px;
    margin-bottom: 10px;
  }
}

.FormattingInfo {
  margin-bottom: 16px;
  padding-top: 0;
  font-size: 0.8125rem;
  color: $of-winter-green-dream;
}

.DisabledFormatting {
  label > span {
    color: $of-forest-green-04 !important;
  }
}

.Label {
  font-size: 0.875rem;
  font-weight: 600;
}
