.ExtensionConfigurationHeader {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ExtensionConfigurationBody {
  margin-top: 0;
  padding-bottom: 10px; /* padding to avoid margins from collapsing */
}

.AmountType {
  display: inline-flex;
  align-items: center;
  padding-bottom: 10px;

  .ModalButton {
    margin-left: 0.3rem;
  }

  .Label {
    font-weight: 600;
    margin-right: 0.5rem;
  }
}
