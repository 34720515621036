@import '~styles/base.scss';

.DragControls {
  position: absolute;
  top: 24px;
  left: -24px;
  width: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Chevron {
  height: 10px;
  width: 25px;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  background: transparent;
}

.DragHandler {
  cursor: grab;
}

.Chevron,
.DragHandler {
  display: flex;
  justify-content: center;
  color: $of-forest-green-03;

  &:hover {
    color: $of-forest-green-01;
  }
}
