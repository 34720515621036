@import '~styles/base.scss';

.StateSelector {
  flex: none;
  align-self: center;
  width: 95px;
  font-size: 14px;
  height: 36px;
  background: none;

  &Resolved {
    background-color: $of-edit-hover;
    border-color: $of-yellow-03;

    &:hover,
    &:focus,
    &:focus-within,
    &[aria-expanded=true],
    [data-side=bottom] {
      border-color: $of-yellow-03;
    }
  }
}

.SelectItem {
  cursor: default;

  &Resolved {
    border-top: 1px solid $of-yellow-03 !important;
  }
}
