@import '~styles/base.scss';

.ExpandableArea {
  margin-bottom: 10px;

  .Hidden {
    display: none;
  }

  .Row {
    display: flex;
    margin: 0.25rem 0;

    > * {
      flex: 1;

      + * {
        margin-left: 1rem;
      }
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;

      > * + * {
        margin-left: unset;
      }
    }
  }

  .SigningDetailsArea {
    padding: 24px 0;
  }

  .SectionHeader {
    font-size: 16px;
    background-color: $of-dark-green-05;

    &:hover {
      background-color: $of-dark-green-04;
    }
  }
}
