.TableContainer {
  position: relative;

  table td {
    background-color: white;
    word-break: break-word;
    background-clip: padding-box;
  }
}

table.ShowBordersTable > {
  tbody > tr > td {
    border-color: #ddd !important;
  }
}

table.HiddenBordersTable:not(.EditableTable) > {
  tbody > tr > td {
    border-color: transparent !important;
  }
}

.EditableTable.HiddenBordersTable {
  &:hover,
  &:global(.resizing-table) {
    & > tbody > tr > td {
      border-color: rgba(221, 221, 221, 0.4) !important;
    }
  }

  &:not(:hover):not(:global(.resizing-table)) {
    & > tbody > tr > td {
      border-color: transparent !important;
    }
  }
}
