@import '~styles/base.scss';

.PreviewButton {
  font-weight: 500;
  border-radius: 8px;
  height: 42px;
  padding: 0 16px;
  transition: ease-in-out 100ms;
  background-color: white;

  &:hover {
    border-color: $of-forest-green-04 !important;
    background-color: $of-dark-green-05;
  }
}
