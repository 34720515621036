@import '~styles/base.scss';

.Party {
  margin: 0 0 28px 0;

  .Container {
    border: 1px solid $of-dark-green-04;
    border-radius: 7px;

    // Logic that handles border top of each participant of company
    div + div[data-state] > div[class*=ParticipantContainer],
    .Header + div[data-state] > div[class*=ParticipantContainer] {
      border-top: 1px solid $of-dark-green-04;
      border-radius: unset;
    }

    .InternalApprovers {
      border-bottom: 1px solid $of-dark-green-05;

      .List > div > div[class*=ParticipantContainer] {
        border-radius: unset;
      }

      .ApproverHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $of-purple-02;
        padding: 12px 20px;
        border-radius: 0;
        border-top: 1px solid $of-dark-green-05;

        &:hover {
          background-color: $of-purple-02;
        }

        h2 {
          color: $of-forest-green-01;
          font-size: 15px;
          font-weight: 600;
          margin: 0 10px 0 0;
        }
      }

      .ColleaguesHeader {
        color: $of-forest-green-01;
        background-color: $of-green-05;
        padding: 12px 20px;
        font-size: 15px;
        font-weight: 600;
        border-radius: 0;
        border-top: 1px solid $of-dark-green-05;
        margin: 0;

        &:hover {
          background-color: $of-green-05;
        }
      }
    }

    .NotEditable {
      pointer-events: none;

      &:hover {
        cursor: inherit;
      }
    }

    .Preview {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 8px;
    }

    .Header {
      display: flex;
      background: $of-green-05;
      padding: 12px 20px;
      border-radius: 7px 7px 0 0;
      justify-content: space-between;
      align-items: center;

      .Edit {
        display: none;
        visibility: hidden;
      }

      &:hover {
        background-color: $of-edit-hover;
        cursor: pointer;

        .Edit {
          width: 40px;
          visibility: visible;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }
      }
    }

    .IsAiExtracted {
      flex-wrap: wrap;
    }

    .Heading {
      color: $of-forest-green-01;
      margin: 0;
      font-size: 18px;
      max-width: 236px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      pointer-events: none;
    }
  }

  .BorderTopRounded {
    border-radius: 7px 7px 0 0 !important;
  }

  .BorderBottomRounded {
    border-radius: 0 0 7px 7px !important;
  }

  .AddButton {
    width: 100%;
  }

  .Row {
    display: flex;
    gap: 10px;
  }
}

.BottomMargin {
  margin-bottom: 20px;
}

.CounterpartiesTitle {
  font-size: 19px;
  color: var(--color-forest-green-01);
  margin-bottom: 16px;
}

.Tooltip {
  width: 320px;
}

.TooltipContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.Divider {
  border: 0;
  opacity: 0.5;
  background-color: $of-forest-green-04;
  width: 100%;
  height: 0.5px;
  margin: 18px 0;
}

.IconTextWrapper {
  display: flex;
  gap: 8px;
}

.TooltipBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.TextContainer {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.CompanyName {
  font-size: 1.125rem;
  font-weight: 600;
  color: $of-forest-green-01;
  line-height: normal;
}

.IconText {
  font-size: 0.75rem;
  font-weight: 500;
  color: $of-winter-green-dream;
  margin-bottom: 2px;
}

.Text {
  font-size: 0.875rem;
  color: $of-forest-green-01;
  line-height: 0.75rem;
}

.Icon {
  height: 11px;
  width: 11px;
  color: $of-winter-green-dream;
  margin-top: 2px;
}

.PreviewMessage {
  color: $of-winter-green-dream;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.ImportSparkle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: min-content;
  width: 100%;
}

.PartySparkle {
  margin: 4px 0;
}
