@import '~styles/base.scss';

.AddFolder {
  cursor: pointer;
  padding: 0;
}

.Tree {
  padding-bottom: 24px;

  .Header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .Title {
      color: $of-forest-green-01;
      font-size: 20px;
      font-weight: 500;
      margin: 0;

      &.Small {
        font-size: 14px;
      }
    }

    .AddFolder {
      display: flex;

      span {
        align-items: center;
        color: $of-forest-green-02;
        display: flex;
        margin-left: 8px;
      }
    }
  }
}

.BranchContainer {
  max-height: 400px;
  overflow: auto;
}
