@import '~styles/base.scss';

.LifecycleEvent {
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 3px;
  max-width: clamp(50px, 100vw, 300px);
  cursor: default !important;

  &.Green {
    background-color: #ecf9f3;
  }

  &.Blue {
    background-color: #edf5fa;
  }

  &.Red {
    background-color: #fbeced;
  }

  &.Orange {
    background-color: #ffefde;
  }

  &.Purple {
    background-color: #f6ecfc;
  }

  &.Yellow {
    background-color: #fffaef;
  }

  &:hover {
    box-shadow: (0 0 8px rgba($of-forest-green-01, 0.2));

    svg {
      opacity: 1;
    }
  }

  .EventType {
    min-width: 6px;
    padding: 0;
    margin: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.Green .EventType {
    background-color: $of-green-01;
  }

  &.Blue .EventType {
    background-color: $of-blue-03;
  }

  &.Red .EventType {
    background-color: $of-red-02;
  }

  &.Orange .EventType {
    background-color: $of-contract-overdue-new;
  }

  &.Yellow .EventType {
    background-color: $of-yellow;
  }

  &.Purple .EventType {
    background-color: $of-purple;
  }

  .EventInformation {
    color: $of-thunder;
    padding: 4px 8px;
    border-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    max-width: calc(100% - 6px);

    @include flex(1);

    .EventLinkContainer {
      display: flex;
      width: 100%;
      font-size: 12px;

      .EventLink {
        display: inline-block;
        margin-bottom: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.TooltipContainer {
  width: block;
  max-width: 400px;
  min-width: 140px;
  font-size: 0.75rem;
  pointer-events: none;
}

.TooltipContent:nth-child(2) {
  font-size: 0.7rem;
  font-weight: 300;
}

:global(.rbc-event[type="popup"]) {
  .LifecycleEvent {
    min-width: 250px;
  }

  .EventInformation {
    max-width: calc(100% - 26px);
  }
}
