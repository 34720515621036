.EventInsight {
  display: flex;
  align-items: center;
  font-size: 10px;

  & > svg {
    margin-right: 2px;
  }

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
