@import '~styles/base.scss';

.EmptyList {
  box-sizing: border-box;
  min-height: 380px;
  padding: 20px 0;
  margin-bottom: 22px;
  background-color: white;
  border-radius: 8px;
}
