@import '~styles/base.scss';

.TooltipInfo {
  display: inline-flex !important;
  margin-left: 0.3rem;
  background-color: transparent;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $of-dark-green-05;
  }

  .Icon {
    display: flex;
    color: $of-forest-green-01;
  }
}

.Message {
  text-align: start;
  margin: 16px 10px;
  max-width: 250px;
  font-size: 0.75rem;
}

.DisabledIcon {
  color: $of-forest-green-03;
}
