@import '~styles/base.scss';

.Link {
  align-items: center;
  display: flex;
  height: 100%;

  @include respondTo(md, min) {
    margin-left: 8px;
  }
}

.LogoOneflow {
  margin-left: 9px;
}
