.Warnings {
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 1rem;
  }
}

.FolderTreeBranchContainer {
  max-height: 45vh;
}
