@import '~styles/base.scss';

@mixin center-with-position-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

:export {
  darkgray: $of-dark-green-05; //My participant
}

.MyParticipantBubble {
  span:first-of-type {
    font-size: 24px;
    color: $of-forest-green-01 !important;
    margin-left: -64px;
    margin-left: 0 !important;

    @include center-with-position-absolute;
  }

  @include center-with-position-absolute;

  width: 66px;
  z-index: 1;
}

.GuestConversion {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: delayedFadeIn 1.5s ease;

  p:first-of-type {
    margin: 0;
  }

  .FieldContainer {
    width: 60%;
    text-align: left;
  }
}

.ModalContainerHeight {
  height: 400px;
}

.ModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .SigningContainer {
    text-align: center;
    font-size: 14px;

    p {
      margin: 0;
    }

    .ConfirmText {
      display: flex;
      justify-content: center;

      span {
        width: 90%;
      }
    }

    .Icon {
      margin-bottom: 0.5em;
    }

    .LinkContainer {
      display: inline-block;
      margin: auto;
      margin-top: 30px;
      text-decoration: underline;

      > svg {
        display: inline-block;
        position: relative;
        top: 3px;
        margin-right: 5px;
        max-height: 16px;
      }

      .LinkText {
        display: inline-block;
        text-decoration: underline;

        &:hover {
          color: lighten($of-forest-green-01, 10%);
        }
      }
    }

    .DisabledLinkContainer {
      pointer-events: none;
      color: $of-dark-green-03;
    }

    .SignEmailContainer {
      font-size: 14px;
      margin-top: 16px;
      padding-top: 16px;
      border-top: solid 1px #ccc;

      p {
        margin-bottom: 10px;
      }
    }
  }
}

.ButtonContainer > button {
  margin: auto;
  margin-top: 25px;
}

.SuccessCheckAnimation {
  z-index: 2;
  position: relative;
  animation: fadeIn 0.3s ease;
  margin: auto;
  margin-top: 0.2rem;
  margin-bottom: 1em;
  width: 99px;
  height: 99px;
  background-image: url('./success-check.gif');
  background-size: 99px;

  &Container {
    position: relative;
  }
}

.Heading {
  color: $of-forest-green-01;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 2px;
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  animation: delayedFadeIn 1.5s ease;

  > * {
    margin-left: 10px;
  }
}

.SignRedirectBody {
  text-align: center;

  a {
    color: $of-blue-02;
  }
}
