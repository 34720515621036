@import '~styles/base.scss';

.Description {
  display: flex;
  margin: 12px 0;

  svg {
    margin-right: 4px;
    width: 14px;
  }
}

.TooltipMessage {
  padding: 10px;
  width: auto;
}

.Badge {
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-right: 8px;
  padding: 0 8px;
  padding-right: 10px;
  font-size: 14px;
  color: $of-forest-green-01;

  .Icon {
    display: flex;
    margin-right: 4px;

    svg {
      height: 14px;
    }
  }

  &.Internal {
    background: #ffefd6;

    svg {
      fill: $of-contract-overdue-new;
    }
  }

  &.Public {
    background: #cbeaff;

    svg {
      color: $of-blue-03;
    }
  }
}
