.DialogContent {
  background-color: white;
  border-radius: 6px;
  padding: 16px;
  box-shadow: 0 5px 5px -3px rgba(black, 0.2), 0 8px 10px 1px rgba(black, 0.14), 0 3px 14px 2px rgba(black, 0.12);
}

/* It is svg */
.DialogArrow {
  fill: white;
}
