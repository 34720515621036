@import '~styles/base.scss';

.ProcessingStatus {
  display: flex;
  background-color: black;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  color: #f0f1f7;
  width: 100%;
  height: 0;
}

.ProcessingStatusContainer {
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(md, max) {
    font-size: 0.825rem;
  }

  .Actions {
    font-size: 1rem;
    padding-top: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @include respondTo(md, max) {
      font-size: 0.825rem;
      padding-top: 0.2rem;
    }
  }
}

.ProcessingStatusMessage {
  padding-top: 0.825rem;
  text-align: center;

  @include respondTo(md, max) {
    padding-top: 0.4rem;
  }
}
