@import '~styles/base.scss';

.UpdateProductItemTriggerButton {
  word-wrap: break-word;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  cursor: pointer;

  &.ResponsiveView {
    width: 100%;
    height: 100%;
    min-height: 10px;
  }

  &:empty {
    /* prevents height being 0 when price value is empty and
       Reduce the clickable area
     */
    width: 100%;
    height: 100%;
    min-height: 10px;
  }

  &:disabled {
    cursor: default;
  }
}

.UpdateProductItemContent {
  max-width: 365px;
  min-width: 365px;
  overflow-y: auto;

  @include respondToNewBreakPoint(small) {
    height: var(--radix-popper-available-height);
  }

  &[class*=DialogContent] {
    background: transparent;
  }

  &[class*=PopoverContent] {
    background: transparent;
    max-height: 614px;

    @include respondToNewBreakPoint(small) {
      height: var(--radix-popover-content-available-height);
    }
  }
}

.PopupBody {
  overflow: inherit;
  display: flex;
  flex-direction: column;
  height: inherit;
  max-height: inherit;
}

.PopupHeader {
  border-radius: 8px 8px 0 0;
  padding: 10px;
  background-color: $of-forest-green-01;
  color: white;
  font-size: var(--document-static-font-size);
}

.SectionDivider {
  margin: 0;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid $of-forest-green-05;
  width: 100%;
}

.FormLayout {
  padding-block: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  flex: 1;
  height: inherit;

  /* Block spacing */

  /* All fields are gapped by 16px */
  > * + * {
    padding-block-start: 16px;
  }

  /* Except those proceed after SectionDivider
      By 10 px = 16 - 5 - 1 = (
      default padding top - section divider padding top - section divider border-bottom
      )
  */
  > * + .SectionDivider + * {
    padding-block-start: 10px;
  }

  .SectionDivider {
    padding-block-start: 5px;
  }

  /* Inline spacing */
  > *:not(.SectionDivider, .ClearSelectedContainer, .RemoveProductContainer) {
    padding-inline: 10px;
  }
}

.RemoveProductContainer {
  width: fit-content;
}

.FieldLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  gap: 8px;
  min-width: 0;
}

.PriceFields {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.RelativePosition {
  position: relative;
}

.InputFieldLabel {
  display: flex;
  min-width: 0;
  gap: 4px;
  padding-inline-start: 1px;
  font-weight: 600;
  font-size: var(--document-static-font-size);
  color: $of-forest-green-01;

  .LabelText {
    line-height: 20px;
    min-width: 0;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.InputField {
  max-height: 35px;
  box-shadow: none;

  > input.Input {
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 6px;
    padding: 6px;
    max-height: 29px;
    color: $of-forest-green-01;
    background-color: #fff;
    border: 1px solid $of-forest-green-05;
    line-height: 1.2;
    outline: 0;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
  }

  // To increase the specificity against legacy css and bootstrap
  > input[type="text"].Input {
    height: 35px;
    font-size: var(--document-static-font-size) !important;
    font-weight: 400;
  }

  > input[type="number"].Input {
    height: 35px;
    font-size: var(--document-static-font-size) !important;
    font-weight: 400;
  }

  > input[type="text"]:invalid {
    border: 1px solid $of-red-02;
    color: $of-red-02;
  }

  > input[type="number"]:invalid {
    border: 1px solid $of-red-02;
    color: $of-red-02;
  }

  > input[type="text"][aria-invalid="true"].Input {
    border: 1px solid $of-red-02;
    color: $of-red-02;
  }

  > input[type="number"][aria-invalid="true"].Input {
    border: 1px solid $of-red-02;
    color: $of-red-02;
  }
}

label[class*="Label"] {
  .CheckBoxLabelText {
    font-size: var(--document-static-font-size);
    font-weight: 600;
    color: $of-forest-green-01;
  }
}

input[type="checkbox"].CheckBox {
  margin: 0;
  width: 14px;
  height: 14px;
  accent-color: $of-forest-green-01;
}

.ClearButton,
.RemoveButton {
  color: $of-red-02;
  padding: 0;
  padding-inline-start: 10px;
  border: 0;
}

.ValidationMessage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  color: $of-red-02;
  line-height: 0.75rem;
  font-size: 0.75rem;
  min-height: 0.85rem;
  cursor: default !important;
}

.FormActions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.RemoveProductContainer,
.FieldLayout,
.FormActions {
  background-color: #fff;
}

.HiddenButton {
  display: none;
}

@media (max-width: 698px) {
  .UpdateProductItemContent {
    min-width: calc(100% - 30px);
  }
}
