@import '~styles/base.scss';

.DataFieldSort {
  color: $of-forest-green-01 !important;
  padding-left: 0;
  padding-top: 0;

  > span {
    margin-left: 0.25rem !important;
  }
}
