.Title {
  font-weight: 500;
  font-size: 1.125rem;
}

.ModalContent {
  padding-top: 18px;
  font-size: 0.8125rem;
}

.Modal [class*=ErrorMessage]:empty {
  display: none;
}

.FieldDescription {
  font-size: 0.6875rem;
  font-style: italic;
  font-weight: 400;
}
