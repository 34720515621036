@import '~styles/base.scss';

.ButtonRadiusRounded {
  border-radius: 7px;
}

.ButtonRadiusHalfRounded {
  border-radius: 0 0 7px 7px;
}

.ButtonFormerColleague {
  margin-top: 28px;
}

.AddButtonIcon {
  margin-right: 0.3rem;
}
