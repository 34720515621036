@import "styles/base.scss";

.InputContainer {
  position: relative;
  display: flex;
}

.LoadingSpinnerIcon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: $of-forest-green-04;
}
