@import '~styles/base.scss';

.ExpandableOptionsHeader {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid $of-dark-green-05;
  background-color: $of-green-05;
  color: $of-forest-green-01;
  border-radius: 7px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 1px;
  height: 47px;

  .ExpandableOptionsHeaderIcon {
    margin-right: 0.3rem;
  }
}

.AdditionalOptions {
  padding: 0;
  font-size: 19px;
  line-height: 23px;
  font-weight: 500;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: transparent !important;
  }
}

.Expandable {
  pointer-events: all;
  cursor: pointer;
  flex-direction: row-reverse;

  &:hover {
    background-color: $of-forest-green-05;
  }
}
