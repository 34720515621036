@import '~styles/base.scss';

.OutlineButton {
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }

  .ArrowBack {
    margin-right: 0.5rem;
  }
}

.AttachmentSignatures {
  padding-bottom: 1rem;
  width: 100%;

  & > div {
    padding: 0 16px;
  }

  ul {
    list-style-type: disc;
  }
}

.SignButton {
  height: 42px;
  padding: 0 32px;
}

.Actions {
  display: flex;
  justify-content: center;
  width: 100%;
}

.BackToTheContractButton {
  padding-left: 0.6rem;
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
}
