@import '~styles/base.scss';

.SpinnerContainer {
  margin: auto;
}

.ErrorMessage {
  color: $of-passion;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  .ErrorIcon {
    margin: 0 0.5rem 0 0;
  }
}
