@import '~styles/base.scss';

@keyframes moveContent {
  from {
    transform: translateY(233px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes delayedFadeOut {
  0% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.GuestConversion {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;

  p:first-of-type {
    margin: 0;
  }

  .Heading {
    color: $of-forest-green-01;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 2px;
  }

  .CompanyNameParagraph {
    width: 384px;
    text-align: center;
  }

  .FadingParagraph {
    animation: fadeIn 500ms ease;
  }

  .FieldContainer {
    width: 265px;
    text-align: left;
  }

  .DocumentsIcon {
    position: absolute;
  }

  .Hidden {
    opacity: 0;
  }

  .DelayedFadein {
    animation: delayedFadeIn 700ms ease;
  }

  .DelayedFadeOut {
    animation: delayedFadeOut 700ms ease;
  }
}

.FadeIn {
  animation: fadeIn 600ms ease;
}

.MoveContent {
  animation: moveContent 500ms ease;
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  > * {
    margin-left: 10px;
  }
}
