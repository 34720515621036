@import '~styles/base.scss';

.ExpandedLayoutContainer {
  --tabs-toolbar-width: 48px;
  --tabs-toolbar-offset-right: 20px;

  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.AdminSidebar {
  width: 72px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $of-forest-green-01;
}

.DocumentLayout {
  display: flex;
  flex-direction: column;
  width: calc(100% - $sidebar-width);

  &.isGuest {
    width: 100%;
  }
}

.Topbar {
  height: 80px;
  width: 100%;
  border: 1px solid $of-forest-green-05;
}

.ContentAndSidebarContainer {
  display: flex;
  flex: 1;
  height: 100%;
}

.ToolbarAndContent {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.EditorToolbar {
  height: 48px;
  background-color: white;
  flex-shrink: 0;
}

.ContentWrapper {
  display: grid;
  // auto is for the content and 0 for the toast provider
  grid-auto-rows: auto 0;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  // for its child to be scrollable in case of overflow
  flex-basis: 0;
}

.Scrollable {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $of-inactive;
}

.ContentContainer {
  --padding-x: 50px;

  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--padding-x);
  padding-right: calc(var(--tabs-toolbar-width) + var(--tabs-toolbar-offset-right) + var(--padding-x));
  box-sizing: border-box;
}

.Content {
  max-width: var(--document-content-max-width);
  width: 100%;
  padding: 60px 0;
  font-size: 0.875rem;
  line-height: 2rem;
  box-sizing: border-box;

  [class*=ContractId] {
    padding-bottom: 20px;
  }
}

.TabsToolbarWhenSidebarExpanded {
  right: 380px;
}

.BottomCenter {
  bottom: 1rem;
}
