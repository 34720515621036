$gradient-shadow: linear-gradient(
  45deg,
  #ffd063,
  #db686f,
  #ac79a4,
  #509dd1,
  #ac79a4,
  #db686f
);

@keyframes animate-gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.Shadow,
.ShadowOnHover {
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $gradient-shadow;
    background-size: 400%;
    border-radius: inherit;
    transform: translate3d(1px, 1px, 0) scale(1.01);
    filter: blur(10px);
    transition: opacity 200ms ease-in-out;
    animation: animate-gradient 20s linear infinite;
  }

  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }
}

.ShadowOnHover {
  &::before {
    opacity: 0;
  }

  &:hover,
  &:focus {
    &::before,
    &::after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
}
