@import '~styles/base.scss';

.TemplateItem {
  width: 130px;
  text-align: center;
  padding-bottom: 1rem;

  &:not(:last-child) {
    padding-right: 0.8rem;
  }

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Info {
      color: $of-dark-green-03;
      margin-right: 0.3rem;
    }

    .InfoSelected {
      color: $of-forest-green-01;
    }
  }

  .TemplateItemBox {
    border-radius: 0.3rem;
    height: 10rem;
    cursor: pointer;
    color: $of-forest-green-01;
    background-color: $of-forest-green-05;
    outline: none;

    &.Selected {
      background-color: $of-forest-green-01;
    }

    .TemplateItemTitle {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 1rem;
      font-weight: 500;
      user-select: none;

      &.Selected {
        color: white;
      }

      .Icon {
        align-self: center;
        margin-bottom: 10px;
      }
    }

    .Checkbox {
      text-align: right;
      padding: 0.4rem;
      color: white;
    }
  }

  .Preview {
    display: none; // flex; we want to hide it for now
    flex-direction: row;
    margin-top: 8px;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    .PreviewIcon {
      margin-right: 4px;
    }
  }
}
