@import '~styles/base.scss';

.AdminPage {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 $spacing-page $spacing-page;
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .Title {
    align-items: center;
    color: $of-forest-green-01;
    display: flex;
    font-size: 30px;
    font-weight: 600;
    height: 48px;
    padding-top: 15px;
  }

  .WorkspaceContainer {
    align-items: center;
    color: $of-forest-green-01;
    display: flex;
    font-size: 13px;
    font-weight: 500;

    svg {
      margin-right: 6px;
    }
  }

  .MainPage {
    display: flex;
  }

  .Title + .MainPage {
    margin-top: 15px;
  }

  .CalendarPage {
    min-width: 0;
  }

  .Content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;

    .TabsContainer {
      display: flex;

      .Tab {
        padding: 1.5rem 0 1.3rem 0;
        margin: 0 0 0 2rem;
        text-decoration: none;
        color: $of-winter-green-dream;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        border-bottom: 2px solid transparent;
        gap: 4px;

        &.Active {
          color: $of-forest-green-01;
          border-bottom: 2px solid $of-forest-green-01;
        }

        &:hover {
          color: $of-forest-green-01;
          border-bottom-color: $of-forest-green-01;
        }

        .Icon {
          margin-left: 5px;
        }
      }
    }

    .Container {
      background-color: white;
      box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
      flex: 1;
      min-width: 0;
      border-radius: 8px;
    }
  }
}
