.ChangePassword {
  margin-top: -14px;
}

.Header {
  font-weight: 500;
  font-size: 1.1rem;
}

.Row {
  width: 100%;
  max-width: 660px;
}

.EmailRow {
  display: flex;
  gap: 11px;

  > [class*='ChangePending'] {
    position: relative;
    top: calc(49px + (43px / 2));
    transform: translateY(-50%);
  }
}

.EmailField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1;
}
