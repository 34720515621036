@import '~styles/base.scss';

.PopupHeader {
  border-radius: 8px 8px 0 0;
  padding: 10px;
  background-color: $of-forest-green-01;
  color: white;
  font-size: var(--document-static-font-size);
}

.PopupFormContainer {
  color: $of-forest-green-01;
}

.PopupFormElement {
  padding: 15px 15px 0 10px;
}

.PopupTextFieldLabel {
  padding-bottom: 9px;

  span {
    font-weight: 600;
    font-size: var(--document-static-font-size);
    color: $of-forest-green-01;
  }
}

.PopupTextField {
  max-height: 25px;
  padding: 6px;
  font-size: var(--document-static-font-size);

  input {
    padding: 0 !important;
    border-radius: 0 !important;
  }
}

.ActionButtonsContainer {
  padding: 15px 15px 15px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button span {
    font-size: var(--document-static-font-size);
  }

  & > * {
    flex-basis: 0;
  }
}

.HiddenButton {
  display: none;
}
