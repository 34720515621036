.BodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.BodyContainerText {
  text-align: center;
  margin-bottom: 45px;
}

.WarningIcon {
  width: 74px;
  height: 74px;
  margin: 45px 0;
}
