@import '~styles/base.scss';

.AccountContainer {
  .Name {
    font-weight: 500;
  }

  .Email {
    font-size: 0.75rem;
    margin-top: 0.375rem;
  }

  .AdministratorIcon {
    position: relative;
    margin-left: 5px;
    margin-right: 3px;
    top: 4px;
  }

  .AccountRole {
    display: flex;
    word-break: break-word;
  }

  .ContentHeader {
    margin: 2rem;
  }

  .Header {
    font-weight: 600;
    font-size: 1.125rem;
    margin: 1rem 0;
  }

  .ActionBarText {
    margin-left: 2rem;
    color: $of-forest-green-01;

    .Header {
      font-weight: 600;
      font-size: 0.875rem;
      margin: 1rem 0;
    }
  }

  .ButtonsContainer {
    display: flex;
    gap: 18px;
  }

  .Buttons {
    margin: auto 0;
  }

  .EmptyState {
    padding: 4rem 2rem;
  }
}
