@import '~styles/base.scss';

.StateContainer {
  display: flex;
  gap: 6px;
  align-items: center;
}

.State,
.TemplateState {
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 4px 9px;
  border: unset;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.State {
  @media only screen and (min-width: 390px) and (max-width: 744px) {
    max-width: 75px;

    &:only-child {
      max-width: unset;
    }
  }

  @media only screen and (min-width: 340px) and (max-width: 390px) {
    max-width: 32px;

    &:only-child {
      max-width: unset;
    }
  }

  @media only screen and (max-width: 340px) {
    max-width: 25px;

    &:only-child {
      max-width: unset;
    }
  }
}

.StateFullWidth {
  max-width: unset;
}

.Draft,
.ImportedDraft,
.UnpublishedTemplate {
  background-color: $of-dark-green-05;
  color: $of-forest-green-01;
}

.Pending {
  background-color: $of-blue-07;
  color: $of-blue-01;
}

.Signed,
.MarkedAsSigned {
  background-color: $of-green-12;
  color: $of-green-13;
}

.Declined {
  background-color: $of-red-05;
  color: $of-red-02;
}

.Template {
  background-color: $of-red-10;
  color: $of-forest-green-01;
}

.Terminated,
.Declined,
.MarkedAsDeclined,
.Canceled,
.Ended {
  background-color: $of-red-11;
  color: $of-red-01;
}

.Overdue {
  background-color: $of-orange-04;
  color: $of-red-01;
}

.Active,
.PublishedTemplate {
  background-color: $of-green-03;
  color: $of-green-13;
}

.Approved,
.ApprovalPending {
  background-color: $of-purple-02;
  color: $of-purple-01;
}

.RightArrowIcon {
  color: $of-winter-green-dream;
}
