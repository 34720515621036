@import '~styles/base.scss';

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.SelectOption {
  cursor: pointer;
  border-radius: 6px;
  padding-block: 10px;
  padding-inline: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  line-height: 1;
  color: $of-forest-green-01;
  max-width: 100%;
}

.OptionName {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 0;
}

.SelectFieldTrigger {
  cursor: pointer;
  background-color: white;
  color: $of-forest-green-01;
  border: none;
  padding: 2px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 30ch;
  font-size: 0.875rem;
  text-align: start;
  border-radius: 6px;
  height: 40px;

  &:active {
    outline: 1px solid $of-forest-green-01;
    background-color: $of-dark-green-05;
  }

  &:hover {
    background-color: $of-dark-green-04;
  }

  &:disabled {
    cursor: default;
  }

  .SelectOption {
    overflow: hidden;
  }

  span {
    display: flex;
    flex-grow: 1;
    min-width: 0;
  }
}

.BrokenFieldTriggerLabel {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: $of-red-02;
}

.SelectContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: 8px 6px 20px 0 rgba(0, 0, 0, 0.1490196078);
  border: 1px solid rgba(0, 0, 0, 0.05);
  transform-origin: 100% 0;
  z-index: 1000;
  max-width: 30ch;
  text-align: start;
  min-width: var(--radix-select-trigger-width);
}

.SelectContent[data-state='open'] {
  animation: scaleIn 100ms ease-out;
}

.SelectContent[data-state='closed'] {
  animation: scaleIn 100ms ease-in;
}

.SelectedIcon {
  display: none;
}

.SelectItem {
  padding-inline-end: 4px;
  background-color: white;
  color: $of-forest-green-02;

  .SelectOption {
    border-radius: 6px;

    &:hover {
      background-color: rgba($of-forest-green-01, 0.08);
    }
  }

  &[aria-selected="true"],
  &[data-state="checked"] {
    .SelectOption {
      background-color: $of-blue-05;
    }

    & .SelectedIcon {
      display: block;
      align-self: center;
      // Parent is flex container
      flex-shrink: 0;
    }
  }
}

.ScrollButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
}

.SelectViewPort {
  padding: 4px;
  max-height: min(var(--radix-select-content-available-height), 320px);
  overflow: scroll;
}

.TooltipMessage {
  text-align: start;
  overflow: auto;
  white-space: normal;
  word-break: break-word;
  font-size: 0.75rem;
}
