@import '~styles/base.scss';

.ActionBarText {
  margin-left: 2rem;
  color: $of-forest-green-01;

  .Header {
    margin: 0;
    font-weight: 500;
    font-size: 1.1rem;
  }

  .Content {
    margin-top: 7px;
  }
}
