@import '~styles/base.scss';

.Download {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: $spacing-page;
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .Heading {
    font-weight: 600;
  }

  .Icon,
  .Alert {
    margin: 2rem 0;
  }

  .Alert {
    color: $of-contract-overdue-new;
  }

  .Link {
    margin-left: 0.25rem;
  }

  .Container {
    height: 32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
    border-radius: 8px;

    .TextBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5rem;

      p,
      ul {
        font-size: 20px;
        margin: 0.5rem 0;
      }
    }
  }
}
