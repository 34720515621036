@import '~styles/base.scss';

.SwedishBankIDButtons {
  // TODO: remove when migrated from backbone to react
  font-family: 'Work Sans', sans-serif;
  gap: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Cancel {
  color: $of-forest-green-01;
}

.SwedishBankIDLabel {
  margin: 0;
  text-align: center;
  font-weight: 500;
}

.SwedishBankIDButton {
  padding: 14px;
  height: 44px;
  width: 100%;
  max-width: 140px;
  border-radius: 4px;
}

.BankIDSameDeviceButton {
  margin-top: 7px;
  color: $of-blue-02;
  font-weight: 400;
  max-width: 200px;
}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
