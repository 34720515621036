@import '~styles/base.scss';

.WorkspaceButton {
  width: 48px;
  height: 48px;
  max-width: 250px;
  max-height: 44px;
  background-color: $of-forest-green-01;
  border: none;
  border-radius: 8px !important;
  color: $of-forest-green-05 !important;
  padding: 8px !important;
  letter-spacing: 0;

  > [class*=_label] {
    color: $of-forest-green-05;
    font-weight: 500;
    margin-right: 6px !important;
  }

  &:hover {
    background-color: rgba(214, 223, 226, 0.1) !important;

    > [class*=_label] {
      color: white;
    }
  }

  &:focus-visible {
    outline: auto !important;
    outline-offset: unset;
  }

  @include respondTo(md, max) {
    max-width: none;
    border-radius: 6px !important;
    width: 100%;
  }
}

.WorkspaceSelectDropdown {
  border-radius: 8px;
}

.WorkspaceButtonIcon {
  margin-right: 8px;
  margin-bottom: 3px;
  vertical-align: middle;
  color: white;
}

.WorkspaceDropdown {
  min-width: 350px;
  max-width: none !important;
  position: absolute;
  top: 131px;
  margin-top: -10px !important;
  z-index: 2;

  @include respondTo(md, max) {
    width: calc(100% - 24px);
  }
}

.EmptyState {
  [class*=Content] {
    padding: 0 10px;
  }
}
