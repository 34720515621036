.CloseButton {
  color: white;
  margin-left: auto;
  padding: 0;
}

.Reversed {
  transform: rotateY(180deg);
}

.SidebarLogoExpanded {
  > a {
    margin: 0 auto;

    &:focus-visible {
      outline: auto;
      outline-offset: 4px;
    }
  }
}
