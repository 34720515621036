.SizeAuto {
  width: auto;
  height: auto;
}

.FluidHeight {
  height: 100% !important;
  padding-top: 0 !important;

  & > video {
    position: relative;
  }
}
