@import '~styles/base.scss';

.PlanSummation {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $of-cloud;
}

.CheckboxContainer {
  margin-top: 1.3rem;
}

.Buttons {
  display: flex;

  & > * {
    margin-left: 0.3rem;
  }
}

.BottomContainer {
  margin-top: 18px;
}
