@import '~styles/base.scss';

.BlankIcon {
  width: 14px;
  height: 14px;
}

.CustomLabel {
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
