@import '~styles/base.scss';

.Anchor {
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.PopoverContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 1030 !important;
  }

  .Popover {
    width: 360px;
    padding: 8px 12px;
    transform-origin: var(--radix-popover-content-transform-origin);
    animation: scaleIn 0.15s ease-out;
  }
}
