@import '~styles/base.scss';

.Container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 13px 32px;
  background: $of-purple-02;
  color: $of-purple-03;
  border-radius: 8px;

  &.Collapsed {
    padding: 13px 16px;
  }
}
