@import '~styles/base.scss';

.Video {
  margin-top: 14px;

  :global(.video-js .vjs-tech) {
    position: relative;
  }
}

.VideoProcessingLink {
  color: $of-blue-02;
  margin-top: 8px;
  text-align: left;
  display: flex;
  align-items: center;

  .ClockIcon {
    margin-right: 6px;
  }
}
