@import '~styles/base.scss';

.InputContainer {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #d6dfe2;
  display: flex;
  flex: 1;
  max-width: calc(100% - 40px);
  padding: 4px;
  white-space: nowrap;

  &:hover {
    text-decoration: none !important;
  }

  &.Error {
    border: 1px solid $of-red-02;
    color: $of-red-02;
  }

  input {
    border: none !important;
    box-shadow: none !important;
    flex: 1;
    height: auto !important;
    line-height: 1.15;
    margin: 0;
    outline: none !important;
    overflow: hidden;
    padding: 1px 2px;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.Chars {
  font-size: 12px;
  padding-left: 8px;
}
