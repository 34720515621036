@import '~styles/base.scss';

.ExtensionContentHeader {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ExtensionContentBody {
  margin-top: 0;

  /* padding to avoid margins from collapsing */
  padding-bottom: 10px;

  ul {
    margin: 0;

    /* to reset stupid list rules */
    padding-left: 15px;

    a {
      color: $of-blue-02;
    }
  }
}
