@import '~styles/base.scss';

:export {
  desktopmin: $contract-view-desktop-min;
  mobilemax: $contract-view-mobile-max;
}

.NoticeBanner {
  padding: 0 10px;
  background-color: $of-red-11;
  color: $of-forest-green-01;
  box-shadow: 0 1px 1px 0 #0000001a;
  height: var(--freemium-banner-height-expanded);
  display: flex;
  align-items: center;
  gap: 6px;
  max-width: calc(100vw - 20px);
}

.NoticeBannerMobile {
  height: var(--freemium-banner-height-collapsed);
  margin-bottom: 4px;
}

.IconContainer {
  display: flex;
  color: $of-red-02;
}
