@import '~styles/base.scss';

.TagContainer {
  display: flex;
}

.TagColumn {
  max-width: 260px;
}

.PaginationContainer {
  display: flex;
  min-height: 2rem;
  padding: 0 2rem 1rem;

  & > :first-child {
    padding: 0;
    margin-right: 0.8rem;

    @include flex(1);
  }
}
