@import '~styles/base.scss';

.ScaledDroppableDragOverlay {
  width: fit-content;
  cursor: grabbing;
}

.DroppableDragOverlayFieldWrapper {
  width: 320px;
  margin: 0;
  padding: 0;
}

.DataFieldName {
  overflow-wrap: break-word;
  max-width: 95%;
  position: absolute;
  background-color: $of-blue-02;
  color: white;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 1;
  font-size: 0.75rem;
  padding: 6px;
  bottom: calc(100% + 4px);
  border-radius: 6px;
}

.DroppableDragOverlayField {
  background-color: white;
  color: var(--formatting-font-color);
  font-family: var(--formatting-font-family);
  font-size: var(--formatting-font-size);
  border: 1px solid $of-blue-02;
  border-radius: 6px;
  padding: 4px;
  line-height: normal;
  min-height: 28px;
  word-break: break-word;
}

.FieldEmpty {
  font-weight: bold;
  font-style: italic;
}
