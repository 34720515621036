@import '~styles/base.scss';

.Expandable {
  .Hidden {
    display: none;
  }

  .Visible {
    display: block;
  }

  .Icon {
    height: 8px;
    margin-left: 4px;
  }
}
