@import '~styles/base.scss';

.Tooltip {
  background: $of-forest-green-01;
  border-radius: 4px;
  padding: 8px;
  color: white;
}

.BarChartContainer {
  :global(.recharts-tooltip-cursor) {
    visibility: hidden !important;
  }
}

.LegendWrapper {
  margin-top: 40px;
}
