
.Prefix,
.FormattedPrice,
.Postfix {
  display: inline-block;
}

.FormattedPrice {
  min-width: max-content;
}
