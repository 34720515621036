@import '~styles/base.scss';

.ReadOnlyLockIcon {
  color: $of-winter-green-dream;
  display: flex;
  align-items: center;
}

.QuantityInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .ReadOnlyLockIcon {
    visibility: hidden;
    pointer-events: none;

    &.HintVisible {
      visibility: visible;
    }
  }

  > div:first-child {
    flex-basis: 100%;
  }
}

.NumberQuantityInputWrapper {
  --input-padding: 4px;
  --input-padding-inline-end: 18px;

  display: flex;

  .ReadOnlyLockIcon {
    margin-inline-start: -20px;
  }

  input {
    color: var(--formatting-font-color);
  }
}

.NumberQuantityInputWrapperResponsive {
  padding-top: 0;
}

.NumberQuantityInputExpanded {
  text-align: center;
  border: 1px solid $of-dark-green-03 !important;
  background-color: #fff;
  font-family: var(--formatting-font-family);
  height: auto;
  min-height: 31.7px; //baseline height for description with default font size and formatting
  box-shadow: inset 0 1px 1px transparent !important;
  line-height: 1.2;
  outline: none;
  box-sizing: border-box;
  display: inline-block;

  &:hover {
    border: 1px solid $of-dark-green-01 !important;
  }

  &[type=text] {
    border-radius: 6px;
    margin: 0;
    padding: var(--input-padding);
    padding-right: var(--input-padding-inline-end);
    color: var(--formatting-font-color);
    width: clamp(100%, 9* var(--formatting-font-size), 150px);
    max-width: max(150px, calc(8 * var(--formatting-font-size)));
    min-width: min(25px, calc(2 * var(--formatting-font-size)));

    &:focus {
      box-shadow: inset 0 1px 1px #fff !important;
    }
  }
}

.Error {
  border: 1px solid $of-red-02 !important;
  color: $of-red-02 !important;
  box-shadow: inset 0 0 0 transparent !important;

  &:hover {
    border: 1px solid $of-red-02 !important;
    color: $of-red-02 !important;
    box-shadow: inset 0 0 0 transparent !important;
  }
}

.NumberQuantityInputResponsive {
  &[type=text] {
    line-height: 0.8rem;
    text-align: right;
    margin: 0;
    background-color: #fff !important;
    border: 1px solid $of-forest-green-04;
    border-radius: 6px;
    box-shadow: inset 0 0 0 transparent !important;
    width: clamp(100%, 9* var(--formatting-font-size), 150px);
    height: auto;
    transition: border linear 0.1s, padding-right linear 0.1s;
    outline: 0;
    box-sizing: border-box;
    display: inline-block;
    padding: var(--input-padding);
    padding-right: var(--input-padding-inline-end);

    &:focus {
      cursor: text;
      box-shadow: inset 0 0 0 transparent !important;
    }
  }
}

.NumberQuantityDisabledExpanded {
  padding-top: 1px;
  text-align: center;
  width: 100%;
}

.NumberQuantityDisabledResponsive {
  padding: 0;
  margin: 0;
  text-align: right;
  width: 100%;
}

.InvalidTooltipMessage {
  text-align: start;
  width: 100%;
  max-width: 100px;
}

.ProductTableCheckBoxWrapper {
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
  }
}

.ProductTableCheckBoxWrapperResponsive {
  label {
    padding: 0;
  }

  label div:first-child {
    border: 1px solid $of-forest-green-04 !important;
  }
}

.ProductTableRadioItem {
  label {
    padding-top: 8px;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      margin: 0 !important;
      background-color: #fff;
    }
  }
}

.ProductTableRadioItemResponsive {
  label {
    padding-top: 0;

    input {
      margin: 0 !important;
      background-color: #fff;
    }
  }
}

.ProductTableRadioItem.ReadOnly {
  label {
    cursor: default;
  }
}

.ProductTableRadioItemResponsive.ReadOnly {
  label {
    cursor: default;
  }
}

.ProductTableCheckBoxWrapper.UnCheckedProductCheckbox {
  input:not([disabled]) + label {
    div:first-child {
      background: #fff;
    }
  }
}
