@import '~styles/base.scss';

:export {
  bordergreen: $of-forest-green-01;
}

.ColorBubble {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform 0.3s ease;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:focus {
    border: 2px solid $of-forest-green-04 !important;
    transform: none !important;
  }

  &:hover:not(div) {
    transform: scale(1.3);
  }

  &.Disabled {
    opacity: 0.5;
  }
}
