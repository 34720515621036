@import '~styles/base.scss';

:root {
  // .FilterSidebarContainer has a constant width of 240 + padding 24 + border 1 is 265
  --filter-sidebar-container: 265px;
}

.ContractName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0.2rem;
}

.TooltipContent {
  max-width: calc(100vw - var(--filter-sidebar-container));
}

.TooltipMessage {
  max-width: 100%;
  word-break: break-word;
}
