@import '~styles/base.scss';

.PopoverContent {
  z-index: 1040;
}

.PopoverBody {
  display: flex;
  max-height: 400px;
  max-width: 300px;
  overflow: auto;
  flex-wrap: wrap;
}

.ExtraTags {
  line-height: inherit;
  padding: 1px;
  font-size: 0.8rem;
  min-width: 2rem;
  display: inline-flex;
  color: $of-forest-green-01;
  margin-right: 4px;
  border: 1px solid $of-forest-green-05;
  border-radius: 30px;
  height: 22px;
  font-weight: 500;

  &:hover {
    // This button can't currently use the box shadow as the container cuts a square overflow
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: $of-forest-green-04 !important;
  }
}
