@import '~styles/base.scss';

:export {
  // Border color
  gray: $of-dark-green-05 //Draft
}

.ExternalLinkContainer {
  display: flex;
  justify-content: space-between;
  border: 1px solid $of-dark-green-04;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;

  button:disabled {
    opacity: 0.2;
  }

  &.NonClickable {
    cursor: default;
  }

  .ExternalLinkTitleContainer {
    color: $of-thunder;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.5rem 1rem;
    width: 0;
    overflow: hidden;
    border-left: 6px solid transparent;
    border-radius: 4px;
    margin: -1px;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    .ExternalLinkIcon {
      margin-top: 3px;
      margin-right: 10px;
      color: $of-blue-04;
    }

    .ExternalLinkTitle {
      font-size: 14px;
      line-height: 22px;
      color: $of-forest-green-01;
    }
  }
}

.TerminateIcon {
  margin-bottom: 33px;
  margin-top: 41px;
}
