@import '~styles/base.scss';

.DropdownContent {
  background-color: white;
  box-shadow: 8px 6px 20px 0 rgba(0, 0, 0, 0.149);
  border: 1px solid rgba(0, 0, 0, 0.051);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  max-height: var(--radix-popper-available-height);
  overflow-y: auto;
}

.DropdownItem {
  color: $of-forest-green-01;
  padding: 8px;
  letter-spacing: 0;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color ease-in-out 100ms;

  &:focus-visible {
    outline: unset;
  }

  &:hover,
  &:focus-within,
  &:focus {
    background-color: rgba($of-forest-green-01, 0.08);
  }
}
