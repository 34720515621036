@import '~styles/base.scss';

.RecipientsCategoryPicker {
  label {
    margin-bottom: 12px;

    &:first-of-type span:first-of-type {
      margin-bottom: 8px;
      padding-bottom: 0 !important;

      & > div {
        justify-content: unset;
        align-items: end;
      }
    }

    // We hide the ErrorMessage span for the first SelectField as it doesnt have or need validation
    &:first-of-type span:last-of-type {
      display: none;
    }

    &:last-of-type {
      margin-bottom: 2px;
    }
  }
}

.PrivateTag {
  color: white;
  border-radius: 20px;
  background: $of-forest-green-03;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 16px;
  line-height: 14px;
  text-align: center;
  margin-left: 4px;
  cursor: default;
}
