@import '~styles/base.scss';

.Topbar {
  background-color: white;
  color: $of-forest-green-01;
  height: 60px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1030;

  .TopbarFixed {
    align-items: center;
    background: white;
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: space-between;
    position: fixed;
    width: calc(100% - 72px - 360px);
    z-index: 1;

    @include respondTo(md, max) {
      top: 72px;
      transition: top 250ms ease-out;
    }

    &.TopbarFixedHidden {
      top: 0;
    }

    .TopbarShadow {
      height: 59px;
      pointer-events: none;
      position: absolute;
      width: calc(100% + 80px);
      border: 1px solid #d6dfe2;
      z-index: 1042;
    }

    .TopbarContent {
      align-items: center;
      background-color: white;
      display: flex;
      flex: 1 1;
      height: 60px;
      justify-content: space-between;
      width: 100%;
      z-index: 1043;

      .LeftSide {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-width: 0;
        flex: 1;
      }

      .RightSide {
        display: flex;
        align-items: center;
        margin-right: 18px;

        @media only screen and (max-width: 999px) {
          margin-right: 0;
          padding: 0 0.5rem;
        }
      }
    }
  }

  &.TopbarWithEditor {
    height: 108px;

    > div {
      box-shadow: -20px 4px 40px rgb(0 0 0 / 15%);
    }
  }
}

.TopbarEditorToolbar {
  height: 48px;
  width: 100%;
  background-color: white;
  overflow-x: hidden;
  user-select: none;
  border-bottom: 1px solid $of-forest-green-05;
}

@media (orientation: landscape) {
  .TopbarEditorToolbar {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    width: unset;
  }
}

.TopbarEditorToolbarGridBlock {
  display: grid;
  width: 100%;
}

.ContractOptionsMenu {
  border-radius: 24px;
  border: 1px solid transparent;
  color: $of-forest-green-01;
  display: flex;
  margin-left: 0.25rem;
  padding: 7px;

  &:hover,
  &[aria-expanded=true] {
    background-color: $of-forest-green-01;
    border-color: $of-forest-green-01;
    color: white;
  }

  @media only screen and (max-width: 999px) {
    margin-left: 0;
  }
}
