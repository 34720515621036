@import '~styles/base.scss';

.Pagination {
  padding: 0 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.WithoutPages {
    padding: 0 8px 1px;
  }

  .Buttons {
    display: flex;

    button {
      font-size: 12px;
      width: 24px !important;
      height: 24px !important;

      &:first-child {
        margin-right: 0.4rem;
      }

      &:not(:first-child) {
        margin-left: 0.2rem;
      }

      &:last-child {
        margin-left: 0.4rem;
      }
    }
  }

  .ArrowButton {
    font-size: 14px !important;
  }

  .ArrowIcon {
    color: $of-forest-green-01;

    &:disabled {
      color: $of-inactive;
    }
  }
}
