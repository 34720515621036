.SendDocumentButton {
  border-radius: 8px !important;
  margin: 0;
  height: 42px;
  font-weight: 500;

  [class*=label] {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.875rem;
  }
}

.Collapsed {
  width: 42px !important;
  height: 42px !important;
}

.Expanded {
  padding: 0 16px;
  height: 42px !important;
}
