@import '~styles/base.scss';

.Row {
  display: flex;
  margin: 0.25rem 0;

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }

  .Filler {
    flex: 1;
  }

  [class*=InputField]:disabled {
    -webkit-text-fill-color: $of-forest-green-03;
  }
}

@media screen and (max-width: 576px) {
  .Row {
    flex-direction: column;

    > * {
      + * {
        margin-left: 0;
      }
    }
  }
}

.EditDataFieldModal > div {
  z-index: 10003;
}
