.TooltipMessage {
  margin: 0;
  text-align: center;
}

.EmptyContractMessage {
  height: 100px;
  margin-top: 150px;
  margin-bottom: 150px;
  text-align: center;
  line-height: 50px;
}
