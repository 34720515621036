@import '~styles/base.scss';

.SignMethodButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  width: 258px;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid $of-dark-green-05;
  box-shadow: 0 0 8px 0 rgba(1, 58, 76, 0.2);
  cursor: pointer;
  background: white;

  &:hover {
    border: 1px solid $of-forest-green-04;
  }

  &.Selected {
    background-color: $of-dark-green-05;
    border: 1px solid $of-winter-green-dream;
  }

  .SignMethodAssetsContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .Logo {
      height: 48px;
      width: 48px;
    }
  }
}

.RadioButton {
  margin: 0 4px 0 0;
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  outline: 0.1em solid $of-forest-green-04;
  border-radius: 50%;
  transform: translateY(0.15em);

  &:checked {
    border: 0.35em solid $of-forest-green-01;
    outline-color: $of-forest-green-01;
  }
}
