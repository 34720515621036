@import '~styles/base.scss';

.Container {
  display: flex;
  align-items: center;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
}

.TemplateListLink {
  display: inline;
}

.TemplateLink {
  padding: 0;

  &:focus,
  &:hover {
    color: $of-forest-green-01;
  }

  & > :last-child {
    max-width: 30rem;
    word-wrap: break-word;
    text-align: left;
    white-space: normal;
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }
}

.CreateSpinner {
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.TemplateList {
  margin: -1rem;
  padding-left: 0.25rem;

  .Heading {
    padding-left: 0.75rem;
    margin: 16px 0;
    font-weight: 600;
    font-size: 14px;
  }

  .TemplateItems {
    min-height: 12rem;
    max-height: 272px;
    overflow-y: auto;

    &.Imported {
      min-height: auto;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $of-forest-green-03;
      box-shadow: 0 0 1px rgba(white, 0.5);
    }
  }
}

.TooltipMessage {
  width: 194px;
}

.EmptyState {
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.EmptySearchState {
  @extend .EmptyState;

  min-height: 11.5rem;
  padding-top: 0;
}

.Button {
  display: inline-flex;
  padding: 0 0.1rem 0.1rem 0.3rem;
  vertical-align: middle;

  > :first-child {
    align-self: center;
  }
}

.EmptyStateContent {
  display: flex;
  flex-direction: column;

  .Message {
    text-align: left;
    width: 29em;

    .MaxSize {
      text-align: center;
      margin-bottom: 8px;
      font-family: var(--contract-static-font-family);
      font-size: 14px;
      font-weight: 400;
      line-height: 13px;
    }
  }

  .Header {
    text-align: center;
    font-weight: 500;
    width: 29em;
  }
}

.CreateDocumentButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 7px;
  width: auto;
  height: 5rem;

  .Dropzone {
    border: 1px dashed $of-forest-green-04;

    &:hover {
      border-color: $of-yellow-04;
      background: $of-edit-hover;
    }
  }
}
