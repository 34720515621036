.Row {
  display: flex;
  margin: 0.25rem 0;

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }
}

.FirstChildFixedHeight {
  > :first-child > div {
    flex: none;
    height: 40px;
  }
}
