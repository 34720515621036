@import '~styles/base.scss';

.Contain {
  display: flex;
  align-items: center;
}

.TooltipText {
  width: auto;
  text-align: center;
  margin: 5px;
}

.Alert {
  color: $of-passion;
  height: 20px;
  width: 20px;
  margin-left: 0.2rem;
}
