.BodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  > .TerminateIcon {
    padding-bottom: 32px;
  }
}

.SharedTemplateIcon {
  width: 74px;
  height: 74px;
  margin-bottom: 30px;
  margin-top: 8px;
}

.Heading {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}
