@import '~styles/base.scss';

.Container {
  background-color: white;
  border-radius: 6px;
  margin: 22px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding-top: 24px;
  padding-bottom: 11px;
}

.CompanyName {
  font-size: 0.625rem;
  font-weight: 500;
  color: $of-winter-green-dream;
  margin: 0 36px 7px 36px;
  text-align: center;
}

.LongSkeleton {
  height: 6px;
  width: 110px;
}

.MediumSkeleton {
  height: 6px;
  width: 76px;
}

.DividerWrapper {
  display: flex;
  width: 100%;
}

.Divider {
  flex: 1;
  border-bottom: 1px solid $of-forest-green-04;
  margin: 11px 14px 11px 14px;
}

.Body {
  margin: 0 14px;
}

.PrimaryButton {
  height: 26px;
  margin-top: 11px;
  background-color: $of-yellow;
  cursor: unset;
  padding: 6px 14px;

  span {
    font-size: 0.625rem;
    font-weight: 500;
    color: $of-forest-green-01;
  }
}

.Logo {
  object-fit: contain;
  margin-bottom: 8px;
  height: 20px;
}

.EnvelopeLogo {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  left: 12px;
  color: $of-forest-green-04;
}
