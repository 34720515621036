@import '~styles/base.scss';

.ImportWrapper {
  display: block;
  margin: 0 -1px;
  border-radius: 4px;
  font-size: 12px;
  padding-bottom: 10px;
  border: 1px solid $of-plum-02;
}

.ImportSparkleContainer {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  margin-bottom: 4px;
  color: $of-dark-green-01;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-family: var(--contract-static-font-family);
  background-color: $of-plum-05;
  height: min-content;
}
