@import '~styles/base.scss';

.Logo {
  border-radius: 4px;
  object-fit: contain;
  margin-left: 12px;
}

.CompanyName {
  font-weight: 500;
  font-size: 1.125rem;
  color: #000;
  margin-left: 12px;
}
