@import '~styles/base.scss';

.MarkAsSignedBody {
  overflow: visible !important;
}

.Folder {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  color: $of-forest-green-01;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
  gap: 8px;
  margin-top: 7px;

  @media (max-width: 634px) {
    flex-direction: column;
  }
}

.SelectedFolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  > span {
    display: block;
    color: $of-forest-green-01;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.OverrideSingleDatePicker {
  width: 142px;

  button {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      cursor: pointer;
      color: $of-dark-green-01;
    }
  }

  :global(.SingleDatePickerInput) {
    height: 40px;
  }
}

.MarkAsSignedMenuItem {
  &:hover,
  &:focus-within {
    background-color: $of-green-03;

    button {
      color: $of-green-08;
    }
  }
}

.Label {
  position: relative;
  font-size: 14px;
  color: $of-forest-green-01;

  .AiImportSparkle {
    position: absolute;
    top: 34px;
    right: -100px;
  }
}
