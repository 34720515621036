@import '~styles/base.scss';

.SidebarSubmenu {
  background-color: $of-dark-green-01;
  transition: width 250ms ease-in-out;
  width: 0;

  &.Expanded {
    left: 216px;
  }
}

.ItemsWrapper {
  padding: 64px 24px 0;
  width: 172px;

  & > a > span {
    margin-bottom: 10px !important;
  }
}

.Desktop {
  position: fixed;
  top: 0;
  margin-right: -220px;
  left: 72px;
  padding: 0;
  z-index: 2;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
}

.Visible {
  &.Desktop {
    width: 220px;
  }
}
