@import '~styles/base.scss';

.WebhookUrlResponse {
  display: flex;
  align-items: center;
  height: 20px;
}

.Loading {
  margin-left: 0.25rem;
}

.Error {
  color: $of-passion;
}

.StatusCode {
  color: $of-peace;
}

.ErrorCode {
  color: $of-passion;
}
