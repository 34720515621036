@import '~styles/base.scss';

.Body {
  min-height: 100px !important;
  flex: 0 !important;
  padding-top: 2rem !important;
}

.BodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $of-forest-green-01;
  letter-spacing: 0.1px;

  .BodyText {
    text-align: center;
    font-size: 14px;
    color: $of-forest-green-01;
    padding: 0 20px;
    margin-bottom: 20px;
  }
}

.WarningIcon {
  width: 74px;
  height: 74px;
  margin: 15px 0 47px 0;
}

.RemoveButton {
  margin-left: 10px;
  padding: 10px;
}
