@import '~styles/base.scss';

.SingleDatePicker {
  --clear-button-display: none;
  --calendar-button-display: inline-block;

  display: flex;
  flex-direction: column;

  .Label {
    font-weight: 400;
    color: $of-thunder;
    padding-bottom: 0.25rem;
    font-size: 13px;
  }

  :global(.DateInput) {
    max-width: unset !important;
    flex: 1 !important;

    input {
      padding: 6px 0 !important;

      &::placeholder {
        font-weight: 400;
        color: $of-forest-green-03;
      }
    }
  }

  :global(.SingleDatePickerInput) {
    display: flex;
  }

  :global(.SingleDatePickerInput__showClearDate) {
    padding-right: unset;
  }

  :global(.SingleDatePickerInput_clearDate),
  :global(.SingleDatePickerInput_calendarIcon) {
    position: unset !important;
    right: unset !important;
    top: unset !important;
    transform: unset !important;
    height: unset !important;
    width: unset !important;
    margin: 0 5px 0 0 !important;
    padding: 10px !important;
  }

  :global(.SingleDatePickerInput_clearDate) {
    display: var(--clear-button-display);
  }

  :global(.SingleDatePickerInput_calendarIcon) {
    display: var(--calendar-button-display);
  }

  :global(.SingleDatePickerInput__disabled) {
    --clear-button-display: none !important;
    --calendar-button-display: inline-block !important;

    color: $of-forest-green-03 !important;
  }
}

.SingleDatePicker :global(.DateInput_input__disabled) {
  color: $of-forest-green-03 !important;
}

.isEmptyFocused {
  :global(.SingleDatePickerInput) {
    --clear-button-display: none !important;
    --calendar-button-display: inline-block !important;
  }
}

.hasNoDate {
  :global(.SingleDatePickerInput):hover {
    --clear-button-display: none !important;
    --calendar-button-display: inline-block !important;
  }
}

.hasDateFocused {
  :global(.SingleDatePickerInput):hover {
    --clear-button-display: inline-block !important;
    --calendar-button-display: none !important;
  }
}

.SingleDatePicker :global(.SingleDatePickerInput):hover {
  --clear-button-display: inline-block;
  --calendar-button-display: none;
}

.SingleDatePicker :global(.DateInput_input):focus-visible {
  outline: 0 !important;
}
