@import '~styles/base.scss';

.Link {
  color: $of-blue-02;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: $of-blue-02;
  }
}
