@import '~styles/base.scss';

.BoxEmptyContainer {
  font-family: var(--contract-static-font-family);

  &:hover {
    background-color: $of-edit-hover;
    cursor: pointer;
  }
}

.ReadOnlyNoFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  color: $of-legacy-light;
  font-size: 14px;
  line-height: 1.55;
  text-align: center;
  border: 1px solid $of-legacy-light;
  padding: 20px 0;

  svg {
    width: 75px;
    height: 75px;
  }

  .ReadOnlyNoFileIcon {
    margin-bottom: 20px;
  }
}

.EmptyStateContainer {
  padding-top: 0;
  padding-bottom: 15px;

  .EmptyStateIcon {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $of-dark-green-05;
    border-radius: 100%;
  }
}

.BoxEmptyDraftState {
  border: none;
}
