@import '~styles/base.scss';

.RemoveParticipant {
  display: flex;

  button {
    color: $of-red-02 !important;
    padding-left: 0;

    & > :last-child {
      padding-top: 2px;
    }
  }
}
