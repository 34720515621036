.ActionButtons {
  display: flex;
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.RoleButtons {
  display: flex;
}

.EditButtonHeaderContainer {
  display: flex;
  align-items: center;
  gap: 32px;
}
