.FormWrapper {
  padding: 0 20px;
}

.Icon {
  visibility: hidden;
}

.ShowIcon {
  visibility: visible;
}
