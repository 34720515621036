@import '~styles/base.scss';

.UploadLink {
  color: $of-forest-green-01;
  font-size: 18px !important;
  line-height: 21px !important;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.LinkInformation {
  line-height: 13px;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 25px 0 14px 0;
  letter-spacing: -0.02em;

  > p {
    margin: unset;
  }
}

.ReadOnlyNoVideo {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  color: $of-legacy-light;
  font-size: 14px;
  line-height: 1.55;
  text-align: center;
  border: 1px solid $of-legacy-light;
  padding: 20px 0;

  svg {
    height: 75px;
    width: 75px;
  }
}

.ReadOnlyNoVideoIcon {
  margin-bottom: 20px;
}

.BoxEmptyDraftState {
  border: none;
}
