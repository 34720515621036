.Description {
  color: var(--formatting-font-color);
  font-family: var(--formatting-font-family);
  font-size: var(--formatting-font-size) !important;
  font-size: 0.75em;
  font-style: italic;
  line-height: 12px;
  margin: 5px 0 0;
  word-break: break-word;
}
