@import '~styles/base.scss';

.IsOverNonPdfSectionsTooltip {
  width: max-content;
  position: absolute;
  background-color: $of-forest-green-01;
  color: white;
  padding: 8px;
  font-size: 0.75rem;
  bottom: calc(100% + 6px);
  border-radius: 6px;
}
