@import '~styles/base.scss';

.DropdownItem {
  width: 100%;
  color: $of-forest-green-01;
  padding: 0.35rem 1rem;
  letter-spacing: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  & > :last-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }
}
