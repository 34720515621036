@import '~styles/base.scss';

.AccountContainer {
  .Name {
    display: flex;
    word-break: break-word;
  }

  .ContentHeader {
    margin: 2rem;
  }

  .Header {
    font-weight: 600;
    font-size: 1.125rem;
    margin: 1rem 0;
  }

  .Buttons {
    margin: auto 0;
  }

  .EmptyState {
    padding: 4rem 2rem;
  }

  .LimitedAccessIcon {
    margin-right: 4px;
    color: $of-red-02;
    min-width: 14px;
  }
}
