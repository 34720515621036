@import '~styles/base.scss';

.DiscardChangesModal {
  .Content {
    svg {
      color: $of-red-02;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .DiscardLabel {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: $of-forest-green-01;
    padding-top: 50px;
  }

  .DiscardInformation {
    text-align: center;
  }
}

.Actions {
  display: flex;
}

.DiscardChangesButton {
  padding: 14px;
  border-radius: 4px !important;
  max-height: 44px;
}
