@import '~styles/base.scss';

.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px;
}

.Container {
  padding: 48px;
  border: solid 1px $of-forest-green-05;
  border-radius: 8px;
  color: $of-forest-green-01;
}
