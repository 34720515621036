@import '~styles/base.scss';

.VideoBoxWrapper {
  .VideoToolbarContainer {
    opacity: 0;

    &.Visible {
      opacity: 1;
    }
  }

  &:hover .VideoToolbarContainer {
    opacity: 1;
  }

  span[data-slate-placeholder=true] {
    margin-top: 9px;
    font-family: var(--contract-static-font-family);
    color: var(--contract-static-text-color);
  }
}

.VideoBoxContainer {
  padding: 0 20px;
}

.IsMobileAndHasData {
  padding: 0;
}

.VideoAndEditableContainer {
  padding: 20px 0;
}

.Small {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.FullWidth {
  padding: 0;
}
