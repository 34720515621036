@import '~styles/base.scss';

.AIAssistPopover {
  visibility: hidden;
  opacity: 0;
  transform-origin: var(--radix-popover-content-transform-origin);

  &.IsVisible {
    visibility: visible;
    opacity: 1;
  }

  * {
    box-sizing: border-box;
  }
}

.Anchor {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.Content {
  background-color: white;
  border: 1px solid var(--radix-border-color);
}

.Container {
  background: white;
  box-shadow:
    rgba(15, 15, 15, 0.05) 0 0 0 1px,
    rgba(15, 15, 15, 0.1) 0 3px 6px,
    rgba(15, 15, 15, 0.2) 0 9px 24px;
  border-radius: 8px;
  overflow: hidden;
}

.Top {
  --input-min-height: 36px;

  min-height: var(--input-min-height);
}

.ResponseContainer {
  margin-top: 2px;
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  padding: 5px 14px;
  max-height: min(40vh, 320px);
  overflow: auto;
}

.Root {
  position: relative;

  [cmdk-list] {
    border-radius: 8px;
    background: white;
    box-shadow:
      rgba(15, 15, 15, 0.05) 0 0 0 1px,
      rgba(15, 15, 15, 0.1) 0 3px 6px,
      #0f0f0f33 0 9px 24px;
    width: 320px;
    isolation: isolate;
    display: flex;
    flex-direction: column;
    max-height: min(40vh, 320);
    overflow-y: auto;

    [cmdk-group] {
      padding: 6px 0;
    }

    [cmdk-group-heading] {
      display: flex;
      padding: 0 14px;
      margin-top: 6px;
      margin-bottom: 8px;
      color: rgba(55, 53, 47, 0.65);
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 120%;
      user-select: none;
    }

    [cmdk-item] {
      user-select: none;
      cursor: pointer;
      margin: 0 4px;
      padding: 0 12px;
      border-radius: 4px;
      height: 28px;
      display: flex;
      align-items: center;

      kbd {
        display: none;
        margin-left: auto;
      }

      &[data-selected="true"],
      &[data-state="open"],
      &:focus {
        background: rgba(55, 53, 47, 0.08);

        kbd {
          display: block;
        }
      }

      &:focus {
        outline: none;
      }
    }

    [cmdk-separator] {
      width: 100%;
      height: 1px;
      background-color: rgba(55, 53, 47, 0.09);
    }
  }
}

.MenuContainer {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
}
