@import '~styles/base.scss';

.AuditLog {
  display: flex;
  flex-direction: column;

  .Empty {
    padding-top: 0;
    min-height: 125px;
  }

  .List {
    @include flex(1);

    margin: 0;
    padding: 0 1rem;

    .ListItem {
      list-style: none;

      &:first-child {
        border-top: 1px solid $of-light-gray;
      }
    }
  }

  .ActorInfo {
    display: flex;
    align-items: center;
    word-break: break-word;
    min-width: 130px;
  }

  .UserBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 1rem;
    color: white;
    font-size: 1.1rem;

    .Initials {
      font-weight: 400;
      width: 44px;
      margin-left: -44px;
    }
  }

  .Message {
    word-break: break-word;
    min-width: 130px;

    .Green {
      color: $of-green-01;
      font-weight: 400;
    }

    .Red {
      color: $of-red-02;
      font-weight: 400;
    }
  }

  .Metadata {
    color: $of-forest-green-03;
    font-size: 0.8rem;
  }

  .Date {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    min-width: 80px;
  }

  .DataRetentionPolicy {
    padding: 2rem 2rem 0 2rem;

    h2 {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}
