@import '~styles/base.scss';

.Container {
  min-height: 400px;
}

.Heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 25px 0 5px 0;
}

.EyeIcon {
  margin-right: 5px;
  margin-bottom: 7px;
}

.Row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.Field {
  margin-right: 10px;
  width: 190px;
}

.Condition {
  margin-right: 10px;
  width: 190px;
}

.Actions {
  display: flex;
  flex-direction: row;

  & > :nth-child(2) {
    margin-left: 10px;
  }
}
