@import '~styles/base.scss';

.MicrophoneControl {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin-top: -5.5rem;

  @include respondTo(md, max) {
    margin-top: -4.5rem;
  }

  .Button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    padding: 0;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: rgba($of-video-gray, 0.4);
    cursor: pointer;
    color: white;
    box-sizing: border-box;

    @include respondTo(md, max) {
      width: 2.75rem;
      height: 2.75rem;
    }

    &:hover {
      border-color: rgba($of-reign, 0.8);
      background-color: rgba($of-video-gray, 0.8);
      color: $of-cloud;
    }

    &:disabled {
      opacity: 1;
    }

    .MicrophoneIcon {
      width: 2.5rem;
      height: 2.5rem;

      @include respondTo(md, max) {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .VolumeMeter {
    color: $of-peace;
  }
}
