@import "~styles/base";

.SelectTrigger {
  outline: none;
  background: white;
  border: 1px $of-forest-green-05 solid;
  border-radius: 5px;
  min-height: 42px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  color: $of-forest-green-01;
  padding: 0 12px;

  &[data-state="open"][data-popper-placement="bottom"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &[data-state="open"][data-popper-placement="top"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:disabled {
    color: $of-forest-green-04;
    background: $of-inactive;
    border: $of-inactive;
    cursor: not-allowed;
  }

  &:hover,
  &[data-state="open"] {
    border-color: $of-dark-green-02;
  }
}

.SelectContent {
  border: 1px $of-dark-green-02 solid;
  background: white;
  // The value is 32 temporarily for  the select content to be above the old React Selects
  // If you're reading this and the react-select is gone, please replace the z-index with 2
  // The reason for the value 2 is that the content should be above the popups and modals which should have z-index 1
  z-index: 32; //
  max-height: 240px !important;
  overflow-y: auto;
  width: var(--radix-select-trigger-width);

  &[data-side="top"] {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
  }

  &[data-side="bottom"] {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: none;
  }
}

.SelectItem {
  outline: none;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $of-forest-green-01;
  padding-right: 0.75em;
  padding-left: 0.75em;

  &:hover,
  &[data-state="checked"] {
    background: $of-dark-green-05;
  }
}

.SelectIcon {
  margin-left: auto;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectLabel {
  padding: 2px 8px;
  font-size: 0.75rem;
  font-weight: 600;
}
