.PopoverContent {
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 5px 5px -3px rgba(black, 0.2), 0 8px 10px 1px rgba(black, 0.14), 0 3px 14px 2px rgba(black, 0.12);
  padding: 1rem;
  will-change: opacity;
}

.PopoverArrow {
  fill: white;
}

.PopoverContent[data-state='open'] {
  animation-name: fade;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
