@import '~styles/base.scss';

.Error {
  color: $of-red-01;
  background-color: $of-red-05;
  border-radius: 3px;
  padding: 10px 16px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  min-width: 50%;
  justify-self: center;

  a {
    color: $of-red-01;
  }

  .Header {
    font-size: 0.875rem; // Default font-size but needed as long as legacy has global rules
    margin: 0 0 0.75rem 0;
  }

  .Paragraph {
    font-size: 0.875rem; // Default font-size but needed as long as legacy has global rules
  }
}

.Info {
  border-radius: 3px;
  padding: 8px 16px;
  margin-bottom: 1rem;
  color: $of-forest-green-01;
  background-color: $of-dark-green-05;
  min-width: 90%;

  a {
    color: $of-forest-green-01;
  }

  .Header {
    font-weight: 500;
    padding-top: 0.75rem;
    font-size: 0.875rem; // Default font-size but needed as long as legacy has global rules
  }

  .Paragraph {
    margin: 1em 0; // Default paragraph margins
    font-size: 0.875rem; // Default font-size but needed as long as legacy has global rules
  }
}
