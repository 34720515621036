@import '~styles/base.scss';

.CancelSubscriptionContainer {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    color: $of-forest-green-01;
    margin: 4px 20px 13px 0;
    line-height: 20px;
    font-weight: normal;

    &.Strong {
      font-weight: 600;
    }
  }
}
