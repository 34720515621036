@import '~styles/base.scss';

.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BodyText {
  color: $of-forest-green-01;
  font-size: 0.875rem;
  text-align: center;
}

.TerminateIcon {
  color: $of-red-02;
  margin-bottom: 30px;
  margin-top: 40px;
}
