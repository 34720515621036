@import '~styles/base.scss';

.EditableResponsive {
  border: none !important;
  box-shadow: inset 0 1px 1px transparent !important;
  background: #fff;

  &.Description {
    > div {
      padding: 4px 8px !important;
      border: 1px solid $of-forest-green-04;
      border-radius: 6px;

      &.ReadOnly {
        padding: 4px 0 !important;
        border: none !important;
      }
    }
  }

  &.ProductDescription {
    &.ReadOnly {
      > div {
        padding: 0 !important;
      }
    }
  }

  &.ProductDescription:not(.ReadOnly) {
    border-color: $of-forest-green-05 !important;
    transition: border linear 0.2s, box-shadow linear 0.2s !important;

    &:hover {
      border-color: $of-forest-green-01 !important;
    }

    &:focus,
    &:focus-within {
      border-color: $of-forest-green-01 !important;
    }
  }

  [class*=EditableContent] {
    min-height: unset !important;
    padding: 4px 8px !important;

    span[data-slate-placeholder=true] {
      /* TODO: Temporary fix for issue in Safari introduced after v15. This line can be removed after issue will be fixed. */
      padding-top: 4px;
      display: inline-block !important;
      color: #747474;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  [class*=EditableReadOnlyContent] {
    border-color: transparent !important;
    border: none;
  }
}

.EditableExpanded {
  cursor: auto;
  border: 1px solid $of-forest-green-04;
  border-radius: 6px;
  background: #fff;
  box-shadow: inset 0 1px 1px transparent !important;
  color: $of-dark-green-01;
  padding: 4px 8px;
  line-height: 1;
  box-sizing: border-box;
  display: inline-block;
  min-height: 25px;
  width: 100%;

  &:hover {
    border: 1px solid $of-forest-green-01;
    background-color: #fff !important;
  }

  >div[class*=EditableContent] {
    min-height: unset;
    padding: 0 !important;
  }
}
