@import '~styles/base.scss';

.DefaultFolder {
  margin-bottom: 25px;

  button {
    font-size: 0.8125rem !important;
  }

  .FolderHeader {
    color: $of-forest-green-01;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .FolderText {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 16px;
    color: $of-winter-green-dream;
    margin-bottom: 12px;
  }

  .SelectFolderButton {
    font-size: 0.75rem;
    width: 100%;
  }

  .SharedTemplate {
    margin-bottom: 7px;
    font-size: 0.75rem;
    line-height: 1.3;
    font-style: italic;
    color: $of-legacy-light;
  }

  .DefaultFolderNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .FolderNameButton {
      font-size: 0.75rem;
      padding: 0;
      color: $of-forest-green-01;
    }
  }
}
