.Label {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
}

.InfoIcon {
  margin-left: 4px;
}
