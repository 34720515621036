@import '~styles/base.scss';
@import '~styles/animation.scss';

:root {
  --tooltip-shadow-color: #0000001a;
  --tooltip-shadow-color-light: #013a4c33;
  --tooltip-container-block-padding: 6px;
  --tooltip-container-inline-padding: 6px;
  --tooltip-container-padding: var(--tooltip-container-block-padding) var(--tooltip-container-inline-padding);
  --tooltip-container-border-radius: 8px;
  --tooltip-background-color: white;
}

// TooltipPrimitive.Content components wrap the given child component
// With outer container (div with data-radix-popper-content-wrapper)
// which controls the positioning of the tooltip and the
// Inner container(div with data-state) when asChild prop is not used and
// Its style can be controlled by className prop passed to TooltipPrimitive.Content
// Use the following class when customization is needed. The z-index you apply here
// Will be applied to to outer wrapper (data-radix-popper-content-wrapper)
// Refer: https://github.com/radix-ui/primitives/blob/main/packages/react/popper/src/Popper.tsx#L264
.ContentContainerInnerWrapper {
  background-color: var(--tooltip-background-color);
  border-radius: var(--container-inner-wrapper-border-radius);
  box-shadow: -1px 1px 20px 0 var(--tooltip-shadow-color);
}

.Animate {
  --animation-timing: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  will-change: animation;

  &[data-side='top'] {
    animation: fadeInUp var(--animation-timing);
  }

  &[data-side='right'] {
    animation: fadeInRight var(--animation-timing);
  }

  &[data-side='bottom'] {
    animation: fadeInDown var(--animation-timing);
  }

  &[data-side='left'] {
    animation: fadeInLeft var(--animation-timing);
  }

  &[data-state='closed'] {
    &[data-side='top'] {
      animation: fadeOutUp var(--animation-timing);
    }

    &[data-side='right'] {
      animation: fadeOutRight var(--animation-timing);
    }

    &[data-side='bottom'] {
      animation: fadeOutDown var(--animation-timing);
    }

    &[data-side='left'] {
      animation: fadeOutLeft var(--animation-timing);
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .Animate {
    will-change: unset;

    &[data-side][data-state] {
      animation: none;
    }
  }
}

.OneflowContainerInnerWrapper {
  --tooltip-container-border-radius: 5px;
  --tooltip-background-color: #{$of-forest-green-01};
  --container-inner-wrapper-border-radius: var(--tooltip-container-border-radius);
}

.LightContainerInnerWrapper {
  --tooltip-container-border-radius: 8px;
  --container-inner-wrapper-border-radius: var(--tooltip-container-border-radius);
  --tooltip-shadow-color: var(--tooltip-shadow-color-light);
}

.ContentContainer {
  font-size: 0.75rem;
  min-width: 0;
  max-width: 250px;
  margin: 0 !important;
  padding: var(--tooltip-container-padding);
  text-overflow: ellipsis !important;
  overflow: hidden;
}

.LightContainer {
  background-color: var(--tooltip-background-color);
  border-radius: var(--tooltip-container-border-radius);
}

.OneflowContainer {
  background-color: var(--tooltip-background-color);
  border-radius: var(--tooltip-container-border-radius);
  color: white;
  line-height: 14px;
}
