@import '~styles/base.scss';

.RecipientListList {
  border: 0.5px solid $of-forest-green-04;
  border-radius: 12px;
  padding: 15px !important;

  .MultipleBadges {
    display: flex;
    flex-direction: row;
  }

  p {
    margin-bottom: 2px;
  }

  .SignLater {
    font-weight: 600;
  }

  .AdditionalInformation {
    font-size: 0.8em;
  }
}

.ApproversList {
  border: 0.5px solid $of-forest-green-04;
  border-radius: 12px;
  padding: 15px !important;
}

.BodyContainer {
  .ApprovalHeader {
    font-size: 1.125rem;
    font-weight: 500;
    color: $of-forest-green-01;
    padding: 0 18px 4px 18px;
    margin: 0;
    letter-spacing: 0.2px;
  }

  .RenderInviteAutoSignToggle {
    padding: 16px 18px;

    label {
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 576px) {
    .RenderInviteAutoSignToggle {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .Mobile {
    max-height: 350px !important;
  }
}
