@import '~styles/base.scss';

.RadioGroupItem {
  input {
    margin: 0 8px 0 0;
    -webkit-appearance: none;
    appearance: none;
    color: $of-forest-green-01;
    background-color: #fff !important; //needed to override bootstrap
    width: 14px;
    height: 14px;
    border: none;
    border-radius: 50%;
    transform: translateY(-1px);
    display: inline-grid;
    place-content: center;
    outline: 1.6px solid $of-forest-green-04;
    vertical-align: middle;

    &:focus-visible {
      outline: $of-forest-green-01 solid 2px !important;
      outline-offset: 2px !important;
      box-shadow: 0 0 0 5px white !important;
    }

    &:hover {
      outline-color: $of-forest-green-01;
    }

    &:focus {
      outline: 1.6px solid $of-forest-green-01;
      outline-offset: 0;
    }

    &:disabled {
      cursor: default;
      opacity: 1;
      background-color: $of-inactive !important; //needed to override bootstrap
      outline-color: $of-forest-green-05;
    }

    &:checked {
      border: 4px solid $of-forest-green-01;
      outline-color: $of-forest-green-01;

      &:hover {
        outline-color: $of-forest-green-01;
      }
    }

    &:disabled:hover {
      outline-color: $of-forest-green-05;
    }

    &:checked:disabled {
      border-color: $of-inactive;
      background-color: $of-forest-green-04 !important; //needed to override bootstrap
      outline-color: $of-forest-green-05;

      &:hover {
        outline-color: $of-forest-green-05;
      }
    }
  }

  label {
    font-size: 16px;
    font-weight: 400;

    &:first-child {
      margin-right: 52px;
    }

    @include respondTo(sm, max) {
      font-size: 16px;

      &:first-child {
        margin-right: 36px;
      }
    }
  }
}
