@import '~styles/base.scss';

.PageContainer {
  /* This could be a prop to the <Table> component, but we override it here instead for now */
  table {
    padding: 0;
  }
}

.Webhook {
  line-height: 2;
  word-break: break-all;
  word-break: break-word;
}
