@import '~styles/base.scss';

.Suggestion span {
  color: $of-green-11 !important;
  text-decoration: underline;
}

.AcceptSuggestionAnimation {
  @keyframes suggestionAccept {
    from {
      color: $of-forest-green-02;
    }

    to {
      color: $of-forest-green-01;
    }
  }

  animation: suggestionAccept 0.3s ease-in-out forwards;
}
