@import '~styles/base.scss';

.Danger {
  &:hover,
  &:focus-within {
    background-color: $of-red-05 !important;

    button {
      color: $of-red-02 !important;
    }
  }
}
