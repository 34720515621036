@import '~styles/base.scss';

.CheckboxTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Label {
  font-weight: 500;
  font-size: 13px;
  color: $of-forest-green-01;
}
