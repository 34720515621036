@import "~styles/base.scss";

:root {
  --links-border-color: #d6dfe2;
  --links-background-color: #e6ebed;
  --links-color: #013a4c;
  --reminders-border-color: #d6dfe2;
  --reminders-background-color: #e6ebed;
  --reminders-color: #013a4c;
  --expanded-shadow-color: #013a4c;
  --expanded-shadow-rgba: rgba(1, 58, 76, 0.2); // this rgba to hex: ccd8db
}

.RightContainer {
  display: flex;
  flex-direction: column;
  background-color: #f7f8fa;
  flex: 1;
  max-width: 20rem;
  min-width: 13rem;
  border-radius: 0 5px 5px 0;

  .RightTopBar {
    display: inline-flex;
    align-items: center;

    button {
      margin-left: auto;
    }
  }

  .RightBottomBar {
    align-self: flex-end;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  &.Draft {
    background-color: $of-dark-green-05;
  }

  &.Pending {
    background-color: $of-blue-05;
  }

  &.Overdue {
    background-color: $of-orange-02;
  }

  &.Signed {
    background-color: $of-green-03;
  }

  &.Declined {
    background-color: $of-red-05;
  }

  .ContractActions {
    padding: 0 0.25rem;
    min-height: 2rem;
  }
}

.ContractCard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  .ContractCardMainArea {
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    border: 1px solid $of-dark-green-04;
    flex-wrap: wrap;
    flex: 1;
  }

  .NameRow {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    font-size: 1.2rem;

    .NameLink {
      overflow: hidden;
    }
  }

  .StateContainer {
    margin-left: auto;
  }

  .ContractValue {
    margin: 4px 0;
    font-size: 0.75rem;

    button {
      color: $of-forest-green-03;
      text-decoration: none;

      &:hover {
        color: $of-forest-green-03;
        text-decoration: underline;
      }

      span {
        gap: 4px;
      }
    }
  }
}

.ContractInformation {
  color: $of-thunder;
  display: flex;
  flex: 1;
  padding: 1rem;
  width: 0;
  overflow: hidden;
  margin: -1px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.CheckboxContainer {
  margin-right: 8px;
  // same height as the document name
  height: 26px;
  display: flex;
  align-items: center;
}

.ContractInformationContent {
  flex: 1;
  width: inherit;
}

.Disabled {
  opacity: 0.6;
}

.LinksContainer,
.ContractInternalRemindersContainer {
  max-height: 0;
  transition: max-height ease 0.5s, box-shadow ease 0.5s;
  flex-basis: 100%;
  overflow: hidden;
  padding: 0;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Expanded {
  box-shadow: 0 5px 12px -2px rgba(1, 58, 76, 0.2);
  max-height: 420px;
  overflow: auto;
  border-left: 1px solid $of-dark-green-04;
  border-right: 1px solid $of-dark-green-04;
  border-bottom: 1px solid $of-dark-green-04;
  border-radius: 0 0 6px 6px;
}

.ActionsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 22px;
  margin-top: 4px;
}

.CardEventActionButtons {
  display: flex;
  gap: 8px;
}

.LinksButton {
  color: var(--links-color);
  border: 1px solid var(--links-border-color);
  background-color: var(--links-background-color);
  display: flex;
  align-items: center;
  height: 22px;
  gap: 6px;
  border-radius: 40px;
  cursor: pointer;
  padding: 9px 10px;

  &:hover {
    --links-background-color: #d6dfe2;
  }

  [class*=label] {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-left: 0 !important;
  }

  @media (max-width: 1180px) {
    display: none;
  }
}

.LinksButtonSmall {
  color: var(--links-color);
  border: 1px solid var(--links-border-color);
  background-color: var(--links-background-color);
  display: none;
  align-items: center;
  height: 22px;
  border-radius: 40px;
  cursor: pointer;
  padding: 9px 10px;

  @media (max-width: 1180px) {
    display: flex;
  }

  &:hover {
    --links-background-color: #d6dfe2;
  }

  [class*=label] {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}

.LinksButtonLabel,
.InternalRemindersButtonLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LinksButtonTooltipLabel,
.InternalRemindersButtonTooltipLabel {
  display: none;

  @media (max-width: 1180px) {
    display: unset;
  }
}

.InternalRemindersButton {
  color: var(--reminders-color);
  background: var(--reminders-background-color);
  border: 1px solid var(--reminders-border-color);
  border-radius: 40px;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 9px 10px;
  cursor: pointer;

  &:hover {
    --reminders-background-color: #d6dfe2;
  }

  [class*=label] {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  @media (max-width: 1180px) {
    display: none;
  }
}

.InternalRemindersButtonSmall {
  color: var(--reminders-color);
  background: var(--reminders-background-color);
  border: 1px solid var(--reminders-border-color);
  border-radius: 40px;
  height: 22px;
  align-items: center;
  padding: 9px 10px;
  cursor: pointer;
  display: none;

  @media (max-width: 1180px) {
    display: flex;
  }

  &:hover {
    --reminders-background-color: #d6dfe2;
  }

  [class*=label] {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}

.InternalRemindersButtonLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.LinksButtonTooltipMessage,
.InternalRemindersButtonTooltipMessage {
  padding: 12px 24px !important;
  font-weight: 500 !important;
}

.BothExpanded {
  &.Expanded {
    box-shadow:
      -5px 0 12px -12px var(--expanded-shadow-color),
      5px 0 12px -12px var(--expanded-shadow-color) !important;
  }

  &:last-of-type {
    box-shadow: 0 5px 12px -2px var(--expanded-shadow-rgba) !important;
  }
}
