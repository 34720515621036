@import '~styles/base.scss';

.Workspaces {
  .Name {
    display: inline-block;
    word-break: break-all;
    word-break: break-word;
  }

  .ContentHeader {
    margin: 2rem;
  }

  .GroupLink {
    padding-right: 1rem;
    display: inline-block;
    word-break: break-all;
    word-break: break-word;

    .GroupRoles {
      margin: 0 0 0 5px;
      font-size: 0.8rem;
      font-style: italic;

      .RoleLink {
        margin: 0 4px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .Buttons {
    margin: auto 0;
  }

  .LimitedAccessIcon {
    margin-right: 4px;
    color: $of-red-02;
    min-width: 14px;
  }
}
