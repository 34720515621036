@import '~styles/base.scss';

:export {
  desktopmin: $contract-view-desktop-min;
  mobilemax: $contract-view-mobile-max;
}

.NoticeBanner {
  padding: 0 10px;
  background-color: $of-edit-hover;
  color: $of-forest-green-01;
  box-shadow: 0 1px 1px 0 #0000001a;
  height: var(--freemium-banner-height-expanded);
  display: flex;
  align-items: center;
  gap: 6px;

  .UpgradeButton {
    padding: unset;
    text-decoration: unset !important;
    display: inline-flex;
  }

  .NoticeBannerContent {
    .FreePlan {
      font-weight: 600;
    }
  }
}

.NoticeBannerMobile {
  height: var(--freemium-banner-height-collapsed);
  margin-bottom: 4px;
}

.IconContainer {
  display: flex;
}
