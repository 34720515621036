@import '~styles/base.scss';

.AdditionalInformationContainer {
  display: flex;
  flex-direction: column;

  .InfoText {
    display: flex;
    align-items: center;
  }

  .AdditionalInformation {
    font-size: 13px;
    font-weight: 600;
    margin-left: 8px;
  }

  .Link {
    color: $of-blue-02 !important;
  }
}
