
.Badge {
  margin-top: 1rem;
  height: auto;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.BrandingLogo {
  max-height: 75px;
  max-width: 100px;
}
