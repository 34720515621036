@import '~styles/base.scss';

.ContractTextEditorToolbar {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;

  .ResponsiveMenu {
    color: $of-dark-green-01;
    padding: 0;
    width: fit-content;
    margin-left: 8px;
    margin-right: 8px;
    border: unset;
    display: flex;
    align-items: center;
    background: white;
  }

  &.Placeholder {
    display: flex;
    align-items: center;
    height: 100%;

    button,
    input {
      pointer-events: none;
      user-select: none;
      background-color: white;
      color: $of-forest-green-05;
      border-style: none;

      &[aria-haspopup=menu]:last-child {
        color: $of-forest-green-05;
      }
    }

    input {
      caret-color: transparent;
    }

    :global(.rich-text-toolbar-divider) {
      background-color: $of-forest-green-05;
    }
  }
}

.ToolbarDivider {
  width: 1px;
  height: 16px;
  background-color: $of-forest-green-02;
}

.ResponsiveMenuDropdown {
  margin-top: 17px;
}

.MenuDropdownIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  border: unset;
  height: 24px;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 0.875rem;
  background: none;

  &:hover {
    background-color: $of-dark-green-06;
  }
}
