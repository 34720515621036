@import '~styles/base.scss';

.hideMenu {
  opacity: 0;
}

.BoxMenu {
  position: relative;
  transition: opacity 0.2s;

  //840px is DOCUMENT_COLLAPSED_LAYOUT_SIZE breakpoint
  @media screen and (max-width: 840px) {
    z-index: unset;
  }

  .Line {
    background-color: $of-dark-green-05;
    height: 3px;
    margin-top: 6px;
    pointer-events: none;

    &.LineCollapsedLayout {
      height: 2px;
    }

    &.IsBoxDndOver {
      background-color: $of-forest-green-01;
    }
  }

  .Outer {
    margin-top: -16px;
    height: 20px;

    &.Collapsed {
      display: flex;
    }

    &.IsBoxDndOver {
      visibility: hidden;
    }
  }

  .ButtonContainer {
    background-color: $of-dark-green-05;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    margin-top: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .AddButton {
    color: $of-dark-green-03;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.2s;

    > svg {
      width: 10px;
      height: 10px;
    }
  }

  &.CollapsedLayout {
    height: 24px;
  }

  &.IsBoxDndOver {
    opacity: 1;
  }
}

.showMenu {
  opacity: 1 !important;

  &:hover {
    .ButtonContainer {
      transition: background-color 0.2s;
      background-color: $of-yellow-02;

      > * {
        color: $of-forest-green-01;
      }
    }
  }
}

.BoxMenuTrigger {
  position: relative;
  height: 31px;
  margin: 0 auto;
  padding: 0;
  transition: opacity 0.2s;
  border: 0;
  background: transparent;
  width: 100%;

  &:focus-visible {
    .BoxMenu {
      opacity: 1;
    }
  }

  &.Collapsed {
    width: unset;
  }

  &[aria-expanded=true] .showMenu {
    .ButtonContainer {
      background-color: $of-yellow !important;
    }

    .AddButton {
      color: $of-forest-green-01;
    }
  }
}

.DropdownContainer {
  position: absolute;
  z-index: 12;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .BoxMenu:active {
    left: -1px;
    top: -1px;
  }
}
