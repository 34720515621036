@import '~styles/base.scss';

.BodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $of-forest-green-01;
  letter-spacing: 0.1px;

  p {
    text-align: center;
  }

  .Header {
    font-size: 18px;
    font-weight: 500;
  }
}

.Confirm {
  margin: 0 auto;
}
