@import '~styles/base.scss';

.CustomDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 86px;
  padding: 0 30px;
  background: none;
  color: $of-forest-green-01;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  cursor: pointer;
  transition: box-shadow 200ms ease-in-out;

  span {
    margin-top: 8px;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

.CreateCustomDocumentText {
  width: 8em;
  font-size: 0.9rem;
  line-height: normal;
}
