@import '~styles/base.scss';

.DeleteOverlayField {
  padding-inline: 8px !important;
  background-color: transparent;
  color: $of-forest-green-01;
  height: 32px;
  width: 32px;

  &:focus,
  &:active {
    background-color: transparent;
    color: $of-forest-green-01;
    outline: 2px solid $of-red-11;
  }

  &:hover {
    background-color: $of-red-05;
    color: $of-red-02;
  }
}
