@import '~styles/base.scss';

/* Default */

.TableRow {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;

  --max-font-size-for-truncation: 19px;

  &:not(.HeaderRow) {
    min-height: 42px;
  }

  @supports not (grid-template-columns: subgrid) {
    grid-template-columns: inherit;
  }
}

.TableCell {
  padding: 8px;
  position: relative;
  color: var(--formatting-font-color);
  font-size: var(--formatting-font-size);
  font-family: var(--formatting-font-family);
}

.ProductNameCell {
  & > h4 {
    font-weight: 700;
    font-size: var(--formatting-font-size);
    color: var(--formatting-font-color);
    overflow-wrap: anywhere;
    word-break: normal;
    text-align: left;
    margin: 0;
    padding-top: 7px;
    line-height: 1.2;
  }
}

.QuantityInputWrapper {
  height: 100%;
}

.EditIcon {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $of-forest-green-01;
  visibility: hidden;
  height: 26px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
  top: 24px;
  transform: translateY(-50%);
  right: calc(-1rem - 2px);

  &:hover {
    background-color: $of-edit-hover;
    border-color: $of-yellow;
  }
}

.TableCell.TableHead {
  display: flex;
  align-items: center;
}

.TableHead {
  background-color: $of-dark-green-05;

  &:first-of-type {
    border-top-left-radius: 2px;
  }

  &:last-of-type {
    border-top-right-radius: 2px;
  }

  button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: 0;
    outline: 0;
  }

  svg {
    color: $of-dark-green-05;
    justify-self: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
  }

  h4 {
    line-height: 1.5;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    justify-self: center;
    text-align: left;
    overflow-wrap: anywhere;
    word-break: normal;
    overflow: auto;
    font-weight: 700;
    color: var(--formatting-font-color);
    font-size: var(--formatting-font-size);
    font-family: var(--formatting-font-family);
  }
}

.TableRow:hover:has(.TableCell.IsEditable) {
  .EditIcon {
    visibility: visible;
  }
}

/* Special cases */

.ProductPriceCell {
  display: flex;
  text-align: right;
  justify-content: flex-end;

  h4 {
    text-align: right;
  }
}

.ProductPriceCell.TableHead {
  padding: 8px 0;

  /* When there is no price value min-content is 0 */
  min-width: min(calc(9 * var(--formatting-font-size)), calc(9 * var(--max-font-size-for-truncation)));

  h4 {
    padding: 0 8px 0 0;
  }

  svg {
    padding: 0 0 0 8px;
  }

  button {
    display: flex;
    text-align: right;
    justify-content: space-between;
  }
}

.ProductQuantity.TableHead {
  justify-content: center;
  min-width: min(calc(6 * var(--formatting-font-size)), calc(6 * var(--max-font-size-for-truncation)));

  h4 {
    text-align: center;
  }
}

.ProductQuantity.TableCell:not(.TableHead) {
  justify-content: center;
}

.TableHead:only-child {
  justify-content: center;
}

.TableHead.IsEditable {
  &:hover {
    background-color: $of-dark-green-04;

    svg {
      color: var(--formatting-font-color);
    }
  }
}

.ExpandedTable {
  max-width: 100%;
  display: grid;
  color: var(--formatting-font-color);
  gap: 0;
  border-bottom: 1px solid $of-dark-green-05;

  /*
    From MDN: https://developer.mozilla.org/en-US/docs/Web/CSS/var
    Note: var(--a,) is valid, specifying that if the --a custom property is not defined
    Or equals a CSS-wide keyword, the var() should be replaced with nothing.

    Refer: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Values_and_Units#css-wide_values
  */
  --name-column-size: var(--name-column-size,);
  --description-column-size: var(--description-column-size,);
  --price_1-colum-size: var(--price_1-colum-size,);
  --price_2-colum-size: var(--price_2-colum-size,);
  --quantity-column-size: var(--quantity-column-size,);

  grid-template-columns: var(--name-column-size,) var(--description-column-size,) var(--price_1-colum-size,) var(--price_2-colum-size,) var(--quantity-column-size,);

  &[data-columns~="name"] {
    --name-column-size: minmax(60px, auto);
  }

  &[data-columns~="description"] {
    --description-column-size: minmax(160px, auto);
  }

  &[data-columns~="price_1"] {
    --price_1-colum-size: 2fr;
  }

  &[data-columns~="price_2"] {
    --price_2-colum-size: 1fr;
  }

  &[data-columns~="count"] {
    --quantity-column-size: min-content;
  }

  /* When it is only quantity colum, let it take all the available space */
  &[data-columns="count"] {
    --quantity-column-size: auto;
  }

  /* Special cases */
  &:not(.ReadOnly) {
    .TableRow:not(.HeaderRow):hover {
      background-color: $of-edit-hover;
      cursor: pointer;

      .EditIcon {
        visibility: visible;
      }
    }
  }

  &:not(.ProductName):not(.Description) {
    .ProductQuantity.TableHead {
      button h4 {
        padding-left: 12px;
        text-align: center;
      }
    }
  }
}
