@import '~styles/base.scss';

.ApproveButton {
  height: 42px;
  padding: 13px 16px 13px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  font-weight: 500;
}

.SmallExpanded {
  padding: 14px;
}
