@import '~styles/base.scss';

.ExpandableArea {
  margin-bottom: 10px;

  .Hidden {
    display: none;
  }

  .Row {
    display: flex;
    margin: 0.25rem 0;

    > * {
      flex: 1;

      + * {
        margin-left: 1rem;
      }
    }
  }

  .MessageArea {
    padding: 24px 0;
  }

  .SectionHeader {
    font-size: 1rem;
  }
}
