@import '~styles/base.scss';

.AuthorBubble {
  display: flex;
  flex-direction: row;
  position: relative;
}

.NewMessageIndicator {
  position: absolute;
  width: 8px;
  border-radius: 4px;
  height: 8px;
  left: -8px;
  top: 0;
  background: $of-red-02;
  opacity: 1;
  transition: 0.5s ease;
  transition-delay: 1s;

  &.FadeOut {
    visibility: hidden;
    opacity: 0;
  }
}

.ParticipantBubble {
  width: 32px;

  span {
    margin-left: -32px;
    font-size: 14px;
    font-weight: bold;
    color: $of-forest-green-01 !important;
  }
}

.MessageInfoWrapper {
  margin: 0 8px;
}

.Author {
  font-weight: bold;
  font-size: 15px;
  word-break: break-word;
}

.MessageDate {
  color: $of-dark-green-02;
  font-size: 13px;
}
