@import '~styles/base.scss';

.Headers {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  align-items: baseline;
}

.SelectActionsContainer {
  display: flex;
  gap: 16px;
}

.SelectActions {
  padding: unset;

  > [class*=label] {
    font-weight: 400;
  }
}

.AvailableWorkspaces {
  min-height: 8rem;
  flex: 1;
  max-height: 280px;
}

.SelectWorkspace {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.EmptyStateHeader {
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.EmptyStateContent {
  line-height: 1.4;
  font-size: 14px;
  font-weight: 300;
  color: $of-thunder;
  margin-bottom: 1rem;
}
