@import '~styles/base.scss';

.Badge {
  border-radius: 0.5rem;
  display: block;
  margin-bottom: 13px;
  margin-right: 15px;
  padding: 8px 10px;
  position: relative;
  width: fit-content;
}

.BadgeIcon {
  margin-top: 2px;
  position: absolute;
  color: $of-forest-green-01;
}

.BadgeText {
  margin-left: 24px;
  font-weight: 600;
  color: $of-forest-green-01;
}

.ApprovalRequired {
  background-color: $of-purple-02;
}

.SingleSignature {
  background-color: $of-blue-05;

  svg {
    height: 18px;
    width: 18px;
  }

  .BadgeText {
    margin-left: 26px;
  }
}

.SigningOrderEnabled {
  background-color: $of-green-03;

  svg {
    path {
      stroke-width: 1.5;
    }
  }
}

.SignLaterEnabled {
  background-color: $of-edit-hover;

  svg {
    height: 18px;
    width: 18px;
  }
}

.ReadyToSign {
  background-color: $of-edit-hover;

  svg {
    path {
      stroke-width: 1.5;
    }
  }
}

.SignOnSameDeviceEnabled {
  background-color: $of-blue-05;

  svg {
    path {
      stroke-width: 1.5;
    }
  }
}
