@import '~styles/base.scss';

.Modal {
  width: 744px;
}

.Content {
  @include flex(1);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0.3rem 0;
  }
}

.FieldContainer {
  display: flex;
  flex-direction: column;
}

.LineContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.SeatsContainer {
  flex: 0 0 199px;
}

.Container {
  margin-top: 0.8rem;
}

.InvoiceContainer {
  margin-top: 0.8rem;
  max-width: 565px;
}

.Total {
  @include flex(1);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0 0 0;
  border: 1px solid $of-cloud;
  border-left: none;
  border-right: none;
  border-bottom: none;
  box-sizing: border-box;
  margin-top: 5px;
}

.CalculationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $of-cloud;
  padding-bottom: 0.7rem;

  .Calculation {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $of-cloud;
    padding-bottom: 1rem;
  }

  .InvoiceText {
    font-size: 11px;
    letter-spacing: -0.02em;
    line-height: 12px;
    text-align: right;
    color: $of-winter-green-dream;
    flex: 0 0 440px;
  }
}

.CheckContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  max-height: 1.5rem;
}

.BottomContainer {
  margin-top: 18px;
}
