.VideoToolbar {
  position: absolute;
  top: -5px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
  width: 242px; // 6 * 40px + 2 * 1px
  left: calc(50% - 118px);
  background: #fff !important;
  margin: 0 !important;
  box-shadow: 0 0 16px rgba(1, 58, 76, 0.2) !important;
  border-radius: 4px !important;
}
