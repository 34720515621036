@import '~styles/base.scss';

.SidebarSubmenu {
  background-color: $of-dark-green-01;
  transition: width 250ms ease-in-out;

  &.Expanded {
    left: 216px;
  }
}

.Mobile {
  max-height: 0;
  transition: max-height 250ms ease-in-out;

  & > a > span {
    margin-bottom: 10px !important;
  }
}

.Visible {
  &.Mobile {
    max-height: 800px;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 20px;

    & > a {
      &:last-child > span {
        margin-bottom: 0 !important;
      }
    }
  }
}
