@import '~styles/base.scss';

.SignButton {
  height: 42px;
  padding: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: 500;
}

.Collapsed {
  padding: 0 16px;
}
