@import '~styles/base.scss';

.AddButton {
  span {
    color: black;
    margin-left: 2px !important;
  }

  div {
    border-color: transparent;
    display: flex;

    button {
      padding-left: 12px;
      margin-left: 6px;
    }

    &:hover {
      border-color: transparent;
      text-decoration: underline;
      text-decoration-color: black;
    }
  }
}

.SortableDrag {
  opacity: 1 !important;
  background-color: $of-edit-hover;
  border-radius: 4px;
}

.EmptyBoxWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.InvalidMessage {
  font-size: 0.6875rem;
  color: $of-red-02;
  line-height: 13px;
}

.FailedAttachmentsWrapper {
  border: 1px solid $of-red-02;
  border-radius: 4px;
}
