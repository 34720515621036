@import '~styles/base.scss';

.Danger {
  &:hover,
  &:focus {
    background-color: $of-red-09;

    button {
      color: $of-red-02;
    }
  }
}

.Users {
  .Buttons {
    display: flex;
    margin: auto 0;

    button {
      margin-right: 0.5rem;
    }
  }

  .User {
    line-height: 2;
    word-break: break-all;
    word-break: break-word;
    position: relative;

    .AdministratorIcon {
      position: relative;
      margin-left: 5px;
      margin-right: 3px;
      top: 4px;
    }
  }

  .ActionBarText {
    margin-left: 2rem;
    color: $of-forest-green-01;

    span {
      font-size: 0.8125rem;
    }
  }

  .Header {
    margin-top: 0;
    font-weight: 600;
    font-size: 1.125rem;
  }

  .SupportLink {
    font-weight: 500;
  }

  .LastActiveColumn {
    min-width: 145px;
  }

  .Authentication {
    min-width: 50px;
  }

  .AuthenticationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Email {
    font-size: 0.8rem;
  }

  .PaginationContainer {
    display: flex;
    min-height: 2rem;
    padding: 0 2rem 1rem;

    & > :first-child {
      padding: 0;
      margin-right: 0.8rem;

      @include flex(1);
    }
  }

  .ReducedLineHeight {
    line-height: 1.2rem !important;
  }

  .AccountRoleName {
    margin-bottom: 0;
  }
}

.StartTrialButton {
  display: inline;
  padding: 0;
  font-weight: bold;
}
