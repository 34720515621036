@import '~styles/base.scss';

/* stylelint-disable no-descending-specificity */

/* de-nesting of formFielSet and ReadOnly makes file harder to
read, disabling lint rule to keep readability */

.ContractSelection {
  margin: 0;
  margin-bottom: 24px;
  flex-direction: column;

  label {
    margin: 3px 0 0 0;

    :global(.ReactSelect__control) {
      height: 54px !important;
    }
  }
}

.SingleTimePicker {
  label {
    height: 20px !important;
  }
}

.ContractCard {
  line-height: 15px;
  letter-spacing: -0.05em;
  margin-bottom: 20px;

  div {
    margin-top: 0 !important;
  }
}

.FindContractIcon {
  margin: 16px 5px 14px 13px;
}

.MiniContractCardLabel {
  font-size: 13px;

  > div {
    margin: 0 !important;
    border-radius: 6px;
  }
}

.CancelButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  button {
    padding: 0 !important;
    color: $of-forest-green-03 !important;
    margin: 19px 21px 19px -3px !important;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.OverrideSingleDatePicker {
  span {
    padding-bottom: 0 !important;
  }

  button {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      cursor: pointer;
      color: $of-dark-green-01;
    }
  }

  :global(.SingleDatePickerInput) {
    height: 40px;
  }
}

.TextFieldLabel {
  > input:disabled {
    background: $of-inactive !important;
    color: $of-forest-green-03 !important;
    border: 1px solid $of-forest-green-05 !important;
    cursor: not-allowed;
  }
}

.SubjectField {
  margin-bottom: 8px;
}

.MessageField {
  margin-bottom: 8px;
  flex-flow: column;

  label {
    margin-left: unset;
  }
}

.TextArea {
  > textarea:disabled {
    background: $of-inactive !important;
    color: $of-forest-green-03 !important;
    border-color: $of-forest-green-05 !important;
    cursor: not-allowed;
    resize: none;
  }
}

.Row {
  display: flex;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;

      @media screen and (max-width: 400px) {
        margin: 0;
      }
    }
  }
}

.ReadOnly {
  .TextFieldLabel {
    > input:disabled {
      border-color: #fff !important;
      cursor: default !important;
    }
  }

  &:disabled {
    .TextFieldLabel {
      border-color: #fff !important;
      cursor: default !important;
    }

    :global(.ReactSelect__control) {
      cursor: default !important;
    }

    :global(.ReactSelect__control--is-disabled) {
      border: none !important;
      cursor: default !important;

      &:hover {
        cursor: default !important;
      }
    }

    // Single Date picker.
    .OverrideSingleDatePicker {
      :global(.SingleDatePickerInput) {
        border-color: $of-inactive !important;
        cursor: default !important;
      }

      & input {
        cursor: default !important;
      }

      & button {
        display: none;
        cursor: default;
      }
    }
  }

  .TextArea {
    > textarea:disabled {
      border-color: #fff !important;
      cursor: default !important;
    }
  }
}

.FormFieldset {
  border: 0;
  margin: 0 0 20px 0;
  padding: 0;
  min-width: auto;

  :global(.ReactSelect__value-container) {
    padding: 0 8px !important;
    max-height: 40px !important;
  }

  :global(.ReactSelect__value-container--is-multi) {
    padding: 8px 12px !important;
    max-height: 82px !important;
  }

  :global(.ReactSelect--is-disabled) {
    pointer-events: unset;

    :global(.ReactSelect__control--is-disabled) {
      background: $of-inactive !important;
      border-color: $of-forest-green-05 !important;
    }

    :global(.ReactSelect__single-value) {
      color: $of-forest-green-03 !important;
    }

    :global(.ReactSelect__multi-value__label) {
      padding-right: 6px;
    }

    :global(.ReactSelect__multi-value__remove) {
      pointer-events: none;

      svg {
        display: none;
      }
    }
  }

  &:disabled {
    -webkit-text-fill-color: $of-forest-green-03;

    .TextFieldLabel {
      color: $of-forest-green-03 !important;

      &:hover {
        border-color: $of-forest-green-05;
      }
    }

    // Single Date picker.
    .OverrideSingleDatePicker {
      display: flex;

      :global(.SingleDatePickerInput) {
        background: $of-inactive;
        border-color: $of-forest-green-05;
        cursor: not-allowed;

        & input {
          background: $of-inactive !important;
          cursor: not-allowed;
          font-style: revert;
          border-radius: 4px !important;
          -webkit-text-fill-color: $of-forest-green-03;
        }

        & button {
          cursor: not-allowed;
          color: unset;
        }
      }
    }
  }

  .RecipientsField {
    display: unset;
  }

  &.ReadOnly {
    .RecipientsField {
      :global(.ReactSelect__control--is-disabled) {
        cursor: default !important;
        background: #fff !important;
        border: 1px solid $of-forest-green-05 !important;
        border-radius: 6px !important;
      }
    }
  }
}

/* This is to circumvent styling from legacy in the contract view */
.ContractViewInternalReminder {
  :global(.DateInput) {
    margin-top: 2px;

    > input {
      border: none;
      box-shadow: none !important;
    }
  }

  textarea:focus {
    box-shadow: none !important;
  }

  :global(.ReactSelect__value-container--is-multi) {
    padding: 6px 8px !important;
  }
}

.DisabledOption {
  cursor: not-allowed !important;
  background: #fff !important;
  color: #cacccd !important;
}

.RecipientsValidationMessage {
  color: $of-red-02;
  line-height: 0.75rem;
  font-size: 0.75rem;
  margin: 0.25rem 0 0.25rem 0;
  min-height: 0.85rem;
}

:root {
  --of-edit-hover: #{$of-edit-hover};
  --warning-text-font-size: 14px;
  --warning-text-line-height: 1.5;
  --warning-text-total-block-space: calc(var(--warning-text-font-size) * var(--warning-text-line-height));
  --warning-text-extra-block-space: calc(var(--warning-text-total-block-space) - var(--warning-text-font-size));
  --warning-text-block-start-space: calc(var(--warning-text-extra-block-space) / 2);
}

.WarningContainer {
  // box config
  margin: 5px 0 15px; // top right & left bottom
  padding: 8px 7px;
  min-height: 0.85rem;
  border-radius: 4px;
  // layout
  display: flex;
  gap: 10px;
  align-items: flex-start;
  // colors
  background-color: transparent;
}

.WarningContainer:empty {
  margin: 0;
}

.WarningIcon {
  // logical property name for padding-top
  padding-block-start: var(--warning-text-block-start-space);
  flex-shrink: 0;
}

.WarningText {
  font-size: var(--warning-text-font-size);
  line-height: var(--warning-text-line-height);
}

.RecipientsNoAccessWarning {
  background-color: $of-edit-hover;
}

// Fix to make sure scrollbar doesn't appear when opening datepicker
.DatePickerField {
  > label {
    margin-bottom: 8px !important;
  }
}

.MiniContractCardAsterisk {
  font-size: 16px;
  color: $of-red-02;
  margin-left: 0.08rem;
}

.AIAssistWrapper {
  position: relative;
  padding-top: 15px;
}

.AIAssistContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.Disabled {
  .DisabledBackground {
    :global(.ReactSelect__control) {
      background-color: #fff !important;
    }

    :global(.ReactSelect__multi-value) {
      background-color: $of-inactive !important;
    }
  }

  :hover {
    cursor: default;
  }

  svg {
    display: none;
  }
}

/* Bug fix for cursor jumping down too quickly in multi select */
:global(.ReactSelect__input-container) {
  width: 0;
}
