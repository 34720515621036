@import '~styles/base.scss';

.SingleMessageBoxContainer {
  background-color: $of-green-09;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  > div {
    margin: 0;
  }
}

.PopoverContentContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 1029 !important;
  }

  .PopoverContent {
    width: 320px;
    padding: 0;
    border-radius: 8px;
    box-shadow: 8px 6px 30px rgba(0, 0, 0, 0.3);
    transform-origin: var(--radix-popover-content-transform-origin);
    animation: scaleIn 0.2s ease;
    overflow: hidden;

    > div:first-child {
      margin: 0;
    }
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
