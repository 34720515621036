@import '~styles/base.scss';

.InputContainer {
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: calc(100% - 14px);

  .Label {
    align-items: center;
    color: var(--formatting-font-color);
    display: flex;
    font-family: var(--formatting-font-family);
    font-size: var(--formatting-font-size);
    font-weight: 600;
    line-height: 1.55;
    margin-bottom: 5px;
    overflow-wrap: anywhere;

    .Text {
      margin: 0;
    }

    .Required {
      color: $of-red-02;
      font-family: 'Work Sans', sans-serif;
      margin-left: 4px;
    }

    .DataFieldIcon {
      color: $of-forest-green-01;
      margin-left: 6px;
    }
  }

  .RequiredIcon {
    position: absolute;
    right: 0;
    top: -29px;
  }
}
