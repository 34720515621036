@import '~styles/base.scss';

.EmptyState {
  display: flex;
  padding: 16px 8px 0 8px;
  flex-direction: column;
  align-items: flex-start;

  span {
    color: $of-forest-green-01 !important;
  }
}

.NoMatchFound {
  overflow-wrap: break-word;
  font-size: 0.875rem;
  line-height: normal;
  letter-spacing: -0.28px;
}

.SearchTerm {
  font-weight: 700;
  word-break: break-all;
}

.UserSuggestion {
  text-align: left;
  margin-top: 8px;
  padding-left: 25px;
  font-size: 0.875rem;
}
