@import '~styles/base.scss';

:root {
  --animation-ms-value: 150ms;
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

.Icon {
  transition: transform 300ms;
}

.Trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: transparent;
  border: 0;
  padding: 0;

  h3,
  svg {
    margin: 0;
    color: $of-forest-green-01;
  }
}

.Trigger[data-state='open'] > .Icon {
  transform: rotate(180deg);
}

.Content {
  background: white;
  overflow: hidden;
  padding-top: 16px;

  [class*=Divider]:last-child {
    margin-bottom: 4px;
  }
}

// Duration and reminders
.Accordion:last-child {
  .Content {
    padding-top: 16px;
  }
}

.Content[data-state='open'] {
  animation: slideDown var(--animation-ms-value) ease-in-out;
}

.Content[data-state='closed'] {
  animation: slideUp var(--animation-ms-value) ease-in-out;
}

.Title {
  font-size: 1.125rem;
  font-weight: 600;
}
