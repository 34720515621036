@import '~styles/base.scss';

.CancellationReasonContainer {
  display: inline-block;

  .CancellationReasonButton {
    border-radius: 4px;
    border: 1px solid $of-forest-green-04;
    margin-top: 16px;
    font-size: 14px;
    color: $of-forest-green-01;
  }
}
