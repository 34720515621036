@import '~styles/base.scss';

.ChangePending {
  background-color: $of-blue-05;
  padding: 4px 8px;
  border-radius: 8px;
  color: $of-forest-green-01;
  font-size: 0.75rem;
  width: fit-content;
  font-weight: 500;
  height: fit-content;
}
