@import '~styles/base.scss';

.Disabled {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    background-color: unset;
  }
}

.MenuItem {
  display: flex;
  gap: 8px;
}
