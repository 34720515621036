@import '~styles/base.scss';

.Container {
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;
}

.Header {
  margin: 0 0 12px 0;
}
