.ExtensionSidebarStatus {
  margin-bottom: 30px;

  .ExtensionSidebarHeader {
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .ExtensionSidebarPlanInformation {
    font-size: 14px;
    font-style: italic;
  }
}
