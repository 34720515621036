@import '~styles/base.scss';

.EditableElement {
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  div[data-slate-editor="true"][contenteditable="true"] {
    overflow: hidden;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      outline: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  span[data-slate-string="true"],
  span[data-slate-zero-width="n"] {
    display: block;
    line-height: 1.375rem;
  }

  span[data-slate-zero-width="n"] {
    width: 100px;
  }
}
