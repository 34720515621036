@import '~styles/base.scss';

.KPI {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 22px 22px 16px 22px;
  height: 92px;
  background-color: white;
  box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
  border-radius: 8px;

  &.withoutLink {
    width: 25%;

    &:not(:last-child) {
      margin-right: $spacing-page;
    }
  }

  &.withLink {
    &:hover {
      transition: all ease-in-out 250ms;
      box-shadow: 0 0 16px rgba($of-forest-green-01, 0.4);
    }
  }

  &.Sent {
    background-color: $of-blue-06;
  }

  &.Signed {
    background-color: $of-green-04;
  }

  &.Declined {
    background-color: $of-red-06;
  }

  &.Overdue {
    background-color: $of-orange-03;
  }

  .StateHeader {
    display: flex;
    align-items: center;

    .IconContainer {
      display: flex;
      width: 44px;
      height: 44px;
      border-radius: 22px;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      color: white;

      &.Sent {
        background-color: $of-blue-03;
      }

      &.Signed {
        background-color: $of-green-01;
      }

      &.Declined {
        background-color: $of-red-02;
      }

      &.Overdue {
        background-color: $of-contract-overdue-new;
      }
    }

    .Count {
      color: $of-forest-green-01;
      font-weight: 600;
      font-size: 48px;
      line-height: 48px;
    }
  }

  .StateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $of-forest-green-01;

    .State {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    .ContractValue {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.Link {
  width: 25%;

  &:hover {
    text-decoration: none;
  }

  &:not(:last-child) {
    margin-right: $spacing-page;
  }
}
