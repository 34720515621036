@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

:global(.fadeIn) {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

:global(.fadeOut) {
  animation-name: fadeOut;
}

// fadeIn: --- Down / Up --- //
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -1%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

:global(.fadeInDown) {
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 1%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

:global(.fadeInUp) {
  animation-name: fadeInUp;
}

// fadeIn: --- Left / Right --- //
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-1%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

:global(.fadeInLeft) {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(1%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

:global(.fadeInRight) {
  animation-name: fadeInRight;
}

// fadeOut: --- Down / Up --- //
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 1%, 0);
  }
}

:global(.fadeOutDown) {
  animation-name: fadeOutDown;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -1%, 0);
  }
}

:global(.fadeOutUp) {
  animation-name: fadeOutUp;
}

// fadeOut: --- Left / Right --- //
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-1%, 0, 0);
  }
}

:global(.fadeOutLeft) {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(1%, 0, 0);
  }
}

:global(.fadeOutRight) {
  animation-name: fadeOutRight;
}
