.Error {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Message {
    margin: 20px 0 0;

    .ReloadButton {
      display: inline;
      padding: 0 0.3rem;
    }
  }
}
