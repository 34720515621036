@import '~styles/base.scss';

.BulkActionsMenu {
  background-color: white;
  margin-left: -1.5rem;
  margin-right: -2rem;
  padding-left: 1.5rem;
  padding-right: 2rem;
  padding-top: calc(4px + 1px); // 1px to count for -1px top value
  padding-bottom: 4px;

  &,
  & > * {
    box-sizing: border-box;
  }

  .Content {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 42px;
    // same as padding-left of the contract card to keep the checkbox aligned with the checkboxes in the contract card
    padding-left: 16px;
    color: $of-forest-green-01;
  }

  .CheckboxLabel {
    margin-left: 8px;
  }

  .SelectedCount {
    display: inline-block;
    color: $of-forest-green-03;
    font-size: 0.8125rem;
  }
}
