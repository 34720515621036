@import '~styles/base.scss';

.CommentAnnotation {
  border-bottom: solid 3px rgba($color: $of-yellow, $alpha: 0.6);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.HighlightedComment {
  span[data-slate-string=true] {
    background-color: rgba($color: $of-yellow, $alpha: 0.6) !important;
    transition: background-color 0.3s ease-out;
  }
}

.PopupContent {
  max-width: 340px;
  min-width: 340px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 8px 6px 30px rgba(0, 0, 0, 0.3);

  @include respondToNewBreakPoint(small) {
    height: var(--radix-popper-available-height);
  }

  div[class*='SingleMessageBox'] {
    margin: 0 auto;
  }
}

.AnimateComment {
  span[data-slate-string=true] {
    animation: pulseInComment 600ms ease-out 600ms, pulseOutComment 600ms ease-out 1200ms;
  }
}

.SuggestionAnnotation {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.HighlightedSuggestion {
  span[data-slate-string=true] {
    background-color: $of-red-05 !important;
    transition: background-color 0.3s ease-in-out;
  }
}

.AnimateSuggestion {
  span[data-slate-string=true] {
    animation: pulseInSuggestion 600ms ease-out 600ms, pulseOutSuggestion 600ms ease-out 1200ms;
  }
}

.AcceptSuggestionAnimation {
  & > * {
    animation: acceptSuggestion 0.3s ease-in-out forwards;
  }
}

@keyframes pulseInComment {
  0% {
    background-color: none;
  }

  100% {
    background-color: rgba($color: $of-yellow, $alpha: 0.6);
  }
}

@keyframes pulseOutComment {
  0% {
    background-color: rgba($color: $of-yellow, $alpha: 0.6);
  }

  100% {
    background-color: none;
  }
}

@keyframes pulseInSuggestion {
  0% {
    background-color: none;
  }

  100% {
    background-color: $of-red-05;
  }
}

@keyframes pulseOutSuggestion {
  0% {
    background-color: $of-red-05;
  }

  100% {
    background-color: none;
  }
}

@keyframes acceptSuggestion {
  0% {
    opacity: 0.9;
  }

  25% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.3;
  }

  100% {
    opacity: 0.1;
  }
}
