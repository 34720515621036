@import '~styles/base.scss';

.ExportAccount {
  .Success {
    display: flex;
    align-items: center;
    margin-top: 1.8rem;

    .Icon {
      margin-right: 0.6rem;
      color: $of-peace;
      animation: zoom-in 300ms ease-in 0s 1;
    }
  }
}
