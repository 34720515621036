@import '~styles/base.scss';

.InternalReminderEventInsight {
  width: 100%;
  display: flex;
  gap: 1%;
  align-items: center;
  font-size: 10px;

  svg {
    margin-right: 2px;
  }

  & > span {
    width: 90%;
    text-align: left;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .PermissionIcon {
    button {
      color: $of-forest-green-01 !important;
      padding: 0;
    }

    svg {
      opacity: 0;
      margin-left: 2px;
    }
  }
}
