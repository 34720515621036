@import '~styles/base.scss';

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.Settings {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: start;
  color: $of-forest-green-01;
  background-image: none;
  text-shadow: none;
  margin: 0;
  padding: 8px;
  outline: none;
  user-select: none;
  position: relative;
  z-index: 11;
  border-radius: 8px;
  background-color: white;
  box-shadow: 8px 6px 20px 0 rgba(0, 0, 0, 0.1490196078);
  border: 1px solid rgba(0, 0, 0, 0.05);
  transform-origin: 100% 0;
  overflow-x: hidden;
  min-width: 310px;

  h4 {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
    font-weight: 500;
    color: $of-forest-green-01;
  }
}

.Settings[data-state='open'] {
  animation: scaleIn 100ms ease-out;
}

.Settings[data-state='closed'] {
  animation: scaleIn 100ms ease-in;
}

.SettingsTrigger {
  cursor: pointer;
  color: $of-forest-green-01;
  background-color: white;
  border: none;
  padding: 2px 4px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  max-width: 310px;
  font-size: 0.875rem;
  border-radius: 6px;

  &:active {
    outline: 1px solid $of-forest-green-01;
    background-color: $of-dark-green-05;
  }

  &:hover {
    background-color: $of-dark-green-04;
  }
}

.ToggleArea {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
}

.Divider {
  border: none;

  /* Get close oneflow color (this is from figma as it is) */
  border-top: 1px solid #dcdcdc;
}

.SettingsCheckbox {
  padding: 8px;
}

.ToggleTooltipMessage {
  max-width: max-content !important;
  display: inline-block;
  font-size: 0.75rem;
}
