@import '~styles/base.scss';

.SelectField {
  flex: 1;
  font-size: 14px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-weight: 400;
  cursor: default !important;

  &.InheritFontStyles {
    color: var(--formatting-font-color);
    font-family: var(--formatting-font-family);
    font-size: var(--formatting-font-size);
  }

  .LabelContainer {
    cursor: default !important;
  }

  .Label {
    cursor: default !important;
    padding-bottom: 0.25rem;
    font-weight: 400;
    color: $of-forest-green-01;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.02em;

    &.BoldLabel {
      font-weight: 600;
    }
  }

  .icon {
    float: right;
  }

  &.Disabled {
    pointer-events: none;
  }

  :global(.ReactSelect--is-disabled) {
    pointer-events: initial;
  }

  :global(.ReactSelect__control--is-disabled) {
    background: $of-inactive;
    border-color: $of-inactive !important;
    cursor: not-allowed;

    :global(.ReactSelect__single-value) {
      color: $of-forest-green-03;
    }
  }

  input {
    grid-column-end: span 2 !important;
  }
}

.ErrorMessage {
  cursor: default !important;
  color: $of-red-02;
  line-height: 0.75rem;
  font-size: 0.75rem;
  margin: 0.25rem 0 0.25rem 0;
  min-height: 0.85rem;
}
