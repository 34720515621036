@import '~styles/base.scss';

.Table {
  width: 100%;
  padding: 0.6rem 1rem;
  color: $of-thunder;
  border: none;
  border-spacing: 0;

  @include respondTo(lg) {
    padding: 1.2rem 2rem;
  }

  .Actions {
    display: flex;
    justify-content: flex-end;

    > div + div {
      margin-left: 0.25rem;
    }
  }

  .ActionsContainer {
    display: flex;
    justify-content: flex-end;
  }

  .TableHead {
    text-align: left;

    .TableHeader {
      padding: 26px 16px;
      text-align: left;
      color: $of-forest-green-01;
      font-weight: 600;
      white-space: nowrap;
      border-bottom: solid 1px $of-dark-green-05;
      letter-spacing: -0.02em;
    }
  }

  .TableBody {
    .TableRow {
      &:hover {
        background-color: $of-edit-hover;
      }
    }

    .TableCell {
      padding: 26px 16px;
      color: $of-forest-green-01;
      border-bottom: solid 1px $of-dark-green-05;
      letter-spacing: -0.02em;

      [data-align-with-tooltip] {
        width: fit-content;
      }
    }

    .TableLoading {
      pointer-events: none;
      width: 100%;

      .LoadingContent {
        text-align: center;
        padding: 4rem;

        .LoadingText {
          margin-top: 0.5rem;
          color: $of-reign;
        }
      }
    }

    .TableEmpty {
      pointer-events: none;
      width: 100%;

      .TableEmptyContent {
        text-align: center;
        padding: 1rem;
      }
    }
  }

  .SelectionColumn {
    width: 1rem;

    .SelectBox {
      margin-left: 1rem;
    }
  }

  .HeaderLink {
    display: inline-flex;

    button {
      font-weight: bold;
      padding: 0;
      margin: 0;
    }
  }
}
