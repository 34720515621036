@import '~styles/base.scss';

.CustomInputWithIcon {
  margin: 16px 5px 14px 13px;
}

.CustomOption {
  padding: 10px 18px;

  &:hover {
    cursor: pointer;
    background: $of-dark-green-05;
  }

  &:first-child {
    margin-top: 10px;
  }
}

.CustomOptionInner {
  background: #fff;
  border-radius: 6px;

  > div:first-child {
    margin-top: 0;
  }
}
