@import '~styles/base.scss';

.SelectedFontFamilyDropdown {
  background-color: $of-dark-green-05;
  display: flex;
}

.CustomOption {
  padding: 8px 18px;

  &:hover {
    cursor: pointer;
    background: $of-dark-green-05;
  }
}

.FontSelectField {
  div[class*=value-container] * {
    font-family: var(--formatting-font-family);
  }
}

.FontFamilyCheckMark {
  margin-left: auto;
  place-self: center;
  height: 12px;
  width: 12px;
}

.CustomOptionText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  padding: 4px 0;
}

.Label {
  font-size: 0.875rem;
  font-weight: 600;
}
