.Row {
  display: flex;
  margin: 0.25rem 0;

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }
}

.Note {
  margin-top: 0;
}

.FirstChildFixedHeight {
  > :first-child > div {
    flex: none;
    height: 40px;
  }
}

.Buttons {
  display: flex;

  & > * {
    margin-left: 0.3rem;
  }
}

.CodeInfo {
  margin-top: 0.5em;
}

.SmsContainer {
  margin: 0 auto auto auto;
  text-align: center;

  > .SmsLabel {
    margin-top: 1em;
    font-size: 18px;
    line-height: 1.3;
  }
}

.PhoneNumberContainer {
  max-width: 300px;
  margin: 8px auto 0 auto;

  :global(.country-list) {
    max-height: 100px;
    width: 200px;
    margin-top: 4px;
    text-align: left;
  }

  :global(.react-tel-input) {
    max-width: 200px;
    margin: auto;

    :global(input) {
      width: 200px;
    }
  }
}
