@import '~styles/base.scss';

.ModalBody {
  padding-top: 24px !important;

  .Description {
    padding-bottom: 24px;
  }
}

.Button {
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.OpenModalButton {
  color: $of-blue-02;
  font-size: 0.8125rem;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 4px;
}
