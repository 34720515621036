@import '~styles/base.scss';

.ScaleWrapper {
  display: inline-block;
  width: $a4-width;
  height: 100%;
  transform-origin: 0 0;
}

.PdfViewerContainer {
  overflow: hidden;

  [class*=ErrorContainer] {
    margin: 0;
  }
}

.LoadingDocument {
  width: 100%;
  height: 100%;
}

.PageBorder {
  margin-bottom: 10px;
  border-bottom: solid 1px $of-legacy-lightest;
}

.LoadingStatusBox {
  width: auto !important;
  justify-content: start;
  padding-top: 100px;
  margin: 0 20px;
}
