@import '~styles/base.scss';

.ToolbarButton {
  border-radius: 6px !important;
  border: none;
  height: 35px;
  padding: 9.5px 8px;

  span {
    line-height: 15px;
  }

  &:hover {
    background-color: rgba($of-forest-green-01, 0.08);
  }
}
