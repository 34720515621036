@import '~styles/base.scss';

.MoveButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  color: $of-forest-green-01;
  border-radius: 6px;

  &:disabled {
    color: $of-forest-green-04;
    cursor: not-allowed;
  }

  &:hover {
    background-color: rgba($of-forest-green-01, 0.08);
  }
}
