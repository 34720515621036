.InitialLoadingContainer {
  margin: 100px 0;
}

.ScrollLoadingContainer {
  height: 24px;
}

.Loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ScrollContainer {
  height: 600px;
  overflow-y: scroll;
}
