@import '~styles/base.scss';

.ConfirmText {
  font-weight: 600;
}

.MarkAsDeclinedMenuItem {
  &:hover,
  &:focus-within {
    background-color: $of-red-09;

    button {
      color: $of-red-02;
    }
  }
}
