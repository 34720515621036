@import '~styles/base.scss';

.Divider {
  height: 1px;
  margin: 1rem 0;
  overflow: hidden;
  border-bottom: 1px dashed $of-forest-green-05;
}

.SolidDivider {
  height: 1px;
  margin: 1rem 0;
  overflow: hidden;
  border-bottom: 1px solid $of-forest-green-05;
}
