@import '~styles/base.scss';

.MessageTop {
  flex: 1;
  width: 100%;
  margin: 0 0 5px 0;
  text-align: center;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .SelectedFile {
    text-decoration: underline;
    margin-bottom: 0.825rem;
  }
}

.MessageBottom {
  flex: 1;
  width: 100%;
  font-size: 0.85rem;
  text-align: center;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.ImageRecommendation {
  margin-top: 2em;

  @include respondTo(md, max) {
    margin-top: 1em;
  }
}
