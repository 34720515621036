@import '~styles/base.scss';

.SnackbarContainer {
  visibility: visible;

  :global(.MuiAlert-message) {
    color: $of-forest-green-01;
    font-size: 16px;
  }

  :global(.MuiSnackbar-root) {
    box-shadow: 0 2px 10px rgba(black, 0.3);
  }

  :global(.MuiAlert-root) {
    align-items: center;
    padding: 10px 16px !important;
  }

  :global(.MuiAlert-icon) {
    color: $of-forest-green-01 !important;
  }

  :global(.MuiSnackbar-anchorOriginBottomLeft) {
    left: 80px;
  }

  @media only screen and (max-width: $break-md) {
    :global(.MuiSnackbar-anchorOriginBottomLeft) {
      left: 25px !important;
    }
  }
}

.Alert {
  :global(.MuiSnackbar-root) {
    background-color: $of-red-05;
    border-radius: 2px;
  }

  :global(.MuiAlert-message) {
    color: $of-red-01;
    font-size: 14px;
    font-family: 'Proxima Nova', sans-serif;
  }

  :global(.MuiAlert-icon) {
    color: $of-red-02 !important;
  }

  :global(.MuiAlert-standardWarning) {
    background-color: $of-red-05 !important;
  }

  :global(.MuiIconButton-label) {
    color: $of-red-02;
  }
}

.Success {
  :global(.MuiIconButton-label) {
    display: none;
  }

  :global(.MuiSnackbar-root) {
    border-radius: 2px;
  }
}
