@import '~styles/base.scss';

.FormItem {
  > * + * {
    margin-top: 4px;
  }
}

.FormLabel {
  color: $of-forest-green-01;
  margin-bottom: 4px;
  font-size: 0.8125rem;
}
