@import '~styles/base.scss';

.Notifications {
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style: none;
  margin-right: 2rem;
  color: $of-forest-green-01;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .EventGroup:not(:first-child) {
    margin-top: 30px;
  }

  .GroupHeader {
    font-weight: 500;
    font-size: 1.1rem;
  }

  .ToggleAll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid $of-inactive;

    .Label {
      flex: 1;
      margin-left: 1rem;

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: 1 1 auto;
      }
    }
  }

  .Event {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-top: 1px solid $of-inactive;

    &:last-child {
      border-top: 1px solid $of-inactive;
      border-bottom: 1px solid $of-inactive;
    }

    .Label {
      flex: 1;
      margin-left: 1rem;

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: 1 1 auto;
      }
    }
  }
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ActionButtons {
  display: flex;
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.TooltipSpan {
  position: absolute;
}
