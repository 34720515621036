@import '~styles/base.scss';

.ExtensionConfigurationHeader {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .AuthSuccess {
    color: $of-peace;
    margin-left: 0.35rem;
  }

  .AuthFailed {
    color: $of-passion;
    margin-left: 0.35rem;
  }
}

.ExtensionConfigurationBody {
  margin-top: 0;
  padding-bottom: 10px; /* padding to avoid margins from collapsing */
}

.Authorization {
  display: flex;
  flex-direction: column;

  .AuthorizationForm {
    > :last-child {
      max-width: fit-content;
    }

    .AuthorizeButton {
      align-self: flex-start;
      margin: 10px 0;
    }
  }
}
