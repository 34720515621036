.Label {
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.Actions {
  display: flex;
  justify-content: flex-end;
}
