@import '~styles/base.scss';

$footer-height: 80px;
$footer-mobile-height: 50px;

.Body {
  display: flex;
  flex-flow: column wrap;
  height: 100vh;
  min-width: 320px;

  @include respondTo(md, max) {
    flex-flow: column-reverse wrap;
  }

  @include flex(1);
}

.Content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - #{$footer-height});
  overflow-y: auto;
  align-items: center;
  justify-content: center;

  @include flex(1);

  @include respondTo(md, max) {
    max-height: calc(100vh - #{$footer-mobile-height});
  }

  @include respondTo(md, max) {
    padding-bottom: 4rem;
  }
}

.Footer {
  background-color: $of-forest-green-01;
  margin-top: auto;
  height: $footer-height;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respondTo(md, max) {
    height: $footer-mobile-height;
  }
}

.Back {
  margin-right: 15px;
  color: white;
  border: 1px solid white;

  &:hover {
    border: 1px solid white;
    background-color: $of-forest-green-02;
  }

  @include respondTo(md, max) {
    line-height: 1;
    font-size: 0.8rem;
  }
}
