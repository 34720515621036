@import '~styles/base.scss';

.ModalBody {
  padding-top: 24px !important;

  .Description {
    padding-bottom: 24px;

    a {
      text-decoration-line: underline;
      color: $of-forest-green-01;
    }
  }

  .ModalTitle {
    font-weight: 600;
    color: $of-forest-green-01;
    margin-bottom: 17px;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .CheckboxGroup {
    div {
      &[class*='FilterRow']:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

.Button {
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.OpenModalButton {
  color: $of-blue-02;
  font-size: 0.8125rem;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 4px;
}

.CustomizeOptionsButton {
  padding: 0;
}
