@import '~styles/base.scss';

.Lifecycle {
  display: flex;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
}

.LifecycleSidebarContainer {
  flex: 1 1 25%;
  height: auto;
  padding-right: 1.5rem;
  max-width: 15rem;

  @media screen and (max-width: 1080px) {
    padding-right: 0;
    max-width: none;
  }
}

.LifecycleSidebar {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;

  button {
    justify-self: center;
    margin-bottom: 0.5rem;
    padding: 0 !important;
  }
}

.LifecycleCalendar {
  flex: 1 1 75%;
  background-color: white;
  height: 100%;
  min-width: 0;

  @media screen and (max-height: 750px) {
    height: calc(100% - 4rem);
  }

  @media screen and (min-height: 1250px) {
    height: calc(100vh - 250px);
  }

  @media screen and (max-width: 1400px) {
    height: 75%;
  }

  :global(.rbc-calendar) {
    height: calc(100% - 2rem);
  }

  :global(.rbc-event-content) {
    height: 46px;
  }

  :global(.rbc-toolbar) {
    button {
      line-height: inherit;
      border-color: $of-forest-green-04;
      border-radius: 4px !important;
      color: $of-forest-green-01;

      &:hover,
      &:focus {
        background-color: $of-dark-green-05;
        cursor: pointer;
        outline: none;
      }
    }

    button:nth-child(2),
    button:nth-child(3) {
      width: 25px;
      height: 25px;
      border: none;
      box-shadow: none;
      padding: 0;
      margin-right: 4px;
      border-radius: 25px !important;
      -webkit-touch-callout: none;

      /* iOS Safari */
      -webkit-user-select: none;

      /* Safari */
      -khtml-user-select: none;

      /* Konqueror HTML */
      -moz-user-select: none;

      /* Old versions of Firefox */
      -ms-user-select: none;

      /* Internet Explorer/Edge */
      user-select: none;

      /* Non-prefixed version, currently */
    }

    button:disabled,
    button[disabled] {
      color: lighten($of-thunder, 40);
      pointer-events: none;
    }
  }

  :global(.rbc-btn-group) {
    display: flex;

    button:nth-child(1) {
      font-size: 12px;
      padding: 6px 8px 4px;
    }

    button:nth-child(2) {
      margin-left: 20px;
    }
  }
}
