.UserBadge {
  margin-top: 1rem;
  height: auto;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.UserInitials {
  color: white;
  font-size: 1.75rem;
  text-align: center;
  width: 4rem;
  margin-left: -4rem;
  font-weight: 300;
}

.UserIcon {
  display: flex;
  justify-content: center;
}
