@import '~styles/base.scss';

.RoleList {
  display: flex;
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  flex-direction: column;

  .IconContainer {
    display: flex;
    align-items: center;

    div {
      margin-right: 0.3rem;
    }
  }

  .Form {
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    justify-content: space-between;
    background-color: #f7f8fa;
    border-top: 1px solid $of-light-gray;
    border-bottom: 1px solid $of-light-gray;
  }

  .Container {
    display: flex;
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    justify-content: flex-start;
    padding: 2.5rem;
  }

  .List {
    width: 40%;
    max-width: 400px;
    margin-right: 3rem;
  }

  .Role {
    width: 100%;
  }

  .ActionMenu {
    &:disabled {
      cursor: not-allowed;
      color: $of-dark-green-03;
    }
  }
}
