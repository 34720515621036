@import '~styles/base.scss';

.OverlayFieldRectangle {
  position: absolute;
  box-sizing: border-box;
  border: 1px solid $of-forest-green-05;
  border-radius: 6px;

  .DataFieldNamePill {
    overflow-wrap: break-word;
    max-width: 95%;
    position: absolute;
    background-color: $of-blue-02;
    color: white;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1;
    font-size: 0.75rem;
    padding: 6px;
    bottom: calc(100% + 4px);
    border-radius: 6px;
    visibility: hidden;
    text-align: start;

    &.IsBroken {
      background-color: $of-red-02;
      color: white;
    }
  }

  .DragHandle {
    text-align: left;
    cursor: grab;
    position: absolute;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    left: -22px;
    top: 0;
    opacity: 0;
    transition: opacity 200ms ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.IsValueEmpty {
    border-color: $of-blue-04;
  }

  &.IsReadOnly {
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }
  }

  &.IsFocused,
  &:hover {
    border-color: $of-forest-green-01;
  }

  &.IsDragging {
    border-color: $of-forest-green-01;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);

    .DragHandle {
      cursor: grabbing;
    }
  }

  &.IsFocused,
  &.IsDragging {
    .DragHandle {
      opacity: 1;
    }
  }

  &.IsBroken {
    border-color: $of-red-02;
  }

  .ResizeCornerHandlerWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .ResizeCornerHandler {
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }

  &:hover {
    .DataFieldNamePill {
      visibility: visible;
    }

    .ResizeCornerHandler {
      background-color: #2981f8 !important;
    }

    .ResizeCornerSignatureFieldHandler {
      background-color: $of-green-16 !important;
    }

    .DragHandle {
      opacity: 1;
    }
  }

  .DragHandleIcon {
    padding: 6px 4px;
    background: $of-blue-04;
    color: black;
    border-radius: 6px;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .SignatureFieldDragHandleIcon {
    background-color: $of-green-16;
  }

  &.SignatureField {
    display: flex !important;
    align-items: center !important;

    &:hover {
      border-color: $of-green-16;
    }

    &.IsDragging {
      border-color: $of-green-16;
    }
  }
}
