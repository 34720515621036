@import '~styles/base.scss';

.Form {
  display: flex;
  flex-direction: column;
  padding: 0 4px;

  input,
  textarea {
    padding: 9px !important;
  }

  .ButtonsContainer {
    display: flex;
    justify-content: flex-end;
  }

  label[for=subject] {
    margin-bottom: 10px !important;

    div {
      border-color: $of-forest-green-05 !important;
    }
  }

  .FormTopRow {
    display: flex;

    svg {
      margin: 10px;
      margin-left: 0;
    }

    .UrlInput {
      margin-bottom: 4px;
      background: white;
      border-color: $of-forest-green-05 !important;
    }

    .Error {
      margin: 0 !important;
    }

    .Select {
      outline: none;
      width: 80px;
      height: 35px;
      margin-right: 8px;
      margin-bottom: 0;
      padding-left: 8px;
      font-size: 14px;
      border-color: $of-forest-green-05 !important;
      border-radius: 4px;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='M1.07575 2.08398L4.99997 6.71596L8.92419 2.08398L9.91608 2.92432L5.81058 7.77026L5.801 7.78132C5.70343 7.89136 5.58333 7.98334 5.44526 8.04779C5.3069 8.11238 5.15532 8.14699 4.99997 8.14699C4.84463 8.14699 4.69305 8.11238 4.55468 8.04779C4.41661 7.98334 4.29651 7.89136 4.19895 7.78132L4.18936 7.77026L0.0838623 2.92432L1.07575 2.08398Z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 108%;
      background-position-y: 11px;
    }
  }

  .FormEmailRow {
    label[for=body] {
      span[class*="ErrorMessage"] {
        display: none !important;
      }
    }

    textarea {
      box-shadow: none !important;
      min-height: unset !important;
      margin-bottom: 8px;
    }

    input,
    textarea {
      &:hover,
      &:focus,
      &:focus-within {
        border-color: $of-forest-green-05 !important;
      }
    }
  }
}
