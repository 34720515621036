@import '~styles/base.scss';

.BlankContract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: $of-forest-green-01;

  h1 {
    font-size: 1.438rem;
    margin: 0 0 0.5rem;
    padding: 0;
    font-weight: normal;
  }
}

.Description {
  color: $of-forest-green-03;
  margin: 0;
  text-align: center;
}

.Actions {
  display: flex;
  margin-top: 28px;
  margin-bottom: 28px;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
}
