@import '~styles/base.scss';

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  gap: 10px;
}

.CustomOption {
  display: flex;
  color: $of-forest-green-01;
  height: 40px;
  align-items: center;
  padding: 8px 12px;
  font-weight: normal;

  & > * {
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
    background: $of-dark-green-05;
  }
}

.SingleValueStyle {
  margin-left: 4px;

  > svg {
    margin-bottom: -2px;
  }
}

.NonEnterpriseBody {
  text-align: center;

  .AdministratorIcon {
    display: flex;
    margin-top: 31px;
    margin-bottom: 47px;
    justify-content: center;
  }
}
