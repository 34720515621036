@import '~styles/base.scss';

.RecordingNotSupported {
  color: white;
  text-align: center;
  position: relative;
  height: 0;
  bottom: 2rem;
}

.RecorderControls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .RecorderControl {
    width: 100%;
    justify-content: start;
    margin-left: 0;

    @include flex(1);

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    svg {
      width: 2rem;
      height: 2rem;
      padding-right: 0.5rem;
    }
  }

  &.MobileControls {
    .RecorderControl {
      margin-bottom: 0;
    }
  }

  .Dropzone {
    cursor: pointer;
  }
}
