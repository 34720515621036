@import '~styles/base.scss';

.Video {
  display: flex;
  flex-direction: column;
  min-height: 0;

  @include orientation(landscape) {
    @include respondTo(md, max) {
      width: 50%;
    }
  }

  @include orientation(portrait) {
    @include respondTo(md, max) {
      width: 90%;
    }
  }

  @include respondTo(md) {
    width: 90%;
  }

  @include respondTo(lg) {
    width: 60%;
  }

  @include respondTo(xl) {
    width: 50%;
  }
}

.VideoPageHeader {
  text-align: center;
  color: black;
  margin-bottom: 2rem;

  @include respondTo(md, max) {
    margin-bottom: 0;
  }
}

.Header {
  font-size: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respondTo(md, max) {
    font-size: 1.4rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .Tooltip {
    display: none;

    @include respondTo(md, max) {
      display: flex;
    }
  }
}

.HeaderText {
  font-size: 1.1rem;
  text-align: center;

  @include respondTo(md, max) {
    font-size: 0.8rem;
    display: none;
  }
}

.VideoCanvas {
  align-items: center;
  background-color: black;
  border-radius: 4px;
}

.VideoActions {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include respondTo(md, max) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .AddActions {
    display: inline-flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex: 1;
  }

  .ConfirmRemoveActions {
    display: inline-flex;
    align-content: flex-end;
    justify-content: flex-end;
    flex: 1;
    min-height: 2.5rem;
  }
}

.DeleteInfoText {
  margin-bottom: 20px;
}
