@import '~styles/base.scss';

.SettingsHeader {
  font-size: 1.5rem;
  font-weight: 500;
  color: $of-forest-green-01;
  margin: 0;

  span {
    color: $of-legacy-dark-gray;
  }
}

.SettingsTabContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
