.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.CreatingFromTemplate {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: 600;
  gap: 68px;
  text-align: center;
  padding: 0 20px;

  > [class*=Container] {
    height: auto;
  }
}
