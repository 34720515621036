.ActionsWrapper {
  display: flex;
  flex-direction: row;
}

.CancelButton {
  margin-right: 35px;
  outline: none;
  padding: 0;
  border: none;
  background: transparent;
}

.Icon {
  margin-bottom: 36px;
  margin-top: 40px;

  svg {
    width: 96px;
    height: 94px;
  }
}

.Body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
}
