@import '~styles/base.scss';

.RadioGroup {
  display: flex;
  flex-direction: column;

  .RadioButton {
    display: flex;
    align-items: center;
    margin-top: 10px;

    input {
      margin: 0 8px 0 0;
      -webkit-appearance: none;
      appearance: none;
      color: $of-forest-green-01;
      background-color: #fff !important; //needed to override bootstrap
      width: 14px;
      height: 14px;
      border: none;
      border-radius: 50%;
      transform: translateY(-1px);
      display: inline-grid;
      place-content: center;
      outline: 1.6px solid $of-forest-green-04;
      vertical-align: middle;

      &:hover {
        outline-color: $of-forest-green-01;
      }

      &:focus {
        outline: 1.6px solid $of-forest-green-01;
        outline-offset: 0;
      }

      &:disabled {
        cursor: default;
        opacity: 1;
        background-color: $of-inactive !important; //needed to override bootstrap
        outline-color: $of-forest-green-05;
      }

      &:checked {
        border: 4px solid $of-forest-green-01;
        outline-color: $of-forest-green-01;

        &:hover {
          outline-color: $of-forest-green-01;
        }
      }

      &:disabled:hover {
        outline-color: $of-forest-green-05;
      }

      &:checked:disabled {
        border-color: $of-inactive;
        background-color: $of-forest-green-04 !important; //needed to override bootstrap
        outline-color: $of-forest-green-05;

        &:hover {
          outline-color: $of-forest-green-05;
        }
      }
    }

    >label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      color: $of-forest-green-01;
    }
  }
}

.TextField {
  margin: 6px 0 0 22px;
  width: 250px;
}

.Hidden {
  visibility: hidden;
}

.Visible {
  visibility: visible;
}
