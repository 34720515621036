@import '~styles/base.scss';

.DragHandle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 100%;
  background-color: $of-green-02;
  border: solid 1px $of-green-02;
  border-radius: 6px 0 0 6px;
  transition: ease-in-out 100ms;
}

.SectionType {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  background-color: #fff;
  padding: 8px;
  border: solid 1px $of-green-02;
  border-left-style: none;
  border-radius: 0 6px 6px 0;
  height: 100%;
  transition: ease-in-out 100ms;
  font-size: 0.875rem;

  svg {
    margin-left: 8px;
    color: $of-green-13;
  }

  // Added this because the box would jump up and down if you were hovering the bottom of the box
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 100%;
    display: none;
  }

  &:hover::after {
    display: block;
  }
}

.SectionLabel {
  font-weight: 500;
  color: $of-forest-green-01;
}

.DraggableSection {
  display: flex;
  height: 40px;
  cursor: grab;
  word-break: break-word;
  position: relative;
  bottom: 0;
  transition: bottom ease-in-out 100ms;

  &:hover:not(.Disabled) {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    bottom: 2px;
  }

  &.Overlay {
    box-shadow: 0 10px 26px 0 #848c8f4a;
    border-radius: 6px;
    border: 1px solid $of-green-13;
    height: 56px;
    width: 147px;
    background: rgba($of-green-03, 0.6);

    .SectionType,
    .DragHandle {
      display: none;
      background-color: $of-inactive;
      border-style: none;
    }

    .SignatureIcon {
      color: $of-green-13;
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  &.Disabled,
  &.IsDragOverlayActive {
    opacity: 0.6;

    .SectionType {
      background-color: $of-green-03;
    }
  }

  &.Disabled {
    cursor: not-allowed;
  }

  &:hover:not(.Overlay, .Disabled) {
    .SectionType {
      background: rgba($of-green-03, 0.6);
      border-color: $of-green-02;
    }

    .DragHandle {
      background-color: $of-green-16;
      border-color: $of-green-16;
    }
  }
}

.DragOverlay {
  cursor: grabbing;
}
