@import "~styles/base.scss";

.WarningText {
  color: $of-forest-green-01;
  opacity: 0.8;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  min-height: 40px;
  flex: 1 1;

  & > :first-child {
    margin-right: 16px;
  }
}
