@import '~styles/base.scss';

.CardBody {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  .DocumentName {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 21px;
    margin-right: 4px;
  }
}

.TitleIconContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  color: $of-forest-green-01;
  margin-bottom: 2px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  height: 14px;
  gap: 4px;
}

.Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.75rem;
  color: $of-forest-green-01;
}

.VisualLine {
  border: 0;
  opacity: 0.5;
  background-color: $of-forest-green-04;
  width: 100%;
  height: 0.5px;
  margin: 15px 0 12px;
}

.InviteUserIcon {
  color: $of-forest-green-03;
}

.SectionTitle {
  font-size: 0.75rem;
  color: $of-forest-green-03;
  font-weight: 500;
}

.MoneyIcon {
  color: $of-forest-green-03;
}

.Value {
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 14px;
}

.TagsContainer {
  margin-top: 18px;
}

.FullNameEmailContainer {
  margin: 4px 12px;
  gap: 2px;
  display: flex;
  flex-direction: column;
}

.IdContainer {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}

.Id {
  font-size: 0.75rem;
  color: $of-forest-green-03;
  font-weight: 500;
}

.FullName {
  font-size: 0.875rem;
  color: $of-forest-green-01;
  word-break: break-word;
}

.Email {
  font-size: 0.75rem;
  color: $of-forest-green-03;
  font-weight: 500;
}

.ValueContainer {
  margin-top: 16px;
}

.MoreParticipants {
  font-size: 0.75rem;
  color: $of-forest-green-03;
  font-weight: 500;
  margin-top: 8px;
  margin-left: 12px;
}
