@import '~styles/base.scss';

.ParticipantInfo {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.Name {
  font-size: 0.9375rem;
  font-weight: 400;
  color: $of-forest-green-01;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  word-break: break-word;
}

.Email {
  font-size: 0.8125rem;
  font-weight: 400;
  color: $of-winter-green-dream;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Description {
  font-size: 0.875rem;
  color: $of-forest-green-01;
  margin-bottom: 18px;
}
