@import '~styles/base.scss';

.MenuItem {
  list-style: none;
  margin: 0.5rem;

  >a,
  button {
    padding: 0.2rem 0.35rem !important;
  }

  .Item {
    min-width: 180px;
    width: 100%;
    color: $of-forest-green-01;
    box-sizing: border-box;

    & > :last-child {
      flex: 1;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: 1 1 auto;
      }
    }
  }
}

.Disabled {
  cursor: not-allowed;
}

.MenuItemActive {
  &:hover,
  &:focus-within {
    background-color: rgba($of-forest-green-01, 0.08);
    border-radius: 6px;
  }
}

.Beta {
  display: flex;
  align-items: center;
}
