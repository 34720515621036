@import '~styles/base.scss';

.ParticipantContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 7px;

  .UpperSection {
    display: flex;
    flex-direction: row;
  }

  &.IsEditable {
    &:hover {
      background-color: $of-edit-hover;
    }
  }

  &.Signed {
    background-color: $of-green-06;
  }

  .SignOrder {
    display: flex;
    align-self: stretch;
    align-items: center;
    padding: 14px 20px 14px 0;

    &.Signed {
      &:hover {
        background-color: $of-green-06;
      }
    }
  }

  .ApproveOrder {
    display: flex;
    align-self: stretch;
    align-items: center;
    padding: 14px 20px 14px 0;
  }
}

// Border radius so hover background does not overflow container
/* stylelint-disable no-descending-specificity */
div[class*=Header] {
  + div[data-state] {
    > div[class*=ParticipantContainer],
    ~ div[data-state] > div[class*=ParticipantContainer] {
      border-radius: unset;
    }
  }

  ~ div[data-state]:last-of-type {
    > div[class*=ParticipantContainer] {
      border-radius: 0 0 7px 7px !important;
    }
  }
}

.Participant {
  display: flex;
  padding: 0 19px;
  line-height: 16px;
  justify-content: space-between;
  align-items: center;
  color: $of-forest-green-01;
  min-height: 72px;
  width: 100%;
  word-break: break-word;

  &.SignOrderEditable {
    padding-right: 0;
  }

  &.ApproveOrderEditable {
    padding-right: 0;
  }

  &.NotEditable {
    cursor: unset !important;
    background-color: transparent !important;
  }

  .Title {
    color: $of-forest-green-03;
    font-size: 12px;
  }

  .TitleRedesign {
    margin-top: 10px;
    color: $of-red-02;
    font-size: 12px;
  }

  .IsImport {
    color: $of-winter-green-dream;
  }

  .RightSide {
    display: flex;
    align-self: stretch;
    align-items: center;
  }

  .ParticipantState {
    display: flex;
    pointer-events: none;
    padding-left: 20px;
  }

  &.Signed {
    background-color: $of-green-06;
  }

  &.HasDeclined {
    color: $of-red-02;

    &:hover {
      color: $of-red-02;
    }

    .Title {
      color: $of-red-02;
    }

    .Name {
      color: $of-red-02;
    }

    .Date {
      color: $of-red-02;
    }
  }

  .Edit {
    visibility: hidden;
    display: none;
    color: $of-forest-green-01;
  }

  .State {
    min-height: 40px;

    .ApprovedBadge {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $of-purple-03;
      width: 36px;
      height: 36px;
      border: 2px solid $of-light-purple;
      border-radius: 20px;
      color: white;
    }
  }

  &.NotLimitedUser {
    &:hover {
      .State {
        visibility: hidden;
        display: none;
      }

      .Edit {
        width: 40px;
        min-height: 40px;
        visibility: visible;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &:hover {
    background-color: $of-edit-hover;
    color: $of-forest-green-01;
    cursor: pointer;
    border-radius: 7px;

    .Title {
      color: $of-forest-green-03;
    }

    .Name {
      color: $of-forest-green-01;
    }

    .Date {
      color: $of-winter-green-dream;
    }

    &.HasDeclined {
      color: $of-red-02;

      .Title {
        color: $of-red-02;
      }

      .Name {
        color: $of-red-02;
      }

      .Date {
        color: $of-red-02;
      }
    }
  }

  .Info {
    padding: 12px 0;
    pointer-events: none;

    & > span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .Name {
      max-width: 212px;
    }
  }

  .EnabledPointerEvent {
    pointer-events: all;
  }

  .Name {
    font-size: 14px;
    line-height: 1.2;
  }

  .SelfLabel {
    display: inline-block;
    border-radius: 10px;
    background: $of-forest-green-01;
    color: #fff;
    padding: 1px 7px;
    font-size: 10px;
    margin-left: 5px;
    vertical-align: top;
  }

  .DeclinedOrError {
    color: $of-red-02 !important;
  }

  .NameWithSignOrder {
    max-width: 180px !important;
  }

  .Type {
    font-size: 12px;
  }

  .TypeInfo {
    color: $of-winter-green-dream;
    display: flex !important;
    font-size: 12px;
    margin-top: 8px;
  }

  .Date {
    color: $of-winter-green-dream;
  }

  .DecisionMessage {
    font-size: 12px;
    margin-top: 10px;
  }
}

div[class*=Header] + div[data-state] > .Participant {
  border-radius: unset;
}

.TooltipText {
  font-size: 12px;
  font-weight: 500;
  width: auto;
  color: $of-light-gray;
  margin: 2px;
}

.ParticipantTooltipMessage {
  max-width: 320px;
}

.PreviewParticipant {
  cursor: not-allowed;
}

.ParticipantSparkle {
  margin: 12px 19px 0;
}
