.SendEmailForm {
  display: flex;
  flex: 1;
  max-width: 350px;
  margin: auto;

  button {
    height: 43px;
    width: 70px;
    margin-left: 8px;
  }
}
