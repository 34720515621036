@import '~styles/base.scss';

.Section {
  margin-bottom: 21px;
}

.ConfirmationArea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 1rem;
  min-height: 44px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
}
