@import '~styles/base.scss';

:root {
  --of-forest-green-01: #{$of-forest-green-01};
}

.InfoMessage {
  margin-bottom: 8px;
  font-size: 0.9375rem;
  line-height: 1.3;
}

.EventContainer {
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 10px;
  word-break: break-word;
  background-color: $of-dark-green-05;

  .EventContainer {
    margin-bottom: 0;
    padding: 0 0 10px 22px;
  }

  &.New {
    background-color: $of-orange-02;
  }

  .NewIndicator {
    display: inline-flex;
    float: right;
    align-items: center;
    color: $of-red-02;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 12px;
    letter-spacing: -0.02em;
  }

  .EventIcon {
    margin-right: 6px;
    width: 14px;
    height: 14px;
    display: inline-block;
  }

  .DateLine {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    margin-bottom: 12px;
    letter-spacing: -0.02em;
    color: $of-forest-green-01;

    .Date {
      text-decoration: underline;
      cursor: help;
    }
  }

  .EventTexts {
    margin-bottom: -12px;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: $of-forest-green-01;

    .Byline {
      margin-bottom: 12px;
      font-size: 0.75rem;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: $of-forest-green-01;
    }

    .IconAndText {
      display: flex;
    }
  }

  .EventText {
    margin-bottom: 12px;
    padding: 0;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: $of-forest-green-01;
    display: inline-block;
  }

  .ShowHideButton {
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: $of-forest-green-01;
    margin-bottom: 12px;
    margin-top: 4px;
    display: inline-block;
  }
}

.Header {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0;
}

.SuggestionAcceptedWrapper {
  min-width: 220px;
  margin: 10px 0;
  line-height: 18px;
}

.HorizontalPadding {
  padding-right: 20px;
  padding-left: 20px;
}

.Banner {
  margin-bottom: 20px;
}
