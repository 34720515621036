@import '~styles/base.scss';

.ParticipantWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  height: 50px;
}

.ParticipantsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.Description {
  font-size: 0.875rem;
  color: $of-forest-green-01;
  margin-bottom: 18px;
}

.OpenButton {
  padding: 10px;
  height: 34px;
  font-size: 0.75rem;
  border-radius: 4px !important;
}

.CheckIconBackground {
  background: $of-contract-signed;
  border: 2px solid $of-sign-border;
  border-radius: 36px;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.SignedTextIconContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}
