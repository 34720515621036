@import '~styles/base.scss';

.SortableProductRowWrapper {
  display: grid;
  position: relative;
  grid-template-columns: subgrid;

  @supports not (grid-template-columns: subgrid) {
    grid-template-columns: inherit;
  }

  grid-column: 1/-1;
}

.SortableDragContainerWrapper {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  transition: width 0.25s ease, opacity 0.25s ease;
  top: 24px;
  transform: translateY(-50%);
  left: calc(-1rem - 2px);
}

.SortableProductRowWrapper:not(.ReadOnly):hover .SortableDragContainerWrapper {
  visibility: unset;
}

.SortableDragContainer {
  height: 26px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
  color: black;
  display: flex;
  align-items: center;
  cursor: grab;

  &:hover {
    background: $of-edit-hover;
    border-color: $of-yellow;
  }
}

.SortableDragIcon {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  flex-shrink: 0;
}

.SortableDragProductName {
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
  transition: max-width 100ms ease;
}

.IsDragging {
  // BoxMenu has z-index of 9.
  // .box-form.can-edit .form-field .editable .drag-anchor: 10
  z-index: 11;
}

.IsDragging .SortableDragContainer {
  cursor: grabbing;
}

.IsDragging,
.SortableDragContainerWrapper:hover ~ [class*=TableRow] {
  background-color: $of-edit-hover;
}

.SortableDragContainerWrapper:hover .SortableDragProductName {
  opacity: 1;
  max-width: 100px;
}
