@import '~styles/base.scss';

.ProductPrice {
  color: var(--formatting-font-color);
  font-size: var(--formatting-font-size);
  letter-spacing: -0.02em;
  text-align: right;
  font-weight: 400;
  line-height: 1.5rem;
  white-space: pre-wrap;
  word-break: break-word;

  > div {
    &:last-child {
      padding-bottom: 0;
    }
  }

  > span > span {
    font-size: var(--document-seventy-percent-font-size);
    line-height: 0.625rem;
  }

  .StrikeThrough {
    & > * {
      text-decoration: line-through;
      line-height: 0.875rem;
    }
  }

  .PriceWithoutDiscount {
    & > * {
      color: var(--formatting-font-color);
      letter-spacing: 0;
      line-height: 0.8rem;
      font-weight: 400;
    }
  }
}
