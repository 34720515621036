.FadeIn {
  display: flex;
  align-items: center;
  opacity: 0;

  &.EnterActive {
    opacity: 1;
  }

  &.Exit {
    opacity: 0;
  }
}
