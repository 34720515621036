.DomainField {
  min-width: 180px;
  display: inline-flex;
  margin: 0 3px;
}

.SubDomain {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .EditButton {
    margin-left: 0.3rem;
  }

  .Label {
    font-weight: 600;
    margin-right: 0.3rem;
  }
}
