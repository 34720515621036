@import '~styles/base.scss';
/* stylelint-disable no-descending-specificity */

.Role {
  display: flex;
  flex: 1;
  margin: 14px 32px 14px 40px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  flex-direction: column;

  .PermissionsList {
    list-style: none;
    padding-left: 0;
    color: $of-forest-green-01;
    margin: 36px 0;

    > div {
      &:not(:first-child) {
        padding-top: 38px;
      }
    }
  }

  .CategoryItemContainer {
    padding-bottom: 1rem;
    border-bottom: 1px solid $of-dark-green-05;

    .CategoryItem {
      flex: 1;

      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        flex: 1 1 auto;
      }

      display: flex;
      font-weight: 600;
      font-size: 1.125rem;
      margin: 0;

      span {
        margin: 1em 0 1em;
      }
    }
  }

  .PermissionItem {
    display: flex;
    flex: 1;
    padding-top: 1.2rem;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }

  .CategoryLabel {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-weight: 500;
    font-size: 1.1rem;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }

  .PermissionLabel {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.825rem;
    color: $of-forest-green-01;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }

  .SensitiveLabel {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.75rem;
    color: $of-blue-01;
    background: $of-blue-07;
    border-radius: 8px;
    padding: 0 9px;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }

  .PermissionState {
    display: flex;
    align-self: right;
  }
}
