.Title {
  font-size: 0.875rem;
  margin-bottom: 16px;
  font-weight: 500;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }
}

.WarningIcon {
  margin: 16px 0;
}
