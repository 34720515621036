@import '~styles/base.scss';

.AddressBookSearchResult {
  padding: 16px 14px 8px;

  &:hover {
    cursor: pointer;
    background-color: $of-dark-green-05;
  }
}

.Company {
  display: flex;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}

.Label {
  display: flex;
  align-items: center;
  color: $of-forest-green-03;
  margin-bottom: 8px;

  .Icon {
    margin-right: 0.5rem;
  }

  .Title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
  }
}
