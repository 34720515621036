@import '~styles/base.scss';

.RolePage {
  display: flex;
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  flex-direction: column;

  .RemoveButton {
    visibility: hidden;
    opacity: 0.5;
    padding: 0;

    &:hover {
      opacity: 1;
    }
  }

  .IconContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    &:hover > .RemoveButton {
      visibility: visible;
    }
  }

  .Form {
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    justify-content: space-between;
    background-color: #f7f8fa;
    border-top: 1px solid $of-light-gray;
    border-bottom: 1px solid $of-light-gray;
  }

  .Container {
    display: flex;
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    justify-content: flex-start;
    padding: 2.5rem;
  }

  .List {
    width: 40%;
    max-width: 400px;
    margin-right: 3rem;
  }
}
