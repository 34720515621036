@import '~styles/base.scss';

.Container {
  .RowContainer {
    align-items: center;
    align-self: start;
    display: grid;
    margin: 0 -20px;
    padding: 0 20px;
    position: relative;

    @media only screen and (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .DeleteRowButton {
      align-items: center;
      border-radius: 12px;
      bottom: 0;
      color: $of-forest-green-01;
      cursor: pointer;
      display: none;
      height: 20px;
      justify-content: center;
      margin: auto 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;

      &:hover {
        box-shadow: 0 2px 10px rgba(black, 0.3);
      }
    }

    &:hover {
      .DeleteRowButton {
        display: flex;
      }
    }
  }

  .FieldContainer {
    position: relative;
  }

  .ButtonContainer {
    padding: 0 10px;
  }
}
