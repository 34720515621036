@import '~styles/base.scss';

.AddRowButton {
  background-color: $of-dark-green-05;
  color: $of-forest-green-04;
  width: 24px !important;
  height: 24px !important;
  margin: 4px 3px 0;

  &:focus,
  &:hover {
    background-color: $of-yellow !important;
    color: $of-forest-green-01;
  }
}
