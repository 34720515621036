@import '~styles/base.scss';

.Ghosting {
  display: flex;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  z-index: 10002;
  border-radius: 0 0 0.25rem 0;
  background-color: red;
  color: white;
  font-weight: 600;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  flex-direction: column;
}
