@import '~styles/base.scss';

.FreemiumMessage {
  width: 60%;
  margin: 0;
  text-align: center;
}

.Link {
  text-decoration: underline;
  color: $of-legacy-lighter;

  &:hover {
    color: $of-legacy-lighter;
  }
}
