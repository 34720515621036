@import '~styles/base.scss';

.EmailChangeNotificationContainer {
  background-color: $of-edit-hover;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 8px;

  > svg {
    color: $of-red-02;
  }

  .EmailChangeNotification {
    display: flex;
    flex-direction: column;

    b {
      font-weight: 500;
    }
  }
}
