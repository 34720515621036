@import '~styles/base.scss';

.Workspaces {
  .ContentHeader {
    margin: 2rem 2rem 0;
  }

  .EmptyStateHeader {
    margin-top: 0;
    font-weight: 600;
    font-size: 1.1rem;
  }
}
