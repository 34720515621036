@import '~styles/base.scss';

.SelectDropdown {
  overflow: hidden;

  .DropdownButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 4px;
    border-color: $of-light-gray;
    color: inherit;

    & > :last-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
    }

    &:hover {
      background-color: #f2f3f8;
      color: $of-thunder;
    }

    &:focus {
      border-color: $of-thunder;
    }
  }

  .Dropdown {
    margin-top: 10px;

    .Options {
      background-color: white;
      box-shadow: 0 0 16px rgba(1, 58, 76, 0.2);
      max-height: 80vh;
      overflow: auto;
      border-radius: 8px;
      padding: 8px 0;
    }
  }
}

.FullWidth {
  width: 100%;
}
