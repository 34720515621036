@import '~styles/base.scss';

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  min-height: 40px;
  flex: 1 1;

  button {
    margin: 0 16px;
  }
}
