@import '~styles/base.scss';

.ModalBody {
  align-items: center;
  min-height: 300px !important;
}

.CountryPickerContainer {
  display: flex;
  flex-direction: column;
  width: 260px;

  .CountryPickerLabel {
    margin-bottom: 4px;
  }

  .CountryPicker {
    width: 100%;
  }
}

.SignMethodsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .SignMethods {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @media only screen and (max-width: $contract-view-mobile-max) {
      grid-template-columns: auto;
    }

    &.Centered {
      display: block;
    }

    .EmptyState {
      text-align: center;
    }
  }
}

.SignMethodsTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: $of-dark-green-01;
  margin-bottom: 20px;
}
