@import '~styles/base.scss';

.Button {
  display: flex;
  justify-content: flex-end;
}

.ExpandButton {
  color: $of-blue-02;
  margin-right: 5px;
  border: none;
  outline: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}

.SuggestionContent {
  hr {
    margin: 12px 0 0 0;
  }

  .SuggestedContent {
    margin-top: 12px;
  }

  .Italic {
    font-style: italic;
  }
}

.MobileSuggestedContent {
  margin-top: 0 !important;
}
