@import '~styles/base.scss';

.RepliesContainer {
  max-height: 300px;
  overflow-y: auto;
}

.Replies {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  margin-top: 18px;
  gap: 18px;
}

.LoadPreviousRepliesButton {
  align-self: start;
  padding: 12px 12px 0;
  color: $of-blue-02;

  > span:last-child {
    margin-left: 4px !important;
  }
}

.MessageReplyEnter {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.MessageReplyEnterActive {
  max-height: 100px;
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.MessageReplyExit {
  max-height: 200px;
  opacity: 1;
}

.MessageReplyExitActive {
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
