@import '~styles/base.scss';

.LaunchButton {
  background-color: $of-yellow;
  border-radius: 24px;
  height: 48px;
  width: 100%;
  max-height: 42px;
  min-height: 42px;
  min-width: 42px;
  max-width: 184px;
  margin-left: 4px;

  &:hover,
  &:focus {
    box-shadow: 0 0 12px rgba(black, 0.4);
    background-color: $of-yellow-02;
  }

  &:focus-visible {
    outline: auto;
  }

  &.MobileView {
    max-width: 100%;
    margin-left: 0;
  }
}

.InlineLaunchButton {
  color: $of-blue-03;
  font-size: 16px;
  line-height: 1;
  padding-right: 0;

  &:hover {
    text-decoration: underline;
  }
}

.CreateNewButton {
  align-self: center;
}
