@import '~styles/base.scss';

.DownloadPDF {
  transition: background-color 100ms ease-out;
  border-radius: 8px;
  color: $of-forest-green-01;
  display: flex;
  height: 42px;
  width: 42px;
  box-sizing: border-box;
  align-items: center;
  place-content: center;

  &:hover {
    background-color: $of-inactive;
  }

  [class*=label] {
    display: flex;
  }
}

.TooltipMessage {
  font-size: 0.75rem;
}
