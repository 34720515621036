@import '~styles/base.scss';

.Content {
  box-sizing: border-box;
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  width: 273px;
  max-height: 270px;
  overflow-y: auto;
}

.ParticipantGroupLabel {
  font-size: 14px;
  font-weight: 700;
  color: $of-dark-green-01;
  padding: 4px 8px;
  padding-top: 8px;
  margin: 0;
}

.ParticipantItem {
  padding: 4px;
  font-size: 14px;
  color: $of-forest-green-01;
  width: 100%;
  justify-content: flex-start;

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    display: inline;
    margin: 0;
    padding: 0 4px;
  }

  &:hover {
    background: $of-dark-green-05;
  }
}

.EmptyStateText {
  color: $of-winter-green-dream;
  font-size: 14px;
  margin: 8px;
}

.SearchContainer {
  position: relative;

  .SearchField {
    margin: 4px 0;
    padding: 8px;
    width: -webkit-fill-available;
    height: 38px;
    border: 1px solid $of-forest-green-05;
    border-radius: 8px;
    box-shadow: none !important;
    outline: none !important;
    color: $of-dark-green-01;
    box-sizing: border-box;

    &.HasResults {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:focus {
      box-shadow: none !important;
    }

    &::placeholder {
      color: $of-forest-green-04;
    }
  }

  .SearchResults {
    position: absolute;
    top: 42px;
    left: 0;
    z-index: 1;
    background: white;
    border: 1px solid $of-forest-green-05;
    width: -webkit-fill-available;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    padding: 2px 4px;

    &:empty {
      display: none;
    }
  }
}

.MobileRecipientSelectorMenu {
  max-height: 260px;
  width: 100%;
  box-shadow: 0 0 8px 0 #013a4c33 !important;
  border-radius: 8px;
}

.MentionButton {
  margin-right: 8px;
}

.ActiveButton {
  background-color: $of-forest-green-05;
}
