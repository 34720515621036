@import '~styles/base.scss';

.AgreementSort {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  max-width: 16rem;
  min-width: 16rem;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .SortByText {
    color: $of-dark-green-03;
    font-style: normal;
    font-size: 10px;
    line-height: 18px;
    text-transform: uppercase;
    text-align: center;
    margin: 13px 5px 0 0;
  }

  .Toggle {
    border-color: $of-dark-green-04;
    line-height: 1.15;
    padding: 0.65rem 0.75rem;
    margin: 0 0 0 0.3rem;
    height: 42px;
    color: $of-dark-green-03;

    &:hover,
    &:focus {
      background-color: white;
      border-color: $of-dark-green-04;
      color: $of-dark-green-03;
    }

    &:hover {
      background-color: $of-dark-green-05;
      border-color: $of-dark-green-03;
      box-shadow: none;
      color: white;
    }
  }
}
