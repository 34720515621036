@import '~styles/base.scss';

.Insight {
  align-items: center;
  color: $of-dark-green-02;
  display: flex;
  font-size: 0.75rem;
  margin-right: 20px;

  .Icon {
    margin-right: 6px;
    padding-bottom: 1px;
  }
}

.Date {
  white-space: nowrap;
}
