@import "~styles/base.scss";

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -6%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.PopoverContentContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 1030 !important;
  }
}

.PopoverContent {
  background-color: #fff;
  padding: 0 !important;
  width: 300px;
  border-radius: 8px;
  font-size: var(--contract-static-font-size);
  font-family: var(--contract-static-font-family);

  [class*=PopoverArrow] {
    display: none !important;
  }
}

.DotIcon {
  visibility: hidden;
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.PopupHeader {
  border-radius: 8px 8px 0 0;
  padding: 6px 10px;
  background-color: $of-forest-green-01;
  color: #fff;
}

.NameContainer {
  padding: 10px 10px 0 10px;

  input {
    padding: 6px !important;
    height: 28px !important;
  }
}

.ButtonCancel {
  &:hover {
    text-decoration: underline;
  }
}

.ButtonSave {
  background-color: $of-forest-green-03;
  border-color: $of-forest-green-03;
  color: #fff;
  padding: 0 18px;
  height: 38px;

  &:hover {
    background-color: $of-dark-green-01;
    border-color: $of-dark-green-01;
  }
}

.PopoverNameButton {
  display: flex;
  justify-content: flex-start;
  padding: 0.35rem 0 !important;
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
  border-radius: 2px;

  [class*="label"] {
    font-weight: 700;
    font-size: 18px;
    line-height: 13px;
    margin: 9px 0;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--formatting-font-color);
    font-family: var(--formatting-font-family);
    padding: 9px 5px;
  }

  &[class*="disabled"] {
    opacity: 1;
  }

  &:hover {
    background-color: $of-edit-hover;
  }

  &:hover [class*="DotIcon"] {
    visibility: visible;
  }
}

.ActionButtonRow {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.InlineEditableContainer {
  margin-bottom: 5px;
  max-width: 100%;
  min-width: 230px;
  padding: 4px 0;
  position: relative;
  width: fit-content;

  &.CollapsedView {
    width: 100%;
  }

  .EditableElement {
    color: var(--formatting-font-color);
    font-family: var(--formatting-font-family);
    font-weight: 700;
  }

  .CharacterCount {
    animation: fadeInDown 150ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
    background: $of-forest-green-03;
    border-radius: 14px;
    bottom: -26px;
    color: white;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    padding: 4px 8px;
    position: absolute;
    right: 0;
    transition: background-color 150ms ease-in;
    white-space: pre;
    z-index: 1;

    &.Error {
      background-color: $of-red-02;
    }
  }
}
