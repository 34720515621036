@import '~styles/base.scss';

:export {
  // Border colors
  lightgreen: $of-green-03; //Signed
  lightred: $of-red-05; //Declined
  lightblue: $of-blue-05; //Pending
  orange: $of-orange-02; //Overdue
  gray: $of-dark-green-05; //Draft
}

.ParticipantTooltipMessage {
  width: 100%;
  max-width: 320px;
}

.Participants {
  color: $of-forest-green-01;
  display: flex;
  align-self: center;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.Bubbles {
  display: flex;
  justify-content: center;
}

.Participant {
  &:not(:first-child) {
    margin-left: -10px;
  }
}

.AggregatedInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  min-height: 2rem;

  span {
    padding-left: 3px;
  }
}

.Icon {
  vertical-align: sub;
  color: $of-forest-green-01;
}

.TooltipText {
  text-align: center;
}
