@import '~styles/base.scss';

.FieldContainer {
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
  gap: 4px;

  .Edit {
    align-self: flex-end;
    color: $of-forest-green-01;
    display: flex;
    height: 14px;
    visibility: hidden;
    margin-bottom: 5px;
  }

  &.Active {
    background-color: $of-edit-hover;

    .Edit {
      visibility: visible;
    }
  }

  &.Uneditable {
    cursor: default;
  }

  &:hover:not(.Uneditable) {
    background-color: $of-edit-hover;

    .Edit {
      visibility: visible;
    }
  }

  .ValidationMessage {
    color: $of-red-02;
    font-size: 0.6875rem;
    line-height: 13px;
  }

  .Wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .Label {
      align-items: center;
      color: var(--formatting-font-color);
      font-family: var(--formatting-font-family);
      font-size: var(--formatting-font-size);
      display: flex;
      font-weight: 600;
      line-height: 1.55;
      margin-left: 5px;

      .Text {
        margin: 0;
        white-space: wrap;
      }
    }

    .Required {
      color: $of-red-02;
      font-family: 'Work Sans', sans-serif;
      margin-left: 4px;
    }

    .DataFieldIcon {
      color: $of-forest-green-01;
      margin-left: 6px;
      flex-shrink: 0;
    }
  }
}
