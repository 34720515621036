@import '~styles/base.scss';

:export {
  // Border color
  gray: $of-dark-green-05 //Draft
}

.MiniContractCard {
  display: flex;
  justify-content: space-between;
  border: 1px solid $of-dark-green-04;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  align-items: center;

  .NameRow {
    display: flex;
    justify-content: flex-start;
    font-size: 1rem;

    .NameLink {
      overflow: hidden;
    }
  }

  .NoContractViewPermission {
    color: $of-red-02;
  }

  button:disabled {
    opacity: 0.2;
  }

  &.NonClickable {
    cursor: default;
  }

  .ContractName {
    font-size: 14px;
    line-height: 22px;
    color: $of-forest-green-01;

    [class*=CounterpartyName] {
      font-size: 14px;
      line-height: 22px;
      color: $of-forest-green-01;
    }

    [class*=NoCounterparties] {
      font-size: 14px;
      line-height: 22px;
      font-style: italic;
      color: $of-dark-green-02;
    }
  }
}

.ContractInformation {
  color: $of-thunder;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 0;
  overflow: hidden;
  border-left: 6px solid transparent;
  border-radius: 4px;
  margin: -1px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  &.Draft {
    border-color: $of-dark-green-05;
  }

  &.Pending {
    border-color: $of-blue-03;
  }

  &.Overdue {
    border-color: $of-contract-overdue-new;
  }

  &.Signed {
    border-color: $of-green-01;
  }

  &.Declined {
    border-color: $of-red-02;
  }
}

.Bold {
  font-weight: 600;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Terminate {
  margin-bottom: 33px;
  margin-top: 41px;
}

.RightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  div {
    padding: 0% !important;
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.WorkspaceName {
  div {
    align-items: center;
  }
}

.ParticipantBubble {
  width: 32px !important;
  font-size: 15px;
  margin-left: 4px;

  svg + span {
    width: 32px !important;
    margin-left: -32px !important;
  }

  :global(.participant-action) {
    margin-top: 21px;
    margin-left: -39px;

    span {
      margin-left: 12px;
    }

    svg {
      margin-left: 14px;
      margin-top: -2px;
    }
  }
}

.Participants {
  flex: none;
  flex-direction: unset;
  width: 200px;
}
