@import '~styles/base.scss';

.Headline {
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 32px 0;
}

.SectionWrapper {
  margin-bottom: 32px;
}

.SectionDetails {
  display: grid;
  grid-template-columns: 120px fit-content(300px);
  row-gap: 18px;
  column-gap: 235px;
}

.SemiBold {
  font-weight: 600;
}
