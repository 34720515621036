.Container {
  align-items: center;
  display: flex;
  height: 100%;

  .AddFieldButton {
    color: black !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 auto !important;
    padding: 10px;

    svg {
      path {
        stroke-width: 2.5;
      }
    }
  }
}
