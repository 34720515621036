@import '~styles/base.scss';

:export {
  forest-green: $of-forest-green-01;
  dark-green-04: $of-dark-green-04;
}

.TimeToSign {
  width: 65%;
  margin-right: $spacing-page;
  background-color: white;
  box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
  overflow: hidden;
  border-radius: 8px;
  color: $of-forest-green-01;

  .Divider {
    height: 1px;
    margin: 0 0 1rem 0;
    overflow: hidden;
  }

  .TimeToSignHeader {
    display: flex;
    align-items: center;
    padding: 30px;
    font-size: 19px;
    line-height: 22px;
    font-weight: 500;
  }
}
