@import '~styles/base.scss';

.EnableAction {
  width: 100%;
}

.DisableAction {
  width: 100%;
}

.ConfirmableBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  p {
    text-align: center;
  }

  svg {
    height: 69px;
    width: 69px;
    margin-bottom: 20px;
    color: $of-red-02;
  }

  .Link {
    white-space: nowrap;
    overflow-wrap: break-word;
  }
}

.TermsContainer {
  margin-top: 15px;

  a {
    color: $of-blue-02;
  }
}
