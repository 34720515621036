@import '~styles/base.scss';

.Cell {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 72px;
  justify-content: center;
}

.Drop {
  width: calc(100% - 2px);
  background-color: $of-green-03;
  display: none;
  justify-content: center;
  align-items: center;
  color: black;
  border: 1px solid $of-green-03;

  @media only screen and (max-width: 700px) {
    height: 44px !important;
    max-height: 44px !important;
    min-height: 44px !important;
  }

  &.Active {
    display: flex;
  }

  &.Over {
    border: 1px dashed $of-dark-green-03;
    border-radius: 6px;
  }
}
