@import '~styles/base.scss';

.PrimaryControl {
  width: 4.5rem;
  height: 4.5rem;
  border: 4px solid rgba($of-reign, 0.4);
  background-color: white;
  border-radius: 50%;
  padding: 0;
  margin: 0 0.5rem;
  color: $of-thunder;
  cursor: pointer;
  box-sizing: border-box;

  @include respondTo(md, max) {
    width: 3.5rem;
    height: 3.5rem;
  }

  &:hover {
    border-color: rgba($of-reign, 0.8);
    background-color: $of-light-gray;
  }

  svg {
    width: 2rem;
    height: 2rem;

    @include respondTo(md, max) {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.StartRecordingText {
  color: $of-cloud;
  text-shadow: 0 0 1px $of-thunder;

  &.Recording {
    visibility: hidden;
  }
}

.CameraControlsContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 0.5rem;
  }

  .Rounded {
    background-color: rgba($of-video-gray, 0.4);
    color: white;
    border: 2px solid rgba($of-video-gray, 0.4);
    border-radius: 50%;

    &:hover {
      background-color: rgba($of-video-gray, 0.6);
    }
  }

  .Placeholder {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.CameraControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin-top: -6.5rem;

  @include respondTo(md, max) {
    margin-top: -5.5rem;
  }

  .Record {
    @extend .PrimaryControl;

    color: $of-passion;

    &:hover {
      color: lighten($of-passion, 20);
    }
  }

  .Stop {
    @extend .PrimaryControl;
  }
}
