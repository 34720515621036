@import '~styles/base.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.TooltipContent {
  max-width: calc(100vw - 26px);

  &.Expanded {
    max-width: calc(100vw - $sidebar-width - 26px);
  }
}

.TooltipMessage {
  max-width: 100%;
  width: unset;
  word-break: break-word;
}

.InlineEditableContainer {
  align-items: center;
  display: inline-grid;
  position: relative;
  vertical-align: top;

  .InlineEditable {
    padding-right: 55px;
  }

  .EditableElement {
    span[data-slate-string="true"] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }

    &.Focused {
      span[data-slate-string="true"] {
        overflow: unset;
      }
    }

    @media screen and (max-width: 840px) {
      font-size: 1rem;
    }
  }

  .CharacterCount {
    animation: fadeIn 150ms linear;
    color: $of-forest-green-03;
    font-size: 10px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: color 150ms linear;

    &.Error {
      color: $of-red-02;
    }
  }
}
