@import '~styles/base.scss';

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include flex(1);
}
