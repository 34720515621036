@import '~styles/base.scss';

.Success {
  @keyframes zoom-in {
    0% {
      transform: scale(0.1);
    }

    90% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  .SuccessIcon {
    color: $of-peace;
    animation: zoom-in 300ms ease-in 0s 1;

    @include respondTo(md, max) {
      width: 3rem;
      height: 3rem;
    }
  }
}

.ErrorMessage {
  font-size: 1rem;
  color: $of-passion;
  text-align: center;
  padding: 1rem 1rem 0 1rem;

  @include respondTo(md, max) {
    font-size: 0.7rem;
  }

  a {
    color: $of-passion;
    text-decoration: underline;
  }
}

.ControlsCanvas {
  background: $of-pitch;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Canvas {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-width: 100%;

  .RecorderControlsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.Rounded {
  background-color: rgba($of-video-gray, 0.4);
  color: white;
  border: 2px solid rgba($of-video-gray, 0.4);
  border-radius: 50%;

  &:hover {
    background-color: rgba($of-video-gray, 0.6);
  }
}

.Placeholder {
  height: 2.5rem;
  width: 2.5rem;
}
