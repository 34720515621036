@import '~styles/base.scss';

.ChangeTemplateModal {
  justify-content: unset !important;
  min-height: min-content !important;
}

.ModalBody {
  flex: 0 1 0 !important;
  min-height: 0 !important;
}

.ModalContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;

  & > * {
    margin: 0;
  }

  .Title {
    font-size: 1rem;
    font-weight: 500;
  }

  .ConsequenceList {
    text-align: start;

    li {
      font-weight: 700;
    }
  }
}

.TemplateGroupSelector {
  &.Disabled {
    :global(.ReactSelect__single-value),
    :global(.ReactSelect__indicators) {
      color: $of-forest-green-04;
    }
  }
}
