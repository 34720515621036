@import '~styles/base.scss';

.VideoPlayerContainer {
  width: 100%;
}

.Small {
  width: 60%;

  @include orientation(portrait) {
    @include respondTo(md, max) {
      width: 100%;
    }
  }
}
