@import '~styles/base.scss';

.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
}

.ContentHeader {
  font-size: 1.5rem;
  color: var(--color-forest-green-01);
  margin: 0;
  font-weight: 500;
}

.HeaderWrapper.Closable .ContentHeader {
  font-weight: 500;
}

.ContentTabContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.ContentTabSectionHeader {
  padding: 12px 0 6px 0;
  font-size: 1rem;
}

.Sections {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
}

.Title {
  color: $of-forest-green-01;
  font-size: 0.938rem;
}
