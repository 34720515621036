@import "~styles/base";

.Body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.FolderTreeBranchContainer {
  max-height: 45vh;
}

.Label {
  padding-bottom: 0.25rem;
  color: $of-forest-green-01;
  font-size: 0.8125rem;
  line-height: 15px;
  display: flex;
}

.RequiredAsterisk {
  color: $of-red-02;
  font-size: 1rem;
  flex-shrink: 0;
}

.TooltipIcon {
  margin-left: 6px;
}

.SelectTrigger {
  outline: none;
  background: white;
  border: 1px $of-forest-green-05 solid;
  border-radius: 5px;
  min-height: 42px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $of-forest-green-01;
  padding: 0 12px;

  &:disabled {
    color: $of-forest-green-04;
    background: $of-inactive;
    border: $of-inactive;
    cursor: not-allowed;
  }

  &:hover,
  &[data-state="open"] {
    border-color: $of-dark-green-02;
  }
}

.SelectFieldRoot {
  &:has(.SelectContent[data-side="bottom"]) {
    .SelectTrigger {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &:has(.SelectContent[data-side="top"]) {
    .SelectTrigger {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.SelectContent {
  border: 1px $of-dark-green-02 solid;
  background: white;
  z-index: 32; // To be on top of the old React Selects
  max-height: 240px !important;
  overflow-y: auto;
  width: var(--radix-select-trigger-width);

  .SelectItem {
    outline: none;
    height: 42px;

    &:hover,
    &[data-state="checked"] {
      background: $of-dark-green-05;
    }
  }

  &[data-side="top"] {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
  }

  &[data-side="bottom"] {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: none;
  }
}
