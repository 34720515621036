.InsightContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 0 18px 0;

  &:empty {
    display: none;
  }
}
