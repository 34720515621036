@import '~styles/base.scss';

.InfoBox {
  background-color: #fff9f4;
  color: $of-forest-green-01;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  margin: 0 19px 14px 19px;

  p {
    margin: 0;
  }

  .InfoBoxTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-weight: 600;
    }
  }

  .InfoMessage {
    margin: 4px 0 0 22px;
  }

  .ExclamationCircle {
    color: #ef9b4e;
    height: 12px;
    margin-right: 5px;
  }
}
