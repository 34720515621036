@import '~styles/base.scss';

:root {
  --of-forest-green-01: #013a4c;
  --reminders-border-color: #a539d9;
  --badge-border-color-upcoming: #a539d9;
  --badge-border-color-past: #eacff6;
  --badge-background-fill: #fbf5fd;
}

.MiniInternalReminderCard {
  display: flex;
  justify-content: space-between;
  border: 1px solid $of-dark-green-04;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 50px;

  button:disabled {
    opacity: 0.2;
  }

  &.NonClickable {
    cursor: default;
  }

  .MiniInternalReminderCardActions {
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px 0;
  }

  &:hover {
    .MiniInternalReminderCardActions {
      opacity: 1;
    }
  }
}

.MiniInternalReminderCardInsight {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: $of-dark-green-02;
  gap: 4px;
}

.MiniInternalReminderCardInformation {
  --border-color: #a539d9;

  color: $of-forest-green-01;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 0;
  overflow: hidden;
  border-left: 6px solid transparent;
  border-radius: 4px;
  margin: -1px;
  border-color: var(--border-color);

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  &.HasPassed {
    --border-color: #d29cec;

    color: $of-forest-green-03;
  }

  .MiniInternalReminderCardSubjectRow {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.MiniInternalReminderCardRecipients {
  max-width: 200px;
  height: 50px;
  display: flex;
  align-self: center;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0.5rem;
}

.MiniInternalReminderCardBubbleTooltipMessage {
  color: #fff !important;
  background: $of-forest-green-01 !important;
  padding: 20px 24px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 5px;
}

.TooltipMessageParticipantName {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.TooltipMessageParticipantEmail,
.TooltipMessageParticipantPhoneNumber {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.MiniInternalReminderCardBubbleTooltip {
  &:not(:first-child) {
    margin-left: -6px;
  }
}

.MiniInternalReminderCardRecipientBubble {
  width: 32px !important;
  font-size: 15px;

  svg + span {
    width: 32px !important;
    margin-left: -32px !important;
  }

  svg circle:nth-of-type(2) {
    fill: var(--badge-background-fill);
  }

  &.HasPassed {
    color: $of-forest-green-03;

    span {
      color: $of-forest-green-03;
    }
  }
}
