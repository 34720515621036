@import '~styles/base.scss';

.BodyParagraph {
  line-height: 1.4;
}

.BodyParagraphRed {
  line-height: 1.4;
  color: $of-red-02;
}

.BulletList {
  padding-left: 1.5rem;
  margin: 0 0 0.6rem 0;
  float: left;

  .ListItem {
    line-height: 1.5;
  }
}
