@import '~styles/base.scss';

.ColorPickerField {
  min-height: 30px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  box-shadow: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  border-color: $of-forest-green-05;
  text-align: left;
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 42px;
  box-sizing: border-box;

  > p {
    margin: 0;
  }
}

.Disabled {
  border: unset;
}

.ColorPickerPopover {
  z-index: 2;
}

.ColorPickerFieldTitle {
  color: $of-forest-green-01;
  font-size: var(--contract-static-font-size);
  padding-bottom: 0.25rem;
  font-weight: 600;
}

.ColorPickerFieldTitleDisabled {
  color: $of-forest-green-04;
  font-size: var(--contract-static-font-size);
  font-weight: 600;
}

.ColorPickerField:hover {
  border-color: $of-forest-green-03;
}

.ColorButton {
  width: 100%;
  padding: 0;
  text-align: left;
  opacity: 1 !important;
  margin-bottom: 26px;

  span {
    width: 100%;
  }

  margin-bottom: 0;
  height: 42px;
  box-sizing: border-box;
}

.ColorButtonWrapper {
  [class*="DisabledButtonWrapper"] {
    cursor: not-allowed;

    .ColorButton {
      color: $of-forest-green-04;
    }

    .ColorPickerField {
      background-color: $of-inactive;
    }
  }
}
