@import '~styles/base.scss';

.Topbar {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--collapsed-document-top-bar-height);
  gap: 5px;
}

@media (orientation: landscape) {
  .Topbar {
    padding-left: max(env(safe-area-inset-left), 12px);
    padding-right: max(env(safe-area-inset-right), 12px);
  }
}

.LeftSideContainer {
  display: flex;
  align-items: center;
  min-width: 0;
}

.IconButton {
  padding: 0;
  border: 0;
  background: unset;
  color: $of-forest-green-01;
}
