@import '~styles/base.scss';

.Failure {
  border-radius: 4px;
  border: none;
  font-size: 14px;
  background-color: $of-red-05;
  color: $of-forest-green-01;

  :global(.MuiIconButton-label) {
    color: $of-red-02;
  }

  :global(.MuiAlert-icon) {
    align-self: center;
  }

  :global(.MuiAlert-standardError) {
    color: $of-forest-green-01;
  }

  :global(.MuiAlert-standardError .MuiAlert-icon) {
    color: $of-forest-green-01;
  }
}

.DocumentViewPositioning {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: max-content;
  width: 100%;
  z-index: 9999;

  &.SingleColumn {
    position: fixed;
  }
}

.SupportEmail {
  color: $of-blue-02;
}
