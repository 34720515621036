@import '~styles/base.scss';

.PriceSummationContainer {
  max-width: 100%;
  display: grid;
  grid-template-columns: minmax(7ch, 40ch) 1fr;
  column-gap: 20px;
  row-gap: 10px;
  line-height: 13px;
  margin-top: 7px;
  color: var(--formatting-font-color);
  font-size: var(--formatting-font-size);
  font-family: var(--formatting-font-family);

  @include respondToNewBreakPoint(medium) {
    grid-template-columns: 1fr;
  }

  &.PriceSummationContainerResponsive {
    line-height: normal;
  }
}

.PriceSummation {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: subgrid;
  text-align: right;

  @supports not (grid-template-columns: subgrid) {
    grid-template-columns: inherit;
  }
}

.ExpandedDiscountedPrice > span:first-child {
  margin-right: 5px;
}

.ResponsiveDiscountedPrice {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.PriceLabel {
  line-height: 1.5;
  font-weight: 600;
  margin: 0;
  word-break: break-word;
  min-width: 80px;

  &.ResponsivePriceLabel {
    display: inline-block;
  }
}

.SummatedPrice {
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-word;
}

.StrikeThrough {
  & > * {
    text-decoration: line-through;
  }
}
