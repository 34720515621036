@import '~styles/base.scss';

.SpinnerContainer {
  margin: auto;
}

.ErrorMessage {
  color: $of-passion;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  .ErrorIcon {
    margin: 0 0.5rem 0 0;
  }
}

.ModalBody {
  div[class*=Body] {
    padding: 10px;
  }
}

.Confirm {
  margin: 0 auto;
  padding-bottom: 1rem;
}

@media screen and (max-width: 576px) {
  .SendButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center !important;

    button {
      width: 100% !important;
    }

    div {
      width: 100%;
    }
  }
}
