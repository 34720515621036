@import '~styles/base.scss';

@keyframes scaleIn {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
  }

  to {
    transform: translateY(0);
  }
}

.PopoverContentContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 1 !important;
  }
}

.PopoverContent {
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(black, 0.3);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 4px;
  transition: opacity 200ms ease-out;

  [class*=RichTextEditorToolbarDivider] {
    height: 35px;
    margin: 0 4px;
  }

  &[data-side='top'] {
    animation: slideUp 200ms ease-out;
  }

  &[data-side='bottom'] {
    animation: slideDown 200ms ease-out;
  }

  &[data-state='closed'] {
    animation: scaleOut 200ms ease-out;
  }
}

.SelectedRectanglePopoverContainer {
  width: 100%;
}
