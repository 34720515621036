@import '~styles/base.scss';

.MessageTop {
  flex: 1;
  width: 100%;
  text-align: center;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  line-height: 21px !important;

  &[message] {
    text-decoration-line: unset;
    display: flex;
    flex-direction: column;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.MessageUnderline {
  text-decoration-line: underline;
  color: $of-blue-02;
}

.MessageBottom {
  flex: 1;
  width: 100%;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  display: flex;
  justify-content: center;
  padding: 25px 0 20px 0;
  column-gap: 5px;
  flex-wrap: wrap;

  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 13px;
  }

  &.ContractBox p {
    margin-bottom: 3px;
  }

  .RejectMessageArea {
    min-height: 15px;
    width: 100%;
    font-size: var(--contract-static-font-size) !important;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  @media screen and (min-width: 1598px) {
    line-height: 1;
  }
}

.ImageRecommendation {
  margin-top: 2em;
}

.Loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 2rem;
  min-height: 4.5rem;
  gap: 20px;

  &.ContractBox {
    margin: 65px;
  }
}

.LoadingInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  gap: 4px;
}

.OfflineErrorWrapper {
  .IconWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .MessageTop {
    font-weight: normal !important;
    text-decoration-line: unset;
    display: flex;
    flex-direction: column;
  }

  .ErrorMessageContainer {
    text-align: center;
    color: $of-red-02;
    margin-top: 8px;

    p {
      margin-bottom: 0;
    }
  }
}

.RequiredMark {
  color: $of-red-02 !important;
}

.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: $of-dark-green-05;
  border-radius: 99999px;
  width: 130px;
  height: 8px;
  transform: translateZ(0);
}

.ProgressIndicator {
  background-color: $of-winter-green-dream;
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    width: 100px;
    height: 100%;
    background:
      linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.35) 50%,
        transparent 100%
      );
    animation: shine 3s infinite;
  }

  @keyframes shine {
    0% {
      left: 0;
    }

    100% {
      left: 100%;
    }
  }
}
