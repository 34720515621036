@import '~styles/base.scss';

.CellContainer {
  background-color: $of-edit-hover;
  border-radius: 8px;
}

.Center {
  justify-content: center;
  display: flex;
  width: 100%;
}

.IconWrapper {
  position: absolute;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  color: black;
  cursor: grab;
  display: flex;
  height: 12px;
  width: 12px;
  padding: 6px;
  z-index: 1;

  &:hover {
    background: $of-edit-hover;
    border-color: $of-yellow;
    cursor: grabbing;
  }
}

.Icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 4px;
}
