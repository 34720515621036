.DataField {
  border-radius: 4px;
  margin: 1px;
}

.DataFieldTooltip {
  width: auto;
  max-width: 150px;
  text-align: start;
}

.DataFieldPlaceholder {
  background-color: #fff4d9;
  font-style: italic;
  font-family: var(--contract-static-font-family);
  color: var(--contract-static-text-color);
  font-size: var(--contract-static-font-size);
}
