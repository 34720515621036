@import '~styles/base.scss';

.Approve {
  margin: 8px 0;
  width: 100%;
  padding: 14px;
  font-size: 16px;
  height: 46px;
}

.Small {
  max-width: 320px;
  min-width: 280px;
}

.ToolTip {
  margin: 0;
  font-weight: 600;
}
