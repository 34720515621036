@import '~styles/base.scss';

.ContractInsight {
  display: flex;
  margin: 8px 0;
  flex-direction: column;

  .InsightsPrimary {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }

  .InsightsSecondary {
    display: flex;
    gap: 20px;
    margin-top: 8px;

    &:empty {
      display: none;
    }
  }
}
