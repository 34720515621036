@import '~styles/base.scss';

.HeaderWithTooltip {
  display: flex;
  flex-direction: column;

  > span {
    line-height: 1.8;
  }
}

.CreatorRoleContainer {
  .Label {
    font-size: 0.875rem;
    display: flex;
    gap: 4px;
    text-align: center;
    padding-bottom: 4px;
    color: $of-forest-green-01;
    font-weight: 600;

    .QuestionCircle {
      display: flex;
      padding: 3px;
    }
  }

  .SelectFieldDescription {
    color: $of-winter-green-dream;
    margin-top: 4px;
    font-size: 0.8125rem;

    a {
      color: $of-winter-green-dream;
      text-decoration: underline;
    }
  }
}

.TooltipMessage {
  line-height: 18px;
}

.SnackbarContainer {
  font-weight: 400;
}
