@import '~styles/base.scss';

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.ActionsMenu {
  color: $of-cloud;
  background-image: none;
  text-shadow: none;
  margin: 0;
  padding: 0;
  outline: none;
  user-select: none;
  position: relative;
  z-index: 11;
  border-radius: 8px;
  background-color: white;
  box-shadow: 8px 6px 20px 0 rgba(0, 0, 0, 0.1490196078);
  border: 1px solid rgba(0, 0, 0, 0.05);
  transform-origin: 100% 0;
  overflow-x: hidden;
  max-height: var(--radix-popper-available-height);

  > div {
    outline: 0;
  }

  [role="separator"] {
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
}

.ActionsMenu[data-state='open'] {
  animation: scaleIn 100ms ease-out;
}

.ActionsMenu[data-state='closed'] {
  animation: scaleIn 100ms ease-in;
}

.TriggerButton {
  color: $of-cloud;
  background-image: none;
  text-shadow: none;
  box-shadow: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  user-select: none;
  background-color: transparent;
  border-radius: 50%;

  :hover {
    cursor: pointer;
  }
}
