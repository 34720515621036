@import '~styles/base.scss';

.State {
  border-radius: 50%;
  padding-left: 16px;
  margin-right: 3px;
}

.Draft {
  @extend .State;

  background-color: $of-dark-green-05;
}

.Pending {
  @extend .State;

  background-color: $of-blue-03;
}

.Overdue {
  @extend .State;

  background-color: $of-contract-overdue-new;
}

.Declined {
  @extend .State;

  background-color: $of-red-02;
}

.Signed {
  @extend .State;

  background-color: $of-green-01;
}

.Disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
