.EditableTableCell {
  position: relative;
  transition: width 0.05s;

  .TableCellResizeHandler {
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    right: -3px;
    cursor: col-resize;
    background-color: transparent;
    user-select: none;
    width: 5px;

    &:hover,
    &.IsResizing {
      background-color: #2f5217;
      opacity: 0.3;
    }
  }
}

:global(.resizing-table) {
  .TableCellResizeHandler:not(.IsResizing) {
    &:hover {
      background-color: transparent;
    }
  }
}

.FocusedTableCell {
  box-shadow: inset 0 0 4px 0 rgb(1 58 76 / 20%) !important;
}
