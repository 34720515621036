@import './popup-forms.module.scss';

.AffixesContainer,
.ColumnNameInputContainer {
  border-bottom: 1px solid $of-dark-green-04;
}

.PriceDisplay {
  margin: 0;
  padding-bottom: 10px;
  color: $of-forest-green-01;
}

.PriceDisplayHelperText {
  padding-bottom: 15px;
  margin: 0;
}

.AffixesInputContainer {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.AffixTextField {
  max-width: 140px;
}

.AffixTextFieldLabel {
  span {
    font-weight: 600;
    color: $of-forest-green-01;
  }
}

.OneTimePaymentLabel {
  font-weight: 600;
}

.OneTimeParagraph {
  padding-top: 10px;
}
