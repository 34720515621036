@import '~styles/base.scss';

.InfoContainer {
  display: flex;
  flex-direction: row;
  line-height: 20px;
}

.Insight {
  display: inline-block;
  margin-right: 10px;

  div {
    padding-top: 0% !important;
  }
}

.WorkspaceName {
  display: inline-block;
  color: $of-dark-green-02;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
