@import '~styles/base.scss';

.ErrorBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  &Header {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  &Info {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    display: grid;

    a {
      text-decoration: underline;
    }
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.FailedIcon {
  width: 70px;
  height: 70px;
  color: $of-red-02;

  &Container {
    padding: 14px;
  }
}
