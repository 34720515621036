/* stylelint-disable unit-no-unknown */
@import '~styles/base.scss';

.CollapsedDocumentContainer {
  display: flex;
  height: var(--collapsed-document-height);
  flex-direction: column;
  width: 100dvw;

  .ContentBodyContainer {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: var(--collapsed-document-body-height); // height of screen minus the bottom navigation
    overflow: hidden;
    // The toasts are relying on the position property to be set to relative
    // so that they can be positioned absolutely.
    position: relative;

    > div {
      &:has(.ToastProvider) {
        height: unset;
      }
    }
  }

  .WithBanner {
    height: var(--collapsed-document-body-height-with-banner);
  }

  .BottomNavigationContainer {
    height: var(--collapsed-document-bottom-navigation-height);
    background-color: #fff;
    align-self: flex-end;
    box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.16);
    position: fixed;
    bottom: 0;
    white-space: nowrap;
    width: 100%;
    display: flex;
  }
}

.TabContainer {
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  &:not([hidden]) {
    display: flex;
  }
}

@media (orientation: landscape) {
  .TabContainer {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: max(env(safe-area-inset-left), 20px);
    padding-right: max(env(safe-area-inset-right), 20px);
  }
}

.ToastProvider {
  bottom: 1rem;
}

.BottomFloatingContainer {
  position: fixed;
  left: 0;
  bottom: var(--collapsed-document-bottom-navigation-height);
  width: 100%;
}
