@import '~styles/base.scss';

.DocumentStateContainer {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: white;
  font-size: 0.875rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid $of-forest-green-05;
  box-sizing: border-box;
  min-height: var(--document-state-banner-height);
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  &.HideBanner {
    animation-timing-function: cubic-bezier(0.7, 0, 0.84, 0);
    animation-fill-mode: forwards;
    animation-name: slideUp;
    animation-duration: 300ms;
    border: unset;
  }

  b {
    font-weight: 600;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
    min-height: 0;
    height: 0;
    padding-bottom: 0;
    padding-top: 0;
    display: none;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0%);
    opacity: 1;
  }

  to {
    transform: translateY(-100%);
    opacity: 0;
    min-height: 0;
    height: 0;
    padding-bottom: 0;
    padding-top: 0;
    display: none;
  }
}

.StateIndicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  flex-shrink: 0;

  &.Signed {
    background-color: $of-green-01;
  }

  &.Pending {
    background-color: $of-blue-02;
  }

  &.Overdue {
    background-color: $of-contract-overdue-new;
  }

  &.Declined,
  &.Canceled,
  &.Ended,
  &.SignedAndTerminated {
    background-color: $of-red-02;
  }
}

.OverdueLink {
  color: $of-blue-02;
  cursor: pointer;
}
