@import '~styles/base.scss';

.SendToButton {
  display: flex;
  align-items: center;
  background-color: $of-blue-06;
  color: $of-blue-02;
  font-size: 14px;
  line-height: 18px;
  padding: 0 8px;
  border-radius: 12px;
  height: 24px;

  &.Error {
    background-color: $of-red-06;
    color: $of-red-02;
  }
}

.Icon {
  margin-left: 4px;
  height: 8px;
  width: 8px;
}
