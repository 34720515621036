.BoxList {
  margin-bottom: 22px;
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 8px;
}

@media (orientation: landscape) {
  .BoxList {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}
