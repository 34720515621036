@import '~styles/base.scss';

.FilterRow {
  line-height: 1.3;

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }

  &:hover {
    .Label {
      text-decoration: underline;
    }
  }
}

.DisabledLabel {
  color: $of-dark-green-03;
}
