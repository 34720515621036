.BankIdContainer {
  margin: 1em auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .ContinueButton {
    margin: 1em auto;
  }
}

.Loading {
  margin: 2em auto;
}
