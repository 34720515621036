@import '~styles/base.scss';

.LanguageSelector {
  margin-bottom: 0.75rem;

  .FieldDescription {
    color: $of-winter-green-dream;
    margin-top: 4px;
    font-style: normal;
    font-size: 0.8125rem;
  }

  .Label {
    margin-bottom: 4px;
    margin-top: 0;
    font-size: 0.875rem;
    color: $of-forest-green-01;
    font-weight: 600;
  }
}
