@import '~styles/base.scss';

.RecipientListContainer {
  margin-bottom: 24px;
}

.ListTitle {
  color: $of-forest-green-01;
  padding: 8px 0;
  font-size: 13px;
  font-weight: 600;
}
