.Heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px !important;
}

.BodyContainer {
  margin-bottom: 30px;
}

.ConfirmationMessage {
  font-weight: 600;
}
