@import '~styles/base.scss';

.SectionRuleWrapper {
  display: block;
  border: 1px solid;
  margin: 0 -1px;
  border-radius: 4px;
  font-size: 12px;
  padding-bottom: 10px;

  &.Visible {
    border-color: $of-blue-02;
  }

  &.Broken {
    border-color: $of-red-02;
  }

  &.Hidden {
    border-color: $of-contract-overdue-new;

    &:hover {
      border-color: $of-contract-overdue-new;
    }
  }

  &.Collapsed {
    padding-bottom: 0;
    border-color: $of-orange-03;
  }
}

.SectionRuleHeader {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  margin-bottom: 4px;
  color: $of-dark-green-01;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-family: var(--contract-static-font-family);

  .DataFieldInfoWrapper {
    display: flex;
  }

  .DataFieldInfo {
    display: flex;
    align-items: center;
    color: $of-dark-green-01;

    span {
      &:last-of-type {
        margin-left: 4px;
      }
    }

    svg {
      margin-right: 4px;
    }
  }

  .ArrowIcon {
    display: flex;
    align-items: center;
    margin-left: 5px;

    button {
      margin-right: 5px;
    }
  }

  .Pill {
    display: flex;
    align-items: center;
    border: 1px solid;
    height: 22px;
    border-radius: 12px;
    font-weight: 600;
    padding: 0 8px;
    margin-left: 5px;
  }

  &.Visible {
    background-color: $of-blue-07;

    .Pill {
      border-color: $of-blue-02;
    }
  }

  &.Broken {
    background-color: $of-red-05;
    color: $of-red-02;

    .Pill {
      border-color: transparent;
      margin-left: 0;

      svg {
        margin-left: 4px;
      }
    }
  }

  &.Hidden {
    background-color: $of-orange-03;
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $of-contract-overdue-new;
    }

    .Pill {
      border-color: $of-contract-overdue-new;
    }
  }

  &.Collapsed {
    border-color: $of-orange-03;
    border-radius: 3px;
    margin-bottom: 0;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.StatusWrapper {
  display: flex;
  align-items: center;
}

.Bold {
  font-weight: bold;
}
