@import '~styles/base.scss';

.Green {
  color: $of-green-01;
}

.Red {
  color: $of-red-02;
}

.TooltipHeader {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.Text {
  white-space: nowrap;
}

.TooltipText {
  width: auto;
}

.ChangedPermission {
  display: flex;
  gap: 8px;
}

.ChangedPermissionLabel {
  max-width: 250px;
  white-space: normal;
  word-break: break-word;
}

.PermissionUpdate {
  display: flex;
  align-items: center;
}

.Italic {
  font-style: italic;
}
