@import '~styles/base.scss';

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.PopoverContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 1029 !important;
  }

  .Popover {
    width: 360px;
    padding: 8px 12px;
    transform-origin: var(--radix-popover-content-transform-origin);
    animation: scaleIn 0.15s ease-out;
  }
}

.Anchor {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.FormInputContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
  border: 1px solid $of-forest-green-05;
  border-radius: 8px;
  margin-bottom: 4px;
  transition: transform 0.15s ease-in-out;

  &.PreviewModeFormInputContainer {
    cursor: not-allowed;
    background-color: $of-inactive;
  }
}

.TextArea {
  flex-grow: 1;
  overflow-wrap: break-word;
  overflow-y: auto !important;
  min-height: 20px;
  max-height: 90px;
  padding: 2px 10px;
  border: none;
  resize: none;
  color: $of-forest-green-01;
  letter-spacing: -0.02em;
  line-height: normal;
  outline: none;

  &[disabled] {
    background: white !important;
  }

  &.Empty {
    height: auto !important;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: $of-winter-green-dream;
  }

  &.PreviewModeTextArea {
    cursor: not-allowed;
    background-color: $of-inactive !important;
  }
}

.IsTextMultiLine {
  width: 100%;
}

.PostSuggestionActions {
  margin: 4px;

  button {
    width: 36px;
    height: 36px;
  }
}

.PostSuggestionRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PostSuggestionRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
