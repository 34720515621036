@import '~styles/base.scss';

.ActionsMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: $of-cloud;
  white-space: nowrap;
  background-image: none;
  text-shadow: none;
  box-shadow: none;
  margin-left: 0;
  border: 1px solid transparent;
  outline: none;
  user-select: none;
}

.EnabledMenu {
  cursor: pointer;
  color: $of-forest-green-01;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0;

  &:hover,
  &[aria-expanded=true] {
    background-color: $of-forest-green-01;
    color: white;
  }

  &:hover {
    box-shadow: 0 2px 10px rgba(black, 0.3);
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color solid 2px;
    outline-offset: 2px;
    box-shadow: 0 0 0 5px white;
  }

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
