@import '~styles/base.scss';

.Overlay {
  position: fixed;
  inset: 0;
  background-color: rgba($color: #000, $alpha: 0.2);
}

.Trigger {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 0;
}

.Content {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  outline: none;
  height: fit-content !important;
  background-color: white;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  overflow: hidden;
  padding: 18px 12px 12px 12px;
  box-shadow: 0 0 16px 0 #013a4c33;

  &::after {
    height: fit-content !important;
  }
}

.DragHandle {
  content: "";
  background-color: $of-forest-green-05;
  width: 35px;
  height: 5px;
  border-radius: 100px;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.Title {
  text-align: center;
  font-weight: 500;
  color: $of-dark-green-01;
  margin: 7px;
}
