@import '~styles/base.scss';

.SelectedValuesContainer {
  margin: 7px 0;
  display: flex;
  flex-flow: row wrap;
}

.SelectedValue {
  border-radius: 20px;
  color: $of-forest-green-01;
  border: 1px solid $of-forest-green-05;
  font-size: 12px;
  padding: 2px 0 2px 14px;
  line-height: 14px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;

  &:hover {
    border-color: $of-forest-green-04;
  }
}

.SelectedValueLabel {
  border-radius: 2px;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.RemoveValue {
  border: unset;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 2px;
  color: $of-forest-green-01;
  margin-right: 4px;
  background-color: transparent !important;

  :hover {
    cursor: pointer !important;
    color: #013a4c !important;
  }
}

.IsFixed {
  visibility: hidden;
  pointer-events: none;
}
