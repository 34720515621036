@import '~styles/base.scss';

.ErrorScreen {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-color: $of-plum-05;
  overflow: hidden;
  z-index: 999999;

  .Logo {
    padding-left: 20px;
    padding-top: 20px;

    @media (min-width: 64rem) {
      float: left;
    }
  }

  .ColumnsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @include respondToNewBreakPoint(large) {
      display: none;
    }
  }

  .LeftColumn {
    width: 100%;
  }

  .ButtonWithIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .ChevronIcon {
      transition: transform 0.3s ease;
    }

    .Inverted {
      transform: rotate(-180deg);
    }
  }

  .ButtonWithIconYellow {
    padding: 10px 13px;
    border-radius: 5px;
    background-color: $of-yellow;
    font-size: 18px;

    &:hover,
    &:visited,
    &:focus {
      text-decoration: none;
    }
  }

  .Content {
    position: absolute;
    top: 42px;
    left: 108px;
    font-family: 'Work Sans', sans-serif;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    @include respondToNewBreakPoint(large) {
      position: static;
      margin: 42px auto 0 auto;
      width: 90%;
      left: 24px;
    }

    .ContentHeaders {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 42px;

      @include respondToNewBreakPoint(xs) {
        gap: 4px;
        margin-bottom: 24px;
      }

      h1 {
        font-size: 48px;
        color: $of-forest-green-01;
        margin-bottom: 16px;

        &.HeaderPrimary {
          margin-bottom: 8px;
        }

        @include respondToNewBreakPoint(xs) {
          font-size: 28px;
        }
      }
    }

    .ContentButtons {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 130px;

      @include respondToNewBreakPoint(xs) {
        flex-direction: column;
      }

      & > a {
        height: 58px;
        width: 220px;
        box-sizing: border-box;

        @include respondToNewBreakPoint(medium) {
          width: 50%;
        }

        @include respondToNewBreakPoint(small) {
          width: 100%;
        }
      }
    }

    .BadGateway {
      @include respondToNewBreakPoint(small) {
        margin-top: 85px;
      }
    }

    .ContentInfo {
      width: 40vw;
      margin-bottom: 80px;
      font-size: 26px;

      @include respondToNewBreakPoint(large) {
        width: 100%;
      }

      @include respondToNewBreakPoint(xs) {
        margin-bottom: 40px;
        font-size: 18px;
      }

      p {
        font-size: 26px;
        color: $of-forest-green-01;

        @include respondToNewBreakPoint(xs) {
          font-size: 18px;
        }

        a,
        a:visited,
        a:focus {
          color: $of-blue-02;
        }
      }
    }

    .HelpButton {
      height: 58px;
      position: relative;
      width: 220px;

      button {
        width: 100%;
        font-size: 18px;
        height: 100%;
      }

      @include respondToNewBreakPoint(medium) {
        width: 50%;
      }

      @include respondToNewBreakPoint(small) {
        width: 100%;
      }

      .OneflowContacts {
        position: absolute;
        width: 100%;
        bottom: -78px;
        transform: translate(0, -50%);
        border-left: 1px solid $of-forest-green-01;
        padding-left: 8px;
        overflow: hidden;

        a {
          display: block;
          font-size: 14px;

          &:first-of-type {
            margin-bottom: 6px;
          }
        }
      }

      .OneflowContactsVisible {
        transition: opacity 0.3s ease;
        opacity: 1;
        visibility: visible;
      }

      .OneflowContactsHidden {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

:global(.main-wrapper) {
  min-height: 0 !important;
}
