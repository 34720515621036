.CustomerId {
  display: inline-flex;
  align-items: center;
  margin-top: 0.5rem;

  button {
    margin-left: 0.3rem;
  }

  .Label {
    font-weight: 600;
    margin-right: 0.3rem;
  }
}
