@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/work-sans/WorkSans-Thin.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/work-sans/WorkSans-ExtraLight.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/work-sans/WorkSans-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/work-sans/WorkSans-Light.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/work-sans/WorkSans-LightItalic.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/work-sans/WorkSans-Regular.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/work-sans/WorkSans-Italic.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/work-sans/WorkSans-Medium.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/work-sans/WorkSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/work-sans/WorkSans-Bold.ttf');
}

/* All other fonts are loaded through AWS (font-manager.js) */
