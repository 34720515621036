@import '~styles/base.scss';

.RadioGroupContainer {
  flex-direction: column;

  .Option {
    margin: 6px;

    >label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      color: $of-forest-green-01;
    }
  }
}
