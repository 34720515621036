@import '~styles/base.scss';

.Preset {
  font-size: 10px;
  line-height: 14px;
  color: $of-forest-green-01;
  padding: 0.25rem 0.5rem;
  margin: 2px;
  border-color: $of-dark-green-03 !important;

  &:hover {
    border-color: $of-dark-green-02 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
