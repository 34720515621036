@import '~styles/base.scss';

.MainContainer {
  padding: 2rem;
}

.Header {
  margin-bottom: 15px;
  color: $of-forest-green-01;
  font-size: 22px;
  font-weight: 600;
}

.ExtensionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 2rem;
}
