@import '~styles/base.scss';

.MiniContractCardsContainer {
  padding: 0.5rem 1rem;
  color: $of-forest-green-01;

  .LinkListHeader {
    font-weight: 500;
    line-height: 22px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .LinkTypeContainer {
    &:not(:last-child) {
      margin-bottom: 22px;
    }

    .LinkTypeLabel {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: -8px;
      line-height: 22px;
    }
  }
}

.Cards {
  overflow-y: auto;
  max-height: 350px;
  padding-right: 13px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $of-forest-green-03;
  }
}

.LinkToButton {
  > li {
    margin: 0.9rem 0;
    max-width: fit-content;

    &:hover,
    &:focus-within {
      background-color: unset !important;
    }
  }

  button {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: $of-forest-green-01;
  }

  button:hover {
    text-decoration: underline;
    color: $of-dark-green-02;
  }

  button:focus {
    color: inherit !important;
  }
}
