@import '~styles/base.scss';

.Message {
  color: $of-forest-green-01;
  flex: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $contract-view-mobile-max) {
    &:last-child {
      margin-bottom: 0%;
    }
  }
}

.TextSmall {
  font-size: 14px;
}

.RecipientContainer {
  color: $of-forest-green-01;
  margin-bottom: 4px;
  display: flex;

  &:empty {
    display: none;
  }

  .RecipientTags {
    display: inline;
  }
}

.Body {
  font-size: 14px;
  color: $of-forest-green-01;
  word-break: break-all;
  word-break: break-word;
}

.BodyText {
  line-height: 1.55;
  max-height: 300px;
  overflow-y: auto;
}

.InfoContainer {
  display: flex;
  justify-content: space-between;

  .ActionButtonsWrapper {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
}

.StatusPill {
  font-size: 12px;
  padding: 0 8px;
  border-radius: 8px;
  height: 24px;
  display: flex;
  align-items: center;
}

.GreenPill {
  color: $of-green-13;
  background-color: $of-green-12;
}

.RedPill {
  color: $of-red-01;
  background-color: $of-red-11;
}
