@import '~styles/base.scss';

.HelpText {
  line-height: 1.4;
  max-width: 660px;
}

.ListItem {
  line-height: 1.4;
}

.Description {
  max-width: 660px;
}

.Header {
  font-weight: 500;
  font-size: 1.1rem;
}

.Row {
  width: 100%;
  max-width: 660px;
}
