@import '~styles/base.scss';

.SearchFilterContainer {
  border-top: 1px solid $of-forest-green-05;
  padding-top: 16px;
}

.SearchFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .FilterHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .Title {
      color: $of-forest-green-01;
      font-weight: 500;
      font-size: 20px;
      margin: 0;
      padding: 0;
    }
  }

  .ResetAll {
    color: #225464;
    font-size: 14px;
    font-weight: 400;
    border: none;
    background-color: transparent !important;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
