@import '~styles/base.scss';

.UserDropdown {
  width: 180px;
  position: absolute;
  max-width: 100%;
}

.UserButton {
  min-width: 180px;
  max-width: 250px;
  height: 48px;
  border: none !important;
  background-color: white;
  padding: 1px 16px 0 16px !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: $of-winter-green-dream !important;
  margin: 0;

  &:hover {
    background-color: white !important;
    color: $of-forest-green-01 !important;
  }
}

.UserButtonIcon {
  margin-right: 12px;
  vertical-align: top;
}

.Tooltip {
  margin: 0 5px;

  &:hover {
    box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
  }
}
