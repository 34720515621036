@import '~styles/base.scss';

:root {
  --alert-bg-success: rgb(237, 247, 237);
}

.AlertWrapper {
  font-family: 'Proxima Nova', sans-serif;
  border-radius: 4px;
  border: 0;
  display: flex;
  align-items: center;
  padding: 10px 16px !important;

  &.SuccessAlert {
    background-color: var(--alert-bg-success);
  }

  &.WarningErrorAlert {
    background-color: $of-red-05;
  }
}

.AlertIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AlertIcon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  font-size: 1.5rem;
  flex-shrink: 0;
  user-select: none;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  opacity: 0.9;
  padding: 7px 0;
  margin-right: 5px;

  &.SuccessAlert {
    color: $of-forest-green-01;
  }

  &.WarningErrorAlert {
    color: $of-red-02;
  }
}

.AlertMessage {
  font-size: 14px;
  flex-grow: 1;

  &.SuccessAlert {
    color: $of-forest-green-01;
  }

  &.WarningErrorAlert {
    color: $of-red-01;
  }
}

.AlertActionWrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: -8px;
  padding-left: 16px;
}

.AlertActionButton {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  overflow: visible;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 3px;
  font-size: 1.125rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.AlertActionIcon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  font-size: 1.5rem;
  flex-shrink: 0;
  user-select: none;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  opacity: 0.9;
  padding: 7px 0;

  &.SuccessAlert {
    color: $of-forest-green-01;
  }

  &.WarningErrorAlert {
    color: $of-red-02;
  }
}
