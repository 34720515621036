@import '~styles/base.scss';

.LoadingPdfPage {
  margin: 0 20px;
}

.FailedPdfPage {
  text-align: center;
  padding-top: 10%;
}

.PdfPage {
  width: $a4-width;
  text-align: center;
  position: relative;

  :global(.popupAnnotation) {
    display: none;
  }

  :global(.squareAnnotation),
  :global(.squareAnnotation) svg,
  :global(.squareAnnotation) svg rect {
    user-select: none;
    cursor: default;
  }

  &:not(:first-child) {
    :global(.textLayer) {
      border-top: 2px solid $of-dark-green-05;
    }
  }
}
