@import '~styles/base.scss';

.ModalClass {
  display: block;
  min-height: auto;
}

.ModalBodyClass {
  min-height: auto !important;
  padding-top: 24px !important;
}

.FoundAlternativeTitle {
  font-size: 14px;
  color: $of-forest-green-01;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.OptionsWrapper {
  display: flex;
  justify-content: center;
  gap: 48px;
}
