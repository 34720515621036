@import '~styles/base.scss';

.FileUpload {
  align-items: center;
  justify-content: center;
  font-family: var(--contract-static-font-family);

  .Dropzone {
    width: 100%;
    border: 1px dashed $of-dark-green-03;
    border-radius: 4px;
    padding: 32px;
    box-sizing: border-box;
    color: $of-forest-green-01;
    cursor: pointer;

    &:hover {
      background-color: $of-edit-hover;
      border: 1px dashed $of-yellow-04;
    }

    &.Small {
      padding: 1.75rem 0 0 0;
    }

    &.ContractBox {
      padding: 1.25rem 0 0 0;
    }
  }

  .DropzoneInvalid {
    width: 100%;
    border: 1px solid $of-passion;
    border-radius: 4px;
    padding: 4rem 2rem;
    box-sizing: border-box;
    color: $of-thunder;
    cursor: pointer;
    background-color: #f9f9f9;
  }

  .Value {
    text-transform: uppercase;
  }

  .DropzoneActive {
    border: 2px solid $of-cloud;
    color: #a3a5ae;
    background-color: #f9f9f9;
  }

  .DropzoneReject {
    color: #ae2216;
    background-color: #ffe1e1;
    border: 2px dashed #f5d0d0;
  }

  .ImageArea {
    display: flex;
    margin-bottom: 2rem;

    .Image {
      position: relative;
      display: flex;
      justify-content: flex-end;

      img {
        max-width: 175px;
        max-height: 100px;
      }

      .Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #636a78;
      }

      .Button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .Icon {
        fill: white;
      }
    }
  }
}
