@import '~styles/base.scss';

.Wrapper {
  color: $of-forest-green-01;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.Disabled {
    cursor: not-allowed;
    color: $of-forest-green-04;
  }
}

.Toggle {
  display: flex;
  align-items: center;
}
