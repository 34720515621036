@import '~styles/base.scss';

:root {
  --internal-selector-background: #ffefd6;
  --public-selector-background: #cbeaff;
}

.TriggerButton {
  font-weight: 600;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;

  &.Disabled {
    background: $of-forest-green-05 !important;
    border-color: $of-forest-green-05 !important;
    color: $of-forest-green-03;

    svg {
      color: $of-forest-green-03 !important;
    }
  }

  .ChevronDownIcon {
    height: 10px;
    margin-left: 8px;
  }

  &.Internal {
    background: var(--internal-selector-background);
    border-color: $of-contract-overdue-new;

    svg:not(.ChevronDownIcon) {
      color: $of-contract-overdue-new;
    }

    &:hover {
      background: #e8d9c3;
    }
  }

  &.Public {
    background: var(--public-selector-background);
    border-color: $of-blue-03;

    svg:not(.ChevronDownIcon) {
      color: $of-blue-03;
    }

    &:hover {
      background: #b8d4e8;
    }
  }
}

.PopoverContent {
  background-color: #fff;
  padding: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  width: 360px;

  .SelectorButton {
    width: 100%;
    background: none;
    border: none;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: $of-inactive;
    }
  }

  .SelectorOption {
    display: flex;
    color: $of-forest-green-01;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
  }

  .IconContainer {
    margin-right: 8px;
    height: 20px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &.Internal {
      background: var(--internal-selector-background);

      svg {
        color: $of-contract-overdue-new;
        height: 15px;
      }
    }

    &.Public {
      background: var(--public-selector-background);

      svg {
        color: $of-blue-03;
      }
    }
  }

  .SelectorLabel {
    text-align: start;

    p {
      &:first-of-type {
        font-weight: 600;
        margin-bottom: 4px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
