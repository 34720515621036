@import '~styles/base.scss';

.ToastList {
  position: absolute;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  z-index: 10000; // Behind modals

  * {
    box-sizing: border-box;
  }
}

.ToastItem {
  position: absolute;
  transform: translateY(var(--y));
  opacity: 0;
  touch-action: none;
  outline: none;
  overflow-wrap: anywhere;
  width: max-content;
  max-width: var(--width);
  min-width: 236px;
  font-size: 13px;
  transition: transform 400ms, opacity 400ms;

  @media (max-width: 840px) {
    max-width: calc(100vw - 2rem);
  }

  &[data-mounted="true"] {
    --y: calc(var(--lift-direction) * var(--offset));

    opacity: 1;
  }

  &[data-mounted="false"] {
    --y: calc(var(--lift-direction) * var(--offset) + var(--lift-direction) * -100%);

    opacity: 0;
  }

  .ContentWrapper {
    transition: scale 600ms ease-in-out 100ms;

    &[data-reoccurred="true"] {
      transition: scale 100ms ease-in-out 0s;
      scale: 1.06;
    }

    &[data-reoccurred="false"] {
      scale: 1;
    }
  }
}

/**
  * .ToastList[data-Y-position='top'], Didn't include .ToastList to allow
  * the viewPortClassName to override the position without using !important
  */
//
[data-y-position='top'] {
  top: 0;

  .ToastItem {
    top: 0;
  }

  --lift-direction: 1;
}

[data-y-position="bottom"] {
  bottom: 0;

  .ToastItem {
    bottom: 0;
  }

  --lift-direction: -1;
}

[data-x-position="left"] {
  left: 0;

  .ToastItem {
    left: 0;
  }
}

[data-x-position="right"] {
  right: 0;

  .ToastItem {
    right: 0;
  }
}

[data-x-position="center"] {
  left: 50%;
  transform: translateX(-50%);

  .ToastItem {
    left: 50%;
    transform: translateX(-50%) translateY(var(--y));
  }
}
