.PdfWrapper {
  padding: 0 20px;
}

.PdfBoxWithFile {
  padding: 0 !important;
}

.PdfBoxWithFile1 {
  padding: 0 !important;
}

.Icon {
  visibility: hidden;
  pointer-events: none;
}

.ShowIcon {
  visibility: visible;
}
