@import '~styles/base.scss';

.LoadingVideo {
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  color: white;
  line-height: 1.55;
  text-align: center;
  border: 1px solid black;

  .Actions {
    font-size: 1rem;
    padding-top: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @include respondTo(md, max) {
      font-size: 0.825rem;
      padding-top: 0.2rem;
    }
  }

  &.Small {
    width: 60%;
  }
}

.Rounded {
  background-color: rgba($of-video-gray, 0.4);
  color: white;
  border: 2px solid rgba($of-video-gray, 0.4);
  border-radius: 50%;

  &:hover {
    background-color: rgba($of-video-gray, 0.6);
  }
}

.Messages {
  margin-top: 0.8rem;
  font-size: 18px !important;
  font-family: var(--contract-static-font-family);
}
