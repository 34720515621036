@import '~styles/base.scss';

.MessagesContainer {
  overflow: auto;
  padding: 0 20px;
  height: 100%;
}

@media (orientation: landscape) {
  .MessagesContainer {
    padding-left: max(env(safe-area-inset-left), 20px);
    padding-right: max(env(safe-area-inset-right), 20px);
  }
}

.ExtraMargin {
  margin-top: 33px;
}

.ScrollArea {
  height: 100%;

  & > div {
    height: 100%;
    display: block !important;
    overflow: hidden;
  }
}

.Banner {
  margin: 12px 0;
}
