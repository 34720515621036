@import '~styles/base.scss';

.BoxEmptyDraftState {
  border: 1px dashed $of-forest-green-04;
}

.Icon {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $of-dark-green-05;
  border-radius: 100%;
}

.EmptySpace {
  padding-bottom: 15px;
}
