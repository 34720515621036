@import '~styles/base.scss';

.ToggleFocusTabOnly {
  :global(.react-toggle.react-toggle--focus:not(.react-toggle--disabled)) {
    border: 1px solid $of-forest-green-04;
  }

  :global(.react-toggle.react-toggle--checked.react-toggle--focus:not(.react-toggle--disabled)) {
    border: 1px solid $of-green-01;
  }
}
