@import '~styles/base.scss';

.Container {
  display: flex;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
}

.Left {
  display: flex;
  gap: 16px;
  flex-grow: 1;
}

.Content {
  color: $of-forest-green-01;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.Title {
  font-size: 0.875rem;
  font-weight: 600;
}

.Description {
  font-size: 0.75rem;
}

.Middle {
  display: flex;
  align-items: center;
}

.Right {
  display: flex;
  flex-shrink: 1;
}

.CenterRight {
  align-items: center;
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $of-green-01;
  color: white;
  width: 24px;
  height: 24px;
}

.CloseButton {
  border: none;
  outline: none;
  background: none;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  color: $of-forest-green-01;
}

.SuccessToast {
  background-color: $of-green-14;
  border: 2px solid $of-green-01;
}

.ErrorToast {
  background-color: $of-red-12;
  border: 2px solid $of-red-02;
}

.InfoToast {
  background-color: $of-blue-08;
  border: 2px solid $of-blue-03;
}

.WarningToast {
  background-color: $of-orange-05;
  border: 2px solid $of-contract-overdue-new;
}
