@import '~styles/base.scss';

.ParticipantSignOrder {
  display: flex;
  width: 52px;

  :global(.ReactSelect__value-container) {
    text-align: center;
    padding: 0 2px !important;
  }

  :global(.ReactSelect .ReactSelect__option) {
    padding: 0 8px !important;
  }
}
