@import '~styles/base.scss';

.NumberField {
  div {
    width: 85px;
    margin-right: 20px;
  }

  span {
    width: 220px;
  }
}

.PeriodDurationContainer {
  > button {
    padding: 0;
    border: none;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  > div > div {
    padding: 0;
  }
}

.TextWrapper {
  font-weight: 600;
  text-decoration: underline;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

.Header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: $of-forest-green-01;
  color: white;
  font-weight: 500;
  padding: 12px 16px;
}

.PopoverContent {
  width: 320px;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  z-index: 9999 !important;
  font-family: var(--document-static-font-family);
  font-size: var(--document-static-font-size) !important;
}

.FormInfoContainer {
  display: flex;
  flex-direction: row;
  height: 34px;
  margin-bottom: 40px;
  margin-left: 16px;

  input {
    height: inherit;
  }

  input,
  label div {
    font-family: var(--document-static-font-family);
    font-size: var(--document-static-font-size) !important;
  }
}

.PeriodAttributeLabel {
  margin: 20px 16px;
  color: $of-forest-green-01;
}

.PeriodAttributeUnit {
  display: flex;
  height: 43px;
  min-width: 110px;

  button {
    background: transparent;
  }
}

.Select {
  border-radius: 6px;
}

.ButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  padding-bottom: 15px;
}

.ButtonCancel {
  &:hover {
    text-decoration: underline;
  }
}

.ButtonSave {
  background-color: $of-forest-green-03;
  border-color: $of-forest-green-03;
  color: #fff;
  padding: 0 18px;
  height: 38px;

  &:hover {
    background-color: $of-dark-green-01;
    border-color: $of-dark-green-01;
  }
}
