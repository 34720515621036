@import '~styles/base.scss';

.Alert {
  color: $of-passion;
  height: 20px;
  width: 20px;
  margin-left: 0.2rem;
}

.LimitedUserContainer {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
}
