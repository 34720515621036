@import '~styles/base.scss';
@import '~./rtf.scss';

.EditableContent {
  @include EditableRTFContent;

  outline: none;
  line-height: 1.55;
}

.EditableReadOnlyContent {
  @include EditableReadOnlyRTFContent;

  line-height: 1.55;
}

.ReadOnly {
  .EditableReadOnlyContent {
    padding: 0 !important;
    border: none !important;
  }
}

.Borderless {
  border: none;
}

.Focused {
  border: 1px solid $of-forest-green-04;
  border-radius: 6px;

  &:global(.open) {
    padding: 4px 8px !important;

    span[data-slate-placeholder=true] {
      color: $of-winter-green-dream;
      padding-top: 0;
    }
  }
}

.Editable {
  position: relative;
  font-family: var(--formatting-font-family);
  color: var(--formatting-font-color);
  font-size: var(--formatting-font-size);

  *[data-slate-placeholder=true] {
    top: 8px !important;
    width: calc(100% - 16px) !important;
  }

  &.EditableBorder {
    width: auto;
    border: 1px solid transparent;
    border-radius: 4px;

    &:global(.open) {
      border: 1px solid #c2c6cb;
    }

    &:hover:not(:global(.open)) {
      border: 1px solid $of-dark-green-04;
    }
  }
}

.DocumentViewEditable {
  *[data-slate-placeholder=true] {
    font-family: var(--document-static-font-family) !important;
    color: black !important;
  }
}

.TextEditable {
  border: 1px solid white;
  border-radius: 4px;
  background-color: white;

  &:hover {
    background-color: $of-legacy-peace !important;
  }

  &:focus,
  &:focus-within {
    background-color: white !important;
    border-color: $of-legacy-light-gray !important;
  }

  &.ReadOnly:hover {
    background-color: white !important;
  }
}
