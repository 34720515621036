@import '~styles/base.scss';

.BodyContainer {
  display: flex;
  flex-direction: column;
  border: 0.5px solid $of-forest-green-04;
  border-radius: 12px;
  padding: 15px !important;
}

.Content {
  flex-direction: column;
}

.Line1 {
  content: "\a";
  white-space: pre;
}

.Line2 {
  margin-bottom: 20px !important;
}

.AddParticipant {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $of-forest-green-05;
  color: $of-forest-green-01;
  border-radius: 7px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  &:hover {
    background-color: $of-forest-green-04;
  }

  .AddButtonIcon {
    margin-right: 0.3rem;
  }
}
