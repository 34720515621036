@import '~styles/base.scss';

.Actions {
  display: flex;
}

.Toggle {
  margin-top: -0.3rem;
}

.Header {
  font-weight: 500;
  font-size: 1.1rem;
}

.Row {
  width: 100%;
  max-width: 660px;
}

.Label {
  margin-right: 2.5rem;
  margin-bottom: 0;
  min-width: 18rem;
}

.ToggleRow {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
