.SlideIn {
  display: flex;
  align-items: center;
  transition: all 100ms;
  opacity: 0;

  &.EnterActive {
    opacity: 1;
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }

  &.Exit {
    opacity: 1;
    flex-grow: 0;
  }
}
