@import '~styles/base.scss';

.Dropzone {
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  color: $of-forest-green-01;
  cursor: pointer;
  overflow: hidden;
}

.DashedBorder {
  border: 1px dashed $of-forest-green-04;

  &:hover:not(.Invalid) {
    border-color: $of-yellow-04;
  }
}

.DropzoneErrors {
  border: 1px solid $of-red-02;
}

.Invalid {
  border: 2px solid $of-red-02;
}

.HiddenDropzone {
  cursor: auto;
}
