@import '~styles/base.scss';

.FormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Form {
  margin: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
}

.TextAreaContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 8px;
  border-radius: 6px;
  background: white;
  border: 1px solid $of-forest-green-05;
}

.TextAreaWrapper {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  background: white;
  align-items: center;
}

.TextArea {
  flex-grow: 1;
  overflow-wrap: break-word;
  overflow-y: auto !important;
  margin-bottom: 3px;
  border: none;
  resize: none;
  letter-spacing: -0.02em;
  line-height: normal;
  outline: none;

  &:focus-visible {
    outline: none;
  }

  &.Empty {
    height: auto !important;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: $of-winter-green-dream;
  }
}

.ActionButtons {
  display: flex;

  button {
    width: 36px;
    height: 36px;
  }
}

.ActionsRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.ActionsContainer {
  position: relative;

  .ValidationError {
    margin: 3px;
    color: $of-red-02;
    transition: height 0.15s ease-in-out;
    font-size: 12px;
  }

  .NoError {
    height: 0;
  }

  .ActionsRow {
    display: flex;
    align-items: center;
  }
}

.MentionContainer {
  margin-bottom: 4px;

  &:empty {
    display: none;
  }
}

.SendMessageTooltip {
  font-size: 12px;
  padding: 10px;
  margin-bottom: 2px;
}

.HiddenPostMessage {
  display: none;
}

.ReplyForm {
  margin-bottom: 0;
}

.PreviewMode {
  cursor: not-allowed;
  border: 1px solid $of-forest-green-05;
  background-color: $of-inactive;
}

.PreviewModeInput {
  cursor: not-allowed;
  background-color: $of-inactive;
}

.IsTextMultiLine {
  width: 100%;
}

.MentionParticipantsSelector {
  margin-bottom: 12px;
}
