@import '~styles/base.scss';

.Modal {
  @include flex(1);

  display: flex;
  flex-direction: column;
  width: 40rem;
  max-width: 100%;
  min-height: 22rem;
  margin: 2rem auto;
  justify-content: space-between;
  outline: none;
  border-radius: 8px;
  color: $of-thunder;
  box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
  background-color: white;

  &.ScrollBar {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
      height: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: $of-forest-green-03;
      box-shadow: 0 0 1px rgba(white, 0.5);
      border: 2px solid white;
    }
  }

  &.IsWide {
    width: 60rem;
  }

  &.IsMediumWide {
    width: 716px;
  }

  &.IsExpansive {
    width: 1210px;
  }

  .Header {
    font-size: 18px;
    padding: 15px;
    font-weight: 500;
    background-color: $of-forest-green-01;
    color: white;
    text-align: center;
    border-radius: 8px 8px 0 0;
    display: flex;

    & > :first-child {
      margin-right: auto;
      width: 2rem;
      visibility: hidden;
    }

    .CloseButtonContainer {
      margin-left: auto;
      width: 2rem;
    }

    .CloseButton {
      width: 100%;
      height: 100%;
      padding: 0;
      color: white;

      &:hover,
      &:focus {
        color: $of-forest-green-03;
        box-shadow: none;
      }
    }
  }

  .Body {
    @include flex(1);

    font-size: 14px !important;
    padding: 1rem 2rem;
    min-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    color: $of-forest-green-01 !important;
    line-height: 20px !important;
    letter-spacing: -0.02em;
  }

  .LoadingContent {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 10rem;

    .LoadingText {
      margin-top: 0.5rem;
      color: $of-reign;
    }
  }

  .Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 36px;
    padding: 1rem;

    .Error {
      width: 90%;

      .ErrorMessage {
        padding: 0 1rem;
        color: $of-passion;
        display: flex;
        align-items: center;
      }

      .ErrorIcon {
        margin: 0 0.5rem 0 0;
      }
    }

    &.CustomError {
      flex-direction: column;
      padding-top: 0;
      min-width: 90%;
    }

    .Actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-height: 40px;
      flex: 1;

      &.CustomError {
        align-self: flex-end;
      }

      &.CenteredActions {
        justify-content: center;
      }
    }
  }
}

.SuccessMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 100%;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  animation: fade-in ease-in-out 600ms;

  .Icon {
    margin-right: 0.5rem;
    color: $of-green-01;
  }
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.5);
  z-index: 10001;
  overflow: auto;
}

.Open {
  overflow: hidden;
}
