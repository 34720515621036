@import '~styles/base.scss';

.CounterpartyToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  &.Disabled {
    cursor: not-allowed;
  }
}

.Title {
  max-width: 75%;
  font-size: 0.875rem;
  font-weight: 600;
  color: $of-forest-green-01;

  &.Disabled {
    color: $of-forest-green-04;
  }
}
