@import '~styles/base.scss';

.PaginationInformation {
  font-size: 13px;
  color: $of-reign;
}

.HiddenPaginationInformation {
  visibility: hidden;
}
