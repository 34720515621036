@import '~styles/base.scss';

.FieldContainer {
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px;
  position: relative;
  border-radius: 8px;

  .Wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Edit {
      color: $of-forest-green-01;
      display: flex;
      margin-bottom: 5px;
      visibility: hidden;
    }
  }

  &.Active {
    background-color: $of-edit-hover;

    .Edit {
      visibility: visible;
    }
  }

  &.Uneditable {
    cursor: default;
  }

  &:hover:not(.Uneditable) {
    .Edit {
      visibility: visible;
    }
  }
}
