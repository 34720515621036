@import '~styles/base.scss';

.AddProduct {
  padding: 4px 8px;
  background-color: $of-forest-green-05;
  color: $of-forest-green-01;
  font-size: 0.75rem;
  letter-spacing: -0.24px;
  min-height: 20px;
  box-sizing: content-box;
  line-height: 1.5;

  button {
    font-family: var(--document-static-font-family);
  }

  > span {
    white-space: nowrap;
  }

  &:hover {
    background-color: $of-forest-green-04;
  }
}
