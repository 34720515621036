@import '~styles/base.scss';

.LinkButton {
  color: $of-blue-02;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ExternalLink {
  margin-left: 8px;
  vertical-align: middle;
  margin-bottom: 3px;
}
