@import '~styles/base.scss';

.AIAssistButton {
  color: $of-purple;
  background-color: white;
  border: 1px solid $of-forest-green-04;
  padding: 2px 12px;
  font-size: 14px;
  font-weight: 500;
  -webkit-text-fill-color: $of-forest-green-01;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 4px;
    height: 14px;
  }

  > span {
    margin-left: 0 !important;
  }
}
