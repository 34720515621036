@use 'sass:math';

@function ratio-padding($width, $height) {
  @return percentage(math.div($height, $width));
}

@function ratio-margin($width, $height) {
  @return -1 * ratio-padding($width, $height);
}

.AspectRatio {
  display: flex;
  overflow: hidden;
  height: 0;

  &.AR16-9 {
    padding-top: ratio-padding(16, 9);
  }

  &.AR9-16 {
    padding-top: ratio-padding(9, 16);
  }

  &.AR3-4 {
    padding-top: ratio-padding(3, 4);
  }

  &.AR4-3 {
    padding-top: ratio-padding(4, 3);
  }
}

.AspectRatioCanvas {
  display: flex;
  flex: 1;
  overflow: hidden;

  &.AR16-9 {
    margin-top: ratio-margin(16, 9);
  }

  &.AR9-16 {
    margin-top: ratio-margin(9, 16);
  }

  &.AR3-4 {
    margin-top: ratio-margin(3, 4);
  }

  &.AR4-3 {
    margin-top: ratio-margin(4, 3);
  }
}
