@import '~styles/base.scss';

:root {
  // based on the minHeight of the overlay-field-rectangle
  --overlay-field-min-height: 18px;
}

.TextFieldContainer {
  display: flex;
  align-items: flex-start;
  height: 100%;
}

.ReadOnlyContainer {
  display: flex;
  align-items: flex-start;
  height: 100%;

  & > * {
    cursor: not-allowed;
  }
}

.TextField {
  text-align: left;
  margin: 0;
  overflow-wrap: break-word;
  resize: none;
  transition: all 100ms;
  background-color: transparent;
  opacity: 1;
  border: none;
  box-shadow: inset 0 0 0 transparent;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 5px;

  &.FieldEmpty:empty {
    background-color: $of-blue-05;
    opacity: 0.7;

    &::placeholder {
      color: $of-winter-green-dream;
    }
  }

  &.ReadOnly {
    box-shadow: none;
    outline: none;
    overflow-y: auto;

    &:empty.Concluded {
      background-color: transparent;
    }
  }

  &:hover,
  &:focus-visible,
  &:focus,
  &:active {
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }

  &.Readonly,
  &:empty {
    background-color: $of-inactive;
    opacity: 0.9;
  }

  &.IsBroken {
    background-color: $of-red-09 !important;
    opacity: 1;

    &::placeholder {
      color: $of-red-02;
    }
  }
}

.IconsContainer {
  display: inline-flex;
  flex-direction: row;
  z-index: 1;
  margin: 0;
  position: absolute;
  right: 0;

  &.RequiredOnly {
    right: 1%;
  }
}

.OverlayLockIcon {
  margin-top: calc(var(--overlay-field-min-height) / 4);
}

.RequiredMark {
  margin-top: calc(var(--overlay-field-min-height) * 0.75);
  color: $of-red-02;
}

.OverlayFieldPopover {
  --overlay-field-remove-action-color: #{$of-forest-green-01};

  max-height: 45px;
  padding: 4px 8px;

  button.DeleteOverlayField {
    padding: 2px !important;
    padding-inline: 8px !important;
    background-color: transparent;
    color: $of-forest-green-01;
    height: 40px;

    &:focus,
    &:active {
      background-color: transparent;
      color: var(-overlay-field-remove-action-color);
      outline: 2px solid $of-red-11;
    }

    &:hover {
      background-color: $of-red-05;
      color: $of-red-02;
    }
  }
}

.OverlayToolbar {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

.VerticalDivider {
  background-color: $of-blue-05;
  width: 1px;

  // Parent is flex
  align-self: stretch;
}

.TooltipContentContainerInnerWrapper {
  box-shadow: none;
}

.TooltipMessage {
  font-size: 0.75rem;
  text-align: start;
}
