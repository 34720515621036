@import '~styles/base.scss';

.DataManagement {
  color: $of-forest-green-01;

  .DataManagementItem {
    display: flex;
    align-items: center;
  }

  .DataManagementExport {
    padding: 2rem;

    .Section {
      margin-bottom: 2rem;
    }

    .Header {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .InfoText {
      padding: 0.7rem 0;
    }

    .EmailField {
      flex-grow: 1;
    }

    .Value {
      padding-top: 0.8rem;
      text-align: left;
    }
  }

  .ScalarInputContainer {
    width: 65px;
  }
}

.LinkText {
  display: block;
  color: white !important;
  text-decoration: underline;
}

.FormRow {
  width: 100%;
  max-width: 660px;
}

.Flex {
  display: flex;
}

.Label {
  margin-right: 2.5rem;
  min-width: 18rem;
}

.Right {
  display: flex;
  gap: 22px;
}
