@import '~styles/base.scss';

.BoxContainer {
  position: relative;

  .BoxMenu {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    @media only screen and (max-width: $contract-view-mobile-max) {
      right: calc(12rem - 147px);
      opacity: 1;
      position: absolute;
    }

    &:focus-visible,
    &.Visible {
      opacity: 1;
    }

    &:focus-within {
      opacity: 1;
    }
  }

  .ImportSparkle {
    opacity: 1;

    @media only screen and (max-width: $contract-view-mobile-max) {
      right: calc(12rem - 200px);
      opacity: 1;
      position: absolute;
    }
  }

  &:hover .BoxMenu {
    opacity: 1;
  }
}
