@import '~styles/base.scss';

.Sticky {
  position: sticky;

  /*
    Using -1px instead of 0 to ensure the Intersection Observer
    triggers when the element becomes sticky. This allows
    the `data-stuck` attribute to be set accurately based on
    the element's stickiness.
  */
  top: -1px;

  &[data-stuck='true'] {
    border-bottom: 1px solid $of-dark-green-05;
  }
}
