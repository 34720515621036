@import '~styles/base.scss';

:root {
  --color-blue-03: #509dd1;
  --color-forest-green-01: #013a4c;
  --color-lilac: #ac79a4;
  --color-red-03: #db686f;
  --color-yellow: #ffd063;
  --gradient-shadow:
    linear-gradient(
      45deg,
      var(--color-yellow),
      var(--color-red-03),
      var(--color-lilac),
      var(--color-blue-03),
      var(--color-lilac),
      var(--color-red-03)
    );
}

.ActiveGenerateButtonContainer {
  position: relative;
  isolation: isolate;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 4px;
    background-image: var(--gradient-shadow);
    background-size: 400%;
    transition: opacity 0.2s ease-in-out;
  }

  &::after {
    filter: blur(10px);
  }

  &:hover {
    text-decoration: none;

    &::before,
    &::after {
      opacity: 1;
      background-image: var(--gradient-shadow);
      transition: opacity 0.5s ease-in-out;
      animation: animate-gradient 20s linear infinite;
    }
  }
}

.LoadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 410px;
  margin: 45px 18px 0 18px;
  flex-direction: column;

  .LoadingText {
    font-size: 12px;
    color: $of-forest-green-03;
    line-height: 14px;
    font-weight: 500;
    position: absolute;
  }
}

.TextAreaContainer {
  margin: 25px 18px 0 18px;
  position: relative;
  transition: transform 0.15s ease-in-out;

  @include respondTo(md, max) {
    margin-top: 0;
  }

  .TextArea {
    font-size: var(--contract-static-font-size);
    color: #013a4c;
    background-color: #fff;
    border: 1px solid #d6dfe2;
    outline: 0;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 8px 8px;
    overflow-wrap: break-word;
    overflow-y: auto !important;
    min-height: 42px;
    max-height: 70px;
    resize: none;
    box-shadow: none;
    line-height: 25px;
  }

  .CharacterCount {
    position: absolute;
    font-size: 12px;
    right: 10px;
    bottom: 21px;
    color: $of-forest-green-03;

    &.Warning {
      color: $of-red-02;
    }
  }
}

textarea::-webkit-input-placeholder {
  color: $of-winter-green-dream;
}

.TypewriterContainer {
  white-space: pre-wrap;
  height: 422px;
  margin: 45px 18px -13px 18px;
  border: 1px solid $of-dark-green-05;
  border-radius: 6px;
  background-color: $of-inactive;
  box-sizing: border-box;
  overflow: auto;
  color: "#000000";
  line-height: 22px;
  font-size: 14px;
  padding-bottom: 30px;

  @include respondTo(md, max) {
    margin-bottom: 14px;
    height: 396px;
  }
}

.CloseButtonContainer {
  margin-left: auto;
  max-width: 2rem;
  position: relative;
}

.CloseButton {
  width: 100%;
  height: 100%;
  padding: 0;
  align-items: flex-start;
  position: absolute;
  top: 8px;
  right: 17px;
  color: $of-forest-green-01;

  &:hover,
  &:focus {
    color: $of-forest-green-03;
    box-shadow: none;
  }
}

.HeaderContainer {
  display: flex;
  text-align: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  & > :first-child {
    margin-right: auto;
    max-width: 2rem;
    visibility: hidden;
  }

  .CloseButton {
    top: 16px;
    right: 25px;
  }
}

.EmptyHeader {
  display: flex;
  padding: 8px 8px 0 0;

  & > :first-child {
    margin-right: auto;
    width: 2rem;
    visibility: hidden;
  }

  .CloseButton {
    right: 17px;
  }
}

.HeaderTextContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $of-forest-green-01;

  .Header {
    font-size: 42px;
    font-weight: 700;
    line-height: 49px;
  }

  .BetaLabelWrapper {
    margin: 30px 0 30px 12px;
  }

  .Text {
    max-width: 65%;
    font-weight: 600;
    line-height: 20px;
  }

  .HeaderTagContainer {
    display: flex;
  }
}

.HeaderVideo {
  width: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  position: relative;
  height: 188px;

  > video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
}

.AIAssistBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 37.5rem;
  overflow-y: hidden;
}

.GenerateButtonContainer {
  display: flex;
}

.SparkleButtonSparkles {
  position: absolute;
  z-index: 2;
  right: -20px;
  bottom: -12px;
  width: 70px;
  height: 70px;
  pointer-events: none;
}

.SparkleButton {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  z-index: 2;
  display: flex;
  padding: 13px 12px !important;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: var(--color-forest-green-01);
  border: 1px solid var(--color-yellow);
  border-radius: 4px;
  background-color: var(--color-yellow);
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s linear;
  overflow-wrap: break-word;
  align-items: center;
  appearance: none;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
    color: var(--color-forest-green-01);
  }

  &.aligncenter {
    text-align: center;
  }

  &.alignright {
    text-align: right;
  }
}

.SparkleButtonWhite {
  background-color: white !important;
  border: 1px solid $of-forest-green-04 !important;
}

@keyframes animate-gradient {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 300% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.SuggestionBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  border: 1px solid $of-dark-green-05;
  border-radius: 12px;
  min-width: 260px;
  height: 154px;

  &:hover {
    background-color: $of-inactive;
    cursor: pointer;
  }
}

.SuggestionsContainer {
  display: flex;
  justify-content: center;
  gap: 18px;
  margin: 0 72px 33px 72px;

  @include respondTo(md, max) {
    gap: 10px;
    margin: 0 18px 33px 18px;
  }
}

.EmptyContainer {
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;

  .EmptyText {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: 400;
    margin: 34px 0 34px 0;
    color: $of-forest-green-01;

    @include respondTo(md, max) {
      margin: 34px 52px 14px 48px;
    }
  }

  .BulbIcon {
    margin: 18px 0 12px 0;
  }

  .SuggestionText {
    font-size: 14px;
    line-height: 20px;
    color: $of-forest-green-01;
    text-align: center;
    margin: 0 16px;
  }
}

.Footer {
  display: flex;
  justify-content: flex-start;
  min-height: 36px;
  padding: 0 18px 18px 18px;
  align-items: center;

  @include respondTo(md, max) {
    flex-direction: column;
  }

  &.CustomError {
    flex-direction: column;
  }

  .Error {
    .ErrorMessage {
      padding: 0 1rem;
      color: $of-passion;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ErrorIcon {
      margin: 0 0.5rem 0 0;
    }
  }

  .Actions {
    display: flex;
    justify-content: flex-end;
    min-height: 40px;
    flex: 2;
    gap: 10px;

    @include respondTo(md, max) {
      width: 100%;
    }
  }

  .Disclaimer {
    display: flex;
    flex: 1.5;

    @include respondTo(md, max) {
      margin-bottom: 12px;
    }
  }

  .DisclaimerText {
    font-size: 12px;
    line-height: 18px;
    color: $of-forest-green-03;

    span {
      position: relative;
    }
  }
}

.SmallerMargins {
  margin: 28px 58px 0 58px !important;
}

.LogoTextContainer {
  margin: 28px 108px 0 108px;
  display: flex;
  gap: 10px;

  @include respondTo(md, max) {
    margin: 0 14px;
    flex-direction: column;
    gap: 12px;
  }

  .LogoContainer {
    min-width: fit-content;

    @include respondTo(md, max) {
      margin-top: 16px;
    }
  }

  .TextContainer {
    margin-right: 42px;
    width: 100%;

    .ErrorMessageContainer {
      border-radius: 4px;
      border: 1px solid $of-red-02;
      background: white;
      padding: 10px;
      text-align: center;
      display: flex;
      flex-direction: row;
      margin-left: 10px;

      @include respondTo(md, max) {
        margin: 0;
      }

      .AlertIcon {
        color: $of-red-02;
        margin-right: 18px;
      }

      .ErrorMessage {
        color: $of-red-02;
      }
    }
  }
}

.CustomModalClass {
  width: 60rem !important;
  height: 37.5rem !important;

  @include respondTo(md, max) {
    height: 40.25rem !important;
  }
}

.CustomModalBodyClass {
  padding: 0 !important;
}
