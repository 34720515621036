@import '~styles/base.scss';

.Title {
  margin-bottom: 35px;
}

.TotalPriceContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $of-cloud;

  .TotalPrice {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .Price {
      font-weight: 600;
      margin-bottom: 0;
    }

    .InformationText {
      margin-top: 0;
      font-size: 11px;
      color: $of-winter-green-dream;
      max-width: 67%;
      line-height: 12.9px;
      text-align: end;
    }
  }

  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.PlanSummation {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $of-cloud;

  .PlanName {
    display: flex;
    flex-direction: column;
    line-height: 16.42px;

    p {
      margin-top: 0;
    }
  }
}

.Button {
  display: flex;

  & > * {
    margin-left: 0.3rem;
  }
}

.BillingAndInvoice {
  line-height: 16.42px;
  margin-top: 20px;

  p:nth-of-type(1) {
    margin-bottom: 0;
  }

  p:nth-of-type(2) {
    margin-top: 0;
  }
}

.CheckboxContainer {
  margin-top: 1.3rem;
}
