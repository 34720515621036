@import '~styles/base.scss';

.Error {
  input {
    border: 1px solid $of-red-02 !important;
  }
}

.PopoverContent {
  border-radius: 6px;
  max-height: 100vh;
  overflow: scroll;

  @media only screen and (orientation: portrait) and (max-width: 700px) {
    max-height: 85vh;
    max-width: none !important;
    min-width: calc(100vw - 30px);
  }

  input {
    flex: none !important;
    height: 35px !important;
    padding: 0 10px !important;
    min-width: 100% !important;
    box-sizing: border-box !important;
  }

  :global(.ReactSelect__input) {
    max-height: 18px;
    padding: 0 !important;
  }

  :global(.ReactSelect__input-container) {
    min-width: 100% !important;
    overflow: hidden !important;
  }

  :global(.ReactSelect__option) {
    padding: 0 11px;
  }

  :global(.ReactSelect__control) {
    max-height: 37px !important;
    min-height: 37px !important;
  }
}

.Checkbox {
  // TODO: temp - reduce once we've changed the error element taking up space for fields.
  padding-bottom: 21.59px;

  label > span > span {
    color: $of-forest-green-01 !important;
    font-weight: 600 !important;
  }
}

.Header {
  background-color: $of-forest-green-01 !important;
  color: white !important;
  font-family: var(--contract-static-font-family);
  padding: 12px 16px !important;
}

.Required {
  color: $of-red-02;
  font-size: 16px;
}

.Label {
  color: $of-forest-green-01 !important;
  font-family: var(--contract-static-font-family);
  font-weight: 600 !important;
  padding-inline-start: 1px;
}

.Container {
  background-color: white;
  padding: 16px 13px;
}

.InputContainer {
  border: none !important;
}

.ButtonContainer {
  display: flex;
  flex-direction: column;

  .RemoveButtonContainer {
    width: fit-content;
  }
}

.Row {
  display: flex;
  justify-content: flex-end;
}

.Cancel {
  align-items: center;
  border: 1px solid transparent;
  color: #013a4c;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  letter-spacing: -0.02em;
  line-height: 1.5rem;
  padding: 0.35rem 0.75rem;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
}

.RemoveButton {
  color: $of-red-02;
  padding: 0.35rem 0;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-bottom: 1px;
  }
}

.SaveButton {
  background-color: $of-forest-green-03;
  border-color: $of-forest-green-03;
  color: #fff;
  height: 38px;
  padding: 0 18px;

  &:hover {
    background-color: $of-dark-green-01;
    border-color: $of-dark-green-01;
  }
}

.FormMessage {
  line-height: 0.75rem;
  font-size: 0.75rem;
  margin: 0.25rem 0 0.25rem 0;
  min-height: 0.85rem;
}
