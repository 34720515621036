@import '~styles/base.scss';

.HeaderText {
  font-size: 1.1rem;
  font-weight: 500;
  color: $of-forest-green-01;
  margin: 8px 0 15px 0;
  line-height: 2rem;
}

.Row {
  width: 100%;
  max-width: 660px;
  line-height: 1.5;
}

.ErrorContainer {
  display: flex;
  margin: 8px 24px;
}

.TemplateTopRow {
  display: flex;
  justify-content: space-between;
}

.AiReviewForm {
  ul {
    padding: 0;
    margin-bottom: 40px;

    li {
      list-style-type: none;
      margin-bottom: 30px;
    }
  }
}

.TemplateButton {
  width: 220px;
  height: 132px;
  border-radius: 6px;
  padding: 12px;
  border: 1px solid $of-dark-green-05;
  box-shadow: 0 0 8px 0 rgba(1, 58, 76, 0.2);
  cursor: pointer;
  background: white;

  &:hover(:not(.CustomTemplate)) {
    border: 1px solid $of-forest-green-04;
  }

  &.Selected {
    background-color: $of-dark-green-05;
    border: 1px solid $of-winter-green-dream;
  }

  &.Disabled {
    background: $of-inactive;
    border-color: $of-inactive !important;
    color: $of-winter-green-dream;
    cursor: not-allowed;
  }

  &.CustomTemplate {
    cursor: default;
  }

  .AssetsContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .ContactSalesButton {
    color: $of-blue-02;
    justify-content: flex-start;
    padding-left: 0;
    font-weight: 500;

    .ActionText {
      white-space: normal;
      line-height: normal;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.PlaybookTypesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.PlaybookParamsHeader {
  font-size: 15px;
  font-weight: 500;
  margin: 36px 0;
}

.PlaybookParamLabel {
  font-weight: 500;
}

.RadioButton {
  margin: 0 4px 0 0;
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  outline: 0.1em solid $of-forest-green-04;
  border-radius: 50%;
  transform: translateY(0.15em);

  &:checked {
    border: 0.35em solid $of-forest-green-01;
    outline-color: $of-forest-green-01;
  }

  &:disabled {
    border-color: $of-forest-green-04;
    outline-color: $of-forest-green-05;
  }

  &:hover {
    cursor: pointer;
  }

  &.Hidden {
    display: none;
  }
}

.PaymentTermContainer {
  position: relative;
  display: flex;
  align-items: baseline;
  margin-top: 16px;
  margin-bottom: 32px;

  .PaymentTermInput {
    width: 100px;

    input {
      width: 100%;
    }
  }

  div[class*=TextField] {
    flex: none;
  }

  span[class*=ErrorMessage] {
    position: absolute;
    top: 45px;
  }
}

.DisabledButtonTooltip {
  text-align: center;
}

.Country {
  color: $of-forest-green-01;
  margin: 2px 6px 2px 0;
  border-radius: 30px;
  border: 1px solid $of-forest-green-05;
  background: none;
  height: 22px;
  overflow: hidden;
  min-width: 32px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    border-color: $of-forest-green-04;
  }

  &.Disabled {
    color: $of-forest-green-03;
    background: $of-inactive;
    cursor: not-allowed;
  }
}

.ArbitrationInputContainer {
  display: flex;
  gap: 4px;
  align-items: baseline;

  label {
    height: unset;
  }

  &:first-of-type {
    margin-top: 16px;
  }
}

.Empty {
  padding: 4rem 2rem 4rem 2rem;
}

.Loading {
  padding: 4rem 2rem 4rem 2rem;
  display: flex;
  justify-content: center;
}
