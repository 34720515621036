@import '~styles/base.scss';

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Welcome {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ContentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    padding: 20px;
    background-color: $of-pink-01;
    border-radius: 7px;
    text-align: center;
    z-index: 1;

    p {
      margin: 0;
      margin-bottom: 20px;
    }

    p:last-of-type {
      padding: 0 60px;
    }

    .Header {
      font-size: 20px;
    }

    .MagicData {
      position: absolute;
      left: 28px;
      bottom: 10px;
      transform: rotate(111deg);
      pointer-events: none;
      animation: fade-in 500ms ease;
    }
  }

  .Buttons {
    display: flex;
    justify-content: space-between;
    width: 65%;

    .DropZone {
      width: 168px;
      height: 90px;
      border: 0.833333px dashed $of-forest-green-04;
      border-radius: 7px;
    }
  }

  .Footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 28px;
    margin-right: -32px;
  }
}
