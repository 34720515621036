@import '~styles/base.scss';

.Select {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: $of-forest-green-01;
  border-radius: 4px;
  border: 1px solid $of-dark-green-04;
  padding: 0.5em;
  line-height: 1;
  flex: 1;

  svg {
    margin-left: 0.5em;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &[aria-expanded=true],
  [data-side=bottom] {
    border-color: $of-forest-green-01;
  }

  &.OpenedOnTop {
    border-top-right-radius: unset;
    border-top-left-radius: unset;
    border-top: none;
  }

  &.OpenedOnBottom {
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  &.Disabled {
    color: $of-dark-green-03;

    &:hover {
      border-color: $of-dark-green-04;
    }
  }
}

.SelectContent {
  z-index: 999999;
  width: var(--radix-select-trigger-width);

  .ItemsContainer {
    max-height: 230px;
    background: white;
    border: 1px solid $of-dark-green-01;
    border-radius: 4px;
  }

  &[data-side=bottom] {
    .ItemsContainer {
      border-top-right-radius: unset;
      border-top-left-radius: unset;
    }
  }

  &[data-side=top] {
    .ItemsContainer {
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
    }
  }
}
