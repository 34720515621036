@import '~styles/base.scss';

.LinkInformation {
  height: 34px;
  display: flex;
  align-items: center;
}

.Tooltip {
  font-size: 12px;
  padding: 4px 8px;
}

.EditIcon {
  color: $of-forest-green-01 !important;
  padding: 4px;
}

.UrlButton {
  width: 260px;
  justify-content: left;
  align-items: center;
  padding: 0 4px;

  svg {
    margin-top: 8px;
    color: $of-dark-green-01;
  }

  span[class*="label"] {
    color: $of-legacy-blue;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.LinkButton {
  margin: 0 8px;
  padding: 0;

  &:hover {
    background-color: $of-dark-green-06;
  }
}
