@import '~styles/base.scss';
@import './utility-styles.scss';

.ExpiryInfo {
  width: 100%;
  padding-block: 16px;
  border-radius: 6px;
  color: $of-winter-green-dream;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.8125rem;
}

.Bold {
  font-weight: 500;
}

.DatePassed {
  color: $of-red-02;
}

.AddExpiry {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 12;
  color: $of-forest-green-01;
}

.AddIconContainer {
  display: grid;
  place-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $of-yellow;
}

.TriggerElement {
  padding-block: 4px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.NoExpiryDate {
  font-size: 12px;
}

.NoExpiryInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-block: 16px;
  color: $of-winter-green-dream;
}

.SigningPeriodHeader {
  padding-top: 8px;
  font-size: 0.875rem;
  font-weight: 600;
  color: $of-forest-green-01;
}

.PopoverContent {
  padding: 0;
  min-width: 300px;
  min-height: 36px;
}

.PopoverHeader {
  border-radius: 8px 8px 0 0;
  padding: 10px;
  background-color: $of-forest-green-01;
  color: white;
}

.ExpiryContainer {
  padding: 16px;
  min-height: 142px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > fieldset {
    border: 0;
    padding: 0;
  }
}

.ActionButtonsContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px;
}
