@import '~styles/base.scss';

.PhoneField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include flex(1);

  .InputField {
    .Input {
      height: 40px;
    }

    .FlagDropdown {
      background-color: white;
      z-index: 0;
    }

    &.error {
      .Input {
        border-color: $of-passion;
      }

      .FlagDropdown {
        border-bottom-color: $of-passion;
        border-top-color: $of-passion;
        border-left-color: $of-passion;
      }
    }

    &.disabled {
      .Input {
        background-color: $of-disable;
        border-color: $of-cloud;
        color: $of-cloud;
      }

      .FlagDropdown {
        background-color: $of-disable;
        border-color: $of-cloud;
        color: $of-cloud;
        opacity: 0.3;
      }
    }
  }

  .ErrorMessage {
    color: $of-passion;
    line-height: 0.85rem;
    font-size: 0.85rem;
    margin: 0.5rem 0.25rem 0;
    min-height: 0.85rem;
  }
}
