@import '~styles/base.scss';

.OneflowContentContainerWrapper {
  --tooltip-container-border-radius: 5px;
  --tooltip-background-color: #{$of-forest-green-01};
  --container-inner-wrapper-border-radius: var(--tooltip-container-border-radius);
  --tooltip-shadow-color: var(--tooltip-shadow-color);
}

.LightContentContainerWrapper {
  --tooltip-container-border-radius: 8px;
  --container-inner-wrapper-border-radius: var(--tooltip-container-border-radius);
  --tooltip-shadow-color: var(--tooltip-shadow-color-light);
}

.TouchableTrigger {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.TooltipInfo {
  display: inline-flex !important;
  margin-left: 0.3rem;
  background-color: transparent;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $of-dark-green-05;
  }
}

.Icon {
  display: flex;
  color: $of-forest-green-01;
}

.Disabled {
  color: $of-forest-green-03;
}

.ContentContainerWrapper {
  font-size: 0.75rem;
  min-width: 0;
  max-width: 250px;
  margin: 0 !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
  box-shadow: -1px 1px 20px 0 var(--tooltip-shadow-color);
  border-radius: var(--container-inner-wrapper-border-radius);
  background-color: var(--tooltip-background-color);

  &:focus-visible {
    outline: none;
  }
}

.ContentContainer {
  padding: var(--tooltip-container-padding);
  border-radius: var(--tooltip-container-border-radius);
  font-weight: 400;

  &:focus-visible {
    outline: none;
  }
}

.LightContentContainer {
  background-color: var(--tooltip-background-color);
}

.OneflowContentContainer {
  background-color: var(--tooltip-background-color);
  color: white;
}
