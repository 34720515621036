@import '~styles/base.scss';

.LoadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
}
