@import '~styles/base.scss';

.Plans {
  p {
    margin-block-start: 1em;
    margin-block-end: 0;
  }

  .LinkContainer {
    display: flex;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  .Container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 798px) {
      flex-direction: column;
      align-items: center;
    }

    .Heading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      padding: 2px 0;
      font-size: 12px;
      border-radius: 4px 4px 0 0;
      background: $of-dark-green-05;
      color: $of-forest-green-01;
      width: 100%;
    }

    .Selection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 210px;
      min-height: 388px;
      position: relative;

      @media screen and (max-width: 797px) {
        justify-content: space-around;
        margin-bottom: 12px;
      }

      margin-left: 8px;
      box-shadow: 0 0 8px rgba($of-forest-green-01, 0.2);
      border-radius: 4px;

      .PlanName {
        margin-top: 16px;
      }

      h1,
      h2,
      p {
        margin: 0.5rem 0;
      }

      h2 {
        font-size: 20px;
        font-weight: bold;
        color: $of-forest-green-01;
        padding-top: 10px;
      }

      .Bold {
        font-size: 15px;
        font-weight: 700;
        letter-spacing: -0.02em;
        line-height: 20px;
        margin-bottom: 16px;
      }

      .BulletPoints {
        display: flex;
        flex-direction: column;

        &.EssentialsBulletPoints {
          margin-top: 43px;

          @media screen and (max-width: 797px) {
            margin-top: 0;
          }
        }

        .BulletPoint {
          display: flex;
          padding: 0 16px;

          span:first-of-type {
            width: 20px;
            margin-right: 16px;

            .CheckMark {
              color: white;
              background: $of-forest-green-02;
              border-radius: 100%;
              padding: 4px;
            }
          }
        }
      }

      .BottomContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 8px 16px 8px;
        align-items: center;

        h1 {
          font-size: 28px;
          font-weight: bold;
          line-height: 20px;
          color: $of-forest-green-01;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }

  .Link {
    color: $of-blue-02;
    text-decoration: underline;
    margin: 0 3px 0 5px;
  }
}
