@import '~styles/base.scss';

.FeatureContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 50%;
  margin-top: 12px;

  .Icon {
    background-color: $of-forest-green-01;
    border-radius: 50%;
    margin-right: 10px;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 70px;
      width: 70px;
    }
  }

  .Label {
    color: $of-forest-green-01;
    font-size: 14px;
  }
}
