@import '~styles/base.scss';

.VideoFeedback {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  .CameraOffIcon {
    width: 6rem;
    height: 6rem;

    @include respondTo(md, max) {
      width: 3rem;
      height: 3rem;
    }
  }
}
