@import '~styles/base.scss';

.AIAssistWrapper {
  position: relative;
  padding-top: 5px;
}

.AIAssistContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.NoBorder {
  border: none;
}

fieldset {
  &.NoBorder {
    @extend .NoBorder;
  }
}
