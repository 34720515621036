@import '~styles/base.scss';

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  color: white;
  width: 24px;
  height: 24px;

  &.Success {
    background-color: $of-green-01;
  }

  &.Error {
    background-color: $of-red-02;
  }

  &.Warning {
    border-radius: 0;
    color: $of-contract-overdue-new;
  }

  &.Info {
    border-radius: 0;
    color: $of-blue-03;
  }
}
