@import '~styles/base.scss';

.RecordTimer {
  display: table;
  background-color: rgba($of-thunder, 0.4);
  padding: 2px 5px;
  border-radius: 0 0 4px 4px;

  .Container {
    display: flex;
    justify-content: center;
    align-items: center;

    .Recording {
      @keyframes recordingIndicator {
        0% {
          color: rgba($of-passion, 0.1);
        }

        100% {
          color: $of-passion;
        }
      }

      animation: recordingIndicator 1s infinite alternate;
    }

    .Paused {
      color: $of-cloud;
    }

    .Duration {
      font-size: 1.5rem;
      color: white;
      font-family: monospace;
      user-select: none;
    }
  }
}
