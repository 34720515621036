@import '~styles/base.scss';

.DatePicker {
  :global(.DayPicker) {
    box-shadow: none;
  }

  :global(.DayPicker__withBorder) {
    box-shadow: none;
  }
}

.DatePickerMonthElement {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  select {
    height: 33px;
    border: 1px solid $of-forest-green-05;
    padding: 4px 6px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}
