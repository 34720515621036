@import '~styles/base.scss';

.Body {
  min-height: 350px;

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .UrlButton {
    padding: 0;
    margin: 0;
    display: inline-flex;

    &:hover {
      text-decoration: underline;
    }
  }

  .SelectedContractContainer {
    margin: 15px 0;
  }

  .LinkContractLabel {
    font-size: 14px;
  }

  .InputContainer {
    position: relative;
    display: flex;
    justify-content: space-between;

    input[disabled] {
      background-color: transparent !important;
    }

    .SelectContainer {
      width: 40%;
      margin-right: 15px;
    }

    @media only screen and (max-width: 760px) {
      flex-direction: column-reverse;

      .SelectContainer {
        width: 100%;
      }
    }
  }

  .ContractItemContainer {
    position: relative;

    .UnselectContract {
      position: absolute;
      z-index: 1;
      right: 0;
      padding: 0.75rem;
    }

    .ErrorMessage {
      color: $of-red-02;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: -0.02em;
    }
  }
}

.ActionButtonContainer {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
}

.LinkToButton {
  button {
    padding: 0;
  }
}
