@import '~styles/base.scss';

.VideoRecorder {
  background: $of-pitch;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .RecordTimerContainer {
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    height: 0;
    position: relative;
  }

  .Canvas {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .Countdown {
    height: 0;
    position: relative;
    top: calc(-50% - 2.5rem);
    font-size: 5rem;
    color: $of-cloud;
    text-shadow: 0 0 1px $of-thunder;
    margin: 0;
    line-height: 5rem;
    user-select: none;
  }

  .CameraControlsContainer {
    display: flex;
  }

  .MicrophoneControlContainer {
    align-self: flex-end;
    display: flex;
  }
}
