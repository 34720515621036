@import '~styles/base.scss';

:root {
  // File Upload Status Box -- Abbreviation: ausb
  --ausb-container-bg: #fff;
  --ausb-scrollbar-width: 7px;
  --ausb-file-text: #{$of-forest-green-01};
  --ausb-loading-icon-color: #{$of-forest-green-01};
  --ausb-file-error-text: #{$of-red-02};
  --ausb-divider-color: #{$of-forest-green-04};
  --ausb-width: 330px;
  --ausb-height: 211px;
  --ausb-header-color: #fff;
  --contract-view-sidebar-width: 360px;
  --ausb-body-max-height: 176px;
  --ausb-header-height: 40px;
  // same as the space between the sidebar and tabs toolbar
  --ausb-container-positioning: 20px;
  --ausb-container-box-shadow: -1px 1px 20px 0 #0000001a;
  --ausb-container-radius: 8px 8px 0 0;
  --ausb-scrollbar-color: #{$of-forest-green-01};
  --ausb-header-bg: #{$of-forest-green-01};
}

.FileUploadStatusBoxContainer {
  bottom: 0;
  background-color: var(--ausb-container-bg);
  width: var(--ausb-width);
  right: var(--ausb-container-positioning);
  border-radius: var(--ausb-container-radius);
  box-shadow: var(--ausb-container-box-shadow);
  overflow: hidden;
  position: absolute;

  &.Closed {
    display: none;
    height: 0;
  }

  @media (max-width: 999px) {
    --ausb-width: 300px;
  }

  @media (max-width: 576px) {
    --ausb-width: 100%;
  }

  &.CollapsedLayout {
    --ausb-container-positioning: 0;

    bottom: var(--collapsed-document-bottom-navigation-height);
  }
}

.Header {
  height: var(--ausb-header-height);
  display: flex;
  background-color: var(--ausb-header-bg);
  color: var(--ausb-header-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  .TitleWrapper {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding-left: 11px;

    .TitleText {
      display: inline-block;
      flex: 1 1;
    }
  }

  .ContainerActionButtons {
    display: flex;
  }
}

.ActionButton {
  padding: 10px;
  color: inherit;
  display: flex;
  align-items: center;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.FileUploadStatusBoxFilesWrapper {
  flex: 1;
  position: relative;
  box-sizing: border-box;
  max-height: var(--ausb-body-max-height);
  overflow: auto;
  overscroll-behavior: contain;

  .FileUploadStatusBoxFilesList {
    height: 100%;
    padding: 16px;

    &::-webkit-scrollbar {
      width: var(--ausb-scrollbar-width);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--ausb-scrollbar-color);
      border-radius: 4px;
    }
  }

  &.Minimized {
    height: 0;
  }
}

.PreviousFilesDividerContainer {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.PreviousFilesLabel {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  line-height: 11.73px;
  color: $of-forest-green-03;
  background: var(--ausb-container-bg);
  padding: 5px;
}

.PreviousFilesDivider {
  border-bottom: 1px dashed var(--ausb-divider-color);
  flex: 1;
}
