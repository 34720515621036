@import '~styles/base.scss';

.DefaultSettings {
  [class*=DefaultFolder] {
    margin-bottom: 0;
  }

  .TemporaryWarning {
    color: $of-red-01;
  }

  .Divider {
    border-bottom-color: $of-dark-green-05;
    margin: 24px 0;
  }
}
