@import '~styles/base.scss';

.Plans {
  text-align: center;

  h3 {
    font-weight: 700;
    font-size: 36px;
    color: $of-forest-green-01;
    margin-block-start: 0.5em;
    margin-block-end: 0.8em;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 0;
  }

  .Container {
    display: flex;
    align-items: flex-end;

    .Selection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25%;
      margin: 0 1rem 1rem 1rem;
      box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
      border-radius: 8px;

      h1,
      h2,
      h3,
      p {
        margin: 0.5rem 0;
        padding: 0 1rem;
      }

      h2 {
        margin-top: 16px;
        font-size: 20px;
        font-weight: bold;
        color: $of-forest-green-01;
      }

      .Heading {
        padding: 12px 0;
        align-self: stretch;
        font-size: 20px;
        border-radius: 8px 8px 0 0;
        background: $of-plum-01;
        color: white;
      }

      .BulletPoints {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        min-height: 235px;
        text-align: left;

        .BulletPoint {
          display: flex;
          align-items: flex-start;
          margin: 5px 0;
          padding: 0 16px;

          span:first-of-type {
            width: 20px;
            margin-right: 7px;

            .CheckMark {
              color: white;
              background: $of-forest-green-03;
              border-radius: 100%;
              padding: 4px;
            }
          }
        }
      }

      .BottomContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 8px 16px 8px;

        h1 {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: bold;
          line-height: 20px;
          color: $of-forest-green-01;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }

  .FooterLinksContainer {
    font-size: 20px;
    margin: 10px 0;
  }
}

.FooterLinks {
  color: $of-blue-02;
}
