@import '~styles/base.scss';

.HeaderText {
  font-size: 1.1rem;
  font-weight: 500;
  color: $of-forest-green-01;
  margin: 8px 0 15px 0;
  line-height: 2rem;
}

.Row {
  width: 100%;
  max-width: 660px;
  line-height: 1.5;
}

.LogoUpload {
  margin: 0 -2px 8px;
}

.LogoRow {
  margin-bottom: 38px;
  display: flex;
  gap: 30px;
}

.Empty {
  padding: 4rem 2rem 4rem 2rem;
}

.FileUploadContainer {
  width: 240px;
}
