@import '~styles/base.scss';

.MenuItem {
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  color: #b0bcc1;
  display: flex;
  height: 42px;
  margin: 0 2px;
  min-width: 42px;
  padding: 0 12px;

  &:hover {
    cursor: pointer;
    background-color: rgba(214, 223, 226, 0.1);
    color: white;
  }

  @include respondTo(md, max) {
    border-radius: 6px;
  }

  &:active {
    background-color: rgba(214, 223, 226, 0.2);
  }

  &.Mobile {
    &:hover {
      background-color: $of-forest-green-01;
      color: #b0bcc1;
    }

    &:active {
      background-color: rgba(214, 223, 226, 0.2);
      color: #b0bcc1;
    }
  }
}

.MenuItemText {
  font-size: 15px;
  line-height: 130%;
  overflow: hidden;

  &.WithIcon {
    margin-left: 13px;
  }
}

.Link {
  &:hover {
    text-decoration: none;
  }

  &:focus-visible {
    outline: auto;
  }
}
