@import '~styles/base.scss';

.PostPopover {
  width: 360px;
  padding: 8px 12px;
  transform-origin: var(--radix-popover-content-transform-origin);
}

.AnnotationToolbar {
  display: flex;
  flex-direction: row;
}
