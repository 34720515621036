@import '~styles/base.scss';

.Header {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 18px;
  width: 100%;

  &.Closed {
    margin-bottom: 14px;
  }

  .FolderButton {
    background-color: transparent;
    border: none;
    color: $of-forest-green-01;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    padding: 0;
    position: relative;
    text-transform: uppercase;

    span {
      display: flex;
      line-height: normal;
    }

    &:hover {
      cursor: pointer;
    }

    .Icon {
      align-items: center;
      display: flex;
      margin-right: 8px;

      .Closed {
        transform: rotate(0deg);
        transition: transform 0.2s;
      }

      .Open {
        transform: rotate(90deg);
        transition: transform 0.2s;
      }
    }
  }
}

.BreadCrumb {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  list-style: none;
  margin: 0 0 0 4px;
  overflow: hidden;
  padding: 0;
}

.Hidden {
  display: none;
}

.FolderList {
  list-style: none;
  padding: 0;
}
