@import '~styles/base.scss';

.HexColorPicker {
  width: 280px !important;
  cursor: pointer !important;
}

.ColorForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input[type="text"].ColorInput {
  font-size: var(--contract-static-font-size) !important;
}

.ColorInputContainer {
  display: flex;
  gap: inherit;
  height: 60px;
  padding-bottom: 5px;

  [class*=TextField] {
    position: relative;
    top: 12px;
    height: fit-content;
  }

  .ColorInput {
    padding: 8px;
    margin: 0;
    width: fit-content;

    >input {
      padding: 0;
      width: 180px;
      height: 15px;
    }
  }

  span[class*=ErrorMessage] {
    width: 180px;

    &:empty {
      display: none;
    }
  }

  .ColorInputLabel {
    display: flex;
    align-items: center;
    font-weight: 400;
    gap: 0.5rem;
    font-family: var(--contract-static-font-family);
    font-size: 0.8125rem;
  }
}

.ColorActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.RecentlyUsedColors {
  &Section {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid $of-forest-green-05;
  }

  &Label {
    margin-bottom: 6px;
    font-family: var(--contract-static-font-family);
    font-size: var(--contract-static-font-size) !important;
  }

  &Buttons {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
}

.ResetButton {
  color: $of-forest-green-01;

  &:hover {
    text-decoration: underline;
  }
}
