@import '~styles/base.scss';

.Container {
  background-color: $of-inactive;
  border-radius: 8px;
  margin: 22px;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.Header {
  min-height: 31px;
  padding: 0 12px;
  border-bottom: 1px solid $of-legacy-lightest;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px 8px 0 0;
}

.CompanyName {
  font-size: 0.625rem;
  font-weight: 500;
  color: $of-winter-green-dream;
}

.SignButton {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 3px !important;
  padding: 4px 11.5px;
  cursor: unset;
  background-color: $of-document-sign-button-green;

  span {
    font-size: 0.625rem;
    font-weight: 500;
  }
}

.Body {
  margin: 8px 70px 0 70px;
  background-color: white;
  padding: 6px 18px 0 18px;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 0 0;
}

.Scroll {
  position: absolute;
  height: 34px;
  width: 10px;
  background-color: white;
  border-radius: 2px;
  top: 41px;
  right: 12px;
}

.ShortSkeleton {
  height: 6px;
  width: 50px;
}

.MediumSkeleton {
  height: 6px;
  width: 76px;
}

.LongSkeleton {
  height: 6px;
  width: 110px;
}

.CenterContent {
  display: flex;
  justify-content: center;
}

.Logo {
  object-fit: contain;
  height: 20px;
}
