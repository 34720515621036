/* stylelint-disable length-zero-no-unit */  // --> we need 0px instead of just 0 to be able to use css vars in the calc() function

@import '~styles/base.scss';

:root {
  --snackbar-original-position: 8px;
  --ausb-visual-height: 211px;
  --snackbar-offset-y: calc(var(--ausb-visual-height) + var(--snackbar-original-position));
}

@media (min-width: 600px) {
  :root {
    --snackbar-original-position: 24px;
  }
}

.AttachmentBoxContainer {
  padding: 0 20px;
}

.DropzoneMenuItem {
  border: none;
}

.EmptyIcon {
  width: 14px;
  height: 14px;
}

.AUSBOverrideSnackbar {
  :global(.MuiSnackbar-anchorOriginBottomLeft) {
    bottom: var(--snackbar-offset-y);
    transition: none;
  }

  &.CloseAUSB,
  &.MinimizeAUSB {
    :global(.MuiSnackbar-anchorOriginBottomLeft) {
      --ausb-visual-height: 35px;
      --snackbar-offset-y: calc(var(--ausb-visual-height) + var(--snackbar-original-position));

      bottom: var(--snackbar-offset-y);
      transition: bottom ease 0.5s;
    }
  }

  &.MinimizeAUSB {
    :global(.MuiSnackbar-anchorOriginBottomLeft) {
      --ausb-visual-height: 35px;
    }
  }

  &.CloseAUSB {
    :global(.MuiSnackbar-anchorOriginBottomLeft) {
      --ausb-visual-height: 0px;
      --snackbar-offset-y: var(--snackbar-original-position);

      bottom: var(--snackbar-offset-y);
    }
  }
}

@media screen and (max-width: 52.5rem) {
  .AUSBOverrideSnackbar {
    :global(.MuiAlert-root) {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .AUSBOverrideSnackbar {
    :global(.MuiSnackbar-anchorOriginBottomLeft) {
      left: 8px !important;
    }
  }
}

@media (min-width: 576px) {
  .AUSBOverrideSnackbar {
    --snackbar-offset-y: var(--snackbar-original-position);

    :global(.MuiSnackbar-anchorOriginBottomLeft) {
      bottom: var(--snackbar-offset-y);
      transition: none;
    }

    &.MinimizeAUSB {
      :global(.MuiSnackbar-anchorOriginBottomLeft) {
        --snackbar-offset-y: var(--snackbar-original-position);

        bottom: var(--snackbar-offset-y);
      }
    }

    &.CloseAUSB {
      :global(.MuiSnackbar-anchorOriginBottomLeft) {
        bottom: 8px;
      }
    }
  }
}
