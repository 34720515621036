@import '~styles/base.scss';

.Search {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;

  .SearchBody {
    display: flex;

    .FilterSidebarContainer {
      min-width: 15rem;
      max-width: 15rem;
      min-height: 40rem;
      border-right: 1px solid $of-light-gray;
      padding-right: 1.5rem;

      > [class*='FilterBox']:nth-child(2),
      > [class*='SearchFilterContainer']:first-child {
        border: unset;
      }
    }

    .ResultContainer {
      min-height: 40rem;
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      padding-left: 1.5rem;
      max-width: calc(100% - 15rem);

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        flex: 1 1 auto;
      }

      .ActionBar {
        display: flex;

        @media screen and (max-width: 400px) {
          >div {
            max-width: 200px;
          }
        }

        .ActionBarDivider {
          margin: 0.5rem;
        }
      }
    }
  }
}

.RightSide {
  display: flex;
  margin-left: auto;
}

.ImportButton {
  height: 42px;
}
