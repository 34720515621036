@import '~styles/base.scss';

.ActionWrapper {
  :hover {
    border-radius: 6px;
    background-color: $of-dark-green-05;
  }
}

.ActionButton {
  background-color: #fff;
  border: 1px solid $of-dark-green-04;
  width: 36px;
  height: 36px;
  border-radius: 6px;

  svg {
    color: $of-blue-02;
  }
}

.RedIcon {
  svg {
    color: $of-red-02;
  }
}

.ResolvedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  cursor: default;
  background-color: $of-green-01;
  color: #fff;

  :hover {
    background-color: $of-green-01;
  }
}

.TooltipMessage {
  max-width: 12rem;
  font-size: 12px;
  text-align: center;
}

.Trigger {
  border: none;
  background-color: transparent;
  color: $of-forest-green-01;
  width: 42px;
  height: 42px;

  &:hover,
  &[aria-expanded=true] {
    background-color: $of-forest-green-05;
    border-radius: 8px;
  }
}

.HiddenMenu {
  display: none;
}

.MenuItemWrapper {
  display: flex;

  svg {
    margin-right: 6px;
  }
}
