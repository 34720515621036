.Folder {
  display: flex;
  gap: 4px;
}

.OriginalLocationContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
