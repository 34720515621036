
.SectionHeader {
  font-weight: 600;
  font-size: 1.1rem;
  padding-top: 0.75rem;
}

.Toggle {
  margin-top: -0.3rem;
}

.DisplayNameDescription {
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.Price {
  font-weight: 600;
}

.NotificationsDescription {
  margin-bottom: 24px;
}

.Actions {
  display: flex;
}

.Header {
  font-weight: 500;
  font-size: 1.1rem;
}

.Row {
  width: 100%;
  max-width: 660px;
}

.ToggleRow {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.Label {
  margin-right: 2.5rem;
  margin-bottom: 0;
  min-width: 18rem;
}
