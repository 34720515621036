.Row {
  display: flex;
  margin: 0.25rem 0;

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }
}

.Note {
  margin-top: 0;
}

.FirstChildFixedHeight {
  > :first-child > div {
    flex: none;
    height: 40px;
  }
}

.Buttons {
  display: flex;

  & > * {
    margin-left: 0.3rem;
  }
}

.Loading {
  margin: 2em auto;
}
