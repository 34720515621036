@import '~styles/base.scss';

.DataField {
  line-height: 2;
  word-break: break-all;
  word-break: break-word;

  .Description {
    font-size: 0.8rem;
    color: $of-reign;
  }
}

.Label {
  display: flex;
  align-items: center;
  gap: 4px;
}
