@import '~styles/base.scss';

.Section {
  margin-bottom: 21px;
}

.SaveToAddressBook {
  margin-bottom: 21px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  gap: 10px;
}
