.FreshchatWidget {
  right: 4px !important;
}

.pre-fc-field {
  margin-top: 30px;
  padding-right: 16px;

  input {
    padding: 8px !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  label {
    top: -20px !important;
    margin-left: 0 !important;
  }

  .pre-fc-error {
    color: #ca2128;
    margin-top: 4px !important;
  }
}

.chat-fc-form-outer div.fc-form form.submitted ul li input:invalid {
  border-color: #ca2128 !important;
}

.chat-fc-form-outer div.fc-form ul li label span {
  color: #ca2128 !important;
}

.chat-fc-form {
  margin-top: 40px !important;
}
