.ImageResizeHandleTop {
  margin: 0 !important;
}

.ImageResizeHandleRight {
  margin: 0 !important;
}

.ImageResizeHandleBottom {
  margin: 0 !important;
}

.ImageResizeHandleLeft {
  margin: 0 !important;
}

.ImageResizeHandleTopRight {
  margin: 0 !important;
}

.ImageResizeHandleBottomRight {
  margin: 0 !important;
}

.ImageResizeHandleBottomLeft {
  margin: 0 !important;
}

.ImageResizeHandleTopLeft {
  margin: 0 !important;
}

.HandleResizeComponentWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .HandleResizeComponent_vertical {
    height: 100%;
    width: 1px;
  }

  .HandleResizeComponent_horizontal {
    width: 100%;
    height: 1px;
  }
}

.HandleResizeCornerComponentWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .HandleResizeCornerComponent {
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
}

.focused {
  background-color: #2981f8 !important;
}

.ImageBreakText {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.ImageBreakTextLeft {
  text-align: left;
}

.ImageBreakTextRight {
  text-align: right;
}

.ImageBreakTextCenter {
  text-align: center;
}

.ImageWrapTextLeft {
  float: right !important;
  margin: 0 0 20px 20px !important;
}

.ImageWrapTextRight {
  float: left !important;
  margin: 0 20px 20px 0 !important;
}

.ImageWithToolbarContainer {
  position: relative;
  user-select: none;
}

.BrokenImageIcon {
  min-width: 50px;
  max-width: 100%;
  height: auto;
}

.ContractImage {
  height: auto;
}
