@import '~styles/base.scss';

.AnnotationToolbar {
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 -8px 12px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  padding: 8px;
  border-bottom: 1px solid $of-dark-green-05;
  overflow-x: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  button {
    width: 100%;
    margin: 0 8px;
  }
}
