@import '~styles/base.scss';

.FieldContainer {
  background-color: transparent;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px;
  position: relative;

  .Wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Edit {
      color: $of-forest-green-01;
      display: flex;
      margin-bottom: 5px;
      visibility: hidden;
    }
  }

  &.Active {
    background-color: $of-edit-hover;

    .Edit {
      visibility: visible;
    }
  }

  &.Uneditable {
    cursor: default;
  }

  &:hover:not(.Uneditable) {
    background-color: $of-edit-hover;
    transition: all 100ms;

    .Edit {
      visibility: visible;
    }
  }

  :global(.CalendarDay),
  :global(.CalendarMonth_caption),
  :global(.DayPicker_weekHeader) {
    font-family: 'Proxima Nova', sans-serif;
  }

  :global(.DateInput) {
    input {
      background-color: #fff !important;
      border: none;
      box-shadow: none !important;
      cursor: pointer;
      line-height: normal !important;
      margin: 0;
      padding: 5px 0 5px 0 !important;

      &:focus {
        box-shadow: none !important;
      }

      &::placeholder {
        color: $of-winter-green-dream;
      }
    }
  }

  .InputField {
    display: flex;
    width: 100%;
    height: 14px;
    padding: 0;

    & > input { // stylelint-disable-line no-descending-specificity
      padding: 4px 8px !important;
      background-color: white !important;
      font-family: var(--formatting-font-family) !important;
      color: var(--formatting-font-color) !important;
      font-size: var(--formatting-font-size) !important;

      &:hover {
        cursor: pointer;
      }
    }

    &.Invalid {
      border-color: $of-red-02;
      box-shadow: 0 0 0 1px $of-red-02;
    }
  }

  :global(.DateInput_1) {
    display: flex;
    max-width: unset;
  }

  :global(.DateInput_fang) {
    top: 42px !important;
    z-index: 111;
  }

  :global(.SingleDatePicker_picker) {
    top: 53px !important;
    z-index: 110;
  }

  :global(.SingleDatePickerInput_calendarIcon) {
    align-items: center;
    color: $of-forest-green-03;
    display: flex;
    margin: 0 0 0 10px;
    padding: 0;
  }

  :global(.SingleDatePickerInput_clearDate) {
    align-items: center;
    color: $of-winter-green-dream;
    display: flex;
    justify-content: center;
    width: 15px;
  }

  :global(.SingleDatePickerInput__withBorder) {
    align-items: center;
    display: flex;
    transition: all 100ms;

    &:hover {
      border-color: $of-forest-green-01;
    }
  }

  &.DataField {
    :global(.DateInput) {
      input {
        &::placeholder {
          color: var(--formatting-font-color);
        }
      }
    }
  }
}

.SelectorContainer {
  display: flex;
  justify-content: center;
  height: 33px;

  button {
    background: transparent;
  }
}

.Select {
  flex: none;
  min-width: 70px;
}

.MonthSelect {
  min-width: 110px;
}

.PopoverContent {
  padding: 0;
  box-shadow: none;
  z-index: 9999;
  font-family: var(--contract-static-font-family);
  font-size: var(--contract-static-font-size);
}

.PickedDate {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > span {
    width: 100%;
  }
}

.ValidationMessage {
  color: $of-red-02;
  font-size: 0.6875rem;
  line-height: 13px;
}
