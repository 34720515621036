@import '~styles/base.scss';

.AddButton {
  padding: 0;
  font-size: 0.8rem;
  color: $of-forest-green-01;

  > [class*=label] {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}

.Popover {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;

  .Header {
    border-bottom: 1px solid $of-light-gray;
    padding: 12px 16px;
    font-weight: 600;
  }

  .Body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .Footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $of-light-gray;
    padding: 0.5rem 1rem;
    font-weight: 600;
  }
}
