@import '~styles/base.scss';

.RemoveParty {
  display: flex;

  .Button {
    color: $of-red-02;
    background: transparent;
    border: none;
    cursor: pointer;

    & > :last-child {
      margin-left: 0.5rem;
    }
  }
}
