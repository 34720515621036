@import '~styles/base.scss';

:root {
  // 45% opacity of $of-forest-green-04;
  --disabled-toggle-color: #d1dbde;
  --disabled-checked-toggle-color: #afe7c8;
}

.react-toggle-track {
  height: 18px;
  width: 34px;
  border-radius: 24px;
  background-color: $of-forest-green-04;
  margin: 1px;
  transition: none;
}

.react-toggle-track-check {
  bottom: 0;
  left: 21px;
  z-index: 1;
  transition: none;
  color: $of-green-01;
}

.react-toggle-thumb {
  position: absolute;
  top: calc(50% - 14px / 2);
  left: 4px;
  right: 18px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transition: none;
  border-color: $of-forest-green-04;
}

.react-toggle {
  border: 1px solid transparent;
  border-radius: 24px;
  box-shadow: none;
  opacity: 1;
}

.react-toggle--checked .react-toggle-track {
  background-color: $of-green-01;
}

.react-toggle--checked .react-toggle-thumb {
  left: 19px;
  right: 3px;
  border-color: $of-green-01;
}

.react-toggle.react-toggle--disabled .react-toggle-track {
  background-color: var(--disabled-toggle-color);
}

.react-toggle--checked.react-toggle--disabled .react-toggle-track {
  background-color: var(--disabled-checked-toggle-color);

  svg {
    color: var(--disabled-checked-toggle-color);
  }
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $of-forest-green-03;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $of-green-01;
}

.react-toggle--checked:not(.react-toggle--disabled) .react-toggle-track .react-toggle-track-check {
  color: $of-green-01;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track .react-toggle-track-check {
  color: $of-green-01;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.react-toggle.react-toggle--focus:not(.react-toggle--disabled) {
  border: 1px solid $of-forest-green-04;
}

.react-toggle.react-toggle--checked.react-toggle--focus:not(.react-toggle--disabled) {
  border: 1px solid $of-green-01;
}

.react-toggle--disabled .react-toggle-thumb {
  border-color: var(--disabled-toggle-color);
}

.react-toggle--checked.react-toggle--disabled .react-toggle-thumb {
  border-color: var(--disabled-checked-toggle-color);
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none;
}

.react-toggle:hover:not(.react-toggle--disabled):not(.react-toggle--checked) .react-toggle-thumb {
  border-color: $of-forest-green-03;
}
