@import '~styles/base.scss';

.Option {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    height: 24px;
    width: 24px;
    color: $of-blue-02;
  }
}
