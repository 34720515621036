@import '~styles/base.scss';

.PdfDropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 86px;
  padding: 0 30px;
  color: $of-forest-green-01;
  border: 1px dashed $of-forest-green-04;
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;

  .Heading {
    display: flex;
    align-items: center;
    margin-top: 8px;
    text-decoration: underline;
  }

  span:last-of-type {
    font-size: 10px;
    line-height: 12px;
    margin-left: 12px;
  }

  &:hover {
    border-color: $of-yellow-04;
    background: $of-edit-hover;
  }
}

.Loading {
  background: $of-yellow-02;
}

.UploadingError {
  color: $of-red-02;
  border: 1px dashed $of-red-02;
  background: transparent;
}
