@import '~styles/base.scss';

.AiInsights {
  display: flex;
  padding: 2rem;

  div[class*=Error] {
    height: fit-content;
    margin: 1rem;
  }
}

.FilterSidebarContainer {
  width: 15rem;
  flex-shrink: 0;
  padding-right: 1.5rem;
  border-right: 1px solid $of-light-gray;

  .Title {
    color: $of-forest-green-01;
    font-weight: 500;
    font-size: 20px;
  }
}

.InsightsContainer {
  width: 85rem;
  padding-left: 1.5rem;
}

.LoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.EmptyStateContainer {
  width: 100%;
}
