@import '~styles/base.scss';

.Row {
  display: flex;
  margin: 0.25rem 0;

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }
}
