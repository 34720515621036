@import '~styles/base.scss';

.DisabledInformTextContainer {
  padding: 10px 0;

  .DisabledInformText {
    color: $of-forest-green-01;
    font-size: 13px;
    font-weight: 700;
    margin: 0;
  }
}

.Header {
  font-weight: 500;
  font-size: 1.1rem;
}

.Row {
  width: 100%;
  max-width: 660px;
}
