@import '~styles/base.scss';

.WorkspaceMobile {
  display: flex;
  flex-direction: row;
  align-items: center;

  .WorkspaceName {
    color: $of-forest-green-01;
    margin-left: 0.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.WorkspaceDesktop {
  display: flex;
  align-items: center;
  min-width: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.25rem;
  margin-top: -0.25rem;

  .WorkspaceButton {
    color: $of-forest-green-01;
    min-width: 0;
    opacity: 1;
    padding: 0 !important;

    &:hover {
      text-decoration: underline; //necessary for correct color on underline
    }
  }

  .WorkspaceButton > span,
  .WorkspaceText {
    margin-left: 0.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .WorkspaceIcon {
    min-width: 12px;
    min-height: 12px;
  }
}
