@import '~styles/base.scss';

.OptionLabel {
  line-height: 14px !important;
}

.OptionEmail {
  font-size: 12px;
  color: $of-winter-green-dream;
  line-height: 12px !important;
}

.OptionIcon {
  margin-left: auto;
  padding-left: 12px;
}

.DisabledOption {
  cursor: not-allowed !important;
  background: #fff !important;
  opacity: 0.4;
}
