@import '~styles/base.scss';

:export {
  mobilemax: $contract-view-mobile-max;
  desktopmin: $contract-view-desktop-min;
}

.App {
  display: flex;
  flex-direction: column;
  min-width: 894px;
  min-height: 100vh;
  max-width: 100vw;

  &.MobileMenuOpen {
    min-width: unset;
  }

  .Body {
    display: flex;
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    @include respondTo(md, max) {
      flex-direction: column;
    }
  }

  .Content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: calc(1058px + 22px);
    background-color: $of-dark-green-05;
    background-image: url('./gradient.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-blend-mode: overlay;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
      background-image: none;
    }

    @include respondTo(md, max) {
      margin-top: 72px;
    }

    &.MobileMenuOpen {
      display: none;
    }
  }

  .InnerContent {
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }
}

.BottomCenter {
  bottom: 1rem;
  position: fixed;
}
