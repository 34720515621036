@import '~styles/base.scss';

.Trigger {
  position: relative;
  color: $of-forest-green-01;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: unset;
  background-color: transparent;
  transition: background-color ease-in-out 100ms;
  justify-content: center;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  &:focus-visible {
    outline: none;
  }
}

.Active {
  background-color: $of-forest-green-05;
}

.MenuItem {
  display: flex;
  gap: 8px;
}
