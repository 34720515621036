@import '~styles/base.scss';

.SearchContainer {
  > div {
    max-width: unset !important;
    margin-top: 0.85rem;
  }
}

.SearchResults {
  border: 1px solid $of-dark-green-04;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100%;
  position: absolute;
  background-color: white;
  max-height: 13rem;
  overflow-y: auto;
  z-index: 9999;
  margin-top: -16px;

  .LoadingSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 125px;
  }
}

.SearchError {
  padding: 0 16px;
}

.InputContainer {
  position: relative;
}
