@import '~styles/base.scss';

.TextEditor {
  font-family: var(--formatting-font-family);
  color: var(--formatting-font-color);
  font-size: var(--formatting-font-size);
  margin-top: 10px;
  margin-bottom: 0;

  span[data-slate-placeholder=true] {
    font-family: var(--document-static-font-family) !important;
    color: black !important;
    font-size: var(--formatting-font-size) !important;
  }
}

.Small {
  width: 60%;

  @include orientation(portrait) {
    @include respondTo(md, max) {
      width: 100%;
    }
  }
}

.FullWidth {
  padding: 0 4px;
}
