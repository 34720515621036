@import '~styles/base.scss';

.ErrorContainer {
  position: relative;
  margin: 0 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 145px;
  border: 1px dashed $of-forest-green-04;
  border-radius: 4px;
  text-align: center;
  color: $of-red-02;
  font-family: var(--contract-static-font-family);
  padding: 20px 0;

  p {
    font-size: 12px;
    margin: 0;
  }

  .Header {
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: $of-red-02;
  }

  .Contact {
    color: $of-dark-green-01;
  }

  .BoxMenu {
    opacity: 0;
    top: 0;
    right: -20px;
    position: absolute;
    transition: opacity 0.5s ease-in-out;

    @media only screen and (max-width: $contract-view-mobile-max) {
      right: calc(12rem - 147px);
      opacity: 1;
      position: absolute;
    }

    &:focus-visible {
      opacity: 1;
    }

    &:focus-within {
      opacity: 1;
    }
  }

  &:hover .BoxMenu {
    opacity: 1;
  }
}
