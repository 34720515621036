@import '~styles/base.scss';

.FileName,
.FileNameExtensionSuffix {
  overflow: hidden;
  white-space: nowrap;
}

.FileName {
  text-overflow: ellipsis;
  flex: 1;
}

.FileNameSplitWithExtension {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  display: flex;
}
