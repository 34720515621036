@import '~styles/base.scss';

.BankIDInformation {
  // TODO: remove next line when migrated from backbone to react
  font-family: 'Work Sans', sans-serif;
  border-radius: 4px;
}

.BankIDInstructionsOtherDevice {
  margin-top: 42px;
  display: flex;
  background-color: $of-edit-hover;
  padding: 28px 20px;
  gap: 28px;
  border-radius: 4px;
}

.BankIDInstructionsPendingSignature {
  margin-top: 21px;
  background-color: $of-inactive;
  color: $of-forest-green-01;
  padding: 16px;
  border-radius: 4px;

  p {
    line-height: 25px;
    margin: 0;
  }
}

.InstructionsList {
  list-style: revert;
  padding-left: 1rem;

  li {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 21px;

    >span {
      margin-right: 6px;
    }
  }
}

.BankIDPendingSignature {
  display: flex;
  justify-content: center;
  margin: 42px 0 21px;
  padding: 4px;
  gap: 9px;
  align-items: center;

  p {
    margin: 0;
  }

  svg {
    flex-shrink: 0;
  }
}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
  }
}

.Instructions {
  color: #000;
}

.InstructionsTitle {
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 10px;
}

.QRCodeIcon {
  margin-left: 6px;
  color: $of-forest-green-01;
  flex-shrink: 0;
}

.BankIDDeviceToggle {
  color: $of-blue-02;
  font-weight: 400;
  margin-top: 15px;
  padding-left: 0;
}

.PhoneOuterFrame {
  width: 43px;
  height: 93px;
  border-radius: 4px;
  padding: 5px 2px;
  background-color: #000;
  pointer-events: none;
  user-select: none;
  flex-shrink: 0;
}

.PhoneScreenshot {
  width: 43px;
  height: 93px;
}

.PendingSignatureManualStart {
  display: flex;
  justify-content: center;

  button {
    min-width: 168px;
    margin-top: -15px;
  }
}
