@import '~styles/base.scss';

p {
  margin: 0;
  margin-bottom: 8px;
}

.CreateWorkspace {
  max-height: 390px;
  padding: 26px 66px 0;

  .Title {
    font-size: 16px;
    font-weight: 600;
  }

  .TextContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 6px;

    p:first-of-type {
      width: 90%;
      padding-bottom: 0.5rem;
    }
  }
}

.Header {
  font-size: 24px;
}

.CreateWorkspaceModal {
  & > div > div {
    min-height: 230px;
  }

  & > div > div > div:nth-child(2) {
    min-height: 230px;
  }
}
