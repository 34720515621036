@import '~styles/base.scss';

.DisabledState {
  margin: 8px 20px;
  padding: 14px;
  background: $of-edit-hover;
  border-radius: 8px;
  color: $of-forest-green-01;
  font-size: var(--contract-static-font-size);
}
