.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Img {
  margin-bottom: 10px;
}

.Buttons {
  display: flex;

  & > * {
    margin-left: 0.3rem;
  }
}
