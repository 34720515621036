@import '~styles/base.scss';

.ExtensionSettings {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 $spacing-page $spacing-page;
  flex: 1;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .Title {
    align-items: center;
    color: $of-forest-green-01;
    display: flex;
    font-size: 30px;
    font-weight: 600;
    height: 48px;
    padding-top: 15px;
  }

  .MainPage {
    margin-top: 30px;
    display: flex;
  }

  .Container {
    background-color: white;
    box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
    flex: 1;
    min-width: 0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    .ExtensionContentContainer {
      flex-grow: 1;
      min-height: 600px;

      .ExtensionTop {
        padding: 24px 24px 0 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ExtensionLogo {
          margin-right: 16px;
          align-self: flex-start;

          .ExtensionLogoSquare {
            width: 75px;
            height: 75px;
          }
        }

        .ExtensionInfo {
          flex-grow: 1;

          .ExtensionTitle {
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 5px;
            margin-top: 0;
          }

          .ExtensionSubTitle {
            font-size: 12px;
          }
        }
      }

      .ExtensionContent {
        padding: 24px 32px 0 32px; /* 0 padding bottom to merge with config */
        line-height: 1.3rem;
      }

      .ExtensionConfiguration {
        display: flex;
        flex-direction: column;
        padding: 0 32px 24px 32px; /* 0 padding top to merge with content */

        .EnableButton {
          display: inline;
          padding: 0;
        }
      }
    }

    .ExtensionSidebarContainer {
      width: 290px;
      min-width: 322px; /* width + padding */
      padding: 34px 32px 0 0;

      .ExtensionSidebarFloatingContent {
        position: sticky;
        position: -webkit-sticky; /* For Safari */
        top: 32px; /* Align to the side padding */
        align-self: flex-start;
        box-shadow: rgba(47, 47, 47, 0.08) 0 0 1px 0, rgba(47, 47, 47, 0.12) 0 0.5px 2px 0;
        border-radius: 8px;
        border: 1px solid $of-light-gray;
        padding: 20px;
      }
    }
  }
}
