@import '~styles/base.scss';

.EditInternalApproverContainer {
  font-size: 0.875rem;
  color: $of-forest-green-01;
  font-weight: 600;

  .ToggleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Text {
    display: flex;
    align-items: center;
  }

  .Tooltip {
    margin-left: 0;
  }
}

.Disabled {
  cursor: not-allowed;
  color: $of-forest-green-04 !important;
}

.Divider {
  border-bottom-color: $of-dark-green-05;
  margin: 24px 0;
}
