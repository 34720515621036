.FakePreview {
  display: block;
  position: relative;
  width: auto;
  height: auto;
  margin: 0 auto;
  cursor: pointer;
  padding-top: 12px;
  border: unset;
  background-color: unset;

  img {
    aspect-ratio: 296/176;
    object-fit: contain;
    max-width: 100%;
  }

  &.ThumbNailPlaceholder {
    background-color: black;
    width: 100%;
    aspect-ratio: 300/169;
  }
}

.PlayButton {
  display: block;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -30px;
  background-color: #0f1622e6;
  border-radius: 3px;
  content: '';
  height: 60px;
  position: absolute;
  width: 80px;

  &::after {
    border-right: none;
    border-left: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
    margin-left: -10px;
    margin-top: -10px;
    border-left-width: 20px;
    content: '';
    position: absolute;
  }
}
