@import '~styles/base.scss';

.Container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 13px 32px;
  background: $of-green-03;
  color: $of-green-15;
  border-radius: 8px;
  font-weight: 500;

  &.Collapsed {
    padding: 13px 16px;
  }
}
