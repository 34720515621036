@import '~styles/base.scss';

.ToggleSwitch {
  position: relative;
  display: flex;
  align-items: center;
  width: 168px;
  height: 48px;
  border: 1px solid $of-forest-green-01;
  border-radius: 40px;
  outline: none;

  &.Disabled {
    border: 1px solid $of-inactive;
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  .Label {
    width: 50%;
    color: $of-forest-green-01;
    text-align: center;
    z-index: 1;
    transition: ease-in-out 100ms;

    &.Disabled {
      color: $of-inactive;
    }
  }

  .Slider {
    position: absolute;
    height: 90%;
    width: 48%;
    left: 2%;
    background: $of-forest-green-01;
    border-radius: 40px;
    transition: ease-in-out 100ms;

    &.Disabled {
      background: $of-inactive;
    }
  }

  .Toggled {
    color: white;
    left: 50%;
  }
}
