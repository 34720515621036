@import '~styles/base.scss';

.CheckboxContainer {
  display: grid;
  grid-template-columns: 1fr;
}

.HiddenCheckbox {
  width: 16px;
  appearance: none;
  border-radius: 4px;
  grid-row-start: 1;
  grid-column-start: 1;
}

.Label {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.3;
  grid-row-start: 1;
  grid-column-start: 1;

  &.ReversedDirection {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &.DisabledCursor {
    cursor: default;

    span > span {
      text-decoration: none !important;
    }
  }

  .Checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 16px;
    width: 16px;
    min-width: 16px;
    border: 1px solid $of-forest-green-04;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover {
      border-color: $of-winter-green-dream;
    }

    &:active {
      border-color: $of-forest-green-01;
    }

    &.Invalid {
      &,
      &:hover,
      &:active {
        border-color: $of-red-02;
        box-shadow: 0 0 0 1px $of-red-02;
      }
    }
  }

  .Checked {
    border-color: $of-forest-green-01;
    background-color: $of-forest-green-01;

    &:hover {
      border-color: $of-forest-green-01;
    }

    &.Disabled {
      background: $of-inactive;
      border-color: $of-forest-green-05;

      & > * {
        border: none;
        color: $of-forest-green-04;
      }
    }
  }

  .Disabled:not(.Checked) {
    background: $of-inactive;
    border-color: $of-forest-green-05;

    :hover {
      border-color: $of-forest-green-05;
    }
  }

  .TextContainer {
    display: flex;
    max-width: 13rem;
    align-items: center;
  }

  .Icon {
    flex-shrink: 0;
    color: white;
    stroke-width: 2;
  }

  &.CheckBoxContainerWithWrappedText {
    align-items: flex-start;
    line-height: 15px;
  }

  .LabelText {
    margin-left: 0.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.CheckBoxLabelWrapText {
      overflow: visible;
      white-space: normal;
      text-overflow: unset;
    }

    &.TextOverflow {
      font-size: 1em;
      color: $of-forest-green-01;
    }

    &.NoMargin {
      margin-left: 0;
    }
  }

  &:hover {
    .Checkbox:not(.Disabled):not(.Checked):not([aria-required="true"]) {
      border-color: $of-winter-green-dream;
    }
  }
}

.TooltipText {
  width: auto;
  text-align: center;
  margin: 5px;
}
