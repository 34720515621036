@import '~styles/base.scss';

.InputIcon {
  margin: 14px 1px 13px 12px;
}

.CustomOption {
  display: flex;
  color: $of-forest-green-01;
  height: 40px;
  align-items: center;
  padding: 8px 12px;
  font-weight: normal;

  & > * {
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
    background: $of-dark-green-05;
  }
}
