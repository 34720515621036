@import '~styles/base.scss';

.ReplaceVideoDropzone {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: none;

  &.Disabled:hover {
    cursor: not-allowed;
    background-color: transparent;
  }

  &:hover {
    background-color: $of-dark-green-06;
  }
}
