@import '~styles/base.scss';

.ReactSelect {
  .ReactSelect__indicators {
    padding-right: 11px;
    color: $of-dark-green-02;
    cursor: pointer;
  }

  .ReactSelect__menu {
    margin-top: -1px;
    box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
    border: 1px solid $of-forest-green-01;
  }

  .ReactSelect__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 240px !important;
  }

  .ReactSelect__menu-list:nth-child(1) {
    padding-top: 0.5rem;
  }

  .ReactSelect__option {
    display: flex;
    color: $of-forest-green-01;
    height: 40px;
    align-items: center;
    padding: 8px 12px;
    font-weight: normal;

    .ReactSelect__option-selected {
      margin-left: auto;
      padding-left: 12px;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      background-color: $of-dark-green-05;
    }

    &:active {
      background-color: $of-dark-green-04;
    }
  }

  .SelectedOptionBackground {
    background-color: $of-dark-green-05;
  }

  .FocusedOptionBackground {
    background-color: $of-dark-green-05;
  }

  .DisabledOption {
    cursor: not-allowed;
    background-color: #fff;
    color: $of-forest-green-04;

    &:hover {
      background-color: #fff;
    }
  }

  .ReactSelect__group {
    .ReactSelect__group-heading {
      text-transform: none;
      color: $of-forest-green-03;
      font-size: 100%;
    }

    .ReactSelect__option {
      padding: 8px 12px 8px 24px;
      font-weight: 400;
    }
  }

  .ReactSelect__input {
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .ReactSelect__menu-portal {
    z-index: 2;
  }

  .ReactSelect__control {
    min-height: 42px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    cursor: pointer;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;

    .ReactSelect__single-value {
      color: $of-forest-green-01;
    }

    .ReactSelect__placeholder {
      color: $of-winter-green-dream;
    }

    &:focus-within {
      border-color: $of-forest-green-01;

      .ReactSelect__single-value,
      .ReactSelect__indicators {
        color: $of-forest-green-01;
      }

      .ReactSelect__placeholder {
        color: $of-winter-green-dream;
      }
    }

    &:hover {
      border-color: $of-dark-green-02;

      .ReactSelect__placeholder,
      .ReactSelect__indicators {
        color: $of-dark-green-02;
      }
    }

    &:hover ~ .ReactSelect__menu-portal {
      .ReactSelect__menu {
        border-color: $of-dark-green-02;
      }

      .ReactSelect__option {
        color: $of-dark-green-02;
      }
    }
  }

  .ReactSelect__value-container {
    padding: 0 8px;
  }

  .ReactSelect__clearIndicator {
    color: $of-forest-green-01;

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      cursor: pointer;
      color: $of-dark-green-02;
    }
  }

  .ReactSelect__value-container--is-multi {
    max-height: 68px;
    overflow-y: scroll;
    scrollbar-color: $of-forest-green-05 #fff;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
      height: 4px;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: $of-forest-green-05;
      box-shadow: 0 0 1px rgba(white, 0.5);
    }
  }

  .ReactSelect__multi-value__remove {
    color: $of-dark-green-02;
    margin-right: 4px;
    background-color: transparent !important;

    &:focus,
    &:focus-visible,
    &:focus-within {
      cursor: pointer !important;
      color: $of-forest-green-01 !important;
      background-color: transparent;
    }

    /* Safari doesn't allow grouping with the above */
    &:hover {
      cursor: pointer !important;
      color: $of-forest-green-01 !important;
      background-color: transparent;
    }
  }

  .ReactSelect__multi-value {
    border-radius: 20px;
    background-color: $of-dark-green-05;
    color: $of-dark-green-02;
    font-size: 12px;
    padding: 2px 0 2px 14px;
    line-height: 14px;
  }

  .ReactSelect__multi-value__label {
    color: $of-dark-green-02;
    font-size: 12px;
  }

  .ReactSelect__noOptionsMessage {
    color: $of-dark-green-03;
    padding: 8px 10px;
  }

  // styling specific for recipients-category-picker
  &#select-recipientsCategory {
    .ReactSelect__control {
      height: 50px !important;
    }

    .ReactSelect__option {
      padding: 14px 36px !important;
    }
  }
}
