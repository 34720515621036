@import '~styles/base.scss';

.Container {
  display: flex;
  justify-content: space-between;
  height: 68px;
  align-items: center;
  padding: 0 48px;
  width: -webkit-fill-available;
}

.Banner {
  display: flex;
  gap: 4px;
  height: 36px;
  border-radius: 8px;
  align-items: center;
  padding: 0 10px;
  color: $of-forest-green-01;
}

.BannerText {
  font-size: 0.875rem;
  color: $of-forest-green-01;
}

.RightSideContainer {
  display: flex;
  gap: 100px;
  align-items: center;
}

.PreviewControlsContainer {
  display: flex;
  gap: 24px;
  align-items: center;
}

.PreviewIconsContainer {
  display: flex;
  gap: 8px;
}

.IconsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.IconContainer {
  height: 32px;
  width: 32px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 100ms;
  cursor: pointer;

  svg {
    color: $of-winter-green-dream;
  }

  &:hover {
    svg {
      color: $of-forest-green-01;
    }
  }

  &.Active {
    background-color: white;

    svg {
      color: $of-forest-green-01;
    }
  }
}
