@import '~styles/base.scss';

.BetaLabel {
  border-radius: 8px;
  padding: 6px;
  font-size: 10px;
  font-weight: 500;
  line-height: 8px;
  color: $of-forest-green-01;
  background-color: $of-red-10;
}
