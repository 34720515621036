@import '~styles/base.scss';

.ReadOnlyNoFile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  color: $of-legacy-light;
  font-size: 14px;
  line-height: 1.55;
  text-align: center;
  border: 1px solid $of-legacy-light;
  padding: 20px 0;

  .ReadOnlyNoFileIcon {
    margin-bottom: 20px;
  }
}

.EmptyStateContainer {
  padding-top: 0;
  padding-bottom: 15px;

  .EmptyStateIcon {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $of-dark-green-05;
    border-radius: 100%;
  }
}

.FailedAssetWrapper {
  border: 1px solid $of-red-02;
  color: $of-red-02;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.FailedIcon {
  width: 48px;
  height: 48px;
  color: $of-red-02;
  margin-top: 18px;
}

.FailedMessage {
  font-size: 18px;
  font-weight: 500;
  margin-top: 9px;
}

.AssetNameWrapper {
  margin-top: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  gap: 3px;

  svg {
    color: $of-forest-green-01;
  }
}

.HelperText {
  color: black;
}
