@import '~styles/base.scss';

.FilesUploadStatusBoxItem {
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;

  .IconWrapper {
    display: flex;
    align-items: center;
    height: 18px;
    margin-right: 10px;

    .Icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: white;

      &.Loading {
        color: var(--ausb-loading-icon-color);
        width: 15px;
        height: 15px;

        svg {
          margin-left: -3px;
        }
      }

      &.Error {
        background: $of-red-02;
      }

      &.Success {
        background: $of-green-01;
      }
    }
  }

  .FileInfo {
    overflow: hidden;

    .FileNameWrapper {
      display: flex;
      font-style: normal;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $of-forest-green-01;
    }

    .ErrorMessageWrapper {
      font-weight: 400;
      color: $of-red-02;

      .ErrorMessage {
        line-height: 16px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
