@import '~styles/base.scss';

.Action {
  background: transparent;
  width: 230px;
  max-height: 150px;
  display: flex;
  gap: 8px;
  padding: 24px;
  border-radius: 4px;
  border: 0;
  outline: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color solid 2px;
    outline-offset: 2px;
  }
}

.Icon {
  color: $of-forest-green-01;
}

.Text {
  color: $of-forest-green-01;
  text-align: left;

  h2 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 1.125rem;
  }
}

.Body {
  color: $of-forest-green-03;
}
