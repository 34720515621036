@import '~styles/base.scss';

.BodyContainer {
  display: flex;
  flex-direction: column;
  border: 0.5px solid $of-forest-green-04;
  border-radius: 12px;
  padding: 15px !important;
}

.Bold {
  content: "\a";
  white-space: pre;
  font-weight: 600;
}

.ExpiryDate {
  display: flex;
  align-items: center;
}

.PopoverContent {
  padding: 0;
  padding-bottom: 8px;
  z-index: 1004;

  .DatePicker {
    > div {
      box-shadow: none;
    }

    .ClearButton {
      margin: auto;
    }
  }
}

.PopoverTriggerButton {
  padding: 0;
  line-height: normal;
  margin-left: 4px;

  span {
    font-weight: 600;
  }
}
