@import './popup-forms.module.scss';

.EditingCheckboxesWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: $of-forest-green-01;
  font-size: var(--document-static-font-size);
  line-height: 13px;
  word-wrap: break-word;
  padding: 15px 15px 0 10px;

  .ColleagueCheckboxLabel {
    display: inline-flex;
  }

  label[class*="Label"] {
    .CheckBoxLabelText {
      font-size: var(--document-static-font-size);
      font-weight: 600;
      color: $of-forest-green-01;
    }
  }

  label[class*='DisabledCursor'] {
    color: $of-forest-green-04;

    .ColleagueCheckbox {
      cursor: not-allowed;
    }

    .ColleagueCheckboxLabel {
      margin-left: 0;

      span {
        cursor: not-allowed;
        padding-left: 0.25rem;
      }
    }
  }
}

.ActionButtonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.DecimalSelectorContainer {
  border-top: 1px solid $of-dark-green-04;
}
