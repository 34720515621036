@import '~styles/base.scss';

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Terminate {
  color: $of-red-02;
  margin-bottom: 33px;
  margin-top: 41px;
}

.Delete {
  margin-bottom: 33px;
  margin-top: 41px;
}

.RemoveButton {
  background-color: $of-red-02;
  color: white;
}
