@import '~styles/base.scss';

.Workspace {
  line-height: 2;
  word-break: break-all;
  word-break: break-word;

  .Description {
    font-size: 0.8rem;
    color: $of-reign;
  }
}

.Counts {
  line-height: 2;
  display: flex;
  flex-direction: column;
}

.Column {
  white-space: nowrap;
}

.Link {
  display: inline-flex;

  .DisabledIcon {
    @extend .Icon;

    color: $of-reign;
  }

  .EditIcon {
    display: none;
  }

  .DisabledEditIcon {
    @extend .EditIcon;

    color: $of-reign;
  }

  &:hover {
    .Icon {
      display: none;
    }

    .EditIcon {
      display: block;
    }
  }
}

.Tooltip {
  display: inline-flex !important;

  .Branding {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;

    .BrandingLogo {
      max-height: 75px;
      max-width: 100px;
    }
  }
}

.TooltipHeader {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.TooltipList {
  padding-left: 18px;

  & > li {
    word-wrap: break-word;
  }
}

.AIReviewBadge {
  color: $of-forest-green-01;
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border-style: none;
  font-size: 14px;
  min-width: 40px;
  width: 40px;
  white-space: nowrap;

  svg {
    height: 20px !important;
    width: 40px !important;
  }
}
