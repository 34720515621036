@import '~styles/base';

.WarningIcon {
  flex-shrink: 0;
  margin-top: 4px;
}

.WarningMessage {
  background-color: $of-edit-hover;
  padding: 12px;
  border-radius: 8px;
  gap: 6px;
  display: flex;
  margin-bottom: 1rem;
}

.WorkspaceCurrencyWarning {
  display: flex;
  flex-direction: column;
  max-height: 200px;
}

.GridContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.GridHeader {
  display: grid;
  grid-template-columns: 1fr 130px 30px 130px;
  gap: 8px;
  padding: 8px;
  background-color: white;
  position: sticky;
  top: 0;
  border-bottom: 1px solid $of-dark-green-05;
  font-weight: 500;
}

.GridBody {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $of-dark-green-05;
}

.GridRow {
  display: grid;
  grid-template-columns: 1fr 130px 30px 130px;
  gap: 8px;
  padding: 8px;
  transition: background-color 0.2s ease;
}

.LineThrough {
  text-decoration: line-through;
}

.DocumentIdColumnHeader {
  text-align: left;
}

.CurrencyColumnHeader {
  text-align: center;
}

.TextCenter {
  text-align: center;
}

.ArrowColumnHeader {
  text-align: center;
}
