@import '~styles/base.scss';

.ModalReset {
  min-height: unset;
}

.InvitationContainer {
  min-height: unset !important;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  gap: 12px;
}

.SingleParticipantLabel {
  margin: 0;
  margin-top: 18px;
  text-align: center;
  font-size: 1.5rem;
}

.ContinueInformation {
  text-align: center;

  p {
    margin: 0;
  }

  b {
    font-weight: 600;
  }
}

.Actions {
  display: flex;
  gap: 12px;

  &.CollapsedView {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 410px) {
      max-width: 285px;
    }
  }
}

.OverrideButton {
  padding: 14px;
  border-radius: 4px !important;
  max-height: 44px;
}

.MoveToPending {
  text-align: center;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: center;
}

.PendingStatePill {
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 4px 9px;
  max-width: fit-content;

  >span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  background-color: $of-blue-07;
  color: $of-blue-01;
}

.CollapsedSingleParticipantActions {
  width: 100% !important;
}

.ErrorMargin {
  margin-top: 44px;
}
