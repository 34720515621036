@import '~styles/base.scss';

.ColleagueSelectorContainer {
  display: flex;
  flex-direction: column;

  @include flex(1);

  & > :first-child {
    font-size: 0.8rem;
  }

  :global(.ReactSelect__value-container--is-multi) {
    overflow-y: auto;
  }
}

.InactiveColleague {
  text-decoration: line-through;
}

.MatchAllWrapper {
  margin-top: 13px;
  margin-bottom: 13px;
}
