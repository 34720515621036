@import '~styles/base.scss';

.ActionsMenu {
  color: $of-winter-green-dream;
  transition: color 0.2s;

  &:hover,
  &[aria-expanded=true] {
    background-color: transparent;
    color: $of-forest-green-01;
  }

  &:hover {
    box-shadow: none;
  }
}

.Hide {
  visibility: hidden;
}

.Show {
  visibility: visible;
}
