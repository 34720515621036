@import '~styles/base.scss';

.NameIconContainer {
  height: 18px;
  display: flex;
  // in order for the three dots to show if the name is too long
  // otherwise the name will be cut off because .OptionName overflows the .NameIconContainer
  min-width: 0;
}

.OptionName {
  font-size: 0.875rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
}

.SelectFieldTrigger {
  cursor: pointer;
  background-color: white;
  color: $of-forest-green-01;
  border: none;
  padding: 0 8px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  max-width: 30ch;
  font-size: 0.875rem;
  text-align: start;
  border-radius: 6px;
  height: 34px;
  overflow: hidden;

  &:hover,
  &[data-state='open'] {
    // same as the hover color of the annotation toolbar button
    background-color: rgba($of-forest-green-01, 0.08);
  }

  &:disabled {
    cursor: default;
  }

  span {
    display: flex;
    flex-grow: 1;
    min-width: 0;
  }
}

.BrokenFieldTriggerLabel {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: $of-red-02;
}

.SelectContent {
  background-color: white;
  border-radius: 8px;
  box-shadow: 8px 6px 20px 0 rgba(0, 0, 0, 0.1490196078);
  border: 1px solid rgba(0, 0, 0, 0.05);
  max-width: 30ch;
  text-align: start;
  min-width: var(--radix-select-trigger-width);
  transform-origin: var(--radix-select-content-transform-origin);
}

.SelectItemIndicatorIcon {
  display: block;
  align-self: center;
  // Parent is flex container
  flex-shrink: 0;
}

.SelectItem {
  cursor: pointer;
  border-radius: 6px;
  height: 34px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  color: $of-forest-green-01;
  max-width: 100%;

  &:hover {
    background-color: rgba($of-forest-green-01, 0.08);
  }

  &[data-state="checked"] {
    background-color: rgba($of-forest-green-01, 0.08);
  }

  &:has ~ &[data-state="checked"] {
    background-color: transparent;
  }
}

// When hovering over another item, the selected item should not have a background color
.SelectContent:has(.SelectItem:hover) {
  .SelectItem[aria-selected="true"]:not(:hover),
  .SelectItem[data-state="checked"]:not(:hover) {
    background-color: transparent;
  }
}

.SelectViewPort {
  padding: 4px;
  max-height: 176px;
  max-height: 242px;
  overflow: scroll;
}

.TooltipMessage {
  text-align: start;
  overflow: auto;
  white-space: normal;
  word-break: break-word;
  font-size: 0.75rem;
}

.ParticipantIcon {
  stroke-width: 1.3;
  color: $of-forest-green-01;
}

.ParticipantText {
  font-weight: 500;
  color: $of-forest-green-01;
}

.SelfLabelContainer {
  display: flex;
  align-items: stretch;
}
