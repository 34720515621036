@import '~styles/base.scss';

.ActionBar {
  flex: 1;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: flex-start;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  background-color: $of-inactive;
  padding: 22px 32px;

  &.Collapsed {
    display: flex;
    align-items: center;
  }
}
