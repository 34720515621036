@import '~styles/base.scss';

.DocumentInformation {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  gap: 4px;
  align-self: center;
  min-width: 0;

  .NameTagContainer,
  .DocumentInformationContainer {
    display: flex;
  }

  .DocumentInformationContainer {
    display: flex;
    gap: 16px;
    padding: 0 12px;
    align-items: center;

    [class*=WorkspaceDesktop] {
      padding: unset;
      font-size: 0.75rem;
      margin-top: 0;

      svg {
        width: 10px;
        color: $of-winter-green-dream;
      }
    }
  }

  .ContractValue {
    font-size: 0.75rem;
  }

  .NameTagContainer {
    gap: 8px;
    height: 22px;
    align-items: center;

    [class*=Tags] {
      margin: 0;
      gap: 8px;
      flex-wrap: nowrap;
    }
  }

  @media screen and (max-width: 840px) {
    .DocumentInformationContainer {
      margin-bottom: 3px;
    }
  }
}
