@import '~styles/base.scss';

.ActionsSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  .ClearSectionButton {
    color: #225464;
  }

  .RightSideActions {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .CancelButton {
    margin-right: 15px;
  }
}

.WorkspaceSection {
  margin-bottom: 30px;

  .WorkspaceName {
    display: flex;
    flex-direction: row;
    align-items: center;

    .WorkspaceIcon {
      margin-right: 5px;
    }
  }
}

.WithClearSection {
  justify-content: space-between;
}

.FolderTreeBranchContainer {
  max-height: 45vh;
}
