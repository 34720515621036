@import '~styles/base.scss';

.ReplyForm {
  transition: all 0.3s ease;

  form {
    padding: 0;
  }

  .ReplyButton {
    padding-left: 0;
    color: $of-blue-02;
    font-weight: 600;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &Expanded {
    max-height: 200px;
    min-height: 80px;
  }

  &Collapsed {
    max-height: 20px;
    min-height: 20px;
    margin: 0 0 8px 0;
  }
}

.HiddenReply {
  display: none;
}
