@import '~styles/base.scss';

.TriggerContainer {
  padding: 5px 24px;
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
  flex-grow: 1;

  .TriggerIcons {
    display: flex;
    gap: 8px;
  }

  &:has(.DocumentCallToActions:empty) {
    .TriggerIcons {
      width: 100%;
      place-content: space-between;
    }
  }

  [class*=DelegateSigningRightsButton] { // Don't have a place in the design for delegate yet
    display: none;
  }
}

@media (orientation: landscape) {
  .TriggerContainer {
    padding-left: max(env(safe-area-inset-left), 24px);
    padding-right: max(env(safe-area-inset-right), 24px);
  }
}

.DownloadPDF {
  padding: 0;
}

.Trigger,
.DownloadPDF {
  border: unset;
  background-color: transparent;
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  color: $of-forest-green-01;
  display: flex;
  border-radius: 8px;
  padding: 0;
  transition: background-color ease-in-out 100ms;
  -webkit-tap-highlight-color: transparent;

  &[data-state="active"] {
    background-color: $of-forest-green-05;
  }
}

.Trigger:disabled {
  color: $of-forest-green-04;
  cursor: not-allowed;
}

.DocumentCallToActions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.DocumentCallToActions:empty {
  display: none;
}

.MessageTabContainer {
  position: relative;
}

.MenuMessageTabContainer {
  position: relative;

  span {
    min-width: 3px;
    top: -1px;
    right: -1px;
    padding: 0 3px;
    line-height: 10px;
  }
}
