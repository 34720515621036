@import '~styles/base.scss';

.BodyParagraph {
  line-height: 1.4;
}

.BodyParagraphRedTop {
  line-height: 1.4;
  color: $of-red-02;
}

.BodyParagraphRedBottom {
  line-height: 1.4;
  color: $of-red-02;
}
