@import 'styles/base.scss';

.CloseSidebarButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $of-forest-green-01;
  border-radius: 4px;

  &:focus-visible {
    outline: -webkit-focus-ring-color solid 2px;
    outline-offset: 2px;
  }
}
