@import '~styles/base.scss';

.IdleDragOverlayField {
  display: flex;
  align-items: stretch;
  border: 1px solid $of-blue-02;
  border-radius: 6px;
  min-width: 320px;
  min-height: 38px;
  height: 100%;
  background-color: white;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
  position: relative;

  .DragHandler {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $of-blue-02;
    color: $of-blue-05;
    cursor: grabbing;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 18px;

    & > svg {
      margin-top: 10px;
    }
  }
}

.IsOverNonPdfSections {
  .DragHandler {
    cursor: no-drop;
  }
}

.DataField {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 14px;
  font-size: 0.875rem;
  color: $of-forest-green-01;
  padding: 8px;
}

.DataFieldName {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  margin-right: 8px;
}
