@import '~styles/base.scss';

.Button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  white-space: nowrap;
  user-select: none;
  outline: none;
  border: 1px solid transparent;
  padding: 0.35rem 0.75rem;
  line-height: 1.5rem;
  border-radius: 4px;
  background-color: transparent;
  color: $of-forest-green-01;
  background-image: none;
  font-size: 14px;
  letter-spacing: -0.02em;

  &:focus-visible {
    outline: -webkit-focus-ring-color solid 2px;
    outline-offset: 2px;
  }

  &[hidden] {
    display: none;
  }

  // remove and set as default after old buttons are all gone
  &.NewButtonDefaults {
    padding: 13px 12px !important;
    line-height: 16px !important;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .icon + .label {
    margin-left: 0.5rem;
  }

  &.iconRight {
    flex-direction: row-reverse;

    .icon + .label {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }

  &.outline {
    border-color: $of-dark-green-04;

    &:hover,
    &:focus {
      border-color: $of-forest-green-01;
    }

    &.danger {
      &:hover,
      &:focus {
        border-color: $of-passion;
        background-color: $of-passion;
        color: white;
      }
    }
  }

  &.secondary {
    border-color: $of-forest-green-01;
    color: $of-forest-green-01;

    &:hover {
      background-color: lighten($of-forest-green-05, 10%);
    }
  }

  &.link {
    border: none;
    color: $of-blue-02;
    text-decoration: underline; // move to link after all old buttons are gone
    font-size: inherit;
    font-weight: inherit;

    &:hover,
    &:focus {
      color: $of-blue-01;
    }

    &.peace {
      color: $of-peace;
    }

    &.underline {
      text-decoration: underline;
      font-size: 15px;
      color: $of-forest-green-01;

      &:hover,
      &:focus {
        color: $of-forest-green-03;
      }
    }

    &.forestGreen {
      color: $of-forest-green-01;

      &:hover,
      &:focus {
        color: $of-forest-green-03;
      }
    }
  }

  &.linkSeparate {
    border: none;
    color: $of-forest-green-01;
    text-decoration: none;
    font-size: inherit;
    font-weight: inherit;

    &:hover,
    &:focus {
      color: $of-forest-green-03;
      text-decoration: underline;
    }
  }

  &.linkInline {
    border: none;
    color: $of-blue-02;
    text-decoration: none;
    font-size: inherit;
    font-weight: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &:not(.link) {
    &.peace {
      background-color: $of-peace;
      color: white;

      &:hover,
      &:focus {
        background-color: darken($of-peace, 10%);
      }

      &.disabled {
        pointer-events: none;
        background-color: $of-disable;
        color: $of-dark-green-03;
        border: solid 1px $of-dark-green-04;
      }
    }

    &.red {
      background-color: $of-red-02;
      color: white;

      &:hover,
      &:focus {
        background-color: $of-red-01;
      }

      &.outline {
        color: white;
        border-color: white;
      }

      &.disabled {
        pointer-events: none;
        background-color: $of-inactive;
        color: $of-dark-green-03;
      }
    }

    &.thunder {
      background-color: $of-thunder;
      color: white;

      &:hover,
      &:focus {
        background-color: lighten($of-thunder, 10%);
      }

      &.outline {
        color: white;
        border-color: white;
      }

      &.disabled {
        color: $of-thunder;
        background-color: lighten($of-thunder, 65);
        border-color: lighten($of-thunder, 40);
      }
    }

    &.yellow {
      color: $of-forest-green-01;
      border-radius: 5px;
      background-color: $of-yellow;
      box-shadow: 0 0 6px $of-plum-05;

      &:hover,
      &:focus {
        background-color: $of-yellow-02;
      }

      &:hover {
        box-shadow: 0 2px 10px $of-plum-05;
      }

      &.disabled {
        background-color: $of-forest-green-05;
        border-color: $of-forest-green-05;

        &.KeepColor {
          background-color: $of-yellow;
          border: none;
          opacity: 0.7;
        }
      }
    }

    &.salmonPink {
      background-color: $of-plum-05;
      color: $of-forest-green-01;

      &:hover {
        background-color: $of-forest-green-01;
        color: white;
      }
    }

    &.transparent {
      background-color: transparent;
      color: #b0bcc1;

      &:hover,
      &:focus {
        background-color: rgba(214, 223, 226, 0.2);
        color: #b0bcc1;
      }
    }
  }

  &.round {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0;

    &:hover,
    &:focus {
      background-color: $of-dark-green-05;
    }
  }

  &.selected {
    // This is only used by pagination and does not work well in combination with kinds and colors
    background-color: $of-forest-green-01;
    color: white;
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.grey {
    background-color: $of-dark-green-05;
    color: $of-forest-green-01;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: $of-dark-green-06;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-disable;
      color: $of-dark-green-03;
    }
  }

  &.outlinedRed {
    background-color: transparent;
    color: $of-contract-declined;
    border: solid 1px $of-contract-declined;

    &:hover,
    &:focus {
      background-color: $of-red-05;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-disable;
      color: $of-dark-green-03;
      border: solid 1px $of-disable;
    }
  }

  // New buttons
  &.Primary {
    border: 1px solid transparent;
    background-color: $of-yellow;
    color: $of-forest-green-01;

    &:hover,
    &:focus {
      background-color: $of-yellow-02;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-disable;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04; // TODO: temp - remove after all buttons are migrated
    }
  }

  &.Secondary {
    border: 1px solid transparent;
    background-color: $of-dark-green-02;
    color: white;

    &:hover,
    &:focus {
      background-color: $of-forest-green-01;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-disable;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04; // TODO: temp - remove after all buttons are migrated
    }
  }

  &.SecondaryLite {
    border: 1px solid transparent;
    background-color: $of-dark-green-05;
    color: $of-forest-green-01;

    &:hover,
    &:focus {
      background-color: $of-dark-green-04;
    }
  }

  &.Special {
    border: 1px solid transparent;
    color: white;
    background-color: $of-forest-green-01;

    &:hover,
    &:focus {
      background-color: $of-dark-green-01;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-disable;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04; // TODO: temp - remove after all buttons are migrated
    }
  }

  &.Approve {
    background-color: $of-purple-03;
    color: white;

    &:hover,
    &:focus {
      background-color: $of-purple-01;
    }

    &.disabled {
      background-color: $of-inactive;
      border: 1px solid $of-forest-green-05;
      color: $of-forest-green-04;
    }
  }

  &.AwaitsApproval {
    background-color: $of-medium-purple;
    color: white;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.Sign {
    background-color: $of-green-01;
    color: white;

    &:hover,
    &:focus {
      background-color: $of-sign-hover;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-inactive;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04;
    }
  }

  &.DocumentSign {
    position: relative;
    background-color: $of-document-sign-button-green;
    color: $of-green-13;
    border-radius: 8px;
    transition: background-color 100ms ease-out;

    &:hover {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -100%;
        width: 100px;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.35) 50%, transparent 100%);
        animation: shine 5s infinite;
      }

      @keyframes shine {
        0% {
          left: -100%;
        }

        30% {
          left: 100%;
        }

        100% {
          left: 100%;
        }
      }
    }

    &:hover,
    &:focus {
      background-color: $of-document-sign-button-green-hover;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-inactive;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04;
    }
  }

  &.Ghost {
    border: 1px solid $of-forest-green-04;
    background-color: white;
    color: $of-forest-green-01;

    &:hover,
    &:focus {
      border: 1px solid $of-forest-green-01;
    }
  }

  &.Save {
    background-color: $of-winter-green-dream;
    color: white;
    border-radius: 8px;
    transition: background-color 100ms ease-out;

    &:hover,
    &:focus {
      background-color: $of-dark-green-01;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-inactive;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04;
    }
  }

  &.Cancel {
    color: $of-red-02;
    border: 1px solid $of-red-02;
    border-radius: 8px;
    transition: background-color 100ms ease-out;

    &:hover,
    &:focus {
      background-color: $of-red-05;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-disable;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04;
    }
  }

  &.Send,
  &.Publish {
    background-color: $of-yellow;
    color: $of-forest-green-01;
    border-radius: 8px;
    transition: background-color 100ms ease-out;

    &:hover,
    &:focus {
      background-color: $of-yellow-02;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-inactive;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04;
    }
  }

  &.Unpublish {
    background-color: $of-forest-green-01;
    color: white;
    border-radius: 8px;
    transition: background-color 100ms ease-out;

    &:hover,
    &:focus {
      background-color: $of-winter-green-dream;
    }

    &.disabled {
      pointer-events: none;
      background-color: $of-inactive;
      color: $of-dark-green-03;
      border: solid 1px $of-dark-green-04;
    }
  }

  &.Wide {
    width: 100%;
    padding: 10px;
  }

  &.AddParticipant {
    align-items: center;
    background-color: $of-dark-green-05;
    color: $of-forest-green-01;
    cursor: pointer;
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    justify-content: center;
    line-height: 14px;
    padding: 10px;
    width: 100%;
    height: 34px;
    border-color: transparent;
    transition: ease-in-out 100ms;

    &.Disabled {
      cursor: not-allowed;
      background-color: $of-inactive;
      color: $of-dark-green-03;

      &:hover {
        background-color: $of-inactive;
      }
    }

    &:hover {
      background-color: $of-dark-green-04;
    }
  }
}

.DisabledButtonWrapper {
  cursor: not-allowed;
  display: flex;
  flex-direction: column;
}

a.Button:hover {
  // This is needed to prevent underline on hover from the default link style in elements.scss
  text-decoration: none;
}
