@import '~styles/base.scss';

.AdditionalInformation {
  font-size: 0.8em;
}

.RecipientDetails {
  margin-right: 0.5em;
  display: inline-block;
  background-color: #eee;
  padding: 2px 3px;
  margin-bottom: 3px;
  border-radius: 5px;

  .RecipientEmail {
    color: $of-legacy-light;
  }
}
