@import '~styles/base.scss';

.ExpiryInfo {
  width: 100%;
  padding-block: 16px;
  border-radius: 6px;
  font-size: 14px;
  color: $of-winter-green-dream;
}

.DatePassed {
  color: $of-red-02;
}
