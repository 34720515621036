@import '~styles/base.scss';

.DragOverlay {
  height: 35px;
  position: absolute;
  top: 30px;
  left: -30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: 12px;
  color: $of-thunder;
  font-size: 1rem;
  border: solid 1px rgba(black, 0.08);
  border-radius: 3px;
  background-color: $of-light-gray;
  box-shadow: 0 3px 12px 2px rgba(black, 0.12);
}

.Text {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 12px;
  margin-left: 7px;
  line-height: 35px;
}
