@import '~styles/base.scss';

.SelectItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em;
  color: $of-forest-green-01;

  &:hover,
  &:focus,
  &.Selected {
    background-color: $of-dark-green-04;
  }

  svg {
    margin-left: 0.5em;
    vertical-align: middle;
  }
}

.SelectTrigger {
  outline: none;
  background: white;
  border: 1px $of-forest-green-05 solid;
  border-radius: 5px;
  min-height: 42px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $of-forest-green-01;
  padding: 0 12px;

  * {
    box-sizing: border-box;
  }

  &[data-state=open] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:disabled {
    color: $of-forest-green-04;
    background: $of-inactive;
    border: $of-inactive;
    cursor: not-allowed;
  }

  &:hover,
  &[data-state=open] {
    border-color: $of-dark-green-02;
  }
}

.SelectContent {
  border: 1px $of-dark-green-02 solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  background: white;
  max-height: 240px !important;
  overflow-y: auto;
  z-index: 32;
  width: var(--radix-select-trigger-width);

  .SelectItem {
    outline: none;
    height: 42px;

    &:hover,
    &[data-state=checked] {
      background: $of-dark-green-05;
    }
  }
}
