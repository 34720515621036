@import '~styles/base.scss';

.HistoryButton {
  width: 180px;
  height: 30px;
  border-radius: 33px;
  background-color: white;
  color: $of-dark-green-01;
  box-shadow: 0 1.1px 11px rgba(0, 0, 0, 0.05);
  border: 1.1px solid $of-dark-green-04;

  span {
    font-size: 14px;
    font-weight: 500; //proxima nova doesn't have 500 but work sans will have
  }

  &:hover,
  &:focus {
    background-color: $of-inactive !important;
    color: $of-dark-green-01 !important;
  }
}

.HistoryButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px auto 0 auto;
  width: 100%;
}

.TabsRoot {
  display: flex;
  flex-direction: column;
  min-height: 50%;
  position: relative;
  flex: 1;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $of-forest-green-03;
  }
}

.TabContent {
  overflow: hidden;
  flex: 1;
}

.TabsList {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 47px;
  justify-content: space-between;
  position: relative;
}

.TabsTriggerContainer {
  display: flex;
  align-items: center;
}

.Trigger {
  position: relative;
  padding: 8px 11px;
  height: 36px;
  font-size: 14px !important;
  color: $of-forest-green-01;
  border: none;
  outline: none;
  background-color: transparent;

  &[data-state='active'] {
    background-color: $of-forest-green-05;
    border-radius: 6px;
  }
}

.TriggerItem {
  position: relative;
}

.CountBadge {
  top: -3px;
  right: -3px;
  text-align: center;
}

.TriggerWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 21px;
  position: relative;
}

.PostMessageContainer {
  margin: 12px 0;
  padding: 0 20px;
}

:export {
  contractviewdesktopmin: $contract-view-desktop-min;
  contractviewmobilemax: $contract-view-mobile-max;
}

.TabHeader {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-bottom: 0;
  justify-content: unset;
}

@media (orientation: landscape) {
  .PostMessageContainer,
  .TabHeader.Closable {
    padding-left: max(env(safe-area-inset-left), 20px);
    padding-right: max(env(safe-area-inset-right), 20px);
  }
}

.HeaderWrapper {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Header {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 500;
}
