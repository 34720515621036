@import '~styles/base.scss';

.Header {
  display: flex;
  border-radius: 8px 8px 0 0;
  background-color: $of-inactive;
  padding: 22px 32px;
  margin: 0;
}

.Body {
  padding: 32px;
  color: $of-forest-green-01;
}

.Label {
  line-height: 28px;
  // same height as input field to align vertically
  height: 41px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &[for] {
    cursor: pointer;
  }
}

.Vertical .Label {
  margin-right: 0;
  justify-content: flex-start;
}

.ErrorMessage {
  align-items: center;
  color: $of-passion;
  display: flex;
  justify-content: center;
  padding: 0 1rem;

  .ErrorIcon {
    margin-right: 0.5rem;
  }
}

.RequiredAsterisk {
  color: $of-red-02;
  margin-left: 0.25rem;
}
