@import '~styles/base.scss';

.MoreOptions {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 0 20px;

  * {
    box-sizing: border-box;
  }
}

.Header {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-forest-green-01);
  margin: 0;
}

.SubHeading {
  font-size: 16px;

  &:last-of-type {
    margin-top: 0;
  }
}

.Notifications {
  max-width: 220px;
  margin: 0;
  font-size: 14px;
}

.NotificationsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.Links {
  display: flex;
  margin-top: 50px;
  font-size: 14px;
  margin-bottom: 20px;

  @media only screen and (min-width: $contract-view-desktop-min) {
    margin-bottom: 30px;
  }
}

.ButtonMargin {
  margin-bottom: 16px;
}

.ButtonPadding {
  padding-left: 0;
}

.DisabledDocumentActions {
  cursor: not-allowed;
}
