@import '~styles/base.scss';

.Bubbles {
  margin: 0 0 40px 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  .PendingApprovers {
    display: flex;
    flex-direction: row;
  }

  .ApprovedApprovers {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .Participant {
    margin-left: -5px;

    span {
      color: $of-forest-green-03;
    }

    .GrayBorder {
      border: 2px solid $of-light-gray;
    }

    .SimpleBubbleUserBadge {
      display: flex;
    }
  }

  .LeftExtraBubble {
    margin-right: -5px;
  }

  .NotApprovedYet {
    z-index: 1;
    margin-left: -5px;
  }

  .FirstBubble {
    margin-left: -5px;
  }

  .Me {
    z-index: 10;
    width: 66px;
    grid-column-start: 2;
    margin-top: 0.3rem;

    div {
      width: auto;
    }

    svg + span {
      width: 66px;
      margin-left: -66px;
      font-size: 24px;
    }

    span:last-of-type {
      svg {
        margin-right: 17px;
      }
    }
  }

  .Approved {
    z-index: 1;
    margin-right: -5px;

    span:first-of-type {
      padding-left: 5px;
    }

    span:last-of-type {
      padding-bottom: 5px;
    }
  }

  .Badge {
    display: flex;

    &I5 {
      z-index: 5;
    }

    &I4 {
      z-index: 4;
    }

    &I3 {
      z-index: 3;
    }

    &I2 {
      z-index: 2;
    }

    &I1 {
      z-index: 1;
    }
  }
}
