@import '~styles/base.scss';

.ChartCard {
  box-sizing: border-box;
  border: 1px solid $of-inactive;
  border-radius: 8px;
  padding: 18px;
  margin-bottom: 1.5rem;

  .TopRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .TitleWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      .Title {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
  }

  .BottomRow {
    display: flex;
    justify-content: center;
  }

  .ChartButton {
    border: 1px solid $of-forest-green-04;

    &:hover {
      border-color: $of-forest-green-03;
    }
  }
}
