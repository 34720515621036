@import '~styles/base.scss';

.SearchContainer {
  position: relative;
  display: inline-flex;
  flex: 1;

  @media only screen and (max-width: 760px) {
    [class*=TextField] {
      max-width: 100%;
    }
  }
}

.ContractsResult {
  border: 1px solid $of-dark-green-04;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 10px 18px;
  margin: 1px;
  position: absolute;
  top: 46px;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: white;
  max-height: 12rem;
  overflow-y: auto;
  z-index: 9999;
}

.ContractInformation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.LoadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
}

.NoContract {
  div:first-child {
    width: 50px;
    height: 50px;
    border-radius: 35px;
  }

  div:nth-child(2) {
    font-size: 22px;
  }

  div:last-child {
    font-size: 15px;
  }
}
