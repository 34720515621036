@import '~styles/base.scss';

.Actions {
  color: $of-forest-green-01;
  transition: ease-in-out 100ms;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &.Active {
    background-color: $of-forest-green-01;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
}

.MenuItem {
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
}

.DeleteMenuItem {
  color: $of-red-02;
}

.Delete {
  &:hover {
    background-color: $of-red-09;
  }
}
