@import '~styles/base.scss';

.PageBreak {
  page-break-after: always;
  user-select: none;
  width: calc(100% - 44px);
  position: relative;
  left: 74px;
  bottom: 4px;
  clear: both;
  margin: 0;
  margin-top: -2px;
  border-top: $of-red-02 dashed 2px !important;

  /* Label */
  &::before {
    content: attr(title);
    position: absolute;
    left: -104px;
    bottom: -8px;
    display: inline-block;
    text-transform: uppercase;
    width: 95px;
    height: 20px;
    padding: 0 5px;
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 7px 0 0 7px;
    background-color: $of-red-02;
  }

  /* Arrow */
  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: -12px;
    font-size: 0;
    pointer-events: none;
    border: transparent solid 10px;
    border-left-color: $of-red-02;
  }
}
