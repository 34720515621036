@import '~styles/base.scss';

.ColorPickerContent {
  border-radius: 6px;
  background-color: white;
  padding: 8px;
  box-shadow: 8px 6px 20px 0 rgba(0, 0, 0, 0.1490196078);
}

.FormattingTrigger {
  cursor: pointer;
  color: $of-forest-green-01;
  background-color: white;
  border: none;
  padding: 4px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  max-width: 310px;
  font-size: 0.875rem;
  border-radius: 6px;

  &:active {
    background-color: $of-dark-green-05;
  }

  &:hover {
    background-color: $of-dark-green-04;
  }

  &.Bolded {
    color: $of-orange-01;
    background-color: $of-orange-02;

    &:hover {
      background-color: $of-orange-03;
    }

    &:focus {
      background-color: $of-orange-03;
    }
  }
}
