@import '~styles/base.scss';

.InfoTextContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 97.5%;
  padding: 10px 8px;
  gap: 8px;
  border-radius: 6px;
  background: $of-edit-hover;
  position: relative;
}

.InfoIcon {
  position: absolute;
  top: 12px;
  left: 10px;
}

.InfoText {
  color: $of-forest-green-01;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: left;
  padding-left: 22px;
  margin: 0;
}

.DelegateeInfo {
  margin-top: 30px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 19px;
  letter-spacing: -0.02em;
}

.Row {
  display: flex;
  margin: 0.25rem 0;

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    > * + * {
      margin-left: unset;
    }
  }
}

.MessageContainer {
  display: flex;
  align-items: center;

  > h1 {
    font-weight: 500;
    color: $of-forest-green-01;
    font-size: 1.125rem;
  }

  > div {
    margin-bottom: 6px;
    margin-left: 8px;
  }
}
