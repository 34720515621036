@import '@react-md/transition/dist/styles.scss';
@import '@react-md/menu/dist/styles.scss';
@import '~styles/base.scss';

.rmd-menu {
  border-radius: 8px;
  background-color: white;
  box-shadow: 8px 6px 20px 0 #00000026;
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: var(--rmd-menu-z-index);
  overflow-x: hidden;
}

ul.rmd-list {
  margin: 0;
  padding: 0;
}

.rmd-divider {
  border-width: 0;
  border-bottom: 1px solid $of-forest-green-05;
  margin: 0;
}
