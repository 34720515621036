@import '~styles/base.scss';

.BodyContainer {
  p {
    color: $of-forest-green-01;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
  }
}

.Modal {
  min-height: unset !important;
}

.Body {
  min-height: unset !important;
}
