@import '~styles/base.scss';

.Badge {
  display: inline-block;
  margin: 0 0.2rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  // Kinds
  &.type,
  &.name {
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1.5rem;
    vertical-align: middle;
    text-align: center;
    letter-spacing: 0.6px;
  }

  &.name {
    font-family: monospace;
  }

  &.outline {
    border: 1px solid;
    background-color: transparent !important;
  }

  // Colors (lowercase)
  &.lightGray {
    color: $of-thunder;
    border-color: $of-cloud;
    background-color: $of-light-gray;
  }
}
