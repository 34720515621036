/* stylelint-disable */
@import '~styles/base.scss';

.BoxActionsMenu {
  position: absolute;
  height: 40px;
  top: 12px;
  right: -32px;

  &.Editable {
    top: -10px;
    right: -49px;
  }

  &.CollapsedLayout {
    right: -45px;
    height: 0px;
  }

  @media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
    right: calc(12rem - 44px);
  }
}

.CogwheelContainer {
  padding: 8px;

  &.CollapsedLayout {
    padding: 5px;
  }

  ul {
    li {
      a {
        width: 0% !important;
      }
    }
  }

  .MenuItem {
    button {
      width: 100%;
    }
  }
}

.Cogwheel {
  color: $of-dark-green-02;

  &.CollapsedLayout {
    width: unset;
    height: unset;
  }

  &:hover,
  :active,
  :visited,
  :focus,
  &[aria-expanded=true] {
    box-shadow: none;
    background-color: transparent;
    color: $of-dark-green-01;
  }
}

.Lock {
  text-align: center;
  color: $of-dark-green-02;

  &:hover {
    box-shadow: none;
    background-color: transparent;
    color: $of-dark-green-01;
  }
}

.TooltipMessage {
  max-width: 12rem;
  font-size: 0.75rem;
  text-align: start;
}
