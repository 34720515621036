@import '~styles/base.scss';

.RecipientDetailsContainer {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid $of-dark-green-05;

  &:last-child {
    border: none;
  }

  .RecipientOrder {
    align-items: center;
    border: 2px solid $of-forest-green-05;
    border-radius: 20px;
    color: $of-forest-green-01;
    display: flex;
    height: 36px;
    justify-content: center;
    margin-right: 8px;
    width: 36px;
  }

  .RecipientInfo {
    width: 70%;
  }

  .RecipientName {
    align-items: center;
    color: $of-forest-green-01;
    display: flex;
    font-size: 15px;
    line-height: 1.6;
  }

  .RecipientEmail {
    color: $of-winter-green-dream;
    font-size: 13px;
  }

  .RecipientRole {
    color: $of-forest-green-01;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    right: 0;
    width: 106px;
  }
}
