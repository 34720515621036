@import '~styles/base.scss';

.ParticipantStatistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $of-forest-green-01 !important;
  background-color: #fff !important;
  line-height: 16px;
  letter-spacing: -0.02em;
  border-radius: 8px;
  font-weight: 300;
  padding: 18px;

  .BasicInfo {
    display: flex;
    flex-direction: column;
    width: 100%;

    .Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      .Name {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: -0.02em;
        margin-right: 4px;
      }

      .Role {
        font-size: 12px;
        background-color: $of-forest-green-03;
        color: #fff;
        border-radius: 20px;
        padding: 4px 12px;
        margin-left: 4px;
      }
    }

    .PartyName {
      overflow: hidden;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: $of-forest-green-01;
      margin-bottom: 2px;
      margin-top: 2px;
    }

    .Title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      color: $of-forest-green-01;
    }

    .RoleLabel {
      overflow: hidden;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: $of-winter-green-dream;
      margin-top: 8px;

      .RolePermissionInfo {
        font-size: 12px;
        color: $of-winter-green-dream;
        margin-top: 4px;
      }
    }
  }
}

.VisualLine {
  border: 0;
  opacity: 0.5;
  background-color: $of-forest-green-04;
  width: 100%;
  height: 0.5px;
  margin: 16px 0 18px;
}

.EventsLog {
  display: flex;
  flex-direction: column;
  color: $of-forest-green-01;
  border-radius: 0 0 8px 8px;
  width: 100%;

  &:empty {
    display: none;
  }

  .NotViewed {
    line-height: 16px;
    letter-spacing: -0.02em;
    color: $of-forest-green-01;
    font-size: 14px;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 600;
  }
}
