@import '~styles/base.scss';

.deleteInfoText {
  margin-bottom: 20px;
}

.PermanentDelete {
  color: $of-red-02;
  margin-bottom: 33px;
  margin-top: 25px;
}

.Delete {
  margin-bottom: 33px;
  margin-top: 41px;
}
