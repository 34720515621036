@import '~styles/base.scss';

.Header {
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.DescriptionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.Description {
  max-width: 850px;
}

.CountryPicker {
  margin-left: 1rem;
  min-width: 300px;
}

.MainContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.ExternalLink {
  color: $of-blue-02;
  text-decoration: underline;

  &:hover {
    color: $of-blue-01;
  }

  .ExternalLinkIcon {
    margin-left: 0.25rem;
    vertical-align: middle;
    margin-bottom: 3px;
  }
}
