@import '~styles/base.scss';

.RemindMe {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  margin-left: -10px;
  padding-left: 10px;
  border-radius: 6px;

  .EditIcon {
    position: absolute;
    display: none;
    top: 8%;
    left: 92%;
  }

  &:hover {
    background-color: $of-edit-hover;
    cursor: pointer;

    .EditIcon {
      display: block;
    }
  }
}

.Readonly {
  position: relative;
  margin-left: -10px;
  padding: 10px 0 10px 10px;
  border-radius: 6px;
}

.RemindMeHeader {
  font-size: 0.875rem;
  font-weight: 600;
  color: $of-forest-green-01;
  margin-bottom: 6px;
}

.ExpiryInfo {
  font-size: 0.8125rem;
  color: $of-winter-green-dream;
  padding: 6px 0;
}

.Inactive {
  color: $of-forest-green-01;
  opacity: 0.5;
  pointer-events: none;
}

.DaysCount {
  display: inline-grid;
  place-content: center;
  box-sizing: border-box;
  border: 1px solid $of-forest-green-04;
  border-radius: 4px;
  padding-inline: 0.25rem;
  min-width: 30px;
  width: fit-content;
  height: 30px;
  background-color: #fff;
  color: $of-forest-green-01;
  font-size: 0.8125rem;
}

.RemindMeInfo {
  font-size: 0.875rem;
  color: $of-forest-green-01;
  display: flex;
  gap: 10px;
  align-items: center;
}

.RemindMeHelperText,
.RemindMeInvalid {
  min-height: 50px;
}

.RemindMeHelperText {
  color: $of-winter-green-dream !important;
}

.RemindMeInvalid {
  color: $of-red-02;
}

.RemindMeForm {
  .PopupHeader {
    border-radius: 8px 8px 0 0;
    padding: 10px;
    background-color: $of-forest-green-01;
    color: white;
  }

  .RemindMeContainer {
    padding: 16px;
    min-height: 142px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .RemindMeInputContainer {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-bottom: 15px;

      >div {
        max-width: 90px;
      }
    }
  }
}

.ActionButtonsContainer {
  display: flex;
  justify-content: flex-end;
}
