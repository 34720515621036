@import '~styles/base.scss';

.MessagesTab {
  background-color: $of-inactive;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .invisible {
    display: none;
  }

  .InfoMessage {
    font-size: 15px;
    line-height: 1.3;
    padding: 10px 20px;
  }
}
