@import '~styles/base.scss';

.TooltipText {
  text-align: center;
  width: auto;
  margin: 12px 2px 2px 2px !important;
}

.Dropdown {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $of-forest-green-01;
  padding-left: 8px;
  margin-left: 8px;
  height: 24px;
  border-radius: 3px;
  border-style: none;
  background: none;

  &:hover {
    background-color: $of-dark-green-06;
    cursor: pointer;
  }

  &.SelectedAlignment {
    background-color: $of-orange-02;
    color: $of-orange-01;
  }
}
