/* stylelint-disable no-descending-specificity */
@import "~styles/base.scss";

@mixin DiscStyleType {
  list-style-type: disc;

  > li {
    text-indent: 0;
  }

  > li::before {
    content: "";
    color: inherit;
    padding-right: inherit;
  }
}

@mixin CircleStyleType {
  list-style-type: none;

  > li {
    text-indent: -1em;
  }

  > li::before {
    content: "○";
    color: black;
    padding-right: 0.7em;
  }
}

@mixin SquareStyleType {
  list-style-type: square;

  > li {
    text-indent: 0;
  }

  > li::before {
    content: "";
    color: inherit;
    padding-right: inherit;
  }
}

@mixin RTFTextStyle() {
  div:not(
  :global(.editor-paragraph),
  :global(.controls),
  :global(.popover-content),
  :global(.file-actions),
  :global(.MuiSnackbar-root *),
  :global(.video-js),
  :global(.tooltip-inner)),
  p,
  ul,
  ol,
  table,
  dl,
  blockquote,
  pre {
    font-size: var(--formatting-font-size); //@rtfFontSize;
    color: var(--formatting-font-color); //@rtfFontColor;
    line-height: 1.55; //@rtfLineHeight;
  }

  :global(.editor-paragraph) {
    margin-bottom: 14px;
  }

  header,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 0.3em 0;
    padding: 0.7em 0 0 0;
    color: var(--formatting-font-color);
    background: none;
    text-rendering: optimizeLegibility;
    font-weight: normal;
  }

  p {
    margin: 0;
    margin-bottom: 14px;
  }

  h1 {
    font-size: 25px;
    line-height: 1.35;

    &:global(.title) {
      margin-bottom: 0;
      font-size: 48px !important;
      line-height: 1.35;
    }

    &:global(.subtitle) {
      font-size: 21px;
      line-height: 1.35;
      color: var(--formatting-font-color-70-percent); // Waiting for formatting functionality, see more in document-default.css
    }
  }

  h2 {
    font-size: 21px;
    line-height: 1.35;
  }

  h3 {
    font-size: 18px;
    line-height: 1.35;
  }

  h4 {
    font-size: 16px;
    line-height: 1.35;
    font-weight: 600;
  }

  h5 {
    font-size: 14px;
    line-height: 1.35;
    font-weight: 600;
  }

  h6 {
    font-size: 14px;
    line-height: 1.55;
    text-transform: uppercase;
  }

  h1 span,
  h2 span,
  h3 span,
  h4 span,
  h5 span,
  h6 span {
    color: inherit;
  }

  a {
    color: $of-legacy-blue;
  }
}

@mixin RTFContent() {
  code,
  object,
  embed,
  video,
  img {
    max-width: 100%;
  }

  video,
  div:not(:global(.legacy-compatible-popover-content), :global(.legacy-compatible-popover-content) div),
  p,
  ul,
  ol,
  table,
  dl,
  figure,
  blockquote,
  pre {
    margin-bottom: 0 0 14px 0;
    border: none;
    background: none;
    box-shadow: none;

    // we don't want any extra margin for the last element in a RTF element
    &:last-child {
      margin-bottom: 0;
    }
  }

  iframe,
  object,
  hr {
    margin-bottom: 14px;
  }

  iframe {
    width: auto;
  }

  blockquote {
    margin-left: 0 !important;
    border-left: 2px solid var(--formatting-font-color) !important;
    padding-left: 1.6em !important;
    color: var(--formatting-font-color);
    font-style: italic;
  }

  ul,
  ol {
    padding-left: 2em;
    list-style: decimal outside;
    color: var(--formatting-font-color);

    > li {
      > * {
        font-family: $sans-font-family;
      }
    }

    &ul,
    &ol {
      margin: 2px;
      padding: 0;
      padding-left: 2em;
      border: none;
    }
  }

  ul {
    @include DiscStyleType;

    ul {
      @include CircleStyleType;

      ul {
        @include SquareStyleType;

        ul {
          @include DiscStyleType;

          ul {
            @include CircleStyleType;

            ul {
              @include SquareStyleType;
            }
          }
        }
      }
    }
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: upper-alpha;

      ol {
        list-style-type: lower-alpha;

        ol {
          list-style-type: decimal;

          ol {
            list-style-type: upper-alpha;

            ol {
              list-style-type: lower-alpha;
            }
          }
        }
      }
    }
  }

  dl dt {
    font-weight: bold;
  }

  dd {
    margin-left: 1em;
  }

  table {
    border-collapse: collapse;
    font-size: 1em;
    width: 100%;

    & td,
    & th {
      padding: 5px;
      border: 1px solid #ddd;
      vertical-align: top;
    }
  }

  table thead td,
  table th {
    font-weight: bold;
    border-bottom-color: #888;
  }

  code {
    background-color: #d8d7d7;
  }

  pre {
    overflow: auto;
    padding: 1em;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: #f8f8f8;
    white-space: pre;
    font-size: 90%;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
  }

  // Image alignment
  :global(.img-align-left) {
    float: left;
    margin: 0 20px 20px 0;
  }

  :global(.img-align-right) {
    float: right;
    margin: 0 0 20px 20px;
  }

  :global(.img-align-center) {
    text-align: center;
  }

  // remove top/bottom margin and padding from first and last element
  // in RTF text to make spacing within an RTF block more consistent with
  // spacing between section
  > p:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  :global(.editor-paragraph):first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  > p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  :global(.editor-paragraph):last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  // Widgets
  :global(.widget-wrapper) {
    display: inline;
    // Make sure images do not overflow textboxes
    max-width: 100%;

    &:global(.wrapper-widget-datafield) {
      display: inline-block;
    }
  }
}

@media screen and (max-width: 480px) {
  :global(.img-align-left) {
    margin: 0 10px 10px 0;
    float: none !important;
  }

  :global(.img-align-right) {
    margin: 0 0 10px 10px;
    float: none !important;
  }
}

@mixin Clearfix {
  zoom: 1;

  &::before,
  &::after {
    /* Fix for safari replaced: \0020  with dot */
    content: '.';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  &::after {
    clear: both;
  }
}

@mixin EditableRTFContent {
  min-height: 22px;
  padding: 8px;
  cursor: text;
  overflow-wrap: break-word;

  @include RTFTextStyle;
  @include RTFContent;
  @include Clearfix;

  ol,
  ul {
    li {
      span {
        font-family: var(--formatting-font-family);
      }
    }
  }

  a span[data-slate-leaf="true"] {
    word-break: break-word;
  }

  > :global(.editor-paragraph):first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  > :global(.editor-paragraph):last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@mixin EditableReadOnlyRTFContent {
  @include EditableRTFContent;

  border: 1px solid transparent;
  word-break: break-word;
}
