.RadixDialogContent {
  position: fixed;
  z-index: 3;
  top: calc(0.25px + 13vh);
  left: 50%;
  transform: translateX(-50%);

  * {
    box-sizing: border-box;
  }
}

.ContentWrapper {
  display: flex;
  width: 90vh;
  max-width: 640px;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
}

.Content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: min-content;
  will-change: transform;
  transform-origin: center center;
  border-radius: 8px;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0 4px 30px,
    rgba(0, 0, 0, 0.04) 0 3px 17px,
    rgba(0, 0, 0, 0.04) 0 2px 8px,
    rgba(0, 0, 0, 0.04) 0 1px 1px;
  font-size: 0.9375rem;
  color: rgb(47, 47, 49);
  overflow: hidden;
  background-color: #fff;
}

.Overlay {
  position: fixed;
  inset: 0;
  z-index: 2;
}
