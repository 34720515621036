@import '~styles/base.scss';

.Row {
  width: 100%;
  max-width: 660px;
  line-height: 1.5;
}

.Grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}

.LogoUpload {
  margin: 0 -2px 8px;
}

.LogoRow {
  margin-bottom: 38px;
  display: flex;
  gap: 30px;
}

.ValueLabel {
  img {
    max-width: 175px;
    max-height: 100px;
  }
}

.LogoPlaceholder {
  align-items: center;
  background-color: $of-inactive;
  border-radius: 4px;
  border: 1px solid $of-forest-green-04;
  display: flex;
  height: 220px;
  justify-content: center;
  width: 330px;
}

.FileUploadContainer {
  width: 240px;
}
