@import '~styles/base.scss';

.SignRate {
  width: 35%;
  color: $of-forest-green-01;
  background-color: white;
  box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
  overflow: hidden;
  border-radius: 8px;

  .Divider {
    height: 1px;
    margin: 0 0 1rem 0;
    overflow: hidden;
  }

  .SignRateHeader {
    display: flex;
    align-items: center;
    padding: 30px;
    font-size: 19px;
    line-height: 22px;
    font-weight: 500;
  }

  [class*="recharts-wrapper"] {
    :focus {
      outline: none;
    }
  }
}
