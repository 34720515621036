@import '~styles/base.scss';

.CollaborationSettingsContainer {
  color: $of-forest-green-01;
  font-size: 0.875rem;
  font-weight: 400;

  .Bold {
    display: flex;
    font-weight: 600;
  }

  .Divider {
    border-bottom-color: $of-dark-green-05;
    margin: 24px 0;
  }

  .Flex {
    display: flex;
  }

  .ToggleContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
  }

  .Tooltip {
    margin-left: 0;
  }

  .TooltipWrapper {
    align-items: center;

    @media (max-width: 280px) {
      width: min-content;
    }
  }
}

.SnackbarContainer {
  font-weight: 400;
}
