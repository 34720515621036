@import '~styles/base.scss';

.SignatureImageContainer {
  // Box config
  box-sizing: content-box; // Keeping it as content-box so that image tag will fill the full space
  border: 0.8px solid $of-forest-green-01;
  border-radius: 7px;
  margin: 12px 24px 18px 24px;
  // Layout
  display: flex;
  justify-content: center;
  align-items: center;
}

.SignatureImageContainer img {
  aspect-ratio: auto 21 / 8;
  // Dimension
  width: 100%;
  height: auto;
}
