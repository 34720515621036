@import '~styles/base.scss';

.WorkspaceNameContainer {
  display: flex;
  color: $of-dark-green-02;

  .WorkspaceName {
    font-size: 0.75rem;
    margin: 0 0 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
