@import '~styles/base.scss';

.PreviewContainer {
  border: 1px solid $of-forest-green-04;
  border-radius: 4px;
  width: 328px;
  height: 220px;
  background: $of-inactive;
  display: flex;
}
