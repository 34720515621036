@import '~styles/base.scss';

.SuggestionAccepted {
  max-width: 250px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $of-forest-green-04;
  font-style: italic;
}

.SemiBold {
  font-weight: 600;
}

.ExpandButton {
  color: $of-blue-02;
  text-align: end;
  border: none;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}

.CollapsedText {
  display: flex;
  flex-direction: column;
}

.TextContainer {
  margin-bottom: 5px;
}
