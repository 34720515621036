@import '~styles/base.scss';

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.PhoneUnverified {
  color: $of-passion;
  margin-left: 0.4rem;
}

.PhoneVerified {
  color: $of-peace;
  margin-left: 0.4rem;
}

.VerifyButton {
  padding: 1rem 0;
}

.Description {
  max-width: 660px;
}

.Header {
  font-weight: 500;
  font-size: 1.1rem;
}

.Row {
  width: 100%;
  max-width: 660px;
}
