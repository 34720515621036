.FormBody {
  max-width: 660px;
}

.Title {
  font-size: 1.1rem;
  font-weight: 500;
}

.Row {
  display: flex;
}

.Label {
  width: 18.6rem;
  font-size: 0.875rem;
  height: 41px;
  display: flex;
  align-items: center;
}

.EmailContainer {
  display: flex;
  gap: 11px;

  > div {
    position: relative;
    top: calc(45px + (43px / 2));
    transform: translateY(-50%);
  }
}

.Fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
