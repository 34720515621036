@import '~styles/base.scss';

.SubscriptionCancelledContainer {
  .Link {
    color: $of-blue-02;
  }

  .BodyTitle {
    font-size: 24px !important;
    font-weight: 400;
    margin: 13px 0 8px;
  }

  p {
    color: $of-forest-green-01;
    font-size: 14px;
    font-weight: normal !important;
    line-height: 20px;
    margin: 20px 26px 13px 0;
  }
}
