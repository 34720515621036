@import '~styles/base.scss';

.DataField {
  position: relative;
  border: 1px solid $of-blue-04;
  border-left: unset;
  border-radius: 0 6px 6px 0;
  display: flex;
  min-width: 0;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 54px;
  font-size: 0.875rem;
  color: $of-forest-green-01;
  padding: 8px;
  bottom: 0;
  box-shadow: none;
  transition: all ease-in-out 100ms;

  &:hover {
    background-color: $of-inactive;
  }

  &.CanBeExpanded:hover:not(.IsDragOverlayActive) {
    background-color: $of-inactive;
    border-color: $of-blue-03;
    cursor: pointer;
  }

  .EditButton {
    text-align: end;
    display: flex;
    align-self: flex-end;

    button {
      color: $of-blue-02;
      font-weight: 500;
      padding: 0;
      line-height: normal;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.ItemDragHandler {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  background-color: $of-blue-04;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 18px;
  bottom: 0;
  box-shadow: none;
  transition: all ease-in-out 100ms;

  &:hover {
    border-color: $of-blue-03;
    background-color: $of-blue-03;
  }

  & > * {
    margin-top: 12px;
  }
}

.ItemDragHandler:hover + .DataField {
  border-color: $of-blue-03;
  background-color: $of-inactive;
}

.DataFieldContainer {
  display: flex;
  align-items: stretch;
  margin-bottom: 8px;
  border-radius: 6px;

  .Draggable {
    align-self: stretch;
    cursor: grab;
  }
}

.DataFieldContainer:has(.ItemDragHandler:hover) {
  & > .DataField {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    bottom: 2px;
  }

  & > .ItemDragHandler {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    bottom: 2px;
  }
}

.IsDragOverlayActiveContainer {
  color: $of-blue-05;
}

.DataFieldHeader {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.DragIsDisabled {
  border-left: 8px solid $of-blue-04;
  border-radius: 6px;
}

.IsDragOverlayActive {
  border: 1px dashed $of-blue-05;
  border-left: 8px solid $of-blue-05;
  border-radius: 6px;
  padding-left: 18px;

  .EditButton {
    visibility: hidden;
  }
}

.DataFieldName {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0;
  margin-right: 8px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.IsExpanded {
    overflow: auto;
    white-space: normal;
    word-break: break-word;
  }

  &.IsActive {
    color: $of-blue-05;
  }
}

.DataFieldBody {
  width: 100%;
  display: block;
  color: $of-forest-green-01;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.IsExpanded {
    overflow: auto;
    white-space: normal;
    word-break: break-word;
  }

  &.EmptyValue {
    color: $of-forest-green-04;

    & > span {
      display: block;
      width: 100%;
    }
  }
}

.DataFieldBody.DragIsActive {
  color: $of-blue-05;
}

.DataFieldPlaceholder {
  font-style: italic;
  font-size: 0.75rem;
}
