@import '~styles/base.scss';

.MarkAsTerminatedMenuItem {
  &:hover,
  &:focus {
    background-color: $of-red-09;

    button {
      color: $of-red-02;
    }
  }
}
