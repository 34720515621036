@import '~styles/base.scss';

:root {
  --of-green-13: #006421;
}

.Popover,
.Signature {
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 1;
  gap: 8px;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  border-radius: 6px;
  background-color: $of-green-03;
  overflow: hidden;

  &.HasSigned {
    background-color: transparent;
    justify-content: center;
  }

  .SignatureIcon {
    color: $of-green-13;
    position: absolute;
    top: 4px;
    right: 4px;
  }

  .SignatureContent {
    object-fit: contain;
    overflow: hidden;
  }
}

.ParticipantTooltipMessage {
  background-color: white;
  border-radius: 8px;
  padding: 30px 26px;
  max-width: 320px;
  max-height: 290px;
  box-shadow: 0 0 16px 0 rgba(1, 58, 76, 0.2);
}

.ParticipantName {
  color: $of-green-13;
}

.OverlayToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.OverlayFieldPopover {
  padding: 4px;
  border-radius: 8px;
}

.PendingSignatureDragging {
  opacity: 0.6;
}

.DuplicateButton {
  padding-inline: 8px !important;
  height: 32px;
  width: 32px;

  &:hover {
    background-color: $of-inactive;
  }
}

.VerticalDivider {
  background-color: $of-blue-05;
  width: 1px;
  margin: 0 4px;
  align-self: stretch;
}
