@import '~styles/base.scss';

.ContractListContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ContractList {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }

  .Loading > :not(.LoadingSpinner) {
    opacity: 0.5;
  }

  .LoadingSpinner {
    height: 38rem;
    margin: 0 auto -38rem;
  }

  .PaginationContainer {
    display: flex;
    min-height: 32px;
    margin-right: 35px;

    & > :first-child {
      flex: 1;
      margin-right: 0.8rem;
      padding: 0;

      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        flex: 1 1 auto;
      }
    }

    .ExportButton {
      margin-right: 0;
      position: absolute;
      bottom: 4px;
      right: 0;
    }
  }

  .TopPagination {
    display: flex;
    flex-direction: row;
    min-height: 32px;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 10px;

    > span {
      color: $of-forest-green-01;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .EmptyState {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .Paragraph {
    margin-bottom: 22px;
  }
}
