@import '~styles/base.scss';

.TagSelectorContainer {
  display: flex;
  flex-direction: column;

  @include flex(1);

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  & > :first-child {
    font-size: 0.8rem;
  }

  :global(.ReactSelect__value-container--is-multi) {
    overflow-y: auto;
  }
}

.SystemTagOption {
  svg {
    margin-right: 3.5px;
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.SystemTagValue {
  border: 1px solid $of-forest-green-01;

  &:hover {
    border-color: $of-forest-green-04;
  }
}

.CheckboxWrapper {
  margin-top: 13px;
  margin-bottom: 13px;
}
