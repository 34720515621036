@import '~styles/base.scss';

.ModalClass {
  display: block;
  min-height: auto;
}

.ModalBodyClass {
  min-height: auto !important;
  margin-bottom: 13px;
}

.MissingFeaturesContainer {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    color: $of-forest-green-01;
    font-weight: 600;
    margin: 13px 20px 16px 0;
    line-height: 20px;
  }

  .TextArea {
    overflow-wrap: break-word;
    overflow-y: auto !important;
    width: -webkit-fill-available;
    min-width: -webkit-fill-available;
    max-width: -webkit-fill-available;
    max-height: 250px;
    padding: 13px;
    margin: 0;
    border: 1px solid $of-forest-green-05;
    border-radius: 6px;
    box-shadow: none;
    color: $of-forest-green-01;
    letter-spacing: -0.02em;
    line-height: normal;

    &:focus-within,
    &:focus,
    &:active {
      border: 1px solid $of-forest-green-01;
      color: $of-forest-green-01;
      outline-color: transparent;
      outline-style: none;
    }

    &:hover {
      border-color: $of-dark-green-02;
    }
  }
}

.Buttons {
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 10px;
  }
}
