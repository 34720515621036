@import '~styles/base.scss';

.FilterBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  border-top: 1px solid $of-forest-green-05;

  &.RemoveDivider {
    border-top: none;
  }

  &:last-child {
    padding-bottom: 1rem;
    border-bottom: 1px solid $of-forest-green-05;
  }

  .Header {
    color: $of-thunder;
    padding: 0 0 0.5rem 0;
    display: flex;
    justify-content: space-between;
    flex: 1;
    border-style: none;
    margin: 0;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    .HeaderContent {
      display: flex;
    }

    .Title {
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .FilterCounter {
    display: flex;
    align-items: center;
    border-radius: 30px;
    color: $of-forest-green-01;
    height: 14px;
    justify-content: center;
    margin: 2px 0 0 0.5rem;
    overflow: hidden;
    padding: 2px 8px;
    text-overflow: ellipsis;
    border: 1px solid $of-forest-green-05;

    &:hover {
      border-color: $of-forest-green-04;
    }
  }
}
