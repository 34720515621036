@import '~styles/base.scss';

.ContractId {
  margin-right: 14px;
  float: right;
  font-size: 12px;
  font-family: var(--document-static-font-family);
  line-height: 1.3;
  color: $of-legacy-light;
}
