@import '~styles/base.scss';

.InlineEditable {
  box-sizing: border-box;
  color: $of-forest-green-01;
  overflow: hidden;
  padding: 0 6px;

  &::before {
    border-radius: 4px;
    border: 1px solid transparent;
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: border-color 150ms linear;
  }

  &.Focused {
    outline: none;

    &::before {
      border-color: $of-forest-green-04;
    }
  }
}
