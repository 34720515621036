@import '~styles/base.scss';

.DeleteButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  color: $of-forest-green-01;
  border-radius: 6px;

  &:hover {
    background-color: $of-red-05;
    color: $of-red-02;
  }

  &:disabled {
    color: $of-forest-green-04;
    cursor: not-allowed;
  }
}
