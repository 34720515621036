@import '~styles/base.scss';

.SingleMessageBox {
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: column;
  background-color: #fff;
  transition: background-color 80ms linear, transform 120ms ease-out;
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
  padding: 12px;
  margin: 12px auto;
  overflow: hidden;

  hr {
    margin: 12px 0 0 0;
    border: 0;
    height: 1px;
    background-color: $of-dark-green-04;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
  }

  &.MobileView {
    padding: 6px 12px 0 12px;
    margin: 0;
    border-radius: 0;
    box-shadow: none;

    &::before {
      width: 0;
    }
  }
}

.HoveredComment {
  &:hover {
    box-shadow: 8px 6px 8px rgba(black, 0.13);
  }
}

.ClickedMessage {
  box-shadow: 8px 6px 8px rgba(black, 0.3);
  transform: translate(-3px, -6px);
}

.ResolvedContainer {
  color: $of-forest-green-01;
  margin-top: 16px;

  .ResolvedStatusLabel {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
    margin-left: 40px;
    color: $of-winter-green-dream;
  }
}

.Icon {
  padding: 4px;
}

.Comment::before {
  background-color: $of-yellow;
}

.Suggestion::before {
  background-color: $of-blue-04;
}

.ResolvedComment::before {
  background-color: $of-edit-hover;
}

.ResolvedSuggestion::before {
  background-color: $of-blue-05;
}

.Hidden {
  display: none;
}
