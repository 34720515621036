@import '~styles/base.scss';

.DecimalContainer {
  border-bottom: 1px solid $of-dark-green-04;
  padding: 15px 15px 0 10px;
}

.DecimalTitle {
  align-items: center;
  color: $of-forest-green-01;
  display: flex;
  font-weight: 600;
  padding-bottom: 10px;
}

.Select {
  margin: 10px 0 15px 0;
  width: 60px;
  background-color: transparent;
}

.InfoIcon {
  margin-left: 5px;
}
