@import 'styles/base.scss';

.SidebarContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-left: 1px solid $of-forest-green-05;
}

.TabsRoot {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.TabContainer {
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

  &.MessagesTabContainer {
    padding: unset;
  }

  &:not([hidden]) {
    display: flex;
  }
}

.TabsList {
  display: flex;
  flex-direction: column;
  padding: 3px 0;
}

.ListIcon {
  padding: 6px;
  margin: 6px;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  display: flex;
  border-radius: 6px;
  transition: background-color ease-in-out 100ms;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $of-inactive;
    cursor: pointer;
  }
}

.ActiveTabTrigger {
  background-color: $of-dark-green-04;
  border-radius: 6px;

  &:hover {
    background-color: $of-dark-green-04;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  // width is to prevent the content getting squeezed when the sidebar is closing
  width: var(--sidebar-width);
}

.TriggerContainer {
  position: relative;
  color: $of-forest-green-01;

  &:disabled {
    color: $of-forest-green-04;
    cursor: not-allowed;
    pointer-events: unset !important;

    &:hover {
      background-color: unset;
    }
  }
}

.TabsTriggerTooltip {
  box-shadow: none;
}

.TabContentContainer {
  width: 0;
  display: flex;
  flex-direction: column;
  transition: width 0.4s;
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;

  &,
  * {
    box-sizing: border-box;
  }
}

.SidebarExpanded {
  width: var(--sidebar-width);
}

.SidebarHidden {
  visibility: hidden;
}

.TabsToolbar {
  position: absolute;
  width: var(--tabs-toolbar-width);
  border-radius: 8px;
  background-color: white;
  transition: right 0.4s;
  top: 48px;
  right: 20px;
  box-shadow: -1px 1px 20px 0 #0000001a;
}
