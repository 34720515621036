@import '~styles/base.scss';

.SidebarWrapper {
  height: 100%;
}

.Sidebar {
  min-width: $sidebar-width;
  transition: width 250ms ease-in-out;
  width: $sidebar-width;
  height: 100%;

  @include respondTo(md, max) {
    width: unset;
  }

  &.Expanded {
    width: 216px;
  }

  .SidebarContent {
    background-color: $of-forest-green-01;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 0 12px;
    text-align: center;
    transition: width 250ms ease-in-out, top 0.3s, min-height 150ms ease-out, height 150ms ease-out;

    &.IsPositionFixed {
      position: fixed;
      height: 100vh;
      width: 72px;
      left: 0;
      top: 0;
      z-index: 1044;
    }

    &.SidebarContentHidden {
      top: -72px;
    }

    @include respondTo(md, max) {
      position: fixed;
      width: 100%;
      height: 72px;
      min-height: 72px;
      overflow: hidden;
      z-index: 1044;

      &.MenuOpen {
        height: 100vh;
        max-width: 100vw;
        overflow: scroll;
        position: unset;
      }
    }

    @include respondTo(md, min) {
      overflow: unset;
    }

    &.Expanded {
      width: 216px;
    }

    .LogoSection {
      box-sizing: border-box;
      color: white;
      display: flex;
      flex-shrink: 0;
      height: 64px;
      justify-content: space-between;
      margin: 0 -12px;
      padding: 15px 12px;

      @include respondTo(md, max) {
        min-height: 72px;
      }
    }

    .WorkspaceSection {
      display: flex;
      justify-content: center;

      & > div {
        width: 100%;
      }
    }

    .ResponsiveSection {
      padding-top: 16px;
      padding-bottom: 11px;
    }

    &.Mobile {
      padding: 0;

      & > .LogoSection {
        margin: 0;
      }

      & > .WorkspaceSection {
        padding: 0 12px;
      }

      & > .ResponsiveSection {
        padding: 16px 12px 11px 12px;
      }
    }
  }
}

.Backdrop {
  background: $of-forest-green-01;
  bottom: -40px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 250ms linear;
  z-index: 1043;

  &.Visible {
    opacity: 25%;
  }
}

.HideBackdrop {
  bottom: -40px;
  left: 216px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1043;
}

.Logo {
  margin: 0 auto;
}

.MobileMenuItems {
  display: flex;
  align-items: flex-end;
}

.MobileMenuToggle {
  color: white;
  padding: 0;
  margin-left: 19px;
}

.FullHeight {
  height: 100%;
}

.CreateButtonWrapper {
  padding: 0 3px;
}
