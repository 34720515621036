@import '~styles/base.scss';

.Dropdown {
  max-width: 400px;
  text-align: left;

  :global(.ReactSelect__option) {
    font-family: var(--document-static-font-family) !important;
    font-size: var(--document-static-font-size) !important;
  }

  :global(.ReactSelect .ReactSelect__placeholder) {
    font-family: var(--document-static-font-family) !important;
  }

  :global(.ReactSelect .ReactSelect__option):not(:last-of-type) {
    padding: 0 14px !important;
  }

  > label > div > div {
    background-color: $of-forest-green-03;
    border-color: $of-forest-green-03;
    cursor: pointer;

    & > div > div {
      color: white !important;
      font-weight: 300;
    }

    &:focus-within {
      color: white !important;
      background-color: $of-forest-green-01;
      border-color: $of-forest-green-01;
    }

    & > div:last-child {
      color: white;
    }

    &:hover > div:last-child {
      color: white !important;
      border-color: $of-forest-green-03;
    }

    &:focus-within > div:last-child {
      color: white !important;
    }
  }
}
