@import '~styles/base.scss';

.ExtensionConfigurationBody {
  margin-top: 0;
  padding-bottom: 10px; /* padding to avoid margins from collapsing */
}

.ConnectionActive {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  border-radius: 0.25rem;
  word-wrap: break-word;
}

.ConnectionActiveIcon {
  margin-left: 0.25rem;
  vertical-align: middle;
  margin-bottom: 3px;
  color: $of-peace;
}

.ConnectionError {
  margin: 1rem;
  padding: 1rem;
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 0.25rem;
  word-wrap: break-word;
}

.ConnectionErrorIcon {
  margin-left: 0.25rem;
  margin-bottom: 3px;
  vertical-align: middle;
  color: $of-passion;
}

.Header {
  display: inline-flex;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.AuthStatus {
  display: flex;
  align-items: center;
}

.InitAuthButton {
  align-self: flex-start;
  margin-bottom: 10px;
}

.InitAuthButtonIcon {
  margin-right: 0.25rem;
}

.ExternalLink {
  margin-left: 0.25rem;
  vertical-align: middle;
  margin-bottom: 3px;
}
