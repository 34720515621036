@import '~styles/base.scss';

.SectionContainer {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  width: 240px;
  box-sizing: border-box;
}

.InnerContainer {
  padding: 10px;
  min-height: 200px;
  border: 2px $of-forest-green-05 solid;
  border-radius: 8px;
  background: white;
}

.LogoContainer {
  display: flex;
  align-items: center;
  min-height: 75px;
}

.Logo {
  width: 150px;
  height: 35px;
}

.LogoSquare {
  height: 75px;
  width: 75px;
}

.ContentContainer {
  min-height: 85px;
}

.Title {
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  font-size: 16px;
  font-weight: 700;
  color: $of-forest-green-01;
}

.SubTitle {
  font-size: 12px;
}

.ToggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ToggleWrapper {
  max-width: 50px;
}

.ToggleInfo {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $of-gray;
}
