@import '~styles/base.scss';

.ContractParties {
  display: flex;
  overflow: hidden;
  line-height: 1.4;

  .NoCounterparties {
    font-style: italic;
    color: $of-dark-green-02;
  }

  .CounterpartyName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $of-forest-green-01;

    &:not(:first-child) {
      margin-left: 3px;
    }
  }
}

.MoreParties {
  margin: 0 0.5rem;
  color: $of-cloud;
}
