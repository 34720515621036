@import '~styles/base.scss';

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 11px;
}

.ErrorWrapper {
  padding: 16px 0;
}

.ErrorMessageWrapper {
  // TODO: remove when migrated from backbone to react
  font-family: 'Work Sans', sans-serif;
  background-color: $of-red-05;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  color: $of-red-01;
  margin-top: 42px;

  p {
    margin: 0;
  }
}

.ErrorMessageTitle {
  font-weight: 500;
}

.ErrorMessageText {
  font-weight: 400;
}
