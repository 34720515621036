@import '~styles/base.scss';

.Dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.Red {
  background-color: $of-red-02;
}

.Green {
  background-color: $of-green-01;
}

.Orange {
  background-color: $of-contract-overdue-new;
}
