@import '~styles/base.scss';

.TemplateLibraryButton {
  margin-left: 10px;
}

.TemplateLibrary {
  > * {
    padding: 0 2rem;
  }

  .TemplateList {
    overflow: auto;
    max-height: 315px;
  }

  .Title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
}

.TemplateLibraryModalPortal {
  & > div > div > div:nth-child(2) {
    padding: 1rem 0;
  }
}
