@import '~styles/base.scss';

.SignRateLegend {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .StateGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40px;

    .State {
      display: flex;
      align-items: center;

      %state-shared {
        width: 2rem;
        height: 0.45rem;
        border-radius: 1rem;
        margin-right: 10px;
      }

      .Sent {
        @extend %state-shared;

        background-color: $of-blue-03;
      }

      .Signed {
        @extend %state-shared;

        background-color: $of-green-01;
      }

      .Declined {
        @extend %state-shared;

        background-color: $of-red-02;
      }

      .Overdue {
        @extend %state-shared;

        background-color: $of-contract-overdue-new;
      }
    }
  }
}
