@import '~styles/base.scss';

.BreadCrumb {
  align-items: center;
  color: $of-forest-green-01;
  display: flex;
  overflow: hidden;

  .Text {
    font-weight: 500;
  }

  .Link {
    align-items: center;
    display: flex;
    font-weight: 500;
    justify-content: center;
  }

  .Divider {
    margin: 0 10px;
  }

  .Label {
    font-weight: 500;
  }
}

.FolderBreadCrumbContainer {
  overflow: hidden;
  padding: 8px 0;

  .BreadCrumbFolderIcon {
    height: 12px;
    margin: 0 6px 0 0;
    width: 12px;
  }

  .FolderBreadCrumbDivider {
    color: $of-forest-green-03;
    font-weight: 400;
    margin: 0 8px 0 5px !important;
  }

  .RemoveDivider {
    display: none;
  }

  .Text {
    color: $of-forest-green-03;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.IsSearchedFolderBreadcrumb {
  align-items: center;
  color: $of-forest-green-03;
  display: flex;
  font-size: 0.75rem;
  line-height: 1.1;
  padding: 0;

  .FolderPathIcon {
    margin: 1px 4px 0 0;
    min-width: 12px;
  }

  .Text {
    display: inline-block;
    font-weight: 400 !important;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Divider {
    margin: 0 5px !important;
  }

  .RemoveDivider {
    display: none;
  }
}

.LinkButton {
  background-color: transparent;
  border: none;
  overflow: hidden;
  padding: 0;

  span {
    color: $of-forest-green-03;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.FolderName {
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FolderNameTooltip {
  word-break: break-all !important;
}

.Last {
  max-width: unset !important;
}

.WorkspaceIcon {
  margin: 0 6px 0 0;
  min-width: 12px;
}
