@import '~styles/base.scss';

.ContractInternalReminderEventsListContainer {
  padding: 0.5rem 1rem;
  color: $of-forest-green-01;

  .ContractReminderEventsListHeader {
    font-weight: 500;
    line-height: 22px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 3.8px;
  }
}

.ContractInternalReminderCards {
  overflow-y: auto;
  max-height: 350px;
  padding-right: 13px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $of-forest-green-03;
  }
}

.CreateInternalReminderFromContractCard {
  margin: 0.5rem 0;

  button {
    justify-content: flex-start;
    padding: 0.2rem 0.35rem !important;
    width: 100%;
    min-width: 180px;
    max-width: 185px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: $of-forest-green-01;
  }

  button:hover {
    text-decoration: underline;
    color: $of-dark-green-02;
  }
}

.InternalReminderTenseLabel {
  font-weight: 600;
  font-size: 11px;
  text-transform: capitalize;
  margin-bottom: -4px;
}

.PastInternalRemindersLabel {
  margin-top: 10px;
}
