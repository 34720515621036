@import 'styles/base.scss';

.CountBadge {
  position: absolute;
  top: 6px;
  right: 4px;
  background-color: $of-red-02;
  color: white;
  font-size: 10px;
  padding: 0 4px;
  line-height: 14px;
  min-width: 6px;
  border-radius: 7px;
  opacity: 1;
  transition: 0.5s ease;
  transition-delay: 1s;

  &.FadeOut {
    visibility: hidden;
    opacity: 0;
  }
}
