/* stylelint-disable */
@import '~styles/base.scss';

.TextStyles {
  color: $of-forest-green-01;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border-style: none;
  font-size: 14px;
  min-width: 120px;
  width: 120px;
  white-space: nowrap;
  background: none;

  .CurrentTextStyle {
    max-width: 71px;
    min-width: 71px;
  }

  &.IsSmall {
    min-width: 46px;
    width: 46px;

    .CurrentTextStyle {
      max-width: 17px;
      min-width: 17px;
    }
  }

  &:hover,
  &[aria-expanded=true] {
    border-style: none;
    background-color: $of-dark-green-06;
  }

  svg {
    order: 3;
    width: 12px;
  }

  &.Active {
    border-style: none;
    color: $of-orange-01;
    background-color: $of-orange-02;

    &:hover {
      background-color: $of-orange-03;
    }
  }

  &.Placeholder {
    pointer-events: none;
    user-select: none;
    background-color: white;
    color: $of-forest-green-05;
    border-style: none;

    &[aria-haspopup=menu]:last-child {
      color: $of-forest-green-05;
    }
  }
}

.TextStylesTooltip {
  div[data-tip=true] {
    margin-bottom: 0 !important;
  }
}

.TooltipText {
  text-align: start;
  width: auto;
  margin: 12px 2px 2px 2px !important;
}

.TextStyleMenuItem {
  margin: 3px 6px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: none;
    font-weight: inherit;
    margin: 6px 0;
  }

  h1 {
    font-size: 25px;

    &.Title {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
  }

  h6 {
    font-size: 14px;
    text-transform: uppercase;
  }

  blockquote {
    border-left: 2px solid #000;
    padding-left: 1.6em;
    font-style: italic;
    margin: 6px 0;
  }

  .Subtitle {
    color: $of-video-gray;
    font-size: 21px;
  }

  button:hover {
    background-color: $of-dark-green-06;
  }

  button:focus {
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-decoration: underline;
    }
  }
}

.ActiveTextStyle {
  background-color: $of-orange-02;
  color: $of-orange-01;
  border-radius: 3px;

  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $of-orange-01;
  }

  blockquote {
    border-left-color: $of-orange-01;
  }

  button {
    color: $of-orange-01 !important;

    &:hover {
      background-color: $of-orange-03;
    }
  }
}
