@import '~styles/base.scss';

.VideoModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;

  &.DisplayBlock {
    display: block;

    & > .Fade {
      animation-name: fadeIn;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
  }

  &.DisplayNone {
    display: none;
  }
}

.Backdrop {
  background: $of-gray;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.CloseButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -18px;
  right: -18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #000;
  border-radius: 50%;
  border: 2px solid white;
  -webkit-box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
}

.ModalContent {
  position: fixed;
  background: white;
  width: 86%;
  max-width: 966px;
  height: auto;
  max-height: 540px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: 4px solid #ffff;
  z-index: 1100;
}

.ContentFluid {
  height: 72%;
}

.RelativeWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
