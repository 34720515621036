@import '~styles/base.scss';

.EmptyState {
  align-items: center;
  box-sizing: border-box;
  color: $of-winter-green-dream;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  height: 100%;
  justify-content: center;
  line-height: 1.026rem;
  padding: 38px;
  text-align: center;

  &Mobile {
    position: static;
    transform: none;
  }

  > span:not(:last-child) {
    font-size: 1.05rem;
    font-weight: 500;
    line-height: 1.232rem;
    margin-bottom: 10px;
  }
}
