@import '~styles/base.scss';

.Edit {
  justify-self: flex-end;
  position: absolute;
  color: $of-forest-green-01;
  display: flex;
  margin-right: 10px;
  margin-top: 14px;
  visibility: hidden;
}

.FieldContainer {
  height: 100%;
  position: relative;
  width: 100%;
  border-radius: 8px;

  &:hover .Editable {
    background-color: $of-edit-hover;
    border-radius: 8px;

    .Edit {
      visibility: visible;
    }
  }

  .Flex {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}

.FieldWrapper {
  display: grid;
}

.FieldSize20 {
  width: 20% !important;
}

.FieldSize40 {
  width: 40% !important;
}

.FieldSize50 {
  width: 50% !important;
}

.FieldSize60 {
  width: 60% !important;
}

.FieldSize80 {
  width: 80% !important;
}

.FieldSize100 {
  width: 100% !important;
}
