@import '~styles/base.scss';

.Section {
  margin-bottom: 21px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  gap: 10px;
}

.SmallerGap button:nth-child(2) {
  padding: 0;
}

.ConfirmationArea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 1rem;
  min-height: 44px;

  .ConfirmationActionButtons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    button:nth-child(2) {
      padding: 0;
    }
  }
}
