@import '~styles/base.scss';

.InfoBoxClipboard {
  word-wrap: break-word;

  .InfoBoxValue {
    font-size: 1rem;
  }

  .CopyButton {
    padding: 0;
    margin-left: 0.5rem;
    display: inline;
    border: none;
    vertical-align: top;

    &:hover {
      color: black;
    }
  }

  .CopyCheck {
    margin-left: 0.5rem;
    margin-right: 2px;
    vertical-align: top;
  }
}
