@import '~styles/base.scss';

.DialogOverlay {
  // Styles copied from radix site
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 0;
  z-index: 1;
  display: grid;
  place-items: center;
  overflow-y: auto;
  background-color: transparent;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  padding: 0;

  // Styles copied from radix site
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px, hsl(206 22% 7% / 20%) 0 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  min-width: 352px;
  max-width: 450px;
  max-height: 85vh;
}

.DialogContent:focus {
  outline: none;
}

.PopoverContent {
  padding: 0;
  min-width: 352px;
  min-height: 36px;
}
