@import '~styles/base.scss';

.CalendarInfo {
  border-radius: 20px;
  font-size: 10px;
  padding: 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: -10px;

  .Apply {
    color: $of-dark-green-02;
    font-size: 10px;
    padding: 0 0.4rem;
    text-decoration: underline;

    &:hover {
      color: $of-dark-green-01;
    }
  }
}

.NavigationButtonPrev {
  &:hover {
    color: $of-dark-green-02;
  }
}

.NavigationButtonNext {
  &:hover {
    color: $of-dark-green-02;
  }
}
