@import '~styles/base.scss';

.Branch {
  color: $of-forest-green-01;
  list-style: none;

  .List {
    margin: 0;
    padding: 0;
  }
}

.Container {
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 34px;
  justify-content: space-between;
  padding-left: 8px;

  &:focus-visible {
    outline: none;
  }

  .Chevron {
    align-items: center;
    display: flex;
    padding-right: 8px;

    .Closed {
      transform: rotate(0deg);
      transition: transform 0.2s;
    }

    .Open {
      transform: rotate(90deg);
      transition: transform 0.2s;
    }
  }

  .Icon {
    align-items: center;
    display: flex;
    margin-right: 4px;
  }

  .LeftSide {
    display: flex;
    flex-direction: row;
    max-width: calc(100% - 48px);
    width: 100%;
  }

  .Placeholder {
    margin-right: 8px;
    min-width: 10px;
  }

  .FolderName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .FolderNameDisabled {
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    .FolderName {
      text-decoration: underline;
      user-select: none;
    }

    div:last-child {
      visibility: visible;
    }
  }
}

.FolderNameTooltip {
  word-break: break-all !important;
}

.Active {
  background: $of-dark-green-05;
}

.EmptyState {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 8px 8px 0;

  .AddFolderButton {
    display: flex;
    justify-content: flex-start;
    padding: 8px 0 0 0;
  }
}

.Edit {
  align-items: center;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: 4px 0 4px 26px;
  width: calc(100% - 18px);

  .Icon {
    align-items: center;
    display: flex;
    margin-right: 4px;
  }
}
