.ExtensionContentHeader {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ExtensionContentBody {
  margin-top: 0;
  padding-bottom: 10px; /* padding to avoid margins from collapsing */

  .FaqContainer {
    margin-bottom: 10px;

    .FaqQuestion {
      font-weight: 600;
    }
  }

  .FaqContainer:last-child {
    margin-bottom: 0;
  }
}
