@import '~styles/base.scss';

.Row {
  display: flex;
  margin: 0.25rem 0;

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;

    > * + * {
      margin-left: unset;
    }
  }
}

.Divider {
  margin-bottom: 1rem;
}

.MessageContainer {
  display: flex;
  align-items: center;

  > h1 {
    font-weight: 500;
    font-size: 1.125rem;
  }

  > div {
    margin-bottom: 6px;
    margin-left: 8px;
  }

  .TooltipMessage {
    font-size: 12px;
    padding: 10px !important;
    background-color: $of-forest-green-01 !important;
    color: white !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  .PrivateLabel {
    color: white;
    border-radius: 20px;
    background: $of-forest-green-03;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 16px;
    line-height: 14px;
    text-align: center;
    cursor: default;
  }
}

.ColleagueContainer {
  margin-bottom: 1.5rem;
}
