@import '~styles/base.scss';

.Confirmation {
  text-align: center;

  .TextBox {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 4px 0 26px;

    .Paragraph {
      line-height: 1.2rem;
      margin: 0;
    }
  }
}

.Heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  margin: 0;
}

.SubHeading {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  margin: 26px 0 0;
}

.SuccessCheckAnimation {
  z-index: 2;
  position: relative;
  animation: fadeIn 0.3s ease;
  margin: auto;
  margin-top: 0.2rem;
  margin-bottom: 1em;
  width: 99px;
  height: 99px;
  background-image: url('./success-check.gif');
  background-size: 99px;
}
