@import '~styles/base.scss';

.Container {
  display: inline-block;
}

.DateTooltip {
  padding: 6px !important;
  background-color: $of-forest-green-01 !important;
  color: white !important;
  margin: 1px !important;
}
