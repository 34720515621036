@import '~styles/base.scss';

@mixin redBackgroundColor {
  background-color: $of-red-05 !important;
  transition: background-color 0.3s ease-in-out;
}

@mixin yellowBackgroundColor {
  background-color: rgba($color: $of-yellow, $alpha: 0.6) !important;
  transition: background-color 0.3s ease-in-out;
}

.Leaf {
  white-space: pre-wrap;
}

.VoidLeaf {
  color: $of-red-02 !important;
  text-decoration-line: line-through;
  user-select: none;
}

.VoidLeafWithFocusedParent {
  color: $of-red-02;
  text-decoration-line: line-through;
  user-select: none;
}

.Suggestion {
  color: $of-red-02 !important;
  text-decoration-line: line-through;
}

.SuggestionWithFocusedParent {
  color: $of-red-02;
  text-decoration-line: line-through;
}

a {
  .SuggestionWithFocusedParent {
    color: unset;
  }
}

:global(.focused-comment) {
  .Leaf {
    span[data-slate-string=true] {
      @include yellowBackgroundColor;
    }
  }
}

:global(.focused-suggestion) {
  .Leaf {
    span[data-slate-string=true] {
      @include redBackgroundColor;

      color: unset !important;
    }
  }
}
