@import '~styles/base.scss';

.FeatureListContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 $of-pink-02;
  border-radius: 10px;
  background-color: $of-pink-01;
  max-width: 576px;
  max-height: 248px;
  padding: 20px 0 20px 20px;
  margin-top: 15px;

  .Header {
    font-size: 18px;
    color: $of-forest-green-01;
    font-weight: 500;
    margin: 0;
  }

  .FeatureList {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    padding: 0 0 0 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
