@import '~styles/base.scss';

.Seats {
  display: flex;
  flex-direction: column;

  .IconContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 0.8rem;
  }

  .TextBoxCentered {
    text-align: center;
  }

  .TextBox,
  .TextBoxCentered {
    p {
      margin: 0.3rem 0;
    }
  }

  .PriceContainer {
    margin-top: 0.8rem;

    .AccountSeatsField {
      width: 85px;
      white-space: nowrap;
    }
  }

  .Container,
  .PriceContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $of-cloud;
  }

  .Amount {
    display: flex;
    align-items: center;
    min-width: 230px;
    justify-content: flex-end;
  }

  .DiscountBubble {
    margin-right: 20px;
    background: $of-green-03;
    color: $of-green-08;
    padding: 5px 17px;
    border-radius: 27px;
  }

  .DiscountExplanation {
    text-align: right;
    font-size: 12px;
    min-height: 20px;
  }

  .PriceInfo {
    padding: 0.75rem 0;
  }

  .PriceTitle,
  .PriceAmount {
    margin: revert;
  }

  .PriceAmount {
    min-width: 160px;
    text-align: right;
  }
}

.Buttons {
  display: flex;

  & > * {
    margin-left: 0.3rem;
  }
}

.BillingPeriod {
  display: flex;
  align-items: center;
  min-height: 30px;

  & > * {
    margin-right: 8px;
  }
}
