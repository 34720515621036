@import '~styles/base.scss';

.InfoBox {
  width: 100%;

  & > * {
    background-color: $of-green-07 !important;
    margin-bottom: 0;
  }
}

.SignContractBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.Loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CounterpartyNames {
  padding: 0 0 1rem 0;
  margin: 0;
}

.CounterpartyName {
  font-weight: 600;
  line-height: 1.4rem;
}

.DisclaimerMessage {
  line-height: 1.4rem;
  margin: 0;
  text-align: center;

  &Header {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.02em;
    text-align: center;
  }
}

.AttachmentName {
  padding-right: 0.4rem;
}

.AttachmentExtension {
  padding-right: 0.4rem;
  font-weight: bold;
  text-transform: uppercase;
}

.ConfirmationMessage {
  padding: 10px;
  margin: 0;
}

.AttachmentSignatureList {
  width: 100%;

  ul {
    padding: 8px 32px;
    list-style: inherit;
    columns: 2;
  }

  li {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.02em;
    list-style-position: inside;
    text-indent: -16px;

    &:nth-child(odd) {
      margin-right: 10px;
    }
  }
}

ul.LegalInformtion {
  list-style-position: inside;
  margin: 0;
  list-style-type: none;
  padding: 0;

  .LegalItem {
    --line-height: 1.5rem;

    line-height: var(--line-height);
    display: flex;

    svg {
      height: var(--line-height);
      width: 14px;
      color: inherit;
      margin-right: 9px;
      flex-shrink: 0;
    }

    ul {
      margin-left: 1.5rem;
      list-style-type: circle;
    }

    strong {
      word-break: break-word;
    }
  }
}

.CircularProgressbarContainer {
  height: 80px;
  width: 80px;
}
