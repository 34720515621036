@import '~styles/base.scss';

.DurationBoxContainer {
  padding: 0 20px;
  color: var(--formatting-font-color);
  font-family: var(--formatting-font-family);
  font-size: var(--formatting-font-size);
}

.EmptyIcon {
  width: 14px;
  height: 14px;
}

.DurationBoxPadding {
  padding: 0 30px;
}

.RecurringWithInitialDuration {
  white-space: nowrap;
}
