/* stylelint-disable unit-no-unknown */

:root {
  // Collapsed document layout
  --collapsed-document-height: 100dvh;
  --collapsed-document-bottom-navigation-height: 68px;
  --collapsed-document-top-bar-height: 60px;
  --collapsed-document-toolbar-height: 48px;

  //Freemium banner
  --freemium-banner-height-expanded: 40px;
  --freemium-banner-height-collapsed: 60px;
  --freemium-banner-shadow-margin-collapsed: 1px;

  // Calculations
  --collapsed-document-body-height: calc(var(--collapsed-document-height) - var(--collapsed-document-bottom-navigation-height)); //height of screen minus the bottom navigation
  --collapsed-document-body-height-with-banner: calc(var(--collapsed-document-height) - var(--collapsed-document-bottom-navigation-height) - var(--freemium-banner-height-collapsed) - var(--freemium-banner-shadow-margin-collapsed)); //height of screen minus the bottom navigation, and freemium banner - shadow margin
  --collapsed-document-body-with-toolbar-height:
    calc(
      var(--collapsed-document-height) - var(--collapsed-document-bottom-navigation-height) - var(--collapsed-document-top-bar-height) - var(--collapsed-document-toolbar-height)
    );
  --collapsed-document-body-without-toolbar-height: calc(var(--collapsed-document-height) - var(--collapsed-document-bottom-navigation-height) - var(--collapsed-document-top-bar-height));

  // Fixed layout variables
  --sidebar-width: 360px;
  --topbar-height: 68px;
  --document-state-banner-height: 40px;
}
