@import '~styles/base.scss';

.DocumentActionsContainer {
  border: 0;
  background-color: transparent;
  padding: 13px;
  display: flex;
  width: 42px;
  height: 42px;
  font-size: 0.875rem;
  color: $of-forest-green-01;
  transition: ease-in-out 100ms;
  align-items: center;

  &.Open,
  &:hover {
    background-color: $of-inactive;
    border-radius: 8px;
  }
}

.DocumentActionsTrigger {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 42px;
  height: 42px;
}

.Icon {
  display: flex;
  width: 10px;
  height: 10px;
  padding: 6px;
  align-items: center;
}
