.ProductTablePopup {
  background: #fff;
  max-width: 365px;
  max-height: 85vh;
  overflow-y: auto;
}

.TriggerButton {
  padding: 0;
  margin: 0;
  word-break: break-all;
  white-space: normal;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;

  svg {
    vertical-align: baseline;
  }

  &:disabled {
    cursor: default;
  }
}

@media (max-width: 365px) {
  .ProductTablePopup {
    min-width: 95%;
  }
}
