.SendButton {
  padding: 0 16px;
  height: 42px;
  font-weight: 500;

  [class*=label] {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.875rem;
  }
}
