@import "styles/base.scss";

.Content {
  padding: 0 1rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 46px;
  border-radius: 6px;
}

.Item[data-selected="true"] .Content {
  background-color: $of-inactive;
}
