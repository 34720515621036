@import '~styles/base.scss';

.Topbar {
  border-bottom: 1px solid $of-forest-green-05;
  box-sizing: border-box;
  display: flex;
  gap: 32px;
  height: calc(var(--topbar-height));
  padding: 10px 20px 10px 8px;
  background-color: white;
  align-items: center;

  .RightSide {
    margin-left: auto;
    gap: 10px;
    display: flex;
    align-items: center;
  }
}
