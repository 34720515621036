@import '~styles/base.scss';

.MyProfileResponsive {
  margin-top: 13px;
  margin-bottom: 21px;
  padding: 0 3px;
  position: relative;
  // sidebar submenu z-index is 2, this needs to be above that
  z-index: 3;

  &.Mobile {
    margin-bottom: 13px;
  }

  .ButtonResponsive {
    border: 1.6px solid #b0bcc1;
    font-size: 1.1rem;
    height: 42px;
    min-width: 42px;

    &:focus-visible {
      outline: 1px solid white;
      outline-offset: 2px;
    }

    [class*=label]:has(svg) {
      display: flex;
    }

    &:has([class*=label]>svg) {
      border: unset;
    }
  }

  .EmptyCircle {
    background: $of-red-02;
    border-radius: 50%;
    bottom: 51px;
    color: $of-red-02;
    font-size: 11px;
    font-weight: 600;
    height: 16px;
    left: 33px;
    position: absolute;
    text-align: center;
    width: 16px;
  }

  .MyProfileButton {
    align-items: center;
    border-radius: 8px;
    color: #b0bcc1;
    cursor: pointer;
    display: flex;
    height: 42px;
    justify-content: flex-start;
    width: 100%;

    .TextWrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 13px;
    }

    .Fullname {
      font-size: 15px;
      line-height: 130%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      white-space: nowrap;
      width: 135px;
    }

    .MyProfileButtonText {
      color: rgba(176, 188, 193, 0.7);
      font-size: 14px;
      line-height: 130%;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .DropdownResponsive {
    background-color: white;
    border-radius: 8px;
    bottom: 20px;
    box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
    box-sizing: border-box;
    left: 72px;
    margin-left: 16px;
    position: absolute;
    transition: all 250ms ease-in-out;
    width: 285px;
    z-index: 1;

    @include respondTo(sm, min) {
      position: fixed;
    }

    &.Expanded {
      margin-left: 160px;
    }

    &.Mobile {
      margin-left: 5px;
      left: 0;
      border: $of-forest-green-02 1px solid;
    }

    .ArrowResponsive::after {
      bottom: 14px;
      left: -20px;
      border: solid transparent;
      content: " ";
      position: absolute;
      pointer-events: none;
      border-bottom-color: white;
      border-width: 10px;
      transform: rotate(-90deg);
    }

    .ArrowMobile::after {
      bottom: -20px;
      left: 20px;
      transform: rotate(-180deg);
    }

    .Header {
      background-color: $of-forest-green-01;
      width: 285px;
      min-height: 102px;
      box-sizing: border-box;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      display: flex;
      color: white;

      .UserAreaResponsive {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        padding: 19px;
        flex: 1 1;

        .User {
          font-size: 20px;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;
          letter-spacing: -0.02em;
          text-align: left;
          white-space: nowrap;
          width: 260px;
        }

        .UserEmail {
          font-size: 14px;
          font-weight: 300;
          text-overflow: ellipsis;
          letter-spacing: -0.02em;
          text-align: left;
          max-width: 100%;
          overflow-wrap: break-word;
        }
      }
    }

    .Menu {
      padding: 8px 0;
      display: flex;
      flex-direction: column;

      .Divider {
        border-bottom: 1px solid $of-light-gray;
        margin: 8px 0;
      }
    }
  }
}

.AdminButtonResponsive {
  font-size: 1.1rem;
  width: 48px;
  height: 48px;
  border: 1.6px solid #b0bcc1;

  &:hover {
    background-color: $of-blue-02 !important;
  }
}
