@import '~styles/base.scss';

.Label {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: $of-forest-green-01;
}

.Icon {
  margin-left: 0.375rem;
  cursor: pointer;
}

.Description {
  margin-top: 0.25rem;
  color: $of-winter-green-dream;
}

.Divider {
  margin: 0;
  border-bottom-color: $of-dark-green-05;
}

.Disabled {
  color: $of-forest-green-04;
}
