.ProductPrice {
  word-break: break-word;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-start;
  line-height: 1.2;
  padding-top: 7px;
}

.Prices {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.DiscountedPrice {
  flex-basis: auto;
}

.BasePrice {
  flex-basis: auto;
}

.HasDiscount {
  & > * {
    text-decoration: line-through;
  }
}

.DiscountAmount {
  min-height: auto;
  flex-grow: 2;
  font-size: var(--document-seventy-percent-font-size);
  align-self: flex-end;
  text-align: right;

  &:first-child {
    padding-right: 4px;
  }
}
