@import "~styles/base.scss";

.FieldContainer {
  background-color: transparent;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px;

  .Wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Edit {
      color: $of-forest-green-01;
      display: flex;
      margin-bottom: 5px;
      visibility: hidden;
    }
  }

  &.Active {
    background-color: $of-edit-hover;

    .Edit {
      visibility: visible;
    }
  }

  &.Uneditable {
    cursor: default;
  }

  &:hover:not(.Uneditable) {
    background-color: $of-edit-hover;

    .Edit {
      visibility: visible;
    }
  }

  :global(.ReactSelect__indicators) {
    color: $of-forest-green-03;

    > div {
      padding: 0 8px !important;
    }
  }

  :global(.ReactSelect__option) {
    color: var(--formatting-font-color) !important;
    min-height: 27px;
    height: auto !important;
  }

  :global(.ReactSelect__option-selected) {
    color: $of-forest-green-01 !important;
  }

  :global(.ReactSelect__placeholder) {
    color: $of-winter-green-dream;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    font-family: var(--document-static-font-family);
  }

  :global(.ReactSelect__clearIndicator) {
    color: $of-winter-green-dream;
    display: flex;
  }

  :global(.ReactSelect__control:hover) {
    border-color: $of-forest-green-01 !important;
  }

  div[role="button"] {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &.Invalid {
    :global(.ReactSelect__control) {
      border-color: $of-red-02 !important;
      box-shadow: 0 0 0 1px $of-red-02;
    }
  }
}

.ValidationMessage {
  color: $of-red-02;
  font-size: 0.6875rem;
  line-height: 13px;
}
