.TableFiltering {
  display: flex;
  flex: 1;
  padding: 2rem 2rem 0 2rem;
  gap: 2rem;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;

    & > div:not(:last-child) {
      padding-right: 2rem;
    }
  }
}
