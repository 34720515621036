@import '~styles/base.scss';

.LaunchButton {
  background-color: $of-yellow;
  border-radius: 24px;
  width: 100%;

  &:hover,
  &:focus {
    box-shadow: 0 0 12px rgba(black, 0.4);
    background-color: $of-yellow-02;
  }

  > .icon {
    font-size: 14px;
    line-height: 130%;
    overflow: hidden;
  }
}

.CreateNewDocument {
  padding-right: 0;
}

.AddIcon {
  text-decoration: underline;
}
