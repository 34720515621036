@import '~styles/base.scss';

$big-play-button-size: 1.63332em;

.video-js .vjs-big-play-button {
  font-size: 5em;
  height: $big-play-button-size;
  width: $big-play-button-size;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
  margin-left: 0;
  border-radius: 50%;
}
