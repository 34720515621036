@import '~styles/base.scss';

.SectionContainer {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  width: 260px;
  box-sizing: border-box;
}

.LinkContainer:hover {
  text-decoration: none;
}

.InnerContainer {
  cursor: pointer;
  background: #fff;
  padding: 1rem;
  min-height: 150px;
  border: 2px $of-forest-green-05 solid;
  border-radius: 8px;
}

.LogoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  width: 100%;

  .LogoSquare {
    width: 75px;
  }

  .ActiveLabelContainer {
    min-height: 75px;

    .ActiveLabel {
      font-size: 12px;
      padding: 0.2rem 0.5rem;
      border-radius: 10px;
      background-color: $of-green-12;
      color: $of-green-13;
    }

    .EntitlementMissingLabel {
      font-size: 12px;
      padding: 0.2rem 0.5rem;
      border-radius: 10px;
      background-color: $of-yellow-02;
      color: $of-forest-green-01;
    }
  }
}

.ContentContainer {
  min-height: 55px;
}

.Title {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: $of-forest-green-01;
}

.SubTitle {
  font-size: 12px;
}

.FooterContainer {
  display: none; /* change to flex to display the tags */
  align-items: left;
}

.DetailsContainer {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $of-gray;
}

.ExtensionTag {
  font-size: 12px;
  margin-right: 0.5rem;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  background: $of-forest-green-05;
  color: $of-forest-green-01;
}
