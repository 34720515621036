@import '~styles/base.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Body {
  min-height: 100px !important;
  flex: 0 !important;
}

.BodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $of-forest-green-01;
  letter-spacing: 0.1px;

  .BodyContainerText {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: $of-forest-green-01;
  }

  .ConfirmText {
    font-size: 14px !important;
    margin-top: 8px;
    font-weight: 400;
  }

  .Title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .Name {
    margin-right: 4px;
    font-weight: 600;
  }

  .LastParticipant {
    font-weight: 600;
    margin-left: 4px;
  }
}

.ConfirmButton {
  margin: 0 auto;
}
