@import '~styles/base.scss';

:root {
  --disabled-gray: #d9e0e2;
  --disabled-green: #bde7d3;
  --focused-green: #14a05d;
}

// Toggle Indicator styles (the "inner" toggling-circle)
.Indicator {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), color 100ms ease-in-out;
  transform: translateX(3px);
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $of-green-01;

  svg {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  // Checked/on styles
  &[data-state=checked] {
    transform: translateX(19px);

    svg {
      opacity: 1;
      transition-delay: 0.1s;
    }
  }
}

// Toggle switch styles (the outer toggle-switch-wrapper)
.ToggleSwitch {
  all: unset;
  position: relative;
  background-color: $of-forest-green-04;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  width: 36px;
  height: 20px;
  transition: background-color 100ms ease-in-out, outline-color 100ms ease-in-out;
  border-radius: 30px;

  &:hover,
  &:focus {
    background-color: $of-winter-green-dream;
  }

  &:focus {
    outline: $of-forest-green-04;
    outline-style: solid;
    outline-offset: 1px;
  }

  &[disabled] {
    background-color: var(--disabled-gray);
    cursor: not-allowed;
  }

  // When the Toggle is checked/on
  &[data-state=checked] {
    outline-color: var(--focused-green);
    background-color: $of-green-01;

    &[disabled] {
      background-color: var(--disabled-green);
      color: var(--disabled-green);

      .Indicator {
        color: var(--disabled-green);
      }
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
      background-color: var(--focused-green);

      .Indicator {
        color: var(--focused-green);
      }
    }
  }
}

.HasFailed {
  background-color: $of-red-02;
  outline-color: $of-red-02;
}
