:root {
  --document-content-max-width: 840px;
  // Formatting variables

  /*
    These static variables should not be overwritten
  */
  --document-static-font-family: "Work Sans", "Helvetica Neue", helvetica, arial, sans-serif;
  --document-static-font-size: 0.875rem;
  --document-static-max-font-size: 24px;
  --document-static-min-font-size: 8px;

  /* 70% of the base font size. Clamp gives us a min, max and preference. */
  --document-seventy-percent-font-size: clamp(var(--document-static-min-font-size), 0.7em, calc(var(--document-static-max-font-size) * 0.7));

  /*
    Variables for formatting
    --formatting-font-family Overrides initial Proxima Nova from legacy bootstrap style which includes backups
  */
  --formatting-font-family: 'Proxima Nova', sans-serif;
  --formatting-font-color: black;
  --formatting-font-color-70-percent: rgba(0, 0, 0, 0.7); /* HEX to RGBA should be implemented along with the functionality of the formatting component */
  --formatting-font-size: 14px;
}
