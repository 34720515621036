@import '~styles/base.scss';

.NoActivityHeader {
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.NoActivityContent {
  line-height: 1.4;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0.5rem;
}

.ActionLinkContainer {
  line-height: 1.4;
  font-size: 14px;
  font-weight: 300;
  margin-top: 1rem;
}

.NoActivityLink {
  text-decoration: underline;
  color: $of-blue-02;

  &:hover {
    color: $of-blue-01;
  }
}

.NoActivityIconContainer {
  display: flex;
  background-color: $of-inactive;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
}
