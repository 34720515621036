@import '~styles/base.scss';

.DashboardDatePicker {
  background-color: white;
  padding: 16px 16px 0 16px;
  border-radius: 4px;
  height: 32px;

  &:hover {
    box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
  }
}
