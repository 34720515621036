@import '~styles/base.scss';

.InfoContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 42px;
  justify-content: space-between;
  max-width: 900px;
  color: $of-forest-green-01;
  padding: 0 32px 32px 32px;
}

.InfoBox {
  display: flex;
  flex-direction: column;
  min-height: 4.1rem;

  .InfoValue {
    color: $of-forest-green-03;
    font-size: 14px;
    font-weight: 400;
    margin-top: 17px;
  }
}

.Divider {
  margin: 0 32px 38px 32px;
}

.Divider.DividerWithMarginTop {
  margin: 38px 32px;
}
