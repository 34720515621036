@import '~styles/base.scss';

.CancellationReasonsContainer {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    color: $of-forest-green-01;
    font-weight: 600;
    margin: 0 20px 0 0;
    line-height: 20px;
  }
}
