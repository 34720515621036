@import '~styles/base.scss';

.MenuHidden {
  display: none;
}

.ActiveFontSize {
  background-color: $of-orange-02 !important;
  border-radius: 3px;

  button {
    color: $of-orange-01 !important;

    &:hover,
    &:active {
      background-color: $of-orange-03 !important;
    }
  }
}

.FontSizeMenuItem {
  margin: 3px 6px;

  button:hover {
    background-color: $of-dark-green-06;
  }
}

.CurrentFontSize {
  max-width: 16px;
  min-width: 16px;
}

.FontSizeSelector {
  color: $of-forest-green-01;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border-style: none;
  font-size: 14px;
  min-width: 46px;
  width: 46px;
  white-space: nowrap;
  background: none;

  &:hover,
  &[aria-expanded=true] {
    border-style: none;
    background-color: $of-dark-green-06;
  }

  svg {
    order: 3;
    width: 12px;
  }

  &.Active {
    border-style: none;
    color: $of-orange-01;
    background-color: $of-orange-02;

    &:hover {
      background-color: $of-orange-03;
    }
  }

  &.Placeholder {
    pointer-events: none;
    user-select: none;
    background-color: white;
    color: $of-forest-green-05;
    border-style: none;

    &[aria-haspopup=menu]:last-child {
      color: $of-forest-green-05;
    }
  }
}

.FontSizeSelectorInput {
  max-width: 38px;
  max-height: 24px;
  margin: 0 8px !important;
  border: solid 1px $of-forest-green-05 !important;
  font-size: 12px !important;
  color: $of-forest-green-01;
  box-shadow: none !important;
  text-align: center;
  padding: 0;

  /* Firefox */
  -moz-appearance: textfield !important;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  &input:focus {
    box-shadow: none !important;
  }
}

input {
  &.FontSizeSelectorInput {
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.FontSizeTooltip {
  div[data-tip=true] {
    margin-bottom: 0 !important;
  }
}

.TooltipText {
  text-align: start;
  width: auto;
  margin: 12px 2px 2px 2px !important;
}
