@import '~styles/base.scss';

:root {
  --table-row-with-bottom-border: rgba(103, 137, 148, 0.8);
  --hundredfifteen-percent-font-size: clamp(var(--document-static-min-font-size), 1em, calc(var(--document-static-max-font-size) * 1.15));
}

.ProductTableContainer {
  color: var(--formatting-font-color);
  font-size: var(--formatting-font-size);

  * {
    font-family: var(--formatting-font-family);
  }

  span[data-slate-placeholder=true] {
    color: black;
    font-family: var(--document-static-font-family) !important;
    font-size: var(--formatting-font-size);
    opacity: 1 !important;
  }

  .ColumnDisabled {
    border-radius: 2px;
    background: $of-forest-green-05;
    height: 15px;
    padding-inline: 0.25rem;
  }
}

.ProductLabel {
  background: $of-forest-green-05;
  margin: 0;
  border-radius: 2px;

  button {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    padding-block: 1rem;
    padding-inline: 0.25rem;
  }

  button h3 {
    font-size: var(--hundredfifteen-percent-font-size);
    color: var(--formatting-font-color);
    text-decoration-line: underline;
    font-weight: 700;
    text-align: left;
    margin: 0;
  }
}

.TableRow {
  padding-inline: 0.25rem;

  div::first-line {
    padding-top: 0;
  }

  &:not(:first-child) {
    padding-top: 1rem;
  }
}

.TableRowGuestView {
  padding-inline: 0.25rem;

  div::first-line {
    padding-top: 0;
  }

  &:not(:first-child) {
    padding-top: 0.6rem;
  }
}

.NameRow {
  font-size: var(--formatting-font-size);
  padding: 0.4rem 0;
  margin: 0;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;

  span {
    color: var(--formatting-font-color);
  }
}

.DataList {
  display: grid;
  grid-template-columns: auto 1fr;
}

.ProductTableItems {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
  padding: 0.5rem 0;

  @supports not (grid-template-columns: subgrid) {
    grid-template-columns: inherit;
  }

  .TableCellLabel {
    button {
      color: var(--formatting-font-color);
      font-size: var(--formatting-font-size);
      justify-items: start;
      text-align: left;
      font-weight: 700;
      line-height: 1.3rem; // to align with the product price
      letter-spacing: 0;
      text-decoration-line: underline;
    }
  }

  .TableCellValue {
    margin: 0; /* To overwrite browser default in document view */
    position: relative;
    display: grid;
    justify-items: end;
    align-items: center;
    font-weight: 400;
    line-height: 0.8rem;
    letter-spacing: 0;
    text-align: right;
  }

  .QuantityInputCell {
    height: 100%;
    display: flex;
    gap: 10px;

    /* For read only view */
    > div:empty {
      flex: 1;
    }
  }

  &:last-child {
    border-bottom: 1px solid var(--table-row-with-bottom-border);
    padding-bottom: 0.7rem;
  }
}

.TableCell {
  div::first-line {
    padding-top: 0 !important;
  }

  &:not(:first-child) {
    padding: 0.5rem 0;
  }
}
