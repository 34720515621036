@import '~styles/base.scss';

.DragHandle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 18px;
  height: 100%;
  background-color: $of-forest-green-05;
  border-radius: 6px 0 0 6px;
  transition: ease-in-out 100ms;

  & > * {
    margin-top: 13.5px;
  }
}

.SectionType {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  background-color: #fff;
  padding: 8px;
  border: solid 1px $of-forest-green-05;
  border-left-style: none;
  border-radius: 0 6px 6px 0;
  height: 100%;
  transition: ease-in-out 100ms;
  font-size: 0.875rem;

  svg {
    margin-left: 8px;
  }

  // Added this because the box would jump up and down if you were hovering the bottom of the box
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 100%;
    display: none;
  }

  &:hover::after {
    display: block;
  }
}

.SectionLabel {
  font-weight: 500;
  color: $of-forest-green-01;
}

.DraggableSection {
  display: flex;
  height: 40px;
  cursor: grab;
  word-break: break-word;
  transition: transform ease-in-out 100ms;
  position: relative;
  bottom: 0;

  &.Disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.Overlay {
    box-shadow: 0 10px 26px 0 #848c8f4a;
    border-radius: 6px;

    .SectionType,
    .DragHandle {
      background-color: $of-inactive;
      border-style: none;
    }
  }

  &.IsDragOverlayActive {
    opacity: 0.5;
  }

  &:hover:not(.Disabled) {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    transform: translateY(-2px);
  }

  &:hover:not(.Overlay, .NotAllowedToUpdateLayout) {
    .SectionType {
      background-color: $of-inactive;
    }

    &:not(.Overlay) {
      .SectionType {
        background-color: $of-inactive;
      }

      .DragHandle {
        background-color: $of-forest-green-04;
      }
    }
  }
}

.DragOverlay {
  cursor: grabbing;
}
