@import '~styles/base.scss';

.VideoActionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8125rem;
}

.VideoActionButtons.AgreementIsPublished {
  justify-content: flex-end;
}

.VideoActionButton {
  padding: 0;

  [class*='label'] {
    margin-left: 4px !important;
  }
}

.DeleteModalButton {
  color: $of-red-02 !important;
  font-size: 0.8125rem !important;

  svg {
    height: 12px;
    width: 12px;
  }
}

.AgreementVideoHelperText {
  color: $of-winter-green-dream;
  font-size: 0.8125rem;
}

.DeleteInfoText {
  margin-bottom: 20px;
}

.Label {
  color: $of-forest-green-01;
  font-size: 0.875rem;
  line-height: 16px;
  margin-bottom: 4px;
  font-weight: 600;
}
