@import '~styles/base.scss';

.SelectFieldLabel {
  font-family: var(--contract-static-font-family);
  font-weight: 600;
  font-size: var(--document-static-font-size);
  color: $of-forest-green-01;
}

.SelectOptionText {
  flex: 1;
}

.SelectOptionLabel {
  font-family: var(--contract-static-font-family);
  font-size: var(--document-static-font-size);
  font-weight: 600;
  color: $of-dark-green-01;
}

.SelectOption {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-block: 8px;
  padding-inline: 16px;
}

.SelectedIcon {
  display: none;
}

.SelectOptionDescription {
  display: block;
  padding-block-start: 4px;
  max-width: 200px;
  color: $of-winter-green-dream;
}

.SelectFieldTrigger {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 29px;
  border: 1px solid $of-forest-green-04;
  border-radius: 4px;
  background-color: white;
  padding-left: 2px;

  .SelectOption {
    align-items: center;
    padding-inline-start: 0;
    color: $of-forest-green-02;
  }

  .SelectOptionDescription {
    display: none;
  }

  &:focus {
    border: 1px solid $of-forest-green-01;
  }
}

.DownChevronIcon {
  padding-inline-end: 10px;
}

.SelectViewPort {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-block: 16px;
  padding-inline: 10px;
}

.SelectOptionIcon {
  padding-inline-start: 5px;
  color: $of-winter-green-dream;
  font-size: 0.75rem;
}

.SelectItem {
  --contrast-enhancing-text-color: #{$of-forest-green-02};

  padding-inline-end: 4px;
  border-radius: 6px;
  background-color: white;
  color: var(--contrast-enhancing-text-color);

  & .SelectOptionLabel {
    padding-inline-start: 0;
  }

  &:hover {
    background-color: hsl(197, 16%, 90%);
  }

  &:focus {
    background-color: hsl(197, 16%, 94%);

    &:hover {
      background-color: hsl(197, 16%, 88%);
    }
  }

  &[aria-selected="true"],
  &[data-state="checked"] {
    & .SelectedIcon {
      display: block;
      align-self: center;
    }
  }

  // aria-selected is true when option is selected and focused(also data-highlighted)
  // @see: https://github.com/radix-ui/primitives/blob/main/packages/react/select/src/Select.tsx#L1231-L1234
  &[aria-selected="true"] {
    background-color: hsl(197, 16%, 76%);

    --contrast-enhancing-text-color: hsl(180, 15%, 16%);
  }

  // data-state is 'checked' when the option is selected irrespective of the option is focused or not
  &[data-state="checked"] {
    background-color: hsl(197, 16%, 78%);

    --contrast-enhancing-text-color: hsl(180, 15%, 18%);

    &:hover {
      background-color: hsl(197, 16%, 84%);

      --contrast-enhancing-text-color: hsl(180, 15%, 28%);
    }

    &:focus {
      &:hover {
        background-color: hsl(197, 16%, 80%);

        --contrast-enhancing-text-color: hsl(180, 15%, 22%);
      }
    }

    // Keyboard arrow up and down (true when focused)
    &[data-highlighted] {
      background-color: hsl(197, 16%, 82%);
      // --contrast-enhancing-text-color: #274A53;

      --contrast-enhancing-text-color: hsl(180, 15%, 26%);
    }
  }

  // Keyboard arrow up and down
  &[data-highlighted] {
    background-color: hsl(197, 16%, 86%);

    --contrast-enhancing-text-color: hsl(180, 15%, 32%);
  }
}

.SelectContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 8px 10px 2px hsl(0, 0%, 45%);
  z-index: 1;
  min-width: var(--radix-select-trigger-width);
  max-width: var(--radix-select-trigger-width);
  max-height: var(--radix-select-content-available-height);
}
