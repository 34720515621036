@import '~styles/base.scss';

.ExpandableOptions {
  margin-bottom: 10px;

  .Hidden {
    display: none;
  }
}

.Divider {
  margin-bottom: 10px;
  border-bottom: 2px solid $of-forest-green-05;
}
