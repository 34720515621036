@import '~styles/base.scss';

.AddButton {
  padding: 0;
  font-size: 12px !important;
  line-height: 14px;

  .AddButtonIcon {
    margin-right: -0.5rem;
  }
}

.PopoverContent {
  padding: 0;
  z-index: 1040;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 280px;

  .Header {
    background-color: $of-forest-green-01;
    padding: 12px 16px;
    font-weight: 400;
    color: white;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .Body {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;

    .TagList {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: auto;
      height: 200px;

      [class*=Tag] {
        min-height: 22px;
      }
    }

    .EmptyState {
      padding: 0;
      height: 200px;
    }

    .LoadingSpinner {
      height: 10rem;
      align-self: center;
    }

    &.WithCreateTagButton {
      .CreateTagButton {
        margin-bottom: 12px;

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .TagList {
        height: 150px;
      }

      .EmptyState {
        height: 150px;
      }
    }
  }
}

.Footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $of-dark-green-05;
  padding: 16px 20px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: -0.02em;

  .ManageTags {
    display: inline-flex;
    color: $of-forest-green-01;
    align-items: center;

    .ExternalIcon {
      margin-left: 10px;
    }
  }
}

.ModalFooter {
  width: 100%;
}

.ModalBody {
  [class*=SearchField] {
    max-width: unset;
  }
}

.SearchFieldIcon {
  width: 42px;
  height: 18px;
}
