@import '~styles/base.scss';

$bubble-size: 46px;

:export {
  // Fill colors
  green: $of-contract-signed;
  red: $of-red-02;
  white: #fff;
  purple: $of-purple-03;
  // Border colors
  lightgreen: $of-green-03; //Signed
  lightred: $of-red-05; //Declined
  lightblue: $of-blue-05; //Pending
  orange: $of-orange-02; //Overdue
  gray: $of-dark-green-05; //Draft
}

.Bubble {
  display: flex;
  align-items: center;
  color: white;
  width: $bubble-size;
  font-size: 1.1rem;

  .Action {
    width: $bubble-size;
    margin-left: -$bubble-size;
    margin-top: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
