.PopoverContentContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 9999 !important;
  }
}

.PopoverTrigger {
  width: 100%;
  justify-content: flex-start;
}
