.Modal {
  min-width: auto;
  text-align: center;

  &.ModalSmall {
    min-height: auto;
    width: 400px;
    text-align: start;
  }

  .ModalBody {
    min-height: auto;
  }
}

.ActionsContainer {
  display: flex;
  justify-content: flex-end;
}

.SaveButton {
  flex-grow: 0;
  font-size: 14px;
}

.WarningIconContainer {
  margin: 34px 0;

  svg {
    height: 74px;
    width: 74px;
  }
}
