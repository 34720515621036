.VideoPlayer {
  display: flex;
  background-color: #f0f1f7;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.VideoElement {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
}
