@import '~styles/base.scss';

.Dropzone {
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  color: $of-forest-green-01;
  cursor: pointer;
  font-family: var(--contract-static-font-family);

  * {
    pointer-events: none;
  }

  &.isDragActive {
    background-color: $of-edit-hover;
    cursor: pointer;
  }
}

.DashedBorder {
  border: 1px dashed $of-forest-green-04;

  &:hover {
    border-color: $of-yellow-04;
    background-color: $of-edit-hover;
    cursor: pointer;
  }
}

.DropzoneErrors {
  border: 1px solid $of-red-02;
}

.HiddenDropzone {
  cursor: auto;
}

.ApiErrors {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  animation: snackbar 1s forwards;
}

@keyframes snackbar {
  from {
    top: -129px;
  }

  to {
    top: 58px;
  }
}
