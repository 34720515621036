.TooltipMessage {
  font-size: 0.75rem;
}

.PublishButton {
  height: 42px;
  padding: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
