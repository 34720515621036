@import '~styles/base.scss';

.Container {
  color: $of-forest-green-01;
  margin-top: -32px;
  padding: 0 32px;

  .PlanTitle {
    font-size: 24px;
    margin: 0 0 4px;
  }

  .PlanInfo {
    font-weight: 500;
    max-width: 283px;
  }

  .UpgradeButton {
    margin-top: 24px;
    padding: 8px 14px;
  }

  .Divider {
    margin: 38px 0;
  }

  .Title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 12px;
  }

  .Column {
    display: flex;
    flex-direction: column;
  }
}
