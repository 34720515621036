@import '~styles/base.scss';

.Tag {
  display: inline-flex;
  flex-direction: row-reverse;
  color: $of-forest-green-01;
  margin: 2px 6px 2px 0;
  border-radius: 30px;
  border: 1px solid $of-forest-green-05;
  height: 22px;
  overflow: hidden;
  min-width: 32px;
  box-sizing: border-box;
  align-items: center;

  &.WithOverflow {
    .Link {
      display: flex;
      overflow: hidden;
    }
  }

  &.LabelSpace {
    margin: 0 0 10px 6px;
  }

  .RemoveTag {
    display: flex;
    border: none;
    padding: 0 4px 0 1px;
    margin-top: 1px;
    color: transparent;
    transition: padding-right 100ms ease-out;
  }

  &:hover {
    border-color: $of-forest-green-04;
    gap: 4px;

    .RemoveTag {
      color: $of-forest-green-01;
      padding-right: 8px;
    }

    .Link {
      text-decoration: none;
    }
  }

  .SystemTagIcon {
    font-size: 1rem;
    align-self: center;
    margin-right: 0.25rem;
    margin-left: 12px;
  }

  .TagLabel {
    button {
      padding-top: 0;
      border: none;
    }

    .SystemTagIcon {
      vertical-align: middle;
    }
  }

  .TagName {
    color: $of-forest-green-01;
    display: inline-flex;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin: 0;
    padding: 4px 0 4px 13px;
    line-height: 14px;
    text-align: left;
    box-sizing: border-box;
    font-weight: 500;

    &.IsNotClickable {
      cursor: default;
    }

    &.IsNotRemovable {
      padding-right: 12px;
    }

    &:hover {
      color: $of-forest-green-01;
    }
  }

  &.System {
    border: 1px solid $of-forest-green-01;

    &:hover {
      border-color: $of-forest-green-04;
    }
  }
}
