@import '~styles/base.scss';

.ParticipantState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: $of-forest-green-04;

  &.WithBadge {
    width: 36px;
    height: 36px;
    border: 2px solid $of-forest-green-05;
    border-radius: 20px;
    color: white;

    &.SignOrder {
      color: $of-forest-green-01;

      &.Signed {
        color: white;
      }

      &.Declined {
        color: white;
      }
    }

    &.ApproveOrder {
      color: $of-forest-green-01;
    }
  }

  &.Declined {
    background-color: $of-red-02;
    border-color: $of-red-08;
  }

  &.Signed {
    background-color: $of-contract-signed;
    border-color: $of-sign-border;
  }

  .HasNoAccess {
    color: $of-red-02;
  }

  .IsImport {
    color: $of-forest-green-01;
  }
}
