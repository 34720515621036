@import '~styles/base.scss';

.InsightsButton {
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 16px;
  padding: 9px 10px;
  height: 22px;
  font-size: 12px;
  font-weight: 500;

  &.Critical {
    border-color: $of-red-02;
    color: $of-red-02;

    &:hover {
      border-color: $of-red-01;
    }
  }

  &.Warning {
    border-color: $of-orange-01;
    color: $of-orange-01;

    &:hover {
      border-color: $of-orange;
    }
  }

  &.Passed {
    color: $of-dark-green-01;

    svg {
      color: $of-contract-signed;
    }
  }

  &.Loading {
    color: $of-blue-02;

    span {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    svg {
      color: $of-blue-02;
    }
  }

  &.Passed,
  &.Loading {
    border: 1px solid $of-forest-green-05;

    &:hover {
      border-color: $of-forest-green-04;
    }
  }
}

.TooltipText {
  font-size: 12px;
}
