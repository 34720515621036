@import "~styles/base.scss";

.Footer {
  background: rgb(249, 249, 248);
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  border-top: 1px solid $of-forest-green-05;
  gap: 8px;
  color: $of-winter-green-dream;
  font-size: 0.75rem;
  min-height: 30px;
}

.Left {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
}

.Right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Button {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  background-color: transparent;
  height: 100%;
  padding: 0 8px;

  .Icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid rgba(55, 53, 47, 0.16);
  }

  &:hover {
    background-color: rgba(55, 53, 47, 0.08);
  }

  &.Active {
    .Icon {
      background: rgb(45, 45, 46);
    }
  }
}

.Error {
  color: $of-red-02;
}
