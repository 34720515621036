@import "~styles/base.scss";

.InputContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  height: 42px;
}

.HiddenInput {
  // remove the `!important` when legacy is gone, it should work without it
  display: none !important;
}

.Input {
  flex: 1;
  background: transparent;
  color: $of-forest-green-01;
  border: none !important;
  // remove the `!important` when legacy is gone, it should work without it
  outline: none !important;
  // TODO: remove box-shadow when legacy is gone
  box-shadow: none !important;
  // to undo the default styles we have in legacy, remove after legacy is gone
  min-height: unset;
  // to undo the default styles we have in legacy, remove after legacy is gone
  margin: 0 !important;
  padding: 4px 0;
  font-size: 0.875rem !important;
  line-height: 21px;
  resize: none;

  &::placeholder {
    color: #7f969d;
  }

  &:focus {
    // remove the `!important` when legacy is gone, it should work without it
    box-shadow: none !important;
  }
}

.IconContainer {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  pointer-events: none;
  height: var(--input-min-height);
}

.Icon {
  pointer-events: none;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: var(--input-min-height);
}

.SeparatorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 1px;
  height: 100%;
  flex: 0 0 auto;
  padding: 8px 14px 8px 10px;

  .Separator {
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(55, 53, 47, 0.16);
  }

  &.Hidden {
    display: none;
  }
}

.PaginationContainer {
  display: flex;
  align-items: center;
  margin-left: -2px;
  margin-right: -2px;

  .PaginationButton {
    user-select: none;
    cursor: pointer;
    background: transparent;
    transition: background 20ms ease-in 0s;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 4px;
    padding: 0;
    margin-left: 2px;
    margin-right: 2px;
    border: none;
    outline: none;
    color: rgba(55, 53, 47, 0.45);

    &:hover {
      background: rgba(55, 53, 47, 0.08);
    }

    &:active {
      background: rgba(55, 53, 47, 0.16);
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .Rotate {
    transform: rotate(180deg);
  }

  .PaginationText {
    font-size: 12px;
    line-height: 16px;
    color: rgba(55, 53, 47, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-variant-numeric: tabular-nums;
  }
}

.ActionButton {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubmitButton {
  width: 18px;
  height: 18px;
  margin: 0;
  border-radius: 50%;
  transition: color 20ms ease-in 0s, background 20ms ease-in 0s;

  &.SubmitButton {
    padding: 0 !important;
  }
}
