@import '~styles/base.scss';

.BulkActions {
  display: flex;
  padding: 0 2rem;
  align-items: center;
  height: 20px;
  color: $of-forest-green-01;

  .Actions {
    margin: 0 0.8rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
  }
}

.Spacing {
  margin-top: 1rem;
}
