@import '~styles/base.scss';
@import './utility-styles.scss';

.SigningPeriodContainer {
  position: relative;
  margin-left: -10px;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 6px;

  .EditIcon {
    position: absolute;
    display: none;
    top: 8%;
    left: 92%;
  }

  &:hover {
    background-color: $of-edit-hover;
    cursor: pointer;

    .EditIcon {
      display: block;
    }
  }
}

.SigningPeriodHeader {
  font-size: 0.875rem;
  font-weight: 600;
  color: $of-forest-green-01;
}

.RemainingDays {
  padding-block: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: $of-forest-green-01;
  font-size: 0.875rem;
}

.DaysCount {
  display: inline-grid;
  place-content: center;
  box-sizing: border-box;
  border: 1px solid $of-forest-green-04;
  border-radius: 4px;
  margin-right: 8px;
  padding-inline: 0.25rem;
  min-width: 30px;
  width: fit-content;
  height: 30px;
  background-color: #fff;
}

.NoExpiryDaysCount {
  border: none;
  color: $of-forest-green-04;
  background-color: $of-inactive;
}

.ExpiryInfo {
  border-radius: 6px;
  width: 100%;
  color: $of-winter-green-dream;
  padding-bottom: 8px;
  font-size: 0.8125rem;
}

.Bold {
  font-weight: 500;
}

.DatePassed {
  color: $of-red-02;
}

.PopupHeader {
  border-radius: 8px 8px 0 0;
  padding: 10px;
  background-color: $of-forest-green-01;
  color: white;
}

.DatePicker {
  :global(.DayPicker) {
    box-shadow: none;
  }

  :global(.DayPicker__withBorder) {
    box-shadow: none;
  }
}

.DatePickerMonthElement {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  select {
    height: 33px;
    border: 1px solid $of-forest-green-05;
    padding: 4px 6px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

.SigningPeriodForm {
  .Validation {
    display: none;
  }

  &:invalid {
    .Validation {
      display: block;
      color: $of-red-02;
    }

    input {
      border-color: $of-red-02;
    }
  }
}

.ExpiryContainer {
  padding: 16px;
  min-height: 142px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > fieldset {
    border: 0;
    padding: 0;
  }
}

.ExpiryTypeContainer {
  display: flex;
  gap: 10px;

  input[type="number"] {
    // sibling label that wraps select field has context box
    box-sizing: content-box;
    margin-bottom: 0;
    width: 85px;
    height: 32px;
    border: 1px solid $of-forest-green-05;
    border-radius: 4px;
    padding: 4px 8px;

    &[hidden] {
      display: none;
    }

    &:focus {
      border-color: $of-forest-green-01;
      box-shadow: 0 0 2px rgba(4, 4, 71, 0.08);
    }

    &:focus-visible {
      outline: 0;
    }
  }

  .DisabledOption {
    cursor: not-allowed;
    background: #fff;
    color: #cacccd;
  }

  // Specific listbox inside the top level container ReactSelect
  // With id starts with react-select and
  // Ends with listbox with ReactSelect__menu class
  :global(.ReactSelect .ReactSelect__menu[id^="react-select"][id$="listbox"]) {
    z-index: 99999;
  }
}

.ActionButtonsContainer {
  display: flex;
  justify-content: flex-end;
}
