@import '~styles/base.scss';

.Divider {
  margin: 24px 0;
  border-bottom-color: $of-dark-green-05;
}

.mb-\[14px\] {
  margin-bottom: 14px;
}

.mt-\[16px\] {
  margin-top: 16px;
}

.SectionSeparatorCustomMargin {
  margin-top: 1.125rem;
}

.SigningAndSecurityContainer {
  :global(.ReactSelect__single-value--is-disabled),
  :global(.ReactSelect__indicators) {
    color: $of-forest-green-04 !important;
  }
}

.Disabled {
  color: $of-forest-green-04;
}
