/* stylelint-disable no-descending-specificity */
@import "~styles/base.scss";

.InputContainer {
  display: flex;
  height: auto;
  flex-direction: column;
  gap: 4px;

  .TextArea {
    border-radius: 6px;
    border: 1px solid $of-forest-green-05;
    box-shadow: none !important;
    box-sizing: border-box;
    color: var(--formatting-font-color);
    font-family: var(--formatting-font-family);
    font-size: var(--formatting-font-size);
    line-height: 1 !important;
    margin: 0;
    min-height: 100px;
    overflow-wrap: break-word;
    overflow: hidden;
    padding: 13px;
    resize: none !important;
    transition: all 100ms;
    width: 100%;

    &:active,
    &:focus-within,
    &:focus,
    &:focus:invalid:focus:invalid,
    &:hover {
      border: 1px solid $of-forest-green-01;
      color: var(--formatting-font-color);
      border-color: $of-forest-green-01;
    }

    &:focus-visible {
      outline: unset;
    }

    &::placeholder {
      color: $of-winter-green-dream;
    }
  }

  .ValidationMessage {
    color: $of-red-02;
    font-size: 0.6875rem;
    line-height: 13px;
  }

  &.Invalid {
    .TextArea {
      &,
      &:focus,
      &:focus:invalid:focus:invalid {
        border-color: $of-red-02;
        box-shadow: 0 0 0 1px $of-red-02 !important;
      }
    }
  }
}
