@import '~styles/base.scss';

.MyProfileItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 20px;
  color: $of-forest-green-01;
  border: none;
  line-height: 1.4;
  margin: 0;

  .Icon {
    display: flex;
  }

  & > :last-child {
    margin-left: 0.5rem;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    letter-spacing: -0.02em;
  }

  &:hover {
    color: $of-forest-green-03;
    text-decoration: none;
  }

  .Text {
    flex: 1;
    font-size: 15px;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }
  }
}
