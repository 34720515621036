@import "~styles/base.scss";

/* stylelint-disable no-descending-specificity */

:export {
  contractviewdesktopmin: $contract-view-desktop-min;
  contractviewmobilemax: $contract-view-mobile-max;
}

.AttachmentInfoContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: center;

  .Icons {
    color: black;
  }
}

.AttachmentFileRowInMobile {
  display: flex;
  flex-direction: column;
  padding-left: 0 0 8px 14px;

  div[class*="ActionsContainer"] {
    justify-content: flex-start !important;
  }

  .AttachmentInfoContainer {
    margin: 0 0 0 12px;
  }
}

.FileNameWrapper {
  margin-top: 9px;
  display: inline-block;
  max-width: 215px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  // Needed for cursive fonts to not be cut off
  padding: 5px 4px 5px 2px;
  font-size: var(--contract-static-font-size);
  font-family: var(--contract-static-font-family);

  @media only screen and (max-width: 844px) {
    max-width: 215;
  }

  @media only screen and (min-width: 820px) and (max-width: 1000px) {
    max-width: 500px !important;
  }

  @media only screen and (min-width: $contract-view-desktop-min) and (max-width: 1100px) {
    max-width: 97px !important;
  }

  @media only screen and (min-width: 1100px) and (max-width: 1200px) {
    max-width: 166px !important;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 452px;
  }
}

.AttachmentFileRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.5px 0 2.5px 0;
  height: 35px;
  border-radius: 5px;
}

.Disabled {
  &:hover {
    background-color: $of-legacy-gray !important;
  }
}

.FailedAttachment {
  background-color: rgba($of-red-02, 0.1);
}

.NameFailed {
  color: $of-red-02;
}

.AddByCounterpartyText {
  font-size: 12px;
  margin-left: 2px;
}

.Hovered {
  background-color: $of-edit-hover;
}

.HoveredFailed {
  background-color: rgba($of-red-02, 0.1);
}

.ActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: var(--contract-static-font-family);

  &.Hidden {
    display: none;
  }

  .Hover:hover {
    text-decoration: underline;

    &:focus {
      text-decoration: underline;
    }
  }

  .Hover:focus {
    text-decoration: none;
  }

  .Remove:hover {
    color: $of-red-02;
  }
}

.LinkButton {
  color: $of-forest-green-01 !important;
}

.ImgLoadingContainer {
  text-align: center !important;
}

.Icons {
  display: flex;
  color: $of-forest-green-01;
  margin-right: 3px;

  svg {
    width: 14px;
    height: 14px;
  }
}

.NoDragHandler {
  width: 10px;
}

.DragHandler {
  padding: 0.35rem 0.15rem;
  display: flex;
  cursor: grab;
}

.PopoverContentContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 1030 !important;
  }
}

.PopoverContent {
  background-color: #fff;
  padding: 0 !important;
  width: 300px;
  border-radius: 8px;
  font-size: var(--contract-static-font-size);
  font-family: var(--contract-static-font-family);
}

.PopupHeader {
  border-radius: 8px 8px 0 0;
  padding: 6px 10px;
  background-color: $of-forest-green-01;
  color: #fff;
}

.NameContainer {
  padding: 10px 10px 0 10px;

  input {
    padding: 6px !important;
    height: 28px !important;
  }
}

.ButtonCancel {
  &:hover {
    text-decoration: underline;
  }
}

.ButtonSave {
  background-color: $of-forest-green-03;
  border-color: $of-forest-green-03;
  color: #fff;
  padding: 0 18px;
  height: 38px;

  &:hover {
    background-color: $of-dark-green-01;
    border-color: $of-dark-green-01;
  }
}

.PopoverNameButton {
  padding: 0.35rem 0 !important;
}

.Row {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
