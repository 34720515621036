@import '~styles/base.scss';

.ErrorMessage {
  padding: 0.5rem 0;
  color: $of-passion;
  display: flex;
}

.TemplateSearchContainer {
  margin-top: 1rem;
  transition: height 200ms ease-in-out;

  &.ShowHeader {
    height: 50px;
  }

  &.ShowHeaderWithResults {
    height: 70px;
  }

  &.Hide {
    height: 0;
    overflow: hidden;
  }
}

.TemplateContainer {
  transition: height 200ms ease-in-out;

  &.Hide {
    height: 0;
    overflow: hidden;
  }
}

.Message {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 500;
  color: $of-forest-green-01;
  letter-spacing: -0.02em;
}

.ImportContractPortal {
  /* stylelint-disable-next-line */
  :global(.modal-loading-content) {
    min-height: 6.5rem;
  }
}

.Folder {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  color: $of-forest-green-01;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
  gap: 8px;
  margin-top: 7px;

  @media (max-width: 634px) {
    flex-direction: column;
  }
}

.SelectedFolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  > span {
    display: block;
    color: $of-forest-green-01;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ToggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .ToggleSwitch {
    width: 184px;
  }

  .ToggleLabel {
    margin: 0;
  }

  .AiImportLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.UploadedPdf {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
}

.ImportButton {
  height: 42px;
  transition: width 200ms ease-in-out;
  position: relative;

  &.Widen {
    width: 150px;
  }

  &.Shrink {
    width: 90px;
  }

  .Text {
    transition: display 200ms ease-in-out;

    &.Show {
      display: block;
    }

    &.Hide {
      display: none;
    }
  }
}
