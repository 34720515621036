@import '~styles/base.scss';

.NameContainer {
  padding: 0.3rem 0;
  display: flex;
  align-items: center;
}

.Link {
  display: flex;
}

.Name {
  display: inline-block;
  word-break: break-word;
}
