@import '~styles/base.scss';

.PickedDate {
  display: inline;
  padding: 0;
  font-weight: 600;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  text-decoration: underline;
}

.SelectorContainer {
  display: flex;
  justify-content: center;
  height: 33px;

  button {
    background: transparent;
  }
}

.Select {
  flex: none;
  min-width: 70px;
}

.MonthSelect {
  min-width: 110px;
}

.PopoverContent {
  padding: 0;
  box-shadow: none;
  z-index: 9999;
  font-family: var(--contract-static-font-family);
  font-size: var(--contract-static-font-size);
}
