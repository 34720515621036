@import '~styles/base.scss';

.Body {
  min-height: 100px !important;
  flex: 0 !important;
  padding-top: 2rem !important;
}

.BodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $of-forest-green-01;
  letter-spacing: 0.1px;

  p {
    text-align: center;
  }

  .DocumentState {
    font-weight: 600;
  }
}

.Confirm {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TryAgainButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
