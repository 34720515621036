@import '~styles/base.scss';

.SelfLabel {
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  background: $of-forest-green-01;
  color: #fff;
  padding: 1px 7px;
  font-size: 10px;
  margin-left: 5px;
  vertical-align: top;
}
