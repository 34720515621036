@import '~styles/base.scss';

.DragControls {
  opacity: 0;
  transition: opacity 0.2s;
}

.BoxDivider {
  position: absolute;
  bottom: -16px;
  width: 100%;
  max-width: var(--document-content-max-width);

  &.CollapsedDocumentBoxDivider {
    position: unset;
    padding-top: 3px;
  }
}

.BoxDividerFirst {
  position: absolute;
  top: -15px;
  width: 100%;
  max-width: var(--document-content-max-width);
}

.BoxItem {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    [class*=_BoxMenu--],
    .DragControls {
      opacity: 1;
    }

    .BoxDividerFirst [class*=_BoxMenu--] {
      opacity: 0;
    }
  }

  &.CollapsedDocument {
    padding-bottom: 0;
    padding-top: 0;
  }

  &:not(:last-child) {
    &.ReadOnly {
      margin-bottom: 24px;
    }
  }
}

.Dimmer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $of-light-gray;
  opacity: 0.4;
}
