@import '~styles/base.scss';

.PersonalInfo {
  width: 100%;

  .Info {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .Label {
      display: flex;
      align-items: center;
      color: $of-forest-green-03;
      margin-bottom: 2px;

      .Icon {
        margin-right: 0.5rem;
      }

      .Title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.02em;
      }
    }

    .Property {
      margin: 0 18px;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: $of-forest-green-01;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.EmailIcon {
  margin-right: 6px;
}
