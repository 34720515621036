@import '~styles/base.scss';

.ContractTemplates {
  .NameColumn {
    box-sizing: border-box;
    min-width: 100px;
    max-width: 162px;

    @include respondTo(md) {
      max-width: 270px;
    }

    @include respondTo(lg) {
      max-width: 400px;
    }

    @include respondTo(xl) {
      max-width: 600px;
    }

    @include respondTo(xxl) {
      max-width: 700px;
    }
  }

  .IDColumn {
    min-width: 60px;
  }

  .StatusColumn {
    width: 60px;
    text-align: center !important;
  }

  .CreatedColumn {
    .CreatedCellContainer {
      display: flex;
      flex-direction: column;
      min-width: 100px;
      max-width: 300px;

      .Date {
        padding: 0.3rem 0;
      }

      .Author {
        padding: 0.3rem 0;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .UpdatedColumn {
    .UpdatedCellContainer {
      display: flex;
      flex-direction: column;
      min-width: 100px;
      max-width: 300px;

      .Date {
        padding: 0.3rem 0;
      }

      .Author {
        padding: 0.3rem 0;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .CreateButton {
    display: inline-flex;
    padding: 0 0.2rem 0.1rem 0;
    vertical-align: middle;

    > :first-child {
      align-self: center;
    }

    > :last-child {
      margin-left: 0 !important;
    }
  }

  .ActionLink {
    > :last-child {
      margin-left: 0.25rem !important;
    }
  }
}

.TemplateState {
  max-width: fit-content;
}
