.CancellationNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

  button {
    padding: 0;
  }
}
