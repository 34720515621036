@import '~styles/base.scss';

.Filters {
  display: flex;
  flex-direction: column;

  label {
    margin: 4px 4px 4px 0;
  }

  .Radiobutton {
    margin: 0 4px 0 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff !important; //needed to override bootstrap
    color: $of-forest-green-01;
    width: 1em;
    height: 1em;
    border: none;
    outline: 0.1em solid $of-forest-green-04;
    border-radius: 50%;
    transform: translateY(0.15em);
    display: inline-grid;
    place-content: center;

    &:hover {
      outline-color: $of-forest-green-01;
    }

    &:focus {
      outline: 0.1em solid $of-forest-green-01;
      outline-offset: 0;
    }

    &:disabled {
      cursor: default;
      opacity: 1;
      background-color: $of-inactive !important; //needed to override bootstrap
      outline-color: $of-forest-green-05;

      :hover {
        outline-color: $of-forest-green-05;
      }
    }

    &:checked {
      border: 0.35em solid $of-forest-green-01;
      outline-color: $of-forest-green-01;

      &:hover {
        outline-color: $of-forest-green-01;
      }

      &:disabled {
        border-color: $of-inactive;
        background-color: $of-forest-green-04 !important; //needed to override bootstrap
        outline-color: $of-forest-green-05;

        &:hover {
          outline-color: $of-forest-green-05;
        }
      }
    }
  }
}
