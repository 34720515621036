@import '~styles/base.scss';

.ExtensionConfigurationHeader {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  .SetupSuccess {
    color: $of-peace;
    margin-left: 0.35rem;
  }

  .SetupFailed {
    color: $of-passion;
    margin-left: 0.35rem;
  }

  .SetupLinkButton {
    align-self: flex-start;
  }
}

.ExtensionConfigurationBody {
  margin-top: 0;
  padding-bottom: 10px; /* padding to avoid margins from collapsing */
}
