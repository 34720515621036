@import '~styles/base.scss';

.DataFieldHeader {
  font-size: 1.5rem;
  font-weight: 500;
  color: $of-forest-green-01;
  margin: 0;
}

[class*=TemplateGroupDropdown] {
  width: 100%;
  justify-content: space-between;
}

.NoTemplateGroupEmptyState {
  font-size: 0.875rem;
  margin: 0 auto;
  margin-top: 14px;
  line-height: 1.15;

  .NoTemplateGroupHeader {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .IconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 16px;
    margin-bottom: 16px;
    background-color: $of-inactive;
    height: auto;
    width: auto;

    svg path {
      stroke-width: 0.15 !important;
    }
  }

  .ExtensionLink {
    display: inline;
    padding: 0;
  }
}

.EmptyStateContainer,
.TemplateGroupSectionContainer,
.InputContainer,
.DataFieldSortContainer {
  padding-right: 20px;
  padding-left: 20px;
}

.ScrollAreaTopPadding {
  padding-top: 5px;
}
