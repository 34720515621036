@import '~styles/base.scss';

.Row {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.ProductSumCheckboxOption {
  margin: 2px 0;

  span {
    font-weight: 500;
  }
}

.ProductSumCheckboxOptionsContainer {
  font-weight: 400;
  padding: 15px 15px 0 10px;
}

.ProductSumCheckboxOptions {
  max-height: 200px;
  overflow-y: auto;
  margin: 12px 0;

  span {
    overflow: visible !important;
    white-space: normal !important;
    text-overflow: clip !important;
  }
}

.ColumnInfoWrapper {
  margin-bottom: 10px;
}

.NameContainer {
  padding: 15px 15px 0 10px;
  border-bottom: 1px solid $of-dark-green-04;

  input {
    margin-top: 2px;
    font-weight: 400;
    padding: 6px !important;
    width: 100%;
    height: 28px !important;
    border: 1px solid $of-forest-green-05 !important;
  }
}

.ProductSumEdit {
  height: auto;
  line-height: 1.55;

  input {
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $of-forest-green-01 !important;
    }
  }

  .LabelProductSum {
    font-size: 14px !important;
    color: $of-forest-green-01;
    font-weight: 600 !important;
  }

  .RemoveButtonContainer {
    width: fit-content;
  }
}

.ProductSumInputContainer {
  border: none !important;

  &.Error input {
    color: $of-red-02 !important;
    border: 1px solid $of-red-02 !important;
  }
}

.PopupHeader {
  border-radius: 8px 8px 0 0;
  padding: 10px;
  background-color: $of-forest-green-01;
  color: white;
}

.Title {
  color: $of-forest-green-01;
  font-weight: 600;
  padding-bottom: 10px;
}

.ButtonRemove {
  color: $of-red-02;
  padding: 15px 15px 0 10px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-bottom: 1px;
  }
}

.ButtonCancel {
  &:hover {
    text-decoration: underline;
  }
}

.ButtonSave {
  background-color: $of-forest-green-03;
  border-color: $of-forest-green-03;
  color: #fff;
  padding: 0 18px;
  height: 38px;

  &:hover {
    background-color: $of-dark-green-01;
    border-color: $of-dark-green-01;
  }
}
