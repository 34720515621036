@import '~styles/base.scss';

.SignMenuItem {
  &:hover,
  &:focus-within {
    background-color: $of-green-03;

    button {
      color: $of-green-08;
    }
  }
}
