@import '~styles/base.scss';

.SummationContainerExpanded {
  display: grid;
  padding-top: 8px;
  justify-items: start;
  align-items: start;
  padding-right: 8px;
  grid-template-columns: 1fr;

  &.canAddProduct {
    grid-template-columns: minmax(100px, auto) 1fr;
  }
}

.PriceSummationExpanded,
.PriceSummationResponsive {
  justify-self: end;
}

.SummationContainerResponsive {
  padding-inline-end: 0.25rem;
  padding-inline-start: 0.25rem;
  justify-items: start;
  align-items: start;
  display: grid;
  grid-template-columns: 1fr;

  &.canAddProduct {
    grid-template-columns: minmax(100px, auto) 1fr;
  }

  div::first-line {
    margin-top: 8px;
  }

  button {
    margin-top: 8px;
  }
}
