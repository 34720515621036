@import '~styles/base.scss';

:export {
  // Border colors
  lightgreen: $of-green-03; //Signed
  lightred: $of-red-05; //Declined
  lightblue: $of-blue-05; //Pending
  orange: $of-orange-02; //Overdue
  gray: $of-inactive; //Draft
  darkgray: $of-dark-green-05; //My participant
  bordergreen: $of-sign-border;
}

.Participants {
  color: $of-forest-green-01;
  display: flex;
  align-self: center;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }
}

.Bubbles {
  display: flex;
  justify-content: center;
}

.Participant {
  &:not(:first-child) {
    margin-left: -10px;
  }
}

.SignedOrderBubbles {
  margin-top: 20px;

  & .Participant {
    float: left;
  }

  .Bubbles {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
  }
}

.MyParticipant {
  z-index: 2;
  grid-column-start: 2;

  span {
    font-size: 24px;
  }

  &Bubble {
    width: 66px;

    span {
      margin-left: -64px;
    }

    > svg {
      height: 60px;
      width: 60px;
    }
  }
}

.UnSignedParticipants {
  z-index: 1;
  float: left;
  margin-right: -10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    color: $of-forest-green-03;
    font-size: 18px;
  }
}

.SignedParticipants {
  z-index: 1;
  float: right;
  margin-left: -10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    font-size: 18px;
  }

  :global(.participant-action) {
    margin-top: 23px;
  }
}

.AggregatedInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  min-height: 2rem;
}

.Icon {
  vertical-align: sub;
  padding-right: 3px;
  color: $of-forest-green-01;
}

.ExtraBubbleLabel {
  &White {
    color: white;
  }

  &Black {
    color: $of-forest-green-03;
  }
}

.UnSignedLabel {
  color: $of-forest-green-03;
}

.SignedLabel {
  color: white;
}

.MyParticipantLabel {
  color: $of-forest-green-01 !important;
}

.Badge {
  display: flex;

  &I5 {
    z-index: 5;
  }

  &I4 {
    z-index: 4;
  }

  &I3 {
    z-index: 3;
  }

  &I2 {
    z-index: 2;
  }

  &I1 {
    z-index: 1;
  }
}

.SimpleBubbleUserBadge {
  display: flex;
}
