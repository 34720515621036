@import '~styles/base.scss';

.EmptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 18px 0;
  font-size: 1.25rem;
  text-align: center;
  color: $of-forest-green-01;
  border-radius: 4px;
}

.WithPadding {
  padding: 100px 0;
}

.Header {
  font-size: 24px;
  font-weight: 500;
  margin: 22px 0 8px 0;
  letter-spacing: -0.02em;
  line-height: 28px;
}

.Content {
  line-height: 22px;
  font-size: 16px;
  color: $of-forest-green-01;
  font-weight: 400;
}

.IconContainer {
  display: flex;
  background-color: $of-inactive;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
}
