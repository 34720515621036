@import '~styles/base.scss';

.ReplaceContract {
  & > :nth-child(2) {
    margin-bottom: 20px;
  }

  .Paragraph {
    font-style: italic;
  }
}

.TooltipMessage {
  p {
    margin: 0;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 10px;
  }
}
