@import '~styles/base.scss';

.TextField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  cursor: default !important;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .Label {
    font-weight: 400;
    color: $of-forest-green-01;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.02em;
    padding-bottom: 0.25rem;
    cursor: default !important;
  }

  .InputField {
    display: flex;
    flex: 1;
    color: $of-forest-green-01;
    border-style: none;
    border-color: transparent;
    background-color: transparent;
    padding: 13px;
    font-size: 14px !important;
    line-height: 15px;
    letter-spacing: -0.02em;
    border-radius: 6px;
    margin-bottom: 0;
    height: auto;
    box-shadow: none;
    min-width: 0;

    &.Ellipsis {
      text-overflow: ellipsis;
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    &::-ms-clear {
      display: none;
    }

    &:focus {
      outline-color: transparent;
      outline-style: none;
      box-shadow: none !important;
      color: $of-forest-green-01;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:focus-within,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $of-dark-green-05 inset !important;
      border-radius: 6px !important;
      -webkit-text-fill-color: $of-forest-green-01;
    }

    &::placeholder {
      color: $of-winter-green-dream;
    }
  }

  &.HasIcon {
    input.InputField {
      padding-left: 0;
    }
  }

  &.IsClearable {
    input.InputField {
      padding-right: 0;
    }
  }

  .InputContainer {
    display: flex;
    flex: 1;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    background-color: white;
    border: 1px solid $of-dark-green-04;
    border-radius: 6px;

    &:focus-within {
      border-color: $of-forest-green-01;
    }

    &:hover {
      border-color: $of-dark-green-02;
    }

    &.ReversedDirection {
      input.InputField {
        padding-left: 0.8rem;
      }
    }

    .icon {
      display: flex;
      min-width: 2em;
      align-items: center;
      justify-content: center;
      font-size: 1em;
    }

    .Clearable {
      @extend .icon;

      padding: 0 16px;
      margin: 0;

      &.StaticSize {
        font-size: revert;
      }
    }

    .ActionButton {
      border-radius: 0 0.25em 0.25em 0;
      margin: -1px -1px -1px 0;
    }

    &.Error {
      border: 1px solid $of-red-02;
      color: $of-red-02;

      &:focus-within {
        border: 1px solid $of-forest-green-01;
      }
    }

    &.Disabled {
      background: $of-inactive;
      border-color: $of-inactive;

      input {
        cursor: not-allowed;
        color: $of-forest-green-03;
      }
    }
  }

  .PasswordFieldWithEye {
    align-items: center;

    .icon {
      position: absolute;
    }

    &.ReversedDirection {
      flex-direction: row-reverse;

      input.InputField {
        padding-right: 2.5rem;
      }
    }
  }

  &.SearchField {
    max-width: 500px;

    .InputContainer {
      border-radius: 20px;
    }
  }

  .ErrorMessage {
    color: $of-red-02;
    line-height: 0.75rem;
    font-size: 0.75rem;
    margin: 0.25rem 0 0.25rem 0;
    min-height: 0.85rem;
    cursor: default !important;
    font-weight: 400;
  }

  // Responsive TextFields should use em instead of rem for sizing but since we want
  // all of our TextFields to be responsive this propperty is meant to be a temporary solution
  &.Responsive {
    font-size: 1em;

    .Label {
      padding-bottom: 0.25rem;
      font-size: 0.9em;
    }

    .ErrorMessage {
      line-height: 1em;
    }
  }
}

.EyeIcon {
  color: darkgray;
  height: 18px;
  width: 18px;
}
