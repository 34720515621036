@import '~styles/base.scss';

.Confirmation {
  text-align: center;

  .TextBox {
    display: flex;
    justify-content: center;
    text-align: center;

    .Paragraph {
      width: 340px;
      line-height: 1.2rem;
      margin: 0;
    }
  }

  .HelpCenter {
    color: $of-peace;
  }

  .Icon {
    fill: $of-peace;
  }
}

.Heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
}
