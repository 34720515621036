.Toggle {
  margin-top: -0.3rem;
}

.Header {
  font-weight: 500;
  font-size: 1.1rem;
}

.Row {
  width: 100%;
  max-width: 660px;
}
