@import '~styles/base.scss';

.PhoneNumber {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  cursor: default !important;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  .Label {
    font-weight: 400;
    color: $of-forest-green-01;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.02em;
    padding-bottom: 0.25rem;
    cursor: default !important;
  }

  .InputField {
    display: flex;
    flex: 1;
    color: $of-forest-green-01;
    border-style: none;
    border-color: transparent;
    background-color: transparent;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.02em;
    border-radius: 6px;
    margin-bottom: 0;
    height: auto;
    box-shadow: none;
    min-width: 0;

    .Input {
      transition: none;
      height: 100%;
      width: 100%;
      border-radius: 6px;
      border-color: $of-forest-green-05;
      box-shadow: none;
      padding: 13px 0 13px 48px;

      &::placeholder {
        color: $of-dark-green-02;
        font-size: 14px;
        letter-spacing: -0.02em;
      }
    }

    .FlagDropdown {
      background-color: white;
      border-radius: 6px 0 0 6px;
      border-color: $of-forest-green-05;

      > ul {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 14px;
        margin-top: 0;
        box-shadow: none;
        border-radius: 0 6px 6px 6px;
        border-width: 1px;
        border-style: solid;
        border-color: $of-forest-green-01;
      }
    }

    &:focus {
      outline-color: transparent;
      outline-style: none;
      box-shadow: none !important;
      color: $of-forest-green-01;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:focus-within,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $of-dark-green-05 inset !important;
      border-radius: 6px !important;
      -webkit-text-fill-color: $of-forest-green-01;
    }

    &.Error {
      .Input,
      .FlagDropdown {
        border: 1px solid $of-red-02 !important;
      }
    }

    &.Disabled {
      .FlagDropdown {
        background-color: $of-disable !important;
        border-color: $of-cloud !important;
        color: $of-cloud;
        opacity: 0.3;
        cursor: not-allowed !important;
      }
    }

    &:focus-within:not(.Disabled) {
      .Input,
      .FlagDropdown {
        border-color: $of-forest-green-01 !important;
        box-shadow: none !important;
      }
    }

    &:hover:not(.Disabled) {
      .Input,
      .FlagDropdown {
        border-color: $of-dark-green-02;

        > div {
          border-radius: 6px 0 0 6px;
        }
      }
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      flex: 1 1 auto;
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: $of-dark-green-02;
    }
  }

  .ErrorMessage {
    color: $of-red-02;
    line-height: 0.75rem;
    font-size: 0.75rem;
    margin: 0.25rem 0 0.25rem 0;
    min-height: 0.85rem;
    cursor: default !important;
  }
}
