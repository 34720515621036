@import '~styles/base.scss';

.CreateBlankContract {
  .Button {
    padding-left: 0;

    &:focus,
    &:hover {
      color: $of-forest-green-01;
    }
  }

  .Divider {
    height: 1px;
    margin: 0.6rem 0 1rem 0;
    overflow: hidden;
    border-bottom: 1px dashed $of-forest-green-05;
  }
}
