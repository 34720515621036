@import '~styles/base.scss';

.TemplateGroupLabel {
  font-weight: 500;
  display: inline-block;
  background-color: $of-blue-05;
  word-break: break-word;
  border-radius: 6px;
  padding: 6px 8px;
}

.TemplateGroupHeader {
  margin-bottom: 8px;
}
