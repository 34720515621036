@import '~styles/base.scss';

.Error {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100%;

  .Icon {
    width: 40px;
    height: 40px;
    color: $of-red-02;
    align-self: center;
  }

  .Header {
    font-size: 14px;
    color: $of-red-02;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 26px;
  }

  .Description {
    font-size: 12px;
    color: $of-red-02;
    text-align: center;
    margin-bottom: 4px;
  }

  .Contact {
    font-size: 12px;
    text-align: center;
  }
}
