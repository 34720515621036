@import '~styles/base.scss';

.UploadFailureAlert {
  //border: 1px solid $of-red-02;
  color: $of-red-02;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem 1rem 0.5rem 0;
  align-items: flex-start;
  line-height: 1.5;
  letter-spacing: -0.015rem;

  .UploadFailureText {
    font-weight: 600;
  }
}

.FileMetaData {
  display: flex;
  padding: 0;

  .FileName {
    padding: 0 0.75rem 0 0;
  }
}

.AlertZone {
  margin-bottom: 1rem;

  .UploadingFailure {
    display: flex;
    margin-bottom: 0;
    border: 1px solid $of-red-02;
    border-radius: 5px;
  }
}

.UploadFailureIcon {
  color: $of-red-02;
  width: 0.875rem;
  height: 0.875rem;
  padding: 1rem;
  align-self: center;
}
