@import '~styles/base.scss';

.EventLog {
  display: flex;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: $of-forest-green-01;
  font-size: 14px;
  margin-bottom: 4px;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: -ms-grid;
    -ms-grid-columns: 1fr 0.6fr;

    :nth-child(1) {
      -ms-grid-column: 1;
    }

    :nth-child(2) {
      -ms-grid-column: 2;
    }
  }

  .EventValue {
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02em;
    margin-left: auto;
  }

  .Label {
    font-style: normal;
    font-weight: 600;
  }
}
