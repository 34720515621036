@import '~styles/base.scss';

.PageContainer {
  /* This could be a prop to the <Table> component, but we override it here instead for now */
  table {
    padding: 0;
  }
}

.InactiveTokenWarning {
  color: $of-red-02;
  margin-left: 5px;
  margin-top: -3px;
  vertical-align: middle;
}
