@import '~styles/base.scss';

.Container {
  :global(.recharts-tooltip-cursor) {
    visibility: hidden !important;
  }
}

.YAxisLabelButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;

  .Label {
    color: $of-winter-green-dream;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      color: $of-forest-green-02;
    }
  }

  .DocumentCount {
    color: $of-forest-green-01;
    font-weight: 500;

    &:hover {
      color: $of-forest-green-02;
    }
  }

  &.Static {
    cursor: default;

    .Label {
      &:hover {
        color: $of-winter-green-dream;
      }
    }

    .DocumentCount {
      &:hover {
        color: $of-forest-green-01;
      }
    }
  }
}
