.TableToolbar {
  position: absolute;
  top: -58px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 11;
  width: 362px;
  left: calc(50% - 181px);
  background: #fff !important;
  margin: 0 !important;
  box-shadow: 0 0 16px rgba(1, 58, 76, 0.2) !important;
  border-radius: 4px !important;
  user-select: none;

  > div {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
