@import '~styles/base.scss';

.Container {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.ParticipantInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ParticipantName {
  color: $of-forest-green-01;
  font-size: 1.125rem;
  font-weight: 600;
}

.Badge {
  padding: 4px 12px;
  border-radius: 20px;
  background-color: $of-forest-green-03;
  color: white;
}

.Company {
  color: $of-forest-green-01;
  font-size: 0.813rem;
  font-weight: 400;
}

.ParticipantTitle {
  color: $of-winter-green-dream;
  font-size: 0.813rem;
  font-weight: 400;
}

.Divider {
  border: 0;
  opacity: 0.5;
  background-color: $of-forest-green-04;
  width: 100%;
  height: 0.5px;
}

.PenIcon {
  color: $of-winter-green-dream;
  margin-top: 1px;
}

.SignMethodLabelIconContainer {
  display: flex;
  gap: 6px;
  align-items: center;
}

.SignMethodLabel {
  color: $of-winter-green-dream;
  font-weight: 500;
  font-size: 0.75rem;
}

.SignMethodText {
  margin: 0 18px;
  color: $of-forest-green-01;
  font-size: 0.75rem;
  font-weight: 500;
}

.SignMethodLogo {
  height: 48px;
  width: 48px;
}

.SignMethodTextImageContainer {
  display: flex;
  align-items: center;
  gap: 18px;
}

.ParticipantNameBadgeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
