@import '~styles/base.scss';

.ProductSumButton {
  border: none;
  padding: 0;
  width: 100%;
  display: block;

  &:hover,
  &.GreenBackground {
    background-color: $of-legacy-peace;
  }
}

.ProductSumContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 5px;
  min-height: 30px;
  border-bottom: 1px solid $of-light-gray;
  color: var(--formatting-font-color);
  font-family: var(--formatting-font-family);
  font-size: var(--formatting-font-size);

  span {
    width: 100% !important;
  }

  p {
    margin-bottom: 0;
    display: inline;
  }
}

.Name {
  font-weight: 600;
  text-align: start;
}

.Sum {
  text-align: end;
}

.Name,
.Sum {
  white-space: normal;
  word-break: break-word;
}

.ProductSumFullPrice {
  text-decoration: line-through;
  margin-right: 8px !important;
}

.PopoverContent {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  max-height: 100vh;
  max-width: 365px;
  overflow: auto;
  padding: 0;

  @media only screen and (orientation: portrait) and (max-width: 700px) {
    max-height: 85vh;
    max-width: none !important;
    min-width: calc(100vw - 30px);
  }

  [class*=PopoverArrow] {
    display: none !important;
  }
}
