@import '~styles/base.scss';

.AIAssistButton {
  color: $of-forest-green-01;
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border-style: none;
  font-size: 14px;
  min-width: 40px;
  width: 40px;
  white-space: nowrap;

  svg {
    height: 20px !important;
    width: 40px !important;
  }
}
