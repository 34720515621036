@import '~styles/base.scss';

.SideMenu {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: 1 1 auto;
  }

  &.Mobile {
    padding: 0 12px;
  }
}

.WrapperDiv {
  &.Mobile {
    padding: 0 12px;
    margin-top: 10px;
  }
}
