@import '~styles/base.scss';

.PopoverContainer {
  [data-radix-popper-content-wrapper] {
    z-index: 9999 !important;
  }

  .Popover {
    width: 360px;
    padding: 8px 4px;
    transform-origin: var(--radix-popover-content-transform-origin);
    animation: scaleIn 0.15s ease-out;
  }
}
