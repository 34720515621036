.TagsContainer {
  justify-content: flex-start;
  font-size: 0.8rem;

  .Tags {
    align-items: center;
    overflow: hidden;
  }

  &.Responsive {
    display: flex;

    .Tags {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
