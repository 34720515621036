$spacing-page: 1.5rem;

$break-xs: 25rem;
$break-sm: 31rem;
$break-md: 52.5rem;
$break-lg: 80rem;
$break-xl: 120rem;
$break-xxl: 160rem;
$sidebar-width: 72px;
$contract-view-desktop-min: 1000px;
$contract-view-mobile-max: 999px;
$a4-width: 793px;

// Example:
// .MyClass {
//   @include respondTo(xs) {
//     width: 100px;
//   }
//   @include respondTo(sm) {
//     width: 200px;
//   }
//   @include respondTo(md) {
//     width: 300px;
//   }
//   @include respondTo(lg, max) {
//     width: 400px;
//   }
//   @include respondTo(xl, max) {
//     width: 500px;
//   }
// }

@mixin orientation($value) {
  @media only screen and (orientation: $value) {
    @content;
  }
}

@mixin respondTo($breakpoint, $breaktype:min) {
  @if $breaktype == max {
    // activate if width is smaller than break point

    @if $breakpoint == xs {
      @media only screen and (max-width: $break-xs) {
        @content;
      }
    }

    @else if $breakpoint == sm {
      @media only screen and (max-width: $break-sm) {
        @content;
      }
    }

    @else if $breakpoint == md {
      @media only screen and (max-width: $break-md) {
        @content;
      }
    }

    @else if $breakpoint == lg {
      @media only screen and (max-width: $break-lg) {
        @content;
      }
    }

    @else if $breakpoint == xl {
      @media only screen and (max-width: $break-xl) {
        @content;
      }
    }

    @else if $breakpoint == xxl {
      @media only screen and (max-width: $break-xxl) {
        @content;
      }
    }
  }

  @else {
    // activate if width is wider than break point

    @if $breakpoint == xs {
      @media only screen and (max-width: $break-xs) {
        @content;
      }
    }

    @else if $breakpoint == sm {
      @media only screen and (min-width: $break-sm) {
        @content;
      }
    }

    @else if $breakpoint == md {
      @media only screen and (min-width: $break-md) {
        @content;
      }
    }

    @else if $breakpoint == lg {
      @media only screen and (min-width: $break-lg) {
        @content;
      }
    }

    @else if $breakpoint == xl {
      @media only screen and (min-width: $break-xl) {
        @content;
      }
    }

    @else if $breakpoint == xxl {
      @media only screen and (min-width: $break-xxl) {
        @content;
      }
    }
  }
}

// With new breakpoints
@mixin respondToNewBreakPoint($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: 30rem) {
      @content;
    }
  }

  @if $breakpoint == small {
    @media (max-width: 36rem) {
      @content;
    }
  }

  @if $breakpoint == medium {
    @media (max-width: 48rem) {
      @content;
    }
  }

  @if $breakpoint == large {
    @media (max-width: 64rem) {
      @content;
    }
  }
}

@mixin flex($flex-grow, $flex-shrink: null) {
  @if ($flex-shrink == null) {
    $flex-shrink: $flex-grow;
  }

  flex: $flex-grow $flex-shrink;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    flex: $flex-grow $flex-shrink auto;
  }
}

// Colors
$of-forest-green-01: #013a4c;
$of-forest-green-02: #346170;
$of-forest-green-03: #678994;
$of-forest-green-04: #99b0b7;
$of-forest-green-05: #d6dfe2;
// https://www.color-name.com/hex/587a85
$of-winter-green-dream: #587a85;

$of-green-01: #3cc885;
$of-green-02: #b6e0cc;
$of-green-03: #e6faea;
$of-green-04: #f5fff6;
$of-green-05: #f3f6f6;
$of-green-06: #dbf9e1;
$of-green-07: #ecf9f3;
$of-green-08: #018445;
$of-green-09: #f2f4f4;
$of-green-10: #00a958;
$of-green-11: #1a846e;
$of-green-12: #9eefc8;
$of-green-13: #006421;
$of-green-14: #f7fffb;
$of-green-15: #288c49;
$of-green-16: #84ae9a;

$of-dark-green-01: #002839;
$of-dark-green-02: #678994;
$of-dark-green-03: #99b0b7;
$of-dark-green-04: #d6dfe2;
$of-dark-green-05: #e6ebed;
$of-dark-green-06: #e2e2e2;

$of-yellow: #ffd063;
$of-yellow-02: #ffdc8a;
$of-yellow-03: #c39427;
$of-yellow-04: #dfb043;
$of-accent-yellow-02: #fff3d9;

$of-pink-01: #f9eef2;
$of-pink-02: #f79bbc;
$of-plum-01: #89285b;
$of-plum-02: #b73d8b;
$of-plum-03: #cb70aa;
$of-plum-04: #e0a8cc;
$of-plum-05: #f2dce4;
$of-red-01: #99221f;
$of-red-02: #d13d47;
$of-red-03: #db686f;
$of-red-04: #eaa4a4;
$of-red-05: #f7dcd7;
$of-red-06: #fff0f0;
$of-red-07: #f4d5d0;
$of-red-08: #f3c9c1;
$of-red-09: #ffecec;
$of-red-10: #f8edf1;
$of-red-11: #ffc6c9;
$of-red-12: #fff8f9;

$of-blue-01: #0f5987;
$of-blue-02: #187bb7;
$of-blue-03: #509dd1;
$of-blue-04: #8ec4e3;
$of-blue-05: #d3e8f4;
$of-blue-06: #eaf7ff;
$of-blue-07: #cbeaff;
$of-blue-08: #f3faff;

$of-orange: #f16923;
$of-orange-01: #db873a;
$of-orange-02: #fbe4ca;
$of-orange-03: #ffeedf;
$of-orange-04: #ffd4ac;
$of-orange-05: #fff9f4;

$of-purple: #a539d9;
$of-medium-purple: #c074e4;
$of-light-purple: #bd96ed;
$of-purple-01: #6b2cb7;
$of-purple-02: #f2ebfc;
$of-purple-03: #8638e5;

$of-cloud: #d2d2d2;
$of-reign: #7f7f7f;
$of-thunder: #3e4658;
$of-pitch: #262626;
$of-lavender: #716aca;
$of-peace: #80c351;
$of-blues: #47a5dd;
$of-passion: #ca2128;
$of-raspberry: #9b1f61;
$of-light-gray: #ebedf2;
$of-gray: #999;
$of-video-gray: #676767;
$of-disable: #f7f7f7;
$of-dark-blue: #164a7e;
$of-inactive: #f2f6f8;
$of-edit-hover: #fff3d8;

// Contract states
$of-contract-draft: $of-reign;
$of-contract-pending: $of-blues;
$of-contract-overdue: $of-orange;
$of-contract-overdue-new: #ef9b4e;
$of-contract-declined: $of-passion;
$of-contract-signed: #3cc182;
$of-sign-border: #d3f2de;
$of-sign-hover: #28b471;

// Legacy Colors
$of-legacy-dark-gray-2: #454752;
$of-legacy-dark-gray: #555a6e;
$of-legacy-gray: #f9f9f9;
$of-legacy-light-gray: #c2c6cb;
$of-legacy-light: #969aa6;
$of-legacy-lighter: #aaacb8;
$of-legacy-lightest: #d9d9d9;
$of-legacy-blue: #446cc7;
$of-legacy-peace: #eff7e9;

// New sign button colors
$of-document-sign-button-green: #47e599;
$of-document-sign-button-green-hover: #5dcb96;

// Typography
// -------------------------
$sans-font-family: "Helvetica Neue", helvetica, arial, sans-serif;
