@import '~styles/base.scss';

.ContractAlert {
  color: $of-passion;
  padding-right: 3px;
  height: 20px;
}

.AlertHeader {
  color: $of-passion;
  font-weight: 600;
}
