@import '~styles/base.scss';

.Dashboard {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
  justify-content: space-between;

  .Header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    .Title {
      color: $of-forest-green-01;
      font-size: 30px;
      font-weight: 600;
    }
  }

  .WorkspaceContainer {
    align-items: center;
    color: $of-forest-green-01;
    display: flex;
    font-size: 13px;
    font-weight: 500;

    svg {
      margin-right: 6px;
    }
  }

  .SignStatistics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: $spacing-page;
    height: calc(300px + 20vmin);
  }

  .EmptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    border-radius: 8px;
    color: $of-forest-green-01;
  }

  .Filters {
    display: flex;
    justify-content: space-between;

    & > :first-child {
      overflow: visible;
    }
  }

  .AgreementKPIs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: $spacing-page;
  }
}
