@import '~styles/base.scss';

.DateRangePickerInput__withBorder,
.SingleDatePickerInput__withBorder {
  border-radius: 6px;
  border: 1px solid $of-dark-green-04;

  &:focus-within {
    border-color: $of-forest-green-01;
  }

  &:hover {
    border-color: $of-dark-green-02;
  }

  .DateInput_input {
    line-height: 26px;
    font-size: 13px;
    font-weight: normal;
    padding-left: 0;
    color: $of-forest-green-01;

    &::placeholder {
      color: $of-winter-green-dream;
      opacity: 1;
    }
  }

  .DateInput_1 {
    border-radius: 6px;
    margin-left: 10px;
    max-width: 90px;

    &:focus-within {
      .DateInput_input {
        &::placeholder {
          color: $of-forest-green-01;
        }
      }
    }
  }

  .DateInput_input__focused {
    border-color: $of-forest-green-01;
  }

  .DateRangePickerInput_clearDates__small {
    padding: 0 6px;
    background-color: transparent;
    margin: 0;

    svg {
      vertical-align: baseline;
      fill: $of-dark-green-02;
    }

    &:hover {
      svg {
        fill: $of-dark-green-03;
      }
    }
  }

  .SingleDatePickerInput_clearDate {
    padding: 0;
    width: 35px;
    height: 35px;
  }
}

.CalendarDay {
  color: $of-forest-green-01;
  font-size: 11px;
  line-height: 12px;
  background-color: $of-inactive;
  border-color: $of-dark-green-05;
  font-weight: 600;

  &:hover {
    background-color: $of-light-gray;
  }

  &.CalendarDay__selected {
    background-color: $of-yellow;
    border-color: $of-yellow;
  }

  &.CalendarDay__hovered_span,
  &.CalendarDay__selected_span {
    background-color: $of-edit-hover;
    border-color: $of-edit-hover;
    color: $of-forest-green-01;
  }
}

.CalendarMonth_caption {
  padding-top: 2px;
  font-size: 14px;
  color: $of-forest-green-01;
}

.DayPicker {
  border-radius: 8px;
  box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
}

.DayPicker_weekHeader {
  padding: 5px 13px !important;
  font-size: 9px;
  line-height: 12px;
  color: $of-forest-green-01;
  top: 50px;

  > ul {
    height: 21px;
    display: flex;
    align-items: center;

    > li > small {
      font-weight: 600;
    }
  }
}

.DayPickerNavigation_button {
  border-color: $of-light-gray;
  border-radius: 4px;
}

.CalendarMonth_caption > strong {
  font-weight: 500;
}

.DashboardDateRange {
  .DateInput_input { // stylelint-disable-line no-descending-specificity
    cursor: pointer;
    font-weight: 300;
    flex: 1;
    padding: 0;
    text-align: center;
    line-height: 16px;
    font-size: 14px;
    color: $of-dark-green-02;
    border-width: 1px;

    &:hover { // stylelint-disable-line no-descending-specificity
      border-color: $of-forest-green-01;
      color: $of-forest-green-01;
    }
  }

  .DateInput_input__focused {
    border-color: $of-forest-green-01;

    &:hover {
      border-color: $of-forest-green-01;
    }
  }

  .DateInput__small {
    width: 80px;
    padding: 0 10px;
  }

  .DateRangePickerInput_arrow {
    display: inline-block;
    color: $of-dark-green-02;
  }
}

.DateRangePicker_picker,
.DayPicker_transitionContainer {
  min-height: 261px;
  border-radius: 8px;
}

.DayPickerNavigation {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
}

.DateInput_fangStroke {
  stroke: transparent;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  color: #cacccd;
}

input::-webkit-input-placeholder {
  color: $of-winter-green-dream;
}
