@import "styles/base.scss";

.CommandMenuDialogContent {
  transition: transform 100ms ease;

  * {
    box-sizing: border-box;
  }

  [cmdk-list] {
    height: min(349px, var(--cmdk-list-height));
    max-height: 400px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
  }

  [cmdk-group]:first-child {
    padding-top: 4px;
  }

  [cmdk-group-heading] {
    font-size: 0.75rem;
    font-weight: normal;
    color: $of-forest-green-03;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0;
    pointer-events: none;
  }

  [cmdk-input] {
    margin: 0;
    font-size: 1rem !important;
    height: 56px !important;
    padding: 13px;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    flex-grow: 1;
    border-bottom: 1px solid $of-forest-green-05;
  }

  [cmdk-item] {
    display: flex;
    align-items: center;
    padding: 4px;
    background: none;
    border: 0;
    outline: none;
    box-sizing: border-box;
    font-size: 0.875rem;

    .Text {
      display: flex;
      gap: 8px;
    }

    .Right {
      margin-left: auto;
    }

    svg {
      flex-shrink: 0;
    }

    .Subtitle {
      color: $of-forest-green-04;
      font-size: 0.75rem;
    }
  }

  [cmdk-shortcuts] {
    display: flex;
    margin-left: auto;
    gap: 8px;

    kbd {
      --gray-02: #e1eaee;

      background-color: var(--gray-02);
      color: $of-dark-green-01;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  [cmdk-empty] {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
  }
}

.Padding {
  height: 4px;
  width: 100%;
}
