.SessionExpiration {
  text-align: center;
  letter-spacing: -0.02em;

  p {
    margin: 0;
  }
}

.Icon {
  padding: 16px 0;
}
