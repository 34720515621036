@import '~styles/base.scss';

.FadeInPlayback {
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.Playback {
  max-height: inherit;
  max-width: inherit;
  height: 100%;
  width: 100%;
}

.Play {
  height: 0;
  position: relative;
  top: calc(50% - 3rem);
  text-shadow: 0.5rem;
  margin: 0;
  line-height: 5rem;
  align-self: center;
  z-index: 1000;

  @include respondTo(md, max) {
    top: calc(50% - 1.5rem);
  }

  .PlayButton {
    width: 6rem;
    height: 6rem;
    border-style: none;
    border-image: initial;
    border-radius: 50%;
    padding: 0.35rem 0.75rem 0.35rem 1.4rem;
    cursor: pointer;

    svg {
      height: 3rem;
      width: 3rem;
    }

    @include respondTo(md, max) {
      width: 3rem;
      height: 3rem;
      padding-right: 1.1rem;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

.RetakeContainer {
  height: 0;
  position: relative;
  top: -3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  color: white;

  @include respondTo(md, max) {
    top: -2rem;
  }

  .Rounded {
    border: 2px solid rgba($of-video-gray, 0.4);
    border-radius: 50%;
    margin-bottom: 10px;
  }
}

.Playbutton,
.RetakeContainer > .Rounded {
  background-color: rgba($of-video-gray, 0.4);
  color: white;

  &:hover {
    background-color: rgba($of-video-gray, 0.6);
  }
}
