@import '~styles/base.scss';

.ToolbarButton {
  color: $of-forest-green-01;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 0;
  width: 24px;
  height: 24px;

  &:hover {
    background-color: $of-dark-green-06;
  }

  &.Disabled {
    color: $of-forest-green-05;
  }

  &.Active {
    color: $of-orange-01;
    background-color: $of-orange-02;

    &:hover {
      background-color: $of-orange-03;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.ToolbarMenuItem {
  margin: 2px 4px;

  &.Disabled button {
    color: $of-dark-green-02;
  }

  &.Active {
    background-color: $of-orange-02;
    color: $of-orange-01;
    border-radius: 3px;

    button {
      color: $of-orange-01 !important;

      &:hover {
        background-color: $of-orange-03;
      }
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.ToolbarButtonPopoverTrigger {
  /* defaultPluginButtonWith is 40 in components/contract-text-editor/contract-text-editor-toolbar/responsive-toolbar-plugins.jsx */
  min-width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;
}

.ToolbarButtonTooltipTrigger {
  margin-left: 8px !important;
  margin-right: 8px !important;
  margin-bottom: 0 !important;
}

.TooltipText {
  line-height: 14px;
  border-radius: 5px !important;
  background-color: $of-forest-green-01 !important;
  color: white !important;
  text-align: center;
  font-size: 0.75rem;
  width: auto;
  margin: 12px 2px 2px 2px !important;
}
