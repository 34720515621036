@import '~styles/base.scss';

.Header {
  margin: 0 32px 20px 0;
}

.BodyMessage {
  text-align: left;
  width: 100%;

  td:not(:last-child) {
    padding-right: 12px;
    white-space: nowrap;
  }
}

.SemiBold {
  font-weight: 600;
}

.NoticeBox {
  margin-top: 10px;
  margin-bottom: 22px;
}

.BulletList {
  ul {
    margin-left: 1.5rem;
    list-style-type: disc;
  }
}

.SeparateParagraph {
  display: block;
}

.NoticePeriod {
  color: $of-red-02;
}

.WithInitialDuration {
  color: $of-forest-green-01;
}

.withInitialDurationInfoBox {
  margin-top: 22px;
}

.DateStyle {
  text-decoration: underline;
  cursor: help;
}

.OpenModalButton {
  margin: 5px 0 5px 10px;
  color: $of-forest-green-01;
  text-decoration: none;
  font-size: 14px;
  font-weight: inherit;
  align-self: flex-start;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
