@import '~styles/base.scss';

:export {
  forest-green: $of-forest-green-01;
}

.ExpandableOptionsItem {
  padding: 10px 10px 12px 20px;
  border-radius: 7px;

  &.Hidden {
    display: none;
  }

  .ItemHeader {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 16px;
    color: $of-forest-green-01;
    align-items: center;

    .TitleWihTooltip {
      display: flex;
    }

    span {
      margin-right: 5px;
    }

    label div {
      align-self: flex-end !important;
    }
  }

  .ItemDescription {
    font-size: 14px;
    line-height: 16px;
    color: $of-forest-green-03;
    padding-right: 60px;
  }

  &.Enabled {
    &:hover {
      background-color: $of-edit-hover;
      cursor: pointer;
    }
  }

  &.Disabled {
    cursor: not-allowed;

    .ItemHeader,
    .ItemDescription {
      color: $of-forest-green-04;
    }
  }

  .InfoBoxContainer {
    margin-top: 15px;
    background-color: #fff9f4;
    color: $of-forest-green-01;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    position: relative;

    .ExclamationCircle {
      position: absolute;
      top: 5px;
      left: 5px;
      color: #ef9b4e;
    }

    .InfoBoxText {
      padding-left: 23px;
      margin: 0;
      line-height: 19px;
    }
  }
}

.Divider {
  margin: 8px 0;
}

.AdditionalOptions {
  padding: 10px 9px 12px 9px;
  margin: 0 -10px;
}
