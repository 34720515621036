@import '~styles/base.scss';

.SpinnerContainer {
  margin: auto;
}

.ErrorMessage {
  color: $of-passion;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  .ErrorIcon {
    margin: 0 0.5rem 0 0;
  }
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  min-height: 40px;
  flex: 1 1;

  button {
    margin: 0 12px;
  }
}
