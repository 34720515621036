@import '~styles/base.scss';

.AIAssistWrapper {
  position: relative;
  padding-top: 15px;
}

.AIAssistContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.SubjectField {
  margin-bottom: 10px;
}

.AIButton {
  margin: 10px 0 13px 0;
}

.Message {
  display: flex;
  flex-direction: row;
  margin: 15px 0 20px 0;

  .MessageItem {
    >label {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.2px;
    }
  }
}
