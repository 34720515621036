.Access {
  .Name {
    display: inline-block;
    word-break: break-all;
    word-break: break-word;
  }

  .ActionButton {
    margin-right: 0.5rem;
  }

  .ContentHeader {
    margin: 2rem;

    .Header {
      margin-top: 0;
      font-weight: 600;
      font-size: 1.1rem;
    }
  }

  .GrantAccessButton {
    display: inline-flex;
    padding: 0 0.2rem 0.1rem 0;
    vertical-align: middle;

    > :first-child {
      align-self: center;
    }

    > :last-child {
      margin-left: 0 !important;
    }
  }

  .Buttons {
    display: flex;
    margin: auto 0;
  }

  .ActionLink {
    > :last-child {
      margin-left: 0.25rem !important;
    }
  }
}
