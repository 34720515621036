@import '~styles/base.scss';

body {
  font-family: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: $of-thunder;
}

a {
  color: $of-forest-green-01;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
