@import '~styles/base.scss';

.VideoUpload {
  margin: 1rem;

  @include respondTo(md, max) {
    margin: 0.5rem;
  }

  user-select: none;
}

.Dropzone {
  width: 100%;
  border: 2px dashed $of-cloud;
  border-radius: 4px;
  padding: 4rem;
  box-sizing: border-box;
  color: $of-cloud;
  cursor: pointer;

  @include respondTo(md, max) {
    padding: 0.5rem;
  }
}

.DropzoneInvalid {
  width: 100%;
  border: 1px solid $of-passion;
  border-radius: 4px;
  padding: 4rem;
  box-sizing: border-box;
  color: $of-cloud;
  cursor: pointer;
  background-color: white;

  @include respondTo(md, max) {
    padding: 0.5rem;
  }
}

.DropzoneActive {
  border: 2px solid $of-cloud;
  color: $of-cloud;
  background-color: white;
}

.DropzoneReject {
  color: $of-passion;
  background-color: white;
  border: 2px dashed $of-passion;
  cursor: not-allowed;
}
