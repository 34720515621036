@import '~styles/base.scss';
@import 'react-big-calendar/lib/sass/styles.scss';

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rbc-overlay {
  max-width: 400px;
  z-index: 999;
  animation: fadeInUp 0.4s ease forwards;
  border: none;
  border-radius: 5px;
  overflow: auto;
  max-height: 300px;
}

.rbc-calendar {
  display: block;

  @media screen and (min-height: 1250px) {
    display: flex;
  }
}

.rbc-row-content {
  height: 100%;
  z-index: 0;
}

.rbc-events {
  background-color: transparent;
  padding: 0;
}

.rbc-row-segments {
  padding: 0;
}

.rbc-event {
  background-color: transparent !important;
  padding: 1px 5px;

  .rbc-selected {
    background-color: transparent !important;
  }

  &:focus {
    outline: none !important;
  }
}

.rbc-header {
  border-left: 0 !important;
  color: white;
  background-color: $of-forest-green-01;
  font-weight: normal;
  padding: 6px 0;
  font-size: 11px;
}

.rbc-overlay-header {
  padding: 8px 10px;
}

.rbc-date-cell {
  padding: 2px 2px 0 0;

  > span {
    display: inline-flex;
    padding: 4px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 11px;
    height: 20px;
    min-width: 20px;
    pointer-events: none;
    color: black;
  }

  &.rbc-now {
    font-weight: normal;

    & > span {
      color: white;
      background-color: $of-forest-green-01;
    }
  }
}

.rbc-month-view {
  border: 1px solid $of-inactive;

  *:focus {
    outline: none;
  }
}

.rbc-month-row {
  min-height: 138px;

  & + .rbc-month-row {
    border-top: 1px solid $of-inactive;
  }
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid $of-inactive;
}

.rbc-show-more {
  font-weight: normal;
  font-size: 10px;
  margin-top: -4px;

  @media screen and (min-height: 1250px) {
    position: absolute;
    bottom: 0;
  }
}

.rbc-toolbar-label {
  padding: 0 195px 0 0 !important;
  text-align: left !important;
  margin-left: 15px;
  font-weight: 600;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-off-range-bg {
  background-color: $of-inactive;
}
