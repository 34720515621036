@import '~styles/base.scss';

body {
  background-color: $of-forest-green-05;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: $of-forest-green-05;
}

.Iframe {
  box-shadow: 0 4px 16px 0 #0000001a;
  border-radius: 8px;
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
}

.IframeWrapper {
  width: calc(100% - 96px);
  height: calc(100% - 88px); //preview header height + 20px
  margin: 0 auto;
  padding: 0 48px;
  display: flex;
  justify-content: center;

  &:has(.MobileIframe) {
    padding-bottom: 20px;
  }
}

.MobileIframe {
  width: 390px;
  height: 810px;
  transform: scale(0.8);
  transform-origin: top;
  border-radius: 38px;
}
