.List {
  border-radius: 8px;
  background: white;
  box-shadow:
    rgba(15, 15, 15, 0.05) 0 0 0 1px,
    rgba(15, 15, 15, 0.1) 0 3px 6px,
    #0f0f0f33 0 9px 24px;
  width: 320px;
  isolation: isolate;
  display: flex;
  flex-direction: column;
  max-height: min(40vh, 320);
  overflow-y: auto;

  .SubList {
    width: 220px;
  }

  .Group {
    padding: 6px 0;
  }

  .GroupHeading {
    display: flex;
    padding: 0 14px;
    margin-top: 6px;
    margin-bottom: 8px;
    color: rgba(55, 53, 47, 0.65);
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 120%;
    user-select: none;
  }

  .Item {
    user-select: none;
    cursor: pointer;
    margin: 0 4px;
    padding: 0 12px;
    border-radius: 4px;
    height: 28px;
    display: flex;
    align-items: center;

    .Icon {
      margin-left: auto;
    }

    kbd {
      display: none;
      margin-left: auto;
    }

    &[data-selected="true"],
    &[data-state="open"],
    &:focus {
      background: rgba(55, 53, 47, 0.08);

      kbd {
        display: block;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .Separator {
    width: 100%;
    height: 1px;
    background-color: rgba(55, 53, 47, 0.09);
  }

  .Button {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    background-color: transparent;
    height: 100%;
    padding: 0 8px;

    .Icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid rgba(55, 53, 47, 0.16);
    }

    &:hover {
      background-color: rgba(55, 53, 47, 0.08);
    }

    &.Active {
      .Icon {
        background: rgb(45, 45, 46);
      }
    }
  }
}
