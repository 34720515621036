@import "~styles/base.scss";

.ToastDescriptionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .InfoTextWrapper {
    display: flex;
    flex-direction: column;

    .Title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .Button {
    margin-left: 10px;
    color: $of-forest-green-01;
    background-color: #fff;
    border: 1px solid $of-video-gray;
    width: 57px;
    height: 32px;
    border-radius: 6px;
  }
}
