@import '~styles/base.scss';

.MostActiveUsers {
  width: 100%;
  margin-top: $spacing-page;
  background-color: white;
  box-shadow: 0 0 16px rgba($of-forest-green-01, 0.2);
  border-radius: 8px;
  color: $of-forest-green-01;

  .Divider {
    height: 1px;
    margin: 0 0 1rem 0;
    overflow: hidden;
  }

  .MostActiveUsersHeader {
    display: flex;
    align-items: center;
    padding: 30px;
    font-size: 19px;
    font-weight: 500;
    line-height: 22px;
  }

  .Name {
    word-break: break-all;
    word-break: break-word;
  }
}
