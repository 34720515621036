@import '~styles/base.scss';

:root {
  --of-forest-green-03: #{$of-forest-green-03};
}

.Row {
  display: flex;
  margin: 0.25rem 0;

  .WorkspaceField {
    max-width: 49.25%;
  }

  [class*=InputField] {
    height: 14px;
  }

  > * {
    flex: 1;

    + * {
      margin-left: 1rem;
    }
  }
}

:global(.ReactSelect__control) {
  min-height: 42px;
}

.WorkspaceRow {
  margin: 0 0 0.25rem 0;
}

.GroupSection {
  width: 50%;

  label {
    margin-right: 0.45rem;
  }
}

.Description {
  color: $of-forest-green-03;
  margin-bottom: 5px;
}

.CheckboxDescription {
  color: $of-forest-green-03;
  margin-bottom: 8px;
}

.Title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.15;
  margin: 14px 0 18px 0;
}

.FirstTitle {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.15;
  margin: 8px 0 14px 0;
}

.SectionTitleAccount {
  font-weight: 500;
  font-size: 1rem;
  margin: 18px 0 8px 0;
}

.SectionTitleWorkspaceGroups {
  font-weight: 500;
  font-size: 1rem;
  margin: 0 0 6px 0;
}

.AccountRoleField {
  width: 50%;

  label {
    margin-right: 0.45rem;
  }
}

.AccountRoleCheckbox {
  width: 50%;
  margin-bottom: 18px;
}

.ActionButtons {
  display: flex;
  justify-content: flex-end;
  min-height: 40px;
  flex: 1 1;

  button {
    margin: 0 12px;
  }
}

@media screen and (max-width: 576px) {
  .Row {
    flex-direction: column;

    .WorkspaceField {
      max-width: unset;
    }

    > * + * {
      margin-left: 0;
    }
  }

  .AccountRoleField,
  .AccountRoleCheckbox,
  .GroupSection {
    width: 100%;

    > label {
      margin-right: 0;
    }
  }
}
