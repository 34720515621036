@import '~styles/base.scss';

.FolderName {
  box-sizing: border-box;
  color: $of-forest-green-01 !important;
  font-weight: 600;
  max-width: 100%;
  overflow-wrap: break-word;
}

.Icon {
  color: $of-forest-green-01;
  margin-top: 15px;
  margin-bottom: 35px;
}

.TemplateIcon {
  margin-bottom: 20px;
}

.ConfirmMessage {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}

.folderInfoText {
  margin-bottom: 20px;
}

.FolderButton {
  padding: 0;
  display: inline-block;
}

.TemplateModalWrapper {
  text-align: left;
  padding: 8px;

  .TemplateModalText {
    display: block;
  }
}

.RedWarningIcon {
  margin-top: 20px;
  margin-bottom: 30px;
  color: $of-red-02;
}

.Actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  .CancelButton {
    margin-right: 15px;
  }
}

.TemplateList {
  padding: 10px 20px;
  border-radius: 4px;
  background-color: $of-dark-green-05;

  .TemplateId {
    margin-left: 5px;
  }
}
