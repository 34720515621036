@import '~styles/base.scss';

.LabelContainer {
  display: flex;

  &:not(.NextToLabel) {
    justify-content: space-between;
  }

  &.NextToLabel > svg {
    margin-left: 6px;
  }
}

.FieldInfoTooltip {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li:not(:last-of-type) {
      margin-bottom: 0.55rem;
    }
  }
}

.Required {
  font-size: 16px;
  color: $of-red-02;
}
