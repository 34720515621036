@import '~styles/base.scss';

.DragHandlerWrapper {
  left: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  z-index: 200;
}

.DragWrapper {
  margin: 0 -18px;
  padding: 0 20px;
  position: relative;

  &:hover {
    .DragHandlerWrapper {
      visibility: visible;
    }
  }

  &.Disabled {
    &:hover {
      .DragHandlerWrapper {
        visibility: hidden;
      }
    }
  }
}

.IconWrapper {
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  color: black;
  cursor: grab;
  display: flex;
  height: 12px;
  padding: 6px;

  &:hover {
    background: $of-edit-hover;
    border-color: $of-yellow;
  }
}

.Icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 4px;
}

.IsDragging {
  background-color: $of-edit-hover;
  z-index: 199;

  > div {
    background-color: $of-edit-hover;
  }
}

.IsDragging .IconWrapper {
  cursor: grabbing;
}
