@import '~styles/base.scss';

.AddRowButtonContainer {
  opacity: 0;
  position: absolute;
  left: -30px;
  bottom: -11px;
}

.ProductSumBoxContainer {
  margin-bottom: 10px;

  &:hover .AddRowButtonContainer {
    opacity: 1;
  }
}

.ProductSummation {
  margin: 0 20px;
  position: relative;

  button {
    * {
      font-family: var(--formatting-font-family);
      font-size: var(--formatting-font-size);
    }
  }

  [class*=EmptyState] * {
    font-family: var(--document-static-font-family);
    font-size: var(--document-static-font-size);
  }

  [data-radix-popper-content-wrapper] {
    z-index: 9999 !important;
  }

  [class*=ProductSumEdit] * {
    font-family: var(--contract-static-font-family);
    font-size: var(--contract-static-font-size) !important;
  }
}
