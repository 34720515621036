/* stylelint-disable unit-no-unknown */
@import '~styles/base.scss';

.DocumentTab {
  background-color: $of-inactive;
  overflow: hidden;
  height: 100%;

  .DocumentTopContainer {
    height: var(--collapsed-document-top-bar-height);
    border-bottom: 1px solid $of-forest-green-05;
    transition: height 300ms ease-in-out;

    &.ShowToolbar {
      height: calc(var(--collapsed-document-top-bar-height) + var(--collapsed-document-toolbar-height) + 2px); //Adding 2px for the border from DocumentTopbar
      border-bottom-color: transparent;
    }

    &.ShowBanner {
      height: calc(var(--collapsed-document-top-bar-height) + var(--document-state-banner-height));
    }

    &.ShowBannerAndToolbar {
      height: calc(var(--collapsed-document-top-bar-height) + var(--collapsed-document-toolbar-height) + var(--document-state-banner-height) + 2px); //Adding 2px for the border from DocumentTopbar
    }

    .DocumentTopbar {
      background-color: #fff;
      border-bottom: 1px solid $of-forest-green-05;
      font-size: 1.25rem;
      height: var(--collapsed-document-top-bar-height);
      position: relative;

      // Temporary when there is no topbar
      p {
        margin: unset;
      }
    }

    .Toolbar {
      height: var(--collapsed-document-toolbar-height);
      border-bottom: 1px solid $of-forest-green-05;
    }
  }

  .Document {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    height: var(--collapsed-document-body-without-toolbar-height);
    overscroll-behavior: contain;

    &.ShowToolbar {
      height: var(--collapsed-document-body-with-toolbar-height);
    }

    &.ShowBanner {
      height: calc(var(--collapsed-document-body-without-toolbar-height) - var(--document-state-banner-height));
    }

    &.ShowBannerAndToolbar {
      height: calc(var(--collapsed-document-body-with-toolbar-height) - var(--document-state-banner-height));
    }
  }
}

.DocumentContentContainer {
  padding: 22px 0;

  [class*=ContractId] {
    padding-bottom: 20px;
  }
}

.DocumentStateBanner {
  min-height: 40px;
  overflow: hidden;
}
